import React from "react";
import styled from "styled-components";

const RedIconStyles = styled.i`
  color: var(--thunderbird);
  font-size: 14px;
`;

const RedExclamationCircle = () => (
  <RedIconStyles className="fa fa-exclamation-circle" />
);

export default RedExclamationCircle;
