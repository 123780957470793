import styled from "styled-components";

const ProfileSubTable = styled.table`
  border-spacing: 0;
  border-width: 0;
  width: 100%;

  tr th {
    color: var(--dove-gray);
  }

  tr {
    th,
    td {
      padding: 0;
      padding-top: 3px;
      font-size: 13px;
    }
  }
`;

export default ProfileSubTable;
