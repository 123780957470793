import { css } from "styled-components";

export const BaseButtonStyles = css`
  // Styles shared by all button states states
  cursor: pointer;
  display: inline;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  line-height: var(--button-line-height);
  border-style: solid;
  border-radius: var(--button-border-radius);
  border-width: var(--button-border-width);
  padding: var(--button-padding);

  // Normal styles
  background: var(--button-background);
  border-color: var(--button-border-color);
  color: var(--button-text-color);

  &:focus,
  &:hover {
    background: var(--button-focus-background);
    border-color: var(--button-border-color);
    color: var(--button-text-color);
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    background: var(--button-disabled-background);
    border-color: var(--button-disabled-border-color);
    color: var(--disabled-text-color);

    cursor: auto;
    opacity: 1;

    &:focus,
    &:hover {
      background: var(--button-disabled-background);
      border-color: var(--button-disabled-border-color);
      color: var(--disabled-text-color);
    }
  }
`;

export const BlueButtonStyles = css`
  --button-border-width: 1px;
  --button-background: var(--denim);
  --button-text-color: var(--white);
  --button-border-color: transparent;

  --button-focus-background: var(--matisse);
  --button-focus-text-color: var(--white);
  --button-border-color: transparent;
`;

export const ModernBlueButtonStyles = css`
  --button-font-size: 12px;
  --button-padding: 8px 20px;
  --button-disabled-border-color: transparent;
`;
