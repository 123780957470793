// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title___r5tzY{font-size:15px;line-height:17px}.catalogNumber___Bv5H1{font-weight:normal}", "",{"version":3,"sources":["webpack://./src/react/components/_academics/ClassInformationCard/ClassInformationCard.module.scss"],"names":[],"mappings":"AAKA,eAJE,cAAA,CACA,gBAAA,CAOF,uBACE,kBAAA","sourcesContent":["@mixin title2 {\n  font-size: 15px;\n  line-height: 17px;\n}\n\n.title {\n  @include title2;\n}\n\n.catalogNumber {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title___r5tzY",
	"catalogNumber": "catalogNumber___Bv5H1"
};
export default ___CSS_LOADER_EXPORT___;
