'use strict';

var _ = require('lodash');

/**
 * Finaid Communications controller
 */
angular.module('calcentral.controllers').controller('FinaidCommunicationsController', function($q, $scope, csLinkFactory, finaidService) {
  $scope.communicationsInfo = {
    csLinks: {},
    aidYear: '',
    isLoading: true,
    taskStatus: '!completed'
  };

  var getVerificationAndAppealsLink = csLinkFactory.getLink({
    urlId: 'UC_CX_FA_COMM_FAFSA'
  }).then(
    function successCallback(response) {
      $scope.communicationsInfo.csLinks.verificationAndAppeals = _.get(response, 'data.link');
    }
  );

  var getOptionalDocumentsLink = csLinkFactory.getLink({
    urlId: 'UC_CX_FA_COMM_FORMS'
  }).then(
    function successCallback(response) {
      $scope.communicationsInfo.csLinks.optionalDocuments = _.get(response, 'data.link');
    }
  );

  var getOptionalDocumentsUploadLink = function(options) {
    return csLinkFactory.getLink({
      urlId: 'UC_CX_FA_FORM_UPLOAD',
      placeholders: {
        'AID_YEAR': options.finaidYearId
      }
    }).then(
      function successCallback(response) {
        $scope.communicationsInfo.csLinks.optionalDocumentsUpload = _.get(response, 'data.link');
      }
    );
  };

  var loadCommunications = function() {
    $scope.communicationsInfo.aidYear = finaidService.options.finaidYear;
    var finaidYearId = finaidService.options.finaidYear.id;
    $q.all([
      getOptionalDocumentsUploadLink({
        finaidYearId: finaidYearId
      }),
      getVerificationAndAppealsLink,
      getOptionalDocumentsLink
    ])
    .then(function() {
      $scope.communicationsInfo.isLoading = false;
    }
    );
  };

  $scope.$on('calcentral.custom.api.finaid.finaidYear', loadCommunications);
});
