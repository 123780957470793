'use strict';

/**
 * Footer controller
 */
angular.module('calcentral.controllers').controller('FooterController', function(csLinkFactory, serverInfoFactory, $ngRedux, $scope) {
  $scope.footer = {
    showInfo: false
  };

  $ngRedux.subscribe(() => {
    let state = $ngRedux.getState();
    const {
      publicLinks: {
        UC_CC_CLC_SPLASH_ABOUT: aboutLink,
        UC_CC_CLC_SPLASH_SUPPORT: supportLink,
        UC_CC_CLC_SPLASH_USAGE_POLICY: usagePolicyLink,
      } = {},
    } = state;

    $scope.footerLinks = {
      aboutLink,
      supportLink,
      usagePolicyLink,
    }
  });

  var loadServerInformation = function() {
    serverInfoFactory.getServerInfo().then(
      function successCallback(response) {
        angular.extend($scope, response.data);
      }
    );
  };

  var loadPublicLinks = function() {
    csLinkFactory.getPublicLinks();
  };

  $scope.$watch('footer.showInfo', function(showInfo) {
    if (showInfo && !$scope.versions) {
      loadServerInformation();
    }
  });

  loadPublicLinks();
});
