import React from "react";
import DashedList from "components/ui/DashedList";
import Pipe from "components/ui/Pipe";
import WithAPILink from "components/ui/WithAPILink";
import HasAccessTo from "./HasAccessTo";
import ItemIfAccess from "./ItemIfAccess";

export default function Form1098T() {
  return (
    <ItemIfAccess urlID="UC_FA_FINRES_1098T">
      <WithAPILink urlID="UC_FA_FINRES_1098T" />
      <HasAccessTo urlIDs={["UC_FA_FINRES_1098T_VIEW"]}>
        <>
          <Pipe />
          <strong>
            <WithAPILink urlID="UC_FA_FINRES_1098T" />
          </strong>
        </>
      </HasAccessTo>

      <HasAccessTo urlIDs={["UC_FA_FINRES_1098T_VIEW"]}>
        <DashedList>
          <li>Use Site ID 11554</li>
        </DashedList>
      </HasAccessTo>
    </ItemIfAccess>
  );
}
