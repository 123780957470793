import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";

import "./SemestersSummary.scss";

export const SemestersSummary = () => {

  const {
    hasStudentHistory,
    hasLawRole,
    hasLawJointDegree,
    semesters,
    totalPreviousCareerCumUnits,
    totalPreviousCareerLawUnits,
    totalLawUnits,
    totalUnits,
  } = useSelector((state) => {
    const { myAcademics, myStatus } = state;

    const { semesters } = myAcademics;

    const {
      hasStudentHistory,
      roles: { law: hasLawRole },
      academicRoles: {
        current: { lawJointDegree: hasLawJointDegree },
      },
    } = myStatus;

    const {
      academicProfile: {
        units: {
          totalUnits,
          totalLawUnits,
          totalPreviousCareerCumUnits,
          totalPreviousCareerLawUnits,
        } = {},
      } = {},
    } = currentUserState(state) || {};

    return {
      hasLawJointDegree,
      hasLawRole,
      hasStudentHistory,
      semesters,
      totalLawUnits: totalLawUnits || 0,
      totalPreviousCareerCumUnits: totalPreviousCareerCumUnits || 0,
      totalPreviousCareerLawUnits: totalPreviousCareerLawUnits || 0,
      totalUnits: totalUnits || 0,
    };
  });

  const showSummary =
    semesters.length &&
    hasStudentHistory &&
    (hasLawRole || totalLawUnits > 0 || hasLawJointDegree);

  let summaryTotalLawUnits = totalLawUnits;
  let summaryTotalUnits = totalUnits;
  if (hasLawJointDegree) {
    summaryTotalLawUnits = totalLawUnits + totalPreviousCareerLawUnits;
    summaryTotalUnits = totalUnits + totalPreviousCareerCumUnits;
  }

  if (showSummary) {
    return (
      <div
        className="SemestersSummary"
        data-testid="academic-summary-semester-summary"
      >
        <div className="cc-table">
          <table className="table cc-class-enrollments">
            <caption>
              <h4>Summary</h4>
            </caption>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th className="text-right cc-academic-summary-table-units">
                  Un.
                </th>
                <th className="text-right cc-academic-summary-table-units">
                  Law Un.
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <td
                  colSpan="2"
                  scope="row"
                  className="text-right cc-academic-summary-table-units"
                >
                  Earned Total:
                </td>
                <td className="text-right cc-academic-summary-table-units">
                  {summaryTotalUnits && (
                    <strong>{parseFloat(summaryTotalUnits).toFixed(1)}</strong>
                  )}
                </td>
                <td className="text-right cc-academic-summary-table-units">
                  {summaryTotalLawUnits && (
                    <strong>
                      {parseFloat(summaryTotalLawUnits).toFixed(1)}
                    </strong>
                  )}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
