import React from "react";
import styled from "styled-components";

import sortDescActive from "icons/sort-desc-active.svg";
import sortDescInactive from "icons/sort-desc-inactive.svg";

const SortArrowStyles = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: var(--small-spacing);

  img:first-child {
    transform: scaleY(-1);
    margin-bottom: 2px;
  }
`;

export const SortAsc = () => (
  <SortArrowStyles>
    <img src={sortDescActive} alt="" />
    <img src={sortDescInactive} alt="" />
  </SortArrowStyles>
);

export const SortDesc = () => (
  <SortArrowStyles>
    <img src={sortDescInactive} alt="" />
    <img src={sortDescActive} alt="" />
  </SortArrowStyles>
);

export const SortInactive = () => (
  <SortArrowStyles>
    <img src={sortDescInactive} alt="" />
    <img src={sortDescInactive} alt="" />
  </SortArrowStyles>
);
