import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SemesterSections } from "./SemesterSections";
import { SemesterAwardHonors } from "./SemesterAwardHonors";
import { useRoles } from "components/hooks/useRoles";
import { MediumSpacer } from "components/ui/VerticalSpacers";

export const Semester = ({ semester }) => {
  const isLawStudent = useRoles((roles) => roles.law);

  const {
    hasEnrolledClasses: isEnrolled,
    hasWithdrawalData: hasWithdrawn,
    hasStudyProgData: hasStudyProg,
    studyProg,
    name,
    slug,
    withdrawalStatus,
  } = semester;

  if (isLawStudent && hasWithdrawn) {
    return null;
  } else {
    return (
      <SemesterStyles data-testid="academic-summary-semester">
        <SemesterHeader>
          {isEnrolled && !hasWithdrawn && (
            <h4 data-testid="academic-summary-semester-header">
              <a className="cc-left" href={`/academics/semester/${slug}`}>
                {name}
              </a>
            </h4>
          )}

          {(hasWithdrawn || !isEnrolled) && <h4 className="cc-left">{name}</h4>}
          {hasWithdrawn && (
            <div
              className="cc-left cc-academics-semester-status"
              style={{ marginTop: "3px" }}
              data-testid="academic-summary-semester-withdrawal-status"
            >
              {withdrawalStatus.withcnclTypeDescr}{" "}
              {withdrawalStatus.withcnclFromDate}
            </div>
          )}

          {hasStudyProg && (
            <span
              className="cc-left cc-academics-semester-status"
              style={{ marginTop: "3px" }}
              data-testid="academic-summary-semester-study-program-type-description"
            >
              {studyProg.studyprogTypeDescr}
            </span>
          )}
        </SemesterHeader>
        <MediumSpacer />
        {isEnrolled && !hasWithdrawn && (
          <SemesterSections semester={semester} />
        )}
        <SemesterAwardHonors semester={semester} />
        {slug == "spring-2020" && isLawStudent && (
          <div>
            <strong>
              *Due to COVID-19, law school classes were graded as credit/no pass
              in spring 2020.
            </strong>
          </div>
        )}
      </SemesterStyles>
    );
  }
};

Semester.propTypes = {
  semester: PropTypes.object,
};

const SemesterStyles = styled.div`
  border: 1px solid var(--silver);
  padding: var(--md) var(--lg);

  .cc-class-enrollments {
    margin-top: 15px;
  }

  .cc-academic-summary-table-units {
    padding-right: 15px !important;
  }
`;

const SemesterHeader = styled.div`
  overflow: hidden;
`;
