import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { getMyStatus } from "data/slices/myStatus";
import { fetchWebMessages } from "data/actions/webMessagesActions";
import { isLoaded } from "data/utils/statusHelpers";

import APILink from "components/ui/APILink";

import "./FinancialAidArchiveLink.scss";

const FinancialAidArchiveLink = ({ label }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWebMessages());
    dispatch(getMyStatus());
  }, [dispatch]);

  const {
    archiveURL,
    archiveURLLoaded,
    canSeeCSLinks,
    isStudent,
    isApplicant,
    statusLoaded,
  } = useSelector((state) => {
    const {
      myWebMessages: {
        universityNotifications: { archiveURL } = {},
        loaded: archiveURLLoaded,
      },
      myStatus: {
        roles: { student: isStudent, applicant: isApplicant },
        canSeeCSLinks: canSeeCSLinks,
        loadState: statusLoadState,
      },
    } = state;

    const statusLoaded = isLoaded(statusLoadState);

    return {
      archiveURL,
      archiveURLLoaded,
      canSeeCSLinks,
      isStudent,
      isApplicant,
      statusLoaded,
    };
  });

  if (statusLoaded && archiveURLLoaded && archiveURL) {
    return (
      <div className="FinancialAidArchiveLink">
        <div className="WidgetSubtitle">
          <h3>Archive</h3>
        </div>

        <div className="ArchiveLink">
          {canSeeCSLinks && (isStudent || isApplicant) && (
            <APILink link={{ ...archiveURL, title: label }} text={label} />
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

FinancialAidArchiveLink.propTypes = {
  label: PropTypes.string,
};

export default FinancialAidArchiveLink;
