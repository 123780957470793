import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router } from "react-router-dom";
import { ReduxProvider } from "components/data/ReduxProvider";
import { AcademicSummaryPage } from "components/pages/AcademicSummaryPage";

const NgAcademicSummary = () => (
  <ReduxProvider>
    <Router>
      <AcademicSummaryPage />
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("academicSummary", react2angular(NgAcademicSummary));
