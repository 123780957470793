import styled from "styled-components";
import "icons/blue-bullet.svg";

const BulletList = styled.ul`
  margin: 0;
  padding: 0;

  > li {
    position: relative;
    list-style-type: none;
    line-height: 20px;
    margin-left: 16px;
  }

  > li:before {
    content: "";
    position: absolute;
    top: 7px;
    left: -16px;
    width: 6px;
    height: 6px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' fill='%2361889E'/%3E%3C/svg%3E%0A");
  }
`;

export default BulletList;
