// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskTitle___BRyNh{flex:1}.taskTitle___BRyNh.overdue___RFhkz{color:var(--thunderbird)}.title___Di3JV{font-weight:bold}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/TaskTitle.module.scss"],"names":[],"mappings":"AAKA,mBACE,MAAA,CAEA,mCACE,wBANW,CAUf,eACE,gBAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n@import 'src/react/stylesheets/mixins';\n\n$danger-color: var(--thunderbird);\n\n.taskTitle {\n  flex: 1;\n\n  &.overdue {\n    color: $danger-color;\n  }\n}\n\n.title {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskTitle": "taskTitle___BRyNh",
	"overdue": "overdue___RFhkz",
	"title": "title___Di3JV"
};
export default ___CSS_LOADER_EXPORT___;
