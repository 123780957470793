// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("icons/academicprogressreport.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("icons/question-solid.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("icons/tools.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("icons/calculator.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".linkContainer{margin-bottom:15px}.linkTitle{color:var(--dove-gray);display:flex;font-size:13px;font-weight:bold}.linkSubTitle{color:var(--mine-shaft);font-size:11px;font-weight:normal}.linkSubText{padding-left:20px}.icon{background-position-y:center;background-repeat:no-repeat;background-size:12px 11px;color:var(--dove-gray);padding-left:20px}.iconApr{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.iconAprWhatIf{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.iconDegreePlanner{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.iconGradeEstimator{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.noPaddingLeft{padding-left:0}", "",{"version":3,"sources":["webpack://./src/react/components/_academics/DegreeProgress/DegreeProgressLinks.scss"],"names":[],"mappings":"AAAA,eACE,kBAAA,CAGF,WACE,sBAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CAGF,cACE,uBAAA,CACA,cAAA,CACA,kBAAA,CAGF,aACE,iBAAA,CAGF,MACE,4BAAA,CACA,2BAAA,CACA,yBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,SACE,wDAAA,CAGF,eACE,wDAAA,CAGF,mBACE,wDAAA,CAGF,oBACE,wDAAA,CAGF,eACE,cAAA","sourcesContent":[".linkContainer {\n  margin-bottom: 15px;\n}\n\n.linkTitle {\n  color: var(--dove-gray);\n  display: flex;\n  font-size: 13px;\n  font-weight: bold;\n}\n\n.linkSubTitle {\n  color: var(--mine-shaft);\n  font-size: 11px;\n  font-weight: normal;\n}\n\n.linkSubText {\n  padding-left: 20px;\n}\n\n.icon {\n  background-position-y: center;\n  background-repeat: no-repeat;\n  background-size: 12px 11px;\n  color: var(--dove-gray);\n  padding-left: 20px;\n}\n\n.iconApr {\n  background-image: url('icons/academicprogressreport.svg');\n}\n\n.iconAprWhatIf {\n  background-image: url('icons/question-solid.svg');\n}\n\n.iconDegreePlanner {\n  background-image: url('icons/tools.svg');\n}\n\n.iconGradeEstimator {\n  background-image: url('icons/calculator.svg');\n}\n\n.noPaddingLeft {\n  padding-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
