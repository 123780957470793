import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TabListContext = createContext();

export const TabList = ({ defaultTab, children }) => {
  const tabState = useState(defaultTab);

  return (
    <TabListContext.Provider value={tabState}>
      {children}
    </TabListContext.Provider>
  );
};

TabList.displayName = "TabList";
TabList.propTypes = {
  defaultTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const ButtonGroup = styled.div`
  font-weight: bold;
  cursor: pointer;

  display: flex;
  justify-content: center;

  button {
    flex: 1;
    border: 1px solid #ccc;
    padding: 5px;

    font-size: 13px;
    font-weight: 400;

    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:first-child {
      border-top-left-radius: var(--small-border-radius);
      border-bottom-left-radius: var(--small-border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--small-border-radius);
      border-bottom-right-radius: var(--small-border-radius);
    }

    &[aria-selected="true"] {
      background: var(--foam);
      color: var(--denim);
      border-color: var(--denim);
    }

    &[aria-selected="false"] {
      background: var(--wild-sand);
      color: var(--dove-gray);

      &:hover {
        color: var(--denim);
      }
    }
  }
`;

export const TabListButtonGroup = ({ children }) => (
  <ButtonGroup role="tablist">{children}</ButtonGroup>
);

TabListButtonGroup.displayName = "TabListButtonGroup";
TabListButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TabListButton = ({ children, panelId, testid }) => {
  const [currentTab, setCurrentTab] = useContext(TabListContext);

  return (
    <button
      role="tab"
      aria-controls={`tabpanel-${panelId}`}
      aria-selected={currentTab === panelId}
      onClick={() => setCurrentTab(panelId)}
      data-testid={testid}
    >
      {children}
    </button>
  );
};

TabListButton.displayName = "TabListButton";
TabListButton.propTypes = {
  children: PropTypes.node,
  panelId: PropTypes.string,
  testid: PropTypes.string,
};

export const TabPanel = ({ children, id }) => {
  const [currentTab] = useContext(TabListContext);

  return (
    <div id={`tabpanel-${id}`} role="tabpanel" hidden={currentTab !== id}>
      {children}
    </div>
  );
};

TabPanel.displayName = "TabPanel";
TabPanel.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};
