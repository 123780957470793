import { createSlice } from "@reduxjs/toolkit";
import { slice as configSlice } from "./config";

const currentUID = createSlice({
  name: "currentUID",
  initialState: null,
  extraReducers: {
    [configSlice.actions.success]: (_state, action) => {
      return action.payload.uid;
    },
  },
});

const { reducer } = currentUID;

export default reducer;
