import { useRef, useState, useEffect } from "react";

export default function usePopover() {
  // Create a DOM node reference to connect to the popover element
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  // Checks if a click event is inside the bounds of the popover. If not,
  // closes the popover by setting popoverOpen -> false.
  const closeOnOutsideClick = (e) => {
    if (ref.current) {
      const isInside = ref.current.contains(e.target);
      setIsOpen(isInside);
    }
  };

  const closeOnEscape = (e) => {
    if (e.key == "Escape") {
      close();
    }
  };

  // Attach an event listener to the DOM to forward click events to this
  // component's outsideClick handler.
  //
  // The return value of useEffect is the cleanup function to be run when the
  // component is unmounted.
  useEffect(() => {
    document.addEventListener("mousedown", closeOnOutsideClick);
    return () => removeEventListener("mousedown", closeOnOutsideClick);
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", closeOnEscape);
    return () => removeEventListener("keyup", closeOnEscape);
  });

  return {
    isOpen,
    close,
    open,
    ref,
  };
}
