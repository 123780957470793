import { buildUserSlice } from "data/utils/buildSlice";
import { userDataThunk } from "data/utils/thunks";
import { advisingStudentAcademicProfileQuery } from "data/queries/advisingStudentAcademicProfile";
import { userGQLRequest } from "data/utils/requests";

const key = "advisingStudentAcademicProfile";
export const slice = buildUserSlice(key);
export const { reducer: advisingStudentAcademicProfile } = slice;
export const fetchAdvisingStudentAcademicProfile = userDataThunk(key, slice, {
  request: userGQLRequest(advisingStudentAcademicProfileQuery),
});
