import React from "react";
import PropTypes from "prop-types";

import { TransferCreditContainer } from "./TransferCreditContainer";
import TransferCreditTitle from "./TransferCreditTitle";
import { AcademicSummaryTable } from "../AcademicSummaryTable";

const byTermDescending = (a, b) => (a.termId >= b.termId ? -1 : 1);

const propTypes = {
  detailed: PropTypes.array,
  summary: PropTypes.object,
  semesters: PropTypes.array,
  isStudent: PropTypes.bool,
  reportLink: PropTypes.object,
};

const LawTransferCredit = ({ detailed, summary, isStudent, reportLink }) => {
  if (detailed && summary) {
    const transferCredit = detailed.sort(byTermDescending);

    return (
      <TransferCreditContainer
        className="TransferCredit cc-transfer-credit-summary"
        data-testid="law-transfer-credit"
      >
        <TransferCreditTitle
          description={summary.careerDescr}
          isStudent={isStudent}
          reportLink={reportLink}
        />

        {transferCredit.length > 0 && (
          <AcademicSummaryTable>
            <thead>
              <tr>
                <th>Institution</th>
                <th className="text-right">Law Units</th>
              </tr>
            </thead>
            <tbody>
              {transferCredit.map((transfer, index) => (
                <tr key={index}>
                  <td>
                    {transfer.school}

                    {transfer.requirementDesignation && (
                      <div className="cc-requirements-designation">
                        {transfer.requirementDesignation}
                      </div>
                    )}

                    <div className="cc-transfer-credit-summary__semester-posted">
                      Posted {transfer.termDescription}
                    </div>
                  </td>
                  <td className="TranferCredit__unit-count">
                    {transfer.lawUnits.toFixed(3)}
                  </td>
                </tr>
              ))}

              {summary && (
                <tr>
                  <td className="text-right">Totals:</td>
                  <td className="TranferCredit__unit-total">
                    {summary.totalTransferUnitsLaw.toFixed(3)}
                  </td>
                </tr>
              )}
            </tbody>
          </AcademicSummaryTable>
        )}
      </TransferCreditContainer>
    );
  }

  return null;
};

LawTransferCredit.propTypes = propTypes;

export default LawTransferCredit;
