import React from "react";
import PropTypes from "prop-types";

import { shortDateIfCurrentYear, parseDate } from "functions/formatDate";

import CampusSolutionsIcon from "../Icons/CampusSolutionsIcon";
import SectionHeader from "../SectionHeader";

import Task from "../Task";
import TaskHeader from "../TaskHeader";
import TaskTitle from "../TaskTitle";

import styles from "./CategorySection.module.scss";

const CategorySection = ({
  categoryKey,
  categorySection,
  children,
  columns,
  items,
}) => {
  return (
    <div className={styles.categorySection}>
      {categorySection === "beingProcessed" && (
        <div className={styles.beingProcessedDivider}>
          <span>{categorySectionLabel(categoryKey)}</span>{" "}
          <span className={styles.Badge}>{items.length}</span>
        </div>
      )}

      <SectionHeader
        columns={columns}
        leftBorder={categorySection === "beingProcessed"}
      />

      <div className={styles.tasks}>
        {children
          ? children
          : items.map((task, index) => (
              <Task
                key={index}
                index={index}
                task={task}
                type={categorySection}
              >
                <TaskHeader task={task}>
                  <CampusSolutionsIcon />
                  <TaskTitle
                    title={task.title}
                    subtitle={`${task.status} ${shortDateIfCurrentYear(
                      parseDate(task.assignedDate)
                    )}`}
                  />
                </TaskHeader>
              </Task>
            ))}
      </div>
    </div>
  );
};

CategorySection.propTypes = {
  categoryKey: PropTypes.string,
  categorySection: PropTypes.string,
  children: PropTypes.node,
  columns: PropTypes.array,
  items: PropTypes.array,
};

CategorySection.defaultProps = {
  columns: [],
};

const categorySectionLabel = (categoryKey) => {
  switch (categoryKey) {
    case "residency":
      return "Received, Being Reviewed";
    default:
      return "Being processed";
  }
};

export default CategorySection;
