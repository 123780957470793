import React, { useState } from "react";
import { useFeatures } from "components/hooks/useFeatures";

export const HubTermLegacyNote = () => {
  const hubTermApiEnabled = useFeatures((f) => f.hubTermApi);
  const [showMore, setShowMore] = useState(false);

  if (hubTermApiEnabled) {
    return (
      <span
        style={{ display: `block`, paddingBottom: `4px`, paddingLeft: `15px` }}
        data-testid="hub-term-legacy-note"
      >
        <strong>Note: </strong>
        Enrollment data for current term and back to Spring 2010 (where
        applicable) is displayed.&nbsp;
        {showMore ? (
          <>
            If enrollments exist in terms prior to Spring 2010, the data will be
            displayed in Summer 2017. If you require a full record now, please
            order a transcript.
          </>
        ) : (
          <button
            className="cc-button-link"
            onClick={() => setShowMore(!showMore)}
          >
            Show more
          </button>
        )}
      </span>
    );
  } else {
    return null;
  }
};
