import React from "react";
import PropTypes from "prop-types";
import ReduxProvider from "components/ReduxProvider";
import SemestersCard from "components/SemestersCard/SemestersCard";
import { react2angular } from "react2angular";

const NgSemestersCard = ({ isAdvisingView }) => (
  <ReduxProvider>
    <SemestersCard isAdvisingView={isAdvisingView} />
  </ReduxProvider>
);

NgSemestersCard.propTypes = {
  isAdvisingView: PropTypes.bool,
};

angular
  .module("calcentral.react")
  .component("studentSemesters", react2angular(NgSemestersCard));
