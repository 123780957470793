import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";

import Popover, { usePopover } from "components/Popover";
import ButtonAsLink from "components/ui/ButtonAsLink";
import InfoIcon from "components/InfoIcon";
import WithMessage from "components/WithMessage";

const Levels = () => {
  const { levels, isCurrentSummerVisitor } = useSelector((state) => {
    const {
      myStatus: {
        academicRoles: {
          current: { summerVisitor: isCurrentSummerVisitor } = {},
        } = {},
      } = {},
    } = state;

    const { academicProfile: { latestLevels: levels = [] } = {} } =
      currentUserState(state) || {};

    return {
      levels: levels || [],
      isCurrentSummerVisitor,
    };
  });

  const ugrdLevelPopover = usePopover();

  if (!isCurrentSummerVisitor && levels.length) {
    return (
      <tr>
        <th>
          Level
        </th>
        <td>
          {levels.map((level, index) => (
            <div key={index}>
              <span>
                {level.description}
                {level.careerCode === 'UGRD' && (
                  <>
                    &nbsp;
                    <ButtonAsLink onClick={ugrdLevelPopover.open}>
                      <InfoIcon />
                    </ButtonAsLink>
                    <div style={{ position: `relative` }}>
                      {ugrdLevelPopover.isOpen && (
                        <WithMessage set="28000" number="322">
                          {(message) => (
                            <Popover
                              ref={ugrdLevelPopover.ref}
                              title={message.text}
                              close={ugrdLevelPopover.close}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: message.description,
                                }}
                              ></span>
                            </Popover>
                          )}
                        </WithMessage>
                      )}
                    </div>
                  </>
                )}
              </span>
            </div>
          ))}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

Levels.displayName = "Levels";
export default Levels;
