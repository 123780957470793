import PropTypes from "prop-types";
import { connect } from "react-redux";

export default connect(mapStateToProps)(WithAPILinkData);

function mapStateToProps({ apiLinks = {} }, { urlID }) {
  return { link: apiLinks[urlID] };
}

// Given a urlID, returns a API link (if found) from the Redux store in
// render-props style to the children.
function WithAPILinkData({ children, link }) {
  if (link) {
    return children(link);
  }

  return null;
}

WithAPILinkData.propTypes = {
  children: PropTypes.func,
  link: PropTypes.object,
};
