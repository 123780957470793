import React from "react";
import PropTypes from "prop-types";

import styles from "./Button.module.scss";

function Button({ disabled, onClick, children, style }) {
  return (
    <button
      style={style}
      className={styles.button}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Button;
