'use strict';

/**
 * Admin Factory
 */
angular.module('calcentral.factories').factory('adminFactory', function(apiService, $http) {
  var actAsUrl = '/act_as';
  var advisorActAsUrl = '/advisor_act_as';
  var delegateActAsUrl = '/delegate_act_as';
  var userByAnyIdUrl = '/api/search_users/';
  var storedUsersUrl = '/api/view_as/my_stored_users';
  var storeSavedUserUrl = '/api/view_as/store_user_as_saved';
  var storeRecentUserUrl = '/api/view_as/store_user_as_recent';
  var deleteSavedUserUrl = '/api/view_as/delete_saved';
  var deleteAllRecentUsersUrl = '/api/view_as/delete/recent';
  var deleteAllSavedUsersUrl = '/api/view_as/delete_all_saved';
  var urlAlumniProfile = '/api/alumni/alumni_profiles';

  var getAlumniProfile = function(options) {
    return apiService.http.request(options, urlAlumniProfile);
  };

  var delegateActAs = function(user) {
    return $http.post(delegateActAsUrl, user);
  };

  var actAs = function(user) {
    return $http.post(actAsUrl, user);
  };

  var advisorActAs = function(user) {
    return $http.post(advisorActAsUrl, user);
  };

  var userLookup = function(options) {
    return apiService.http.request(options, userByAnyIdUrl + options.id);
  };

  var getStoredUsers = function(options) {
    return apiService.http.request(options, storedUsersUrl);
  };

  var storeUser = function(options) {
    return $http.post(storeSavedUserUrl, options);
  };

  var storeUserAsRecent = function(options) {
    return $http.post(storeRecentUserUrl, options);
  };

  var deleteUser = function(options) {
    return $http.post(deleteSavedUserUrl, options);
  };

  var deleteAllRecentUsers = function() {
    return $http.post(deleteAllRecentUsersUrl);
  };

  var deleteAllSavedUsers = function() {
    return $http.post(deleteAllSavedUsersUrl);
  };

  return {
    actAs: actAs,
    advisorActAs: advisorActAs,
    delegateActAs: delegateActAs,
    deleteAllRecentUsers: deleteAllRecentUsers,
    deleteAllSavedUsers: deleteAllSavedUsers,
    deleteUser: deleteUser,
    getStoredUsers: getStoredUsers,
    storeUser: storeUser,
    storeUserAsRecent: storeUserAsRecent,
    userLookup: userLookup,
    getAlumniProfile: getAlumniProfile,
  };
});
