import styled from "styled-components";
import { Columns as Row, Column } from "components/ui/Page";

export const ColumnOne = styled(Column)`
  flex: 1.3;
`;

export const ColumnTwo = styled(Column)`
  flex: 4.7;
  gap: 0px;
`;

export const IconWrapper = styled.i`
  margin-right: 4px;
  color: var(--denim);
`;

export const StyledHeader = styled.strong`
  color: var(--dove-gray);
`;

export const StyledInfoContainer = styled.span`
  display: flex;
  flex-direction: column;
  padding-top: ${({ $isFirst }) => $isFirst ? "16px" : "4px" };
  padding-bottom: 4px;
`;

export const StyledRow = styled(Row)`
  border-bottom: 1px solid lightgray;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 2px;
  &:first-child {
    border-top: 1px solid lightgray;
  }
  &:last-child {
    border-bottom: none;
  }
`;
