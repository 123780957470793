import { Form as FormikForm } from "formik";
import styled from "styled-components";

const FormStyles = styled(FormikForm)`
  padding: 0;
  background: none;
  border: none;

  input,
  textarea {
    margin: 0;
  }

  .field + .field {
    margin-top: 15px;
  }

  .actions {
    margin-top: 15px;
  }
`;

export default FormStyles;
