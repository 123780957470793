import React, { useState } from "react";
import PropTypes from "prop-types";

import EnrollmentNotice from "./EnrollmentNotice";
import TermSwitcher from "./TermSwitcher";
import { Card, CardTitle, CardBody } from "./Card";

const careerDescription = (code) => {
  switch (code) {
    case "UGRD":
      return "Undergraduate";
    case "GRAD":
      return "Graduate";
    case "LAW":
      return "Law";
    case "UCBX":
      return "";
    default:
      return "";
  }
};

export default function EnrollmentCard({
  presentations = [],
  termInstructions = [],
  children,
}) {
  const termIds = presentations.map((pres) => pres.termId);
  const [activeTermId, setActiveTermId] = useState(termIds[termIds.length - 1]);
  const presentation = presentations.find(
    (pres) => pres.termId === activeTermId
  );
  const termInstruction = termInstructions.find(
    (inst) => inst.term_id === activeTermId
  );

  const enrollmentPeriods = termInstruction
    ? termInstruction.enrollmentPeriods.filter(
        (period) => period.acadCareer === presentation.academicCareerCode
      )
    : [];

  const calgrantAckRequired = termInstruction.requiresCalgrantAcknowledgement;

  const subtitleForPresentation = (presentation) => {
    if (
      ["haasEveningWeekendMba", "haasFullTimeMba", "haasExecMba"].includes(
        presentation.design
      )
    ) {
      return "MBA";
    }

    return careerDescription(presentation.academicCareerCode);
  };

  if (presentations.length > 0) {
    return (
      <Card>
        <CardTitle>
          <h2 data-testid="title">
            {presentation.design === "concurrent"
              ? "Concurrent Enrollment "
              : "Class Enrollment "}
            <span data-testid="subtitle">
              {subtitleForPresentation(presentation)}
            </span>
          </h2>
        </CardTitle>
        <CardBody>
          <TermSwitcher
            termIds={termIds}
            activeTermId={activeTermId}
            setActiveTermId={setActiveTermId}
          />

          <EnrollmentNotice presentation={presentation} />

          {children({ presentation, enrollmentPeriods, calgrantAckRequired })}
        </CardBody>
      </Card>
    );
  }

  return null;
}

EnrollmentCard.defaultProps = {
  children: () => {},
};

EnrollmentCard.propTypes = {
  children: PropTypes.func,
  presentations: PropTypes.arrayOf(PropTypes.shape({})),
  termInstructions: PropTypes.arrayOf(PropTypes.shape({})),
};
