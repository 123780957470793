import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Semester from "./Semester";
import TermLimiter from "./TermLimiter";
import Card from "components/../react/components/Card";
import { connect } from "react-redux";

import styles from "./SemestersCard.module.scss";

import { getMyStatus } from 'data/slices/myStatus'
import { fetchMyAcademics } from "data/actions/myAcademicsActions";

import ViewAcademicSummaryLink from "./ViewAcademicSummaryLink";

const SemestersCard = ({
  error,
  fetchData,
  hasStudentHistory,
  hubTermFeatureActivated,
  isAdvisingView,
  isLoading,
  semesters,
  pastSemestersCount,
  pastSemestersLimit,
}) => {
  useEffect(() => {
    fetchData();
  }, []);

  const [termsExpanded, setTermsExpanded] = useState(false);

  if (!hasStudentHistory) {
    return null;
  } else {
    let displayedSemesters = semesters;
    if (!termsExpanded) {
      displayedSemesters = semesters.slice(0, pastSemestersLimit);
    }

    return (
      <Card
        className={styles.semestersCard + ` cc-react-widget`}
        title="Semesters"
        loading={isLoading}
        error={error}
        secondaryContent={
          <ViewAcademicSummaryLink isAdvisingView={isAdvisingView} />
        }
      >
        {semesters.length > 0 &&
          displayedSemesters.map((semester) => (
            <Semester
              semester={semester}
              isAdvisingView={isAdvisingView}
              key={semester.termId}
            ></Semester>
          ))}

        {semesters.length > 0 && (
          <TermLimiter
            termsExpanded={termsExpanded}
            setTermsExpanded={setTermsExpanded}
            pastSemestersCount={pastSemestersCount}
            hubTermFeatureActivated={hubTermFeatureActivated}
          />
        )}
      </Card>
    );
  }
};

SemestersCard.propTypes = {
  fetchData: PropTypes.func.isRequired,
  error: PropTypes.object,
  featureActivated: PropTypes.bool,
  hasStudentHistory: PropTypes.bool,
  isAdvisingView: PropTypes.bool,
  isLoading: PropTypes.bool,
  semesters: PropTypes.array,
  pastSemestersCount: PropTypes.number,
  pastSemestersLimit: PropTypes.number,
  hubTermFeatureActivated: PropTypes.bool,
};

function mapState({ myStatus, myAcademics }) {
  const {
    isLoading: myStatusLoading = true,
    hasStudentHistory = false,
    error = null,
    features: { hubTermApi: hubTermFeatureActivated = false },
  } = myStatus;

  const {
    isLoading: myAcademicsLoading = true,
    semesters = [],
    pastSemestersCount = 0,
    pastSemestersLimit = 0,
  } = myAcademics;

  const isLoading = myAcademicsLoading || myStatusLoading;

  return {
    error,
    hasStudentHistory,
    isLoading,
    semesters,
    pastSemestersCount,
    pastSemestersLimit,
    hubTermFeatureActivated,
  };
}

function mapDispatch(dispatch) {
  return {
    fetchData: () => {
      dispatch(getMyStatus());
      dispatch(fetchMyAcademics());
    },
  };
}

export default connect(mapState, mapDispatch)(SemestersCard);
