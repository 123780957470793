import React from "react";
import PropTypes from "prop-types";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import DegreeProgressLinks from "components/../react/components/_academics/DegreeProgress/DegreeProgressLinks";

const NgDegreeProgressLinks = (props) => {
  return (
    <ReduxProvider>
      <DegreeProgressLinks {...props} />
    </ReduxProvider>
  );
};

NgDegreeProgressLinks.propTypes = {
  aprLink: PropTypes.object,
  aprFaqsLink: PropTypes.object,
  aprWhatIfLink: PropTypes.object,
  degreePlannerLink: PropTypes.object,
  showPnpCalculator: PropTypes.func,
  showPnpCalculatorLink: PropTypes.bool,
  isAdvisingStudentLookup: PropTypes.bool,
};

angular
  .module("calcentral.react")
  .component("degreeProgressLinks", react2angular(NgDegreeProgressLinks));
