import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const MissingPhotoWrapper = styled.div`
  background: var(--silver);
  display: block;
  font-size: 80px;
  height: 96px;
  padding-top: ${(props) => (props.isStudentSummaryView ? "none" : "10px")};

  text-align: center;
  width: 72px;
  line-height: 1.5;

  i {
    color: var(--white);
  }
`;
const MissingPhoto = ({ isStudentSummaryView }) => {
  return (
    <MissingPhotoWrapper
      data-testid="missing-photo"
      isStudentSummaryView={isStudentSummaryView}
    >
      <i className="fa fa-user"></i>
    </MissingPhotoWrapper>
  );
};

MissingPhoto.displayName = "MissingPhoto";
MissingPhoto.propTypes = { isStudentSummaryView: PropTypes.bool };
export default MissingPhoto;
