import React from "react";
import PropTypes from "prop-types";
import TimeCell from "../TimeCell";
import MissingAppointmentMessage from "../MissingAppointmentMessage";

import { enrollmentPeriodShape } from "../propTypes";

function EnrollmentPeriodRow({
  enrollmentPeriod: {
    beginTime: { pacific },
  },
  maxUnits,
}) {
  return (
    <tr>
      <td>Begin Enrollment {maxUnits} Units Max</td>
      <td>
        <TimeCell time={pacific} />
      </td>
    </tr>
  );
}

EnrollmentPeriodRow.propTypes = {
  enrollmentPeriod: enrollmentPeriodShape,
  maxUnits: PropTypes.string,
};

function SummerEnrollmentPeriods({ enrollmentPeriods, maxUnits }) {
  if (enrollmentPeriods.length) {
    return (
      <div className="cc-table">
        <table className="table">
          <thead>
            <tr>
              <th>Law Period</th>
              <th width="33%">Start</th>
            </tr>
          </thead>
          <tbody>
            <EnrollmentPeriodRow
              enrollmentPeriod={enrollmentPeriods[0]}
              maxUnits={maxUnits}
            />
          </tbody>
        </table>
      </div>
    );
  }

  return <MissingAppointmentMessage />;
}

SummerEnrollmentPeriods.propTypes = {
  enrollmentPeriods: PropTypes.arrayOf(PropTypes.shape({})),
  maxUnits: PropTypes.string,
};

export default SummerEnrollmentPeriods;
