import React from "react";
import ReduxProvider from "components/ReduxProvider";
import { react2angular } from "react2angular";
import DelegateStudentList from "components/DelegateStudentList";

const NgDelegateStudentList = () => {
  return (
    <ReduxProvider>
      <DelegateStudentList />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("delegateStudentList", react2angular(NgDelegateStudentList));
