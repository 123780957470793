import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import UpNextCard from "components/../react/components/_dashboard/UpNext/UpNextCard";

const NgUpNextCard = () => {
  return (
    <ReduxProvider>
      <UpNextCard />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("upNextCard", react2angular(NgUpNextCard));
