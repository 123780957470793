'use strict';

/**
 * Set the security configuration for CalCentral
 */
angular.module('calcentral.config').config(function(calcentralConfig, $httpProvider) {
  var token = calcentralConfig.csrfToken;
  // Setting up CSRF tokens for POST, PUT and DELETE requests
  if (token) {
    $httpProvider.defaults.headers.post['x-csrf-token'] = token;
    $httpProvider.defaults.headers.put['x-csrf-token'] = token;

    $httpProvider.defaults.headers.delete = {
      'x-csrf-token': token
    };
  }
});
