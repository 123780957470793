import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";

const CollegeAndLevelError = () => {
  const { errored } = useSelector((state) => {
    const {
      academicProfile: { ihubPersonServerErrored: errored = false } = {},
    } = currentUserState(state) || {};

    return { errored };
  });

  if (errored) {
    return <div>There was a problem reaching campus services.</div>;
  } else {
    return null;
  }
};

CollegeAndLevelError.displayName = "CollegeAndLevelError";
export default CollegeAndLevelError;
