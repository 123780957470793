/**
 * Note: See new styled component in /src/components/Card.js
 */
import React from "react";
import PropTypes from "prop-types";
import Spinner from "components/../react/components/Spinner";
import "./Card.scss";

import ErrorMessage from "components/ui/ErrorMessage";

const Card = ({
  id,
  children,
  title,
  loading,
  error,
  className,
  secondaryContent,
  node,
  style,
}) => {
  const classNames = ["Card", className].join(" ");

  return (
    <div
      id={id}
      ref={node}
      className={classNames}
      style={style}
      data-testid="card"
    >
      <div className="Card__title">
        <h2 tabIndex="0">{title}</h2>
        {secondaryContent}
      </div>
      <div className="Card__body">
        {loading ? <Spinner /> : <ErrorMessage {...error} />}

        {!loading && !error && children}
      </div>
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  className: PropTypes.string,
  secondaryContent: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  node: PropTypes.any,
  style: PropTypes.object,
};

Card.displayName = "Card";

export default Card;
