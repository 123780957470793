import React from "react";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const Minors = () => {
  const { minors } = useSelector((state) => {
    const {
      advising: { userId: advisingStudentUid },
    } = state;

    const { advisingStudentAcademicProfile: { minors = [] } = {} } =
      advisingStudentState(state, advisingStudentUid) || {};

    return {
      minors,
    };
  });

  if (minors && minors.length > 0) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>
            {minors.length === 1 ? "Minor" : "Minors"}
          </StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {minors.map((minor, index) => (
            <div key={index}>
              <div style={{ color: "var(--dusty-gray)" }}>{minor.college}</div>
              <div>{minor.description}</div>
              {minor.subPlans.map((subPlan, index) => (
                <div key={index}>{subPlan}</div>
              ))}
            </div>
          ))}
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
Minors.displayName = "Minors";
