import { combineReducers } from "redux";
import buildDataReducer from "../build-data-reducer";

import {
  FETCH_ACADEMICS_DIPLOMA_START,
  FETCH_ACADEMICS_DIPLOMA_SUCCESS,
  FETCH_ACADEMICS_DIPLOMA_FAILURE,
  FETCH_ACADEMICS_STUDENT_RESOURCES_START,
  FETCH_ACADEMICS_STUDENT_RESOURCES_SUCCESS,
  FETCH_ACADEMICS_STUDENT_RESOURCES_FAILURE,
} from "../action-types";

export const DiplomaReducer = buildDataReducer(
  FETCH_ACADEMICS_DIPLOMA_START,
  FETCH_ACADEMICS_DIPLOMA_SUCCESS,
  FETCH_ACADEMICS_DIPLOMA_FAILURE
);

export const StudentResourcesReducer = buildDataReducer(
  FETCH_ACADEMICS_STUDENT_RESOURCES_START,
  FETCH_ACADEMICS_STUDENT_RESOURCES_SUCCESS,
  FETCH_ACADEMICS_STUDENT_RESOURCES_FAILURE
);

export const AcademicsReducer = combineReducers({
  diploma: DiplomaReducer,
  studentResources: StudentResourcesReducer,
});

export default AcademicsReducer;
