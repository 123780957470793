import { connect } from "react-redux";

function WithCSRFToken({ children, csrfToken }) {
  return children(csrfToken);
}

function mapStateToProps({ config: { csrfToken } }) {
  return { csrfToken };
}

export default connect(mapStateToProps)(WithCSRFToken);
