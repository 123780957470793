// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".switcher___B20KW{margin-left:-15px;margin-right:-15px;display:flex;align-items:stretch;height:42px}.tab___cEc3g{font-weight:bold;flex:1;text-align:center;line-height:42px}.currentTab___U1Y_M{color:var(--denim);border-bottom:2px solid var(--denim)}.inactiveTab___xHwNN{color:var(--dove-gray);border-bottom:2px solid var(--gallery);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/NotificationsCard/Tabs.module.scss"],"names":[],"mappings":"AAQA,kBACE,iBAAA,CACA,kBAAA,CAEA,YAAA,CACA,mBAAA,CACA,WAXO,CAcT,aACE,gBAAA,CACA,MAAA,CACA,iBAAA,CACA,gBAlBO,CAqBT,oBACE,kBArBY,CAsBZ,oCAAA,CAGF,qBACE,sBAzBc,CA0Bd,sCAAA,CACA,cAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n@import 'src/react/stylesheets/mixins';\n\n$height: 42px;\n$activeColor: var(--denim);\n$inactiveColor: var(--dove-gray);\n$inactiveBorderColor: var(--gallery);\n\n.switcher {\n  margin-left: -15px;\n  margin-right: -15px;\n\n  display: flex;\n  align-items: stretch;\n  height: $height;\n}\n\n.tab {\n  font-weight: bold;\n  flex: 1;\n  text-align: center;\n  line-height: $height;\n}\n\n.currentTab {\n  color: $activeColor;\n  border-bottom: 2px solid $activeColor;\n}\n\n.inactiveTab {\n  color: $inactiveColor;\n  border-bottom: 2px solid $inactiveBorderColor;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switcher": "switcher___B20KW",
	"tab": "tab___cEc3g",
	"currentTab": "currentTab___U1Y_M",
	"inactiveTab": "inactiveTab___xHwNN"
};
export default ___CSS_LOADER_EXPORT___;
