import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { APILink } from "components/ui/APILink";
import { BulletList } from "components/ui/Lists";

export const AdmitBulletLinks = ({ links }) => (
  <FlexBulletList>
    {Object.entries(links).map(([key, link]) => (
      <li key={key}>
        <APILink link={link} />
        {link.linkDescription && <p>{link.linkDescription}</p>}
      </li>
    ))}
  </FlexBulletList>
);

AdmitBulletLinks.propTypes = {
  links: PropTypes.object,
};

export const FlexBulletList = styled(BulletList)`
  display: flex;
  flex-direction: column;
  gap: var(--lg);

  li a:first-child + p {
    margin-top: 0;
  }

  li p:last-child {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 15px;
  }
`;
