import { useUser } from "./useUser";
import { useFinaidYearId } from "./useFinaidYearId";

export function useFinaidYear(callback) {
  const finaidYearId = useFinaidYearId();

  return useUser((slice) => {
    const { finaid: { [finaidYearId]: finaidYear = {} } = {} } = slice;
    return callback(finaidYear);
  });
}
