import PropTypes from "prop-types";
import { connect } from "react-redux";
import { activeRoles } from "../../../react/helpers/roles";

export default connect(mapStateToProps)(HasAccessTo);

// HasAccessTo will only display links that are returned from Link API and accessible given
// the specified roles, careers and delegate access.
function HasAccessTo({
  children,
  isCurrentlyNonDegreeSeekingSummerVisitorApplicantOrStudent,
  isDelegate,
  roles,
  urlIDs,
}) {
  if (urlIDs) {
    const has = urlIDs.some((linkID) => {
      return hasAccessToLinkID(
        linkID,
        roles,
        isDelegate,
        isCurrentlyNonDegreeSeekingSummerVisitorApplicantOrStudent
      );
    });

    if (has) {
      return children;
    }

    return null;
  }

  return null;
}

HasAccessTo.propTypes = {
  careerCodes: PropTypes.array,
  children: PropTypes.node,
  isCurrentlyNonDegreeSeekingSummerVisitorApplicantOrStudent: PropTypes.bool,
  isDelegate: PropTypes.bool,
  linkNames: PropTypes.array,
  linkIDs: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.object,
  myAcademics: PropTypes.object,
  myStatus: PropTypes.object,
  roles: PropTypes.array,
  urlIDs: PropTypes.array,
};

function mapStateToProps({
  financialResourcesLinks: { links = {}, matriculated = false } = {},
  myStatus = {},
}) {
  const {
    applicantStudentRoles = [],
    academicRoles: {
      historical: { degreeSeeking: studentDegreeSeeking, summerVisitor } = {},
    } = {},
  } = myStatus;

  const applicantStudentDegreeSeeking =
    applicantStudentRoles.includes("degreeSeeking") || studentDegreeSeeking;

  return {
    links,
    roles: activeRoles({ ...myStatus.roles, matriculated }),
    careerCodes: activeRoles(myStatus.roles),
    isDelegate: myStatus.delegateActingAsUid,
    isCurrentlyNonDegreeSeekingSummerVisitorApplicantOrStudent:
      summerVisitor && !applicantStudentDegreeSeeking,
  };
}

const hasAccessToLinkID = (
  key,
  roles,
  delegate,
  isCurrentlyNonDegreeSeekingSummerVisitorApplicantOrStudent
) => {
  // any defaults need to be true, otherwise will cause all links to not show
  const defaultAccess = {
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: true,
  };

  const currentLinkAccess = {
    ...defaultAccess,
    ...linkAccess[key],
  };

  const hasPermittedRole = currentLinkAccess.roles
    ? roles.filter((value) => currentLinkAccess.roles.includes(value)).length >
      0
    : true;

  const hasPermittedCareer = currentLinkAccess.careers
    ? roles.filter((value) => currentLinkAccess.careers.includes(value))
        .length > 0 ||
      /* Applicants or exStudents will not have a current career and may need to have the link still displayed */
      roles.filter((value) => ["applicant", "exStudent"].includes(value))
        .length > 0
    : true;

  const allowsDelegate =
    !delegate || (currentLinkAccess.allowsDelegateAccess && delegate);

  const allowsNonDegreeSeekingSummerVisitorApplicantOrStudent =
    !isCurrentlyNonDegreeSeekingSummerVisitorApplicantOrStudent ||
    (currentLinkAccess.allowsNonDegreeSeekingSummerVisitorApplicantOrStudent &&
      isCurrentlyNonDegreeSeekingSummerVisitorApplicantOrStudent);

  return (
    hasPermittedRole &&
    hasPermittedCareer &&
    allowsDelegate &&
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent
  );
};

const UGRD = "undergrad";
const GRAD = "graduate";
const LAW = "law";

const nonConcurrentCareers = [UGRD, GRAD, LAW];
const undergradCareerOnly = [UGRD];

const linkAccess = {
  UC_FA_FINRES_ACTIVATE_FPP: {
    roles: ["matriculated", "registered"],
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_BEARS_FIN_SUCCESS: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_INTERNATIONAL: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_BILLING_FAQ: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_CAL_STUDNT_CENTRL: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_COA: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_CAL1CARD: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_DELEGATE: {
    roles: ["student"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
  },
  UC_FA_FINRES_DIRECT_DEP: {
    roles: ["student"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_DIRECT_DEP_ENRL: {
    roles: ["student"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
  },
  UC_FA_FINRES_DIRECT_DEP_MANAGE: {
    roles: ["student"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
  },
  UC_FA_FINRES_DREAM_ACT: {
    roles: ["student", "applicant", "staff", "faculty", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_EMERG_LOAN: {
    roles: ["matriculated", "registered"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_EMERG_LOAN_APPLY: {
    roles: ["matriculated", "registered"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_FAFSA: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_FAFSA_VERIFY: {
    roles: ["student", "applicant", "staff", "faculty", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_FED_LOANS: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_FA_FORMS: {
    roles: ["student", "applicant", "staff", "faculty", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_FASO: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_FA_SUMMARY: {
    roles: ["matriculated", "registered", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_FA_SUMMARY_DELEGA: {
    roles: ["matriculated", "registered", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_GRAD_FIN_SUPPORT: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_IGRAD: {
    roles: ["matriculated", "registered", "staff", "faculty"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_CASHCOURSE: {
    roles: ["matriculated", "registered", "staff", "faculty"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: true,
  },
  UC_FA_FINRES_LEAVING_CAL: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_LOAN_CALCULATOR: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_MEAL_PLAN: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_MEAL_PLAN_LEARN: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_NSLDS: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_PAYMENT_OPTIONS: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_STATE_INST_LOANS: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_ADVOCATE: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_SUMMER_ESTIMATOR: {
    roles: ["student", "applicant"],
    careers: undergradCareerOnly,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_SUMMER_FEES: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_SUMMER_SCHEDULE: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_SUMMER_WEB: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_SUMMER_WITHDRAW: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_1098T: {
    roles: ["student"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_1098T_VIEW: {
    roles: ["student"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: false,
  },
  UC_FA_FINRES_TUITION_AND_FEES: {
    roles: ["student", "applicant", "exStudent"],
    allowsDelegateAccess: true,
  },
  UC_FA_FINRES_TUIT_AND_FPP: {
    roles: ["matriculated", "registered"],
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_WITHDRAW: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
  UC_FA_FINRES_WORK_STUDY: {
    roles: ["student", "applicant", "exStudent"],
    careers: nonConcurrentCareers,
    allowsDelegateAccess: true,
    allowsNonDegreeSeekingSummerVisitorApplicantOrStudent: false,
  },
};
