import {
  SET_CURRENT_ROUTE_PROPERTIES,
  ADVISOR_UNDERGRAD_DEGREE_PROGRESS_REFRESH,
  ADVISOR_UNDERGRAD_DEGREE_PROGRESS_START,
  ADVISOR_UNDERGRAD_DEGREE_PROGRESS_SUCCESS,
} from "../../action-types";

export default function undergradDegreeProgress(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_ROUTE_PROPERTIES:
      return {};
    case ADVISOR_UNDERGRAD_DEGREE_PROGRESS_REFRESH:
      return { uid: action.value, loadState: "pending" };
    case ADVISOR_UNDERGRAD_DEGREE_PROGRESS_START:
      return { ...state, loadState: "pending" };
    case ADVISOR_UNDERGRAD_DEGREE_PROGRESS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { feed: { degreeProgress = {}, links = {} } = {} } = action.value;

      return {
        ...state,
        ...degreeProgress,
        links,
        loadState: "success",
      };
    default:
      return state;
  }
}
