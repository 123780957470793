import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { react2angular } from "react2angular";

import styled from "styled-components";
import Navigation from "components/Navigation";
import ReduxProvider from "components/ReduxProvider";

const NavigationWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

function NgNavigation() {
  return (
    <Router>
      <ReduxProvider>
        <NavigationWrapper>
          <Navigation />
        </NavigationWrapper>
      </ReduxProvider>
    </Router>
  );
}

angular
  .module("calcentral.react")
  .component("navigationBar", react2angular(NgNavigation));
