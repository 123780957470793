import { useSlice } from "components/hooks/useSlice";
import React from "react";
import styled from "styled-components";

export const PronounHeading = styled.div`
  color: var(--dove-gray);
  font-size: 11px;
  font-weight: normal;
`;

export const Pronouns = () => {
  const isDelegate = !!useSlice("myStatus").delegateActingAsUid;
  const pronouns = useSlice("userProfile").pronouns || "";

  if (pronouns && !isDelegate) {
    return <PronounHeading>pronouns: {pronouns}</PronounHeading>;
  }

  return null;
};
Pronouns.displayName = "Pronouns";
