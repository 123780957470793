import {
  ADVISING_RESOURCES_START,
  ADVISING_RESOURCES_SUCCESS,
  ADVISING_RESOURCES_REFRESH,
} from "../../action-types";
const sortLinks = (feed) => {
  const linkSortOrderKeys = [
    "Service Indicators",
    "Advising Assignments",
    "Administrative Transcript",
    "Academic Progress Report",
    "What-If Reports",
    "Student Milestones",
    "Degree Planner",
    "Advising Notes",
    "External Student Docs-ImageNow",
  ];

  for (const key in feed) {
    feed[key].sortOrder = linkSortOrderKeys.indexOf(feed[key].name).toString();
  }

  return (
    Object.values(feed || []).sort(
      (a, b) =>
        a.sortOrder && b.sortOrder && a.sortOrder.localeCompare(b.sortOrder)
    ) || []
  );
};
export default function (state = {}, action) {
  switch (action.type) {
    case ADVISING_RESOURCES_REFRESH:
      return { uid: action.value, loadState: "pending" };
    case ADVISING_RESOURCES_START:
      return { ...state, uid: action.value, loadState: "pending" };
    case ADVISING_RESOURCES_SUCCESS:
      return {
        ...state,
        resourceLinks: sortLinks(action.value.feed),
        loadState: "success",
      };
    default:
      return state;
  }
}
