import React from "react";

import FontAwesomeIcon from "./FontAwesomeIcon";
import { ICON_CHECK_CIRCLE } from "./IconTypes";

const GreenCheckIcon = () => (
  <FontAwesomeIcon name={ICON_CHECK_CIRCLE} color="green" />
);

export default GreenCheckIcon;
