import sortBy from "functions/sortBy";

export class MessageBuilder {
  constructor(
    courseCode,
    semesterName,
    displayedView,
    instructorsName,
    enrollStatus,
    selectedStudents
  ) {
    this.courseCode = courseCode;
    this.semesterName = semesterName;
    this.displayedView = displayedView;
    this.instructorsName = instructorsName;
    this.enrollStatus = enrollStatus;
    this.selectedStudents = selectedStudents;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Getters

  get message() {
    const requestSubject = this.getSubjectRequest();
    const bodyLines = this.getBodyLines();
    return "SUBJECT\n\n" + requestSubject + "\n\n" + bodyLines.join("\n");
  }

  //////////////////////////////////////////////////////////////////////////////
  // Functions

  // Maps selected students to an array of sections that will be affected by the request.
  findAffectedSections() {
    let sections = [];
    this.selectedStudents.forEach((student) => {
      student.sections.forEach((section) => {
        sections.push(section);
      });
    });

    return [...new Set(sections)];
  }

  getBodyLines() {
    const sortingNote = this.getSortingNote();
    const bodyRequest = this.getBodyRequest();
    const textSectionList = this.textSectionList();
    const textStudentList = this.textStudentList(this.selectedStudents, true);

    return [
      "BODY\n",
      "Schedule or enrollment manager,\n",
      "Within the constraints of pre-requisites (if normally enforced for this class), reserve capacity, overall class capacity and any other relevant concerns, please fulfill the following request for students enrolled or on the wait list for " +
        this.courseCode +
        " for " +
        this.semesterName +
        ".\n",
      "REQUEST: " + bodyRequest,
      "Sections:\n",
      textSectionList,
      "Students:\n",
      textStudentList +
        sortingNote +
        "The students are also broken out by individual section and Class Number below.",
      "If this request cannot be fulfilled in a reasonable amount of time, please let me know.\n",
      "Thank you",
      this.instructorsName,
      "\n" + this.sectionGroupedTextStudentList(this.selectedStudents),
    ];
  }

  // Returns suggested body of message request
  getBodyRequest() {
    let bodyRequest = "";
    switch (this.displayedView) {
      case "promote": {
        bodyRequest =
          "PROMOTE the following waitlisted students to the top of the wait list:\n";
        break;
      }
      case "enroll": {
        bodyRequest =
          "ENROLL the following waitlisted students into the class:\n";
        break;
      }
      case "remove": {
        bodyRequest =
          "REMOVE the following waitlisted students from the wait list:\n";
        break;
      }
      case "drop": {
        bodyRequest =
          "DROP the following enrolled students from any enrolled sections:\n";
        break;
      }
    }
    return bodyRequest;
  }

  // Returns sorting order note
  getSortingNote() {
    if (this.enrollStatus === "waitlisted") {
      return "The above students are listed by waitlist priority. ";
    }
    if (this.enrollStatus === "enrolled") {
      return "The above students are listed alphabetically by last name. ";
    }
  }

  // Returns suggested subject of message request
  getSubjectRequest() {
    let subjectRequest = "";
    const subjectEnd = this.courseCode + " class for " + this.semesterName;
    switch (this.displayedView) {
      case "promote": {
        subjectRequest =
          "Request to promote waitlisted students in " + subjectEnd;
        break;
      }
      case "enroll": {
        subjectRequest =
          "Request to enroll waitlisted students in " + subjectEnd;
        break;
      }
      case "remove": {
        subjectRequest =
          "Request to remove waitlisted students from " + subjectEnd;
        break;
      }
      case "drop": {
        subjectRequest = "Request to drop enrolled students from " + subjectEnd;
        break;
      }
    }
    return subjectRequest;
  }

  /*
   * Returns string containing formatted list of students grouped by sections
   * @param {Array}    student list
   * @param {String}   enrollment status (e.g. 'enrolled', 'waitlist')
   * @return {String}
   */
  sectionGroupedTextStudentList(students) {
    let list = "";
    const line = "------------------------------\n\n";
    let sections = this.studentsGroupedBySections(students);
    let sectionKeys = Object.keys(sections);

    sectionKeys.forEach((key) => {
      let currentSection = sections[key];
      list = list.concat(line);
      list = list.concat("Section: " + currentSection.name + "\n");
      list = list.concat("Class Number: " + currentSection.ccn + "\n\n");
      list = list.concat(this.textStudentList(currentSection.students, false));
    });

    list = list.concat(line);
    return list;
  }

  /*
   * Returns section list string
   * @param {Array} array of students sections
   * @returns {String}
   */
  sectionNameList(sections) {
    if (typeof sections === "undefined" || sections.length === 0) {
      return "";
    } else {
      return sections
        .map((section) => {
          return section.name;
        })
        .join(", ");
    }
  }

  /**
   * Converts array of students to array containing sections with applicable students
   * @param {Array} students    Students array
   * @returns Object
   */
  studentsGroupedBySections(students) {
    let groupedStudents = {};
    students.forEach((student) => {
      if (typeof student.sections === "object") {
        student.sections.forEach((section) => {
          if (section.ccn) {
            if (typeof groupedStudents[section.ccn] === "undefined") {
              groupedStudents[section.ccn] = {};
              groupedStudents[section.ccn].name = section.name;
              groupedStudents[section.ccn].ccn = section.ccn;
              groupedStudents[section.ccn].students = [];
            }
            groupedStudents[section.ccn].students.push(student);
          }
        });
      }
    });
    return groupedStudents;
  }

  /*
   * Returns all unique affected sections in a formatted text string that
   * includes name and ccn, to be used in rendered email for each action
   */
  textSectionList() {
    const sections = this.findAffectedSections();
    let list = "";

    sections.forEach((section) => {
      list = list.concat("Section: " + section.name + "\n");
      list = list.concat("Class Number: " + section.ccn + "\n\n");
    });
    return list.slice(0, -1);
  }

  /**
   * Returns tab spaced table of students. Sorted by waitlist position when enrollStatus is 'waitlisted'
   * @param {Array} students student objects to include in text list
   * @param {Boolean} includeSections flags whether sections should be included
   * @returns String
   */
  textStudentList(students, includeSections) {
    const includeSectionsList =
      typeof includeSections !== "undefined" ? includeSections : false;
    const isWaitlist = this.enrollStatus === "waitlisted";

    let list = "";
    let sortKey = "last_name";
    if (isWaitlist) {
      sortKey = "waitlist_position";
    }
    const sortedStudents = students.concat().sort(sortBy(sortKey));
    sortedStudents.forEach((student) => {
      if (isWaitlist) {
        list = list.concat(
          "Waitlist Position: " + student.waitlist_position + "\n"
        );
      }
      list = list.concat("Student ID: " + student.student_id + "\n");
      list = list.concat("Last Name: " + student.last_name + "\n");
      list = list.concat("First Name: " + student.first_name + "\n");
      if (includeSectionsList) {
        list = list.concat(
          "Sections: " + this.sectionNameList(student.sections) + "\n"
        );
      }
      list = list.concat("Career: " + student.academic_career + "\n");
      list = list.concat("\n");
    });
    return list;
  }
}
