import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Widget from "components/../react/components/Widget/Widget";
import "icons/clipboard-list.svg";

import styles from "./AlumniOverlay.module.scss";
import calcentralLogoWhiteBg from "assets/images/calcentral_logo_whitebg.svg";

const defaultAlumData = {
  homepageLinkObj: {},
  landingPageMessage: "",
  landingPageSubTitle: "",
  skipLandingPage: false,
};

const AlumniOverlay = (props) => {
  const [errored, setErrored] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [alumData, setAlumData] = useState(defaultAlumData);

  const widgetConfig = {
    errored: errored,
    errorMessage: "The Alumni Homepage is not available right now.",
    isLoading: isLoading,
    padding: true,
    title: "Alumni Homepage",
    visible: true,
  };

  const httpHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    "x-csrf-token": props.csrfToken,
  };

  const fetchData = () => {
    const url = "/api/alumni/alumni_profiles";
    fetch(url, { method: "GET" })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setErrored(true);
          setIsLoading(false);
        }
      })
      .then((data) => {
        setAlumData({
          homepageLinkObj: data.homepageLink,
          landingPageMessage: data.landingPageMessage.descrlong,
          landingPageSubTitle: data.landingPageSubTitle.descrlong,
          skipLandingPage: data.skipLandingPage,
        });
        if (data.skipLandingPage) {
          navigateToLandingPage(false, data.homepageLink.url);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  const navigateToLandingPage = (saveDontShowAgain, homepageURL) => {
    const callLogout = fetch("/logout", {
      method: "POST",
      headers: httpHeaders,
    });
    let promiseList = [callLogout];
    if (saveDontShowAgain) {
      const setSkipFlag = fetch("/api/alumni/set_skip_landing_page", {
        method: "POST",
        headers: httpHeaders,
      });
      promiseList = [setSkipFlag, callLogout];
    }
    setIsLoading(true);
    Promise.all(promiseList)
      .then((_res) => {
        window.location.href = homepageURL;
      })
      .catch((_err) => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setDontShowAgain(e.target.checked);
  };

  const handleOnClick = () => {
    navigateToLandingPage(dontShowAgain, alumData.homepageLinkObj.url);
  };

  const landingPage = (
    <div className={styles.lightbox}>
      <Widget
        cardStyle={{ width: "300px", borderRadius: "5px", textAlign: "center" }}
        hideHeader={true}
        config={{ ...widgetConfig }}
      >
        <div className={styles.cardDiv}>
          <img className={styles.logo} src={calcentralLogoWhiteBg} />
          <h2>{alumData.landingPageSubTitle}</h2>
          <div width="100%">
            <p className={styles.messageText}>{alumData.landingPageMessage}</p>
          </div>
          <br />
          <a
            title={alumData.homepageLinkObj.title}
            target="_self"
            className="cc-react-button cc-react-button--blue"
            onClick={handleOnClick}
          >
            {alumData.homepageLinkObj.name}
          </a>
          <p className={styles.showCheck}>
            <span className={styles.showCheckbox}>
              <input
                name="numberOfGuests"
                type="checkbox"
                value={dontShowAgain}
                onChange={handleInputChange}
              />
            </span>
            <span> Don&#39;t show me this again.</span>
          </p>
        </div>
      </Widget>
    </div>
  );

  return landingPage;
};

AlumniOverlay.propTypes = {
  csrfToken: PropTypes.string,
};

const mapStateToProps = ({ config: { csrfToken } }) => {
  return { csrfToken };
};

export default connect(mapStateToProps)(AlumniOverlay);
