import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";

import UnitsRow from "./UnitsRow";
import ProfileSubTable from "./ProfileSubTable";

const unitsPresent = (units) => units !== null && units > 0;

const CumulativeUnits = () => {
  const {
    isCurrentSummerVisitor,
    totalUnits,
    totalLawUnits,
    totalTransferAndTestingUnits,
    totalUnitsTakenNotForGPA,
    totalUnitsPassedNotForGPA,
    totalPreviousCareerCumUnits,
    totalPreviousCareerLawUnits,
    transferUnitsAccepted,
    hasLawJointDegree,
  } = useSelector((state) => {
    const {
      myStatus: {
        academicRoles: {
          current: {
            summerVisitor: isCurrentSummerVisitor,
            lawJointDegree: hasLawJointDegree,
          } = {},
        } = {},
      },
    } = state;

    const {
      academicProfile: {
        units: {
          totalUnits,
          totalLawUnits,
          totalTransferAndTestingUnits,
          totalUnitsTakenNotForGPA,
          totalUnitsPassedNotForGPA,
          totalPreviousCareerCumUnits,
          totalPreviousCareerLawUnits,
          transferUnitsAccepted,
        } = {},
      } = {},
    } = currentUserState(state) || {};

    return {
      isCurrentSummerVisitor,
      totalUnits: totalUnits || 0,
      totalLawUnits: totalLawUnits || 0,
      totalTransferAndTestingUnits: totalTransferAndTestingUnits || 0,
      totalUnitsTakenNotForGPA: totalUnitsTakenNotForGPA || 0,
      totalUnitsPassedNotForGPA: totalUnitsPassedNotForGPA || 0,
      totalPreviousCareerCumUnits: totalPreviousCareerCumUnits || 0,
      totalPreviousCareerLawUnits: totalPreviousCareerLawUnits || 0,
      transferUnitsAccepted: transferUnitsAccepted || 0,
      hasLawJointDegree,
    };
  });

  if (!isCurrentSummerVisitor && (totalUnits > 0 || totalLawUnits > 0)) {
    let summaryTotalLawUnits = totalLawUnits;
    let summaryTotalUnits = totalUnits;
    let summaryTotalTransferUnits = totalTransferAndTestingUnits;
    let showPNP = true;
    if (
      hasLawJointDegree &&
      (totalLawUnits > 0 || totalPreviousCareerLawUnits > 0)
    ) {
      summaryTotalLawUnits = totalLawUnits + totalPreviousCareerLawUnits;
      summaryTotalUnits = totalUnits + totalPreviousCareerCumUnits;
      summaryTotalTransferUnits =
        totalTransferAndTestingUnits + transferUnitsAccepted;
      showPNP = false;
    }

    return (
      <tr>
        <th>Cumulative Units</th>
        <td>
          <ProfileSubTable>
            <tbody>
              <UnitsRow name="Total Units" value={summaryTotalUnits} />

              {unitsPresent(totalLawUnits) && (
                <UnitsRow name="Law Units" value={summaryTotalLawUnits} />
              )}

              {unitsPresent(totalTransferAndTestingUnits) && (
                <UnitsRow
                  name="Transfer Units"
                  value={summaryTotalTransferUnits.toFixed(3)}
                />
              )}

              {unitsPresent(totalUnitsTakenNotForGPA) && showPNP && (
                <UnitsRow name="P/NP Total" value={totalUnitsTakenNotForGPA} />
              )}

              {unitsPresent(totalUnitsPassedNotForGPA) && showPNP && (
                <UnitsRow
                  name="P/NP Passed"
                  value={totalUnitsPassedNotForGPA}
                />
              )}
            </tbody>
          </ProfileSubTable>
        </td>
      </tr>
    );
  }

  return null;
};

CumulativeUnits.displayName = "CumulativeUnits";

export default CumulativeUnits;
