import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import getUserLinks from "data/actions/getUserLinks";
import Popover, { usePopover } from "components/ui/Popover";
import InfoIcon from "components/ui/InfoIcon";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { UC_SR_EXP_GRAD_TERM_CHG_REQ } from "functions/urlIds";
import getMessageCatalog from "data/actions/getMessageCatalog";
import WithMessage from "components/WithMessage";
import WithAPILink from "components/ui/WithAPILink";
import LightText from "components/ui/LightText";
import { useCSRFToken } from "components/hooks/useCSRFToken";
import { useUser } from "components/hooks/useUser";

export function GradLawGraduation() {
  const dispatch = useDispatch();
  const csrfToken = useCSRFToken();
  const { graduation: { gradOrLaw: gradOrLawGraduation } = {} } = useUser(
    (user) => user.academicProfile
  );

  useEffect(() => {
    if (csrfToken) {
      dispatch(getMessageCatalog(csrfToken));
      dispatch(getUserLinks(csrfToken));
    }
  }, [csrfToken, dispatch]);

  if (gradOrLawGraduation === null || gradOrLawGraduation === undefined) {
    return null;
  }

  const { expectedGraduationTerms: terms = [] } = gradOrLawGraduation;

  if (terms.length > 0) {
    return (
      <>
        <dt data-testid="grad-law-graduation">Expected Graduation</dt>
        <dd>
          {terms.map((term, index) => (
            <GraduationTerm term={term} key={index} />
          ))}
        </dd>
      </>
    );
  }

  return null;
}

GradLawGraduation.displayName = "GradLawGraduation";
export default GradLawGraduation;

const GraduationTerm = ({ term }) => {
  const popover = usePopover();

  return (
    <div>
      {term.plans.map((plan) => (
        <LightText key={plan}>
          <span key={plan}>{plan}</span>
        </LightText>
      ))}
      <div>
        {term.expectedGradTermNames.join(", ")}
        &nbsp;
        {term.career && term.career.code && term.career.code == "GRAD" && (
          <ButtonAsLink onClick={popover.open}>
            <InfoIcon />
          </ButtonAsLink>
        )}
      </div>
      {term.career && term.career.code && term.career.code == "GRAD" && (
        <div style={{ position: `relative` }}>
          {popover.isOpen && (
            <WithMessage set="28000" number="321">
              {(message) => (
                <Popover
                  ref={popover.ref}
                  title={message.text}
                  close={popover.close}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: message.description }}
                  ></div>
                  <div style={{ marginTop: `5px` }}>
                    <WithAPILink urlId={UC_SR_EXP_GRAD_TERM_CHG_REQ} />
                  </div>
                </Popover>
              )}
            </WithMessage>
          )}
        </div>
      )}
    </div>
  );
};

GraduationTerm.displayName = "GraduationTerm";
GraduationTerm.propTypes = {
  term: PropTypes.object,
};
