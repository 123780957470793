import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import StatusItemLink from "./StatusItemLink";

function StandingsStatusItem({ currentStandings = [], loaded, onClick }) {
  if (loaded) {
    const hasAlert =
      currentStandings.length && currentStandings[0].statusCode !== "GST";

    if (hasAlert) {
      return (
        <StatusItemLink to="/academics" onClick={onClick}>
          <i className="fa fa-exclamation-circle cc-icon-red"></i>
          <strong>Academic Status</strong>
        </StatusItemLink>
      );
    }
  }

  return null;
}

StandingsStatusItem.propTypes = {
  currentStandings: PropTypes.array,
  loaded: PropTypes.bool,
  onClick: PropTypes.func,
};

function mapStateToProps({ myStandings: { currentStandings, loaded } }) {
  return { currentStandings, loaded };
}

export default connect(mapStateToProps)(StandingsStatusItem);
