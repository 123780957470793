import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import SpinnerIf from "components/ui/SpinnerIf";
import { BaseButton } from "components/ui/Buttons";
import { LargeSpacer } from "components/ui/VerticalSpacers";

import asyncRequest from "utils/asyncRequest";
import mapStateToCSRF from "utils/mapStateToCSRF";
import { updateServiceAlert } from "utils/requests";
import { validationSchema } from "./serviceAlert.module.js";
import ServiceAlertForm from "./ServiceAlertForm";
import PreviewBox from "./PreviewBox";
import ServiceAlert from "./ServiceAlert";

const destroyServiceAlert = async ({ id, csrfToken }) =>
  asyncRequest("DELETE")(`/api/service_alerts/${id}`, csrfToken)();

export default connect(mapStateToCSRF)(EditServiceAlert);

function EditServiceAlert({ csrfToken }) {
  const { id } = useParams();
  const [serviceAlert, setServiceAlert] = useState({
    title: "",
  });

  const [loadState, setLoadState] = useState("pending");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/service_alerts/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        setServiceAlert(data);
        setLoadState("success");
      });
  }, [id]);

  const onSubmit = (values, { setErrors }) => {
    updateServiceAlert(csrfToken)(id, values)
      .then((res) =>
        res.json().then((data) => ({
          data,
          res,
        }))
      )
      .then(({ data, res }) => {
        if (res.ok) {
          alert("Service alert updated successfully");
          navigate("/service_alerts");
        } else {
          setErrors(data);
        }
      });
  };

  const destroy = async () => {
    const confirmResponse = confirm(
      "Are you sure you want to delete this service alert?"
    );

    if (confirmResponse) {
      const res = await destroyServiceAlert({ csrfToken, id });

      if (res.ok) {
        alert("Service alert deleted successfully");
        navigate("/service_alerts");
      }
    }
  };

  return (
    <>
      <LargeSpacer />
      <Card>
        <CardTitle style={{ justifyContent: `space-between` }}>
          <h2>Edit Service Alert</h2>
          <BaseButton onClick={() => destroy()}>Delete</BaseButton>
        </CardTitle>
        <CardBody>
          <SpinnerIf loading={loadState === "pending"}>
            <Formik
              initialValues={serviceAlert}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <div style={{ display: `flex`, gap: `15px` }}>
                  <div style={{ flex: `1` }}>
                    <ServiceAlertForm />
                  </div>
                  <div style={{ flex: `1` }}>
                    <PreviewBox>
                      <ServiceAlert {...formik.values} />
                    </PreviewBox>
                  </div>
                </div>
              )}
            </Formik>
          </SpinnerIf>
        </CardBody>
      </Card>
    </>
  );
}

EditServiceAlert.displayName = "EditServiceAlert";
EditServiceAlert.propTypes = {
  csrfToken: PropTypes.string,
};
