import React from "react";
import { ErrorBoundary } from "../ui/ErrorBoundary";
import ViewAsCardUI from "./ViewAsCard/ViewAsCardUI";

export function ViewAsCard() {
  return (
    <ErrorBoundary title="View As">
      <ViewAsCardUI />
    </ErrorBoundary>
  );
}
