export const gradingLapseDeadlineMessage = ({
  gradingBasisIsFrozen,
  showGradingLapseDeadline,
  deadline,
}) => {
  if (showGradingLapseDeadline) {
    return "Student Completion Deadline is 30 days before " + deadline;
  }
  if (gradingBasisIsFrozen) {
    return "Frozen";
  }
};

export function indicateDisplayColumnIndex({
  showGradingLapseDeadline,
  lapseDateDisplayColumnIndex,
  gradingBasisIsFrozen,
  frozenDisplayColumnIndex,
}) {
  if (showGradingLapseDeadline) {
    return lapseDateDisplayColumnIndex;
  }
  if (gradingBasisIsFrozen) {
    return frozenDisplayColumnIndex;
  }
  return false;
}
