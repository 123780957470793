import * as Yup from "yup";
import PropTypes from "prop-types";

export const validationSchema = Yup.object({
  title: Yup.string().required("cannot be left blank"),
  body: Yup.string().required("cannot be left blank"),
  publication_date: Yup.date().required("is required").typeError("is invalid"),
});

export const serviceAlertProps = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  body: PropTypes.string,
  publicationDate: PropTypes.string,
  banner: PropTypes.bool,
  splashOnly: PropTypes.bool,
};

export const serviceAlertShape = PropTypes.shape(serviceAlertProps);
