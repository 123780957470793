import React from "react";
import { react2angular } from "react2angular";

import ReduxProvider from "components/ReduxProvider";
import SIRNextSteps from "components/SIRNextSteps";

function NgSIRNextSteps() {
  return (
    <ReduxProvider>
      <SIRNextSteps />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("sirNextSteps", react2angular(NgSIRNextSteps));
