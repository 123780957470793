import React from "react";
import PropTypes from "prop-types";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import FinancialAidTasks from "components/../react/components/_finances/FinancialAidTasks";

const NgFinancialAidTasks = ({ year }) => (
  <ReduxProvider>
    <FinancialAidTasks year={year} />
  </ReduxProvider>
);

NgFinancialAidTasks.propTypes = {
  year: PropTypes.string,
};

angular
  .module("calcentral.react")
  .component("financialAidTasks", react2angular(NgFinancialAidTasks));
