import styled from "styled-components";

const ListBullets = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:before {
    content: "–";
    margin-right: 5px;
  }
`;

export default ListBullets;
