import _ from "lodash";

const buildDataActions = ({
  url,
  key,
  start_const,
  success_const,
  failure_const,
}) => {
  const onStart = () => ({
    type: start_const,
  });

  const onSuccess = (value) => ({
    type: success_const,
    value: value,
  });

  const onFailure = (err) => ({
    type: failure_const,
    value: err,
  });

  const loadData = () => {
    return async (dispatch, getState) => {
      const state = _.get(getState(), key);

      if (state.loaded || state.isLoading) {
        return new Promise((resolve, _reject) => resolve(state));
      } else {
        dispatch(onStart());

        fetch(url, { method: "GET" })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
          })
          .then((data) => {
            dispatch(onSuccess(data));
          })
          .catch((error) => {
            if (error.response) {
              const failure = {
                status: error.response.status,
                statusText: error.response.statusText,
              };
              dispatch(onFailure(failure));
            }
          });
      }
    };
  };

  return {
    onStart,
    onSuccess,
    onFailure,
    loadData,
  };
};

export default buildDataActions;
