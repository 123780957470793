import React from "react";

import { TranscriptName } from "./TranscriptName";
import { Pronouns } from "../AcademicProfileCard/Pronouns";
import { AcademicProfileList } from "../AcademicProfileCard/AcademicProfileList";
import { Major } from "../AcademicProfileCard/Major";
import { Minor } from "../AcademicProfileCard/Minor";
import { Emphases } from "../AcademicProfileCard/Emphases";
import { Careers } from "../AcademicProfileCard/Careers";
import { Levels } from "../AcademicProfileCard/Levels";
import { GradLawGraduation } from "../AcademicProfileCard/GradLawGraduation";
import { UndergraduateGraduation } from "../AcademicProfileCard/UndergraduateGraduation";
import { CumulativeUnits } from "../AcademicProfileCard/CumulativeUnits";
import { GPA } from "../AcademicProfileCard/GPA";
import { Degrees } from "../AcademicProfileCard/Degrees";
import { LawAwards } from "../AcademicProfileCard/LawAwards";
import { useSlice } from "components/hooks/useSlice";

export const AcademicProfile = () => {
  const { sid: studentId } = useSlice("myStatus");

  return (
    <AcademicProfileList data-testid="academic-summary-student-profile">
      <dt>Name</dt>
      <dd>
        <TranscriptName />
        <Pronouns />
      </dd>

      <dt>Student ID</dt>
      <dd>{studentId}</dd>

      <Major />
      <Minor />
      <Emphases />
      <Careers />
      <Levels />
      <UndergraduateGraduation showCheckListLink={true} />
      <GradLawGraduation />
      <CumulativeUnits />
      <GPA />
      <Degrees />
      <LawAwards />
    </AcademicProfileList>
  );
};

AcademicProfile.displayName = "AcademicProfile";
