import React from "react";
import PropTypes from "prop-types";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";

export function ExternalLink({ href, children, showNewWindow = true }) {

  const target = showNewWindow ? "_blank" : "_self";
  const rel = showNewWindow ? "noopener noreferrer" : null;

  return (
    <a href={href} target={target} rel={rel}>
      <AccessibleLinkContent showNewWindow={showNewWindow}>
        {children}
      </AccessibleLinkContent>
    </a>
  );
}

ExternalLink.displayName = "ExternalLink";
ExternalLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  showNewWindow: PropTypes.bool,
};
