import React from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import { studentPropTypeShape } from "./propTypes";

import styled from "styled-components";

import { BaseButton } from "components/ui/Buttons";

export const MessageParagraph = styled.p`
  margin: 0 0 var(--small-spacing) 0;
`;

import { MessageBuilder } from "./MessageBuilder";

export const MessagesSection = function ({
  courseCode,
  displayedView,
  selectedStudents,
  semesterName,
  setDisplayedView,
  searchFilters,
}) {
  const { instructorsName } = useSelector((state) => {
    const { myStatus: { preferredName: instructorsName } = {} } = state || {};

    return { instructorsName };
  });

  if (selectedStudents) {
    const messageBuilder = new MessageBuilder(
      courseCode,
      semesterName,
      displayedView,
      instructorsName,
      searchFilters.enrollStatus,
      selectedStudents
    );
    const message = messageBuilder.message;

    return (
      <div>
        <div style={{ padding: "var(--medium-spacing) 0" }}>
          <MessageParagraph>
            The message in the text field below is for the classroom scheduler /
            enrollment manager (&quot;scheduler&quot;) for your class. To use
            it, copy the content, and paste it into your email application. Edit
            as needed, and address the message to your scheduler. Note that a
            subject is included. Pasting that into the subject field will make
            it easier for your scheduler to most efficiently gather and process
            requests from your department.
          </MessageParagraph>

          <MessageParagraph>
            <strong>Note:</strong> These students&apos; positions on waitlists,
            enrollment, etc. will not be updated in CalCentral until the
            scheduler has fulfilled your request.
          </MessageParagraph>
        </div>
        <div style={{ padding: "0 0 var(--large-spacing)" }}>
          <BaseButton
            onClick={() => {
              setDisplayedView("default");
            }}
          >
            Hide Message
          </BaseButton>
        </div>
        <textarea
          style={{
            marginRight: "var(--small-spacing)",
            height: "25em",
            width: "100%",
          }}
          value={message}
          onChange={(e) => e.stopPropagation()}
        ></textarea>
      </div>
    );
  } else {
    return null;
  }
};

MessagesSection.displayName = "MessagesSection";
MessagesSection.propTypes = {
  courseCode: PropTypes.string,
  displayedView: PropTypes.string,
  selectedStudents: PropTypes.arrayOf(studentPropTypeShape),
  semesterName: PropTypes.string,
  setDisplayedView: PropTypes.func,
  searchFilters: PropTypes.object,
};
