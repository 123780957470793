import { combineReducers } from "redux";

import enrollmentResources from "./users/enrollmentResources";
import userAuth from "./users/userAuth";

const userReducer = combineReducers({
  enrollmentResources,
  userAuth,
});

export default function UsersReducer(state = {}, action) {
  if (action.uid) {
    return { ...state, [action.uid]: userReducer(state[action.uid], action) };
  }
  return state;
}
