import styled from "styled-components";
import { BaseButtonStyles, BlueButtonStyles } from "./BaseButtonStyles";

const BaseButton = styled.button`
  ${BaseButtonStyles}
`;

const BlueButton = styled(BaseButton)`
  ${BlueButtonStyles}
`;

export { BaseButton, BlueButton };
