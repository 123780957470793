import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTestMode } from "./useTestMode";

export function dispatchSlice(fetchFunction) {
  const isTestMode = useTestMode();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isTestMode) {
      dispatch(fetchFunction());
    }
  }, [isTestMode, dispatch]);
}
