import { gql } from 'graphql-request';
import {
  MESSAGE_CATALOG_START,
  MESSAGE_CATALOG_SUCCESS,
} from '../action-types';

import graphqlClient from 'utils/graphqlClient';

const query = gql`
  {
    messages {
      set
      number
      text
      description
    }
  }
`;

export default function getMessageCatalog() {
  return (dispatch, getState) => {
    const { csrfToken, messageCatalog } = getState();

    if (
      messageCatalog.loadState === 'success' ||
      messageCatalog.loadState === 'pending'
    ) {
      return new Promise((resolve, _reject) => resolve(messageCatalog));
    } else {
      dispatch({ type: MESSAGE_CATALOG_START });

      graphqlClient(csrfToken)
        .request(query)
        .then(({ messages }) => {
          dispatch({
            type: MESSAGE_CATALOG_SUCCESS,
            value: messages,
          });
        });
    }
  };
}
