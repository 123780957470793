import React from "react";
import UnitsRow from "./UnitsRow";
import { useAcademicRoles } from "components/hooks/useAcademicRoles";
import { useUser } from "components/hooks/useUser";
import { SubList } from "./SubList";

const unitsPresent = (units) => units !== null && units > 0;

export function CumulativeUnits() {
  const {
    summerVisitor: isCurrentSummerVisitor,
    lawJointDegree: hasLawJointDegree,
  } = useAcademicRoles((roles) => roles);

  const units = useUser((user) => user.academicProfile.units);
  const {
    totalUnits = 0,
    totalLawUnits = 0,
    totalTransferAndTestingUnits = 0,
    totalUnitsTakenNotForGPA = 0,
    totalUnitsPassedNotForGPA = 0,
    totalPreviousCareerCumUnits = 0,
    totalPreviousCareerLawUnits = 0,
    transferUnitsAccepted = 0,
  } = units;

  if (!isCurrentSummerVisitor && (totalUnits > 0 || totalLawUnits > 0)) {
    let summaryTotalLawUnits = totalLawUnits;
    let summaryTotalUnits = totalUnits;
    let summaryTotalTransferUnits = totalTransferAndTestingUnits;
    let showPNP = true;
    if (
      hasLawJointDegree &&
      (totalLawUnits > 0 || totalPreviousCareerLawUnits > 0)
    ) {
      summaryTotalLawUnits = totalLawUnits + totalPreviousCareerLawUnits;
      summaryTotalUnits = totalUnits + totalPreviousCareerCumUnits;
      summaryTotalTransferUnits =
        totalTransferAndTestingUnits + transferUnitsAccepted;
      showPNP = false;
    }

    return (
      <>
        <dt data-testid="cumulative-units">Cumulative Units</dt>
        <dd>
          <SubList>
            <UnitsRow name="Total Units" value={summaryTotalUnits} />

            {unitsPresent(totalLawUnits) && (
              <UnitsRow name="Law Units" value={summaryTotalLawUnits} />
            )}

            {unitsPresent(totalTransferAndTestingUnits) && (
              <UnitsRow
                name="Transfer Units"
                value={summaryTotalTransferUnits.toFixed(3)}
              />
            )}

            {unitsPresent(totalUnitsTakenNotForGPA) && showPNP && (
              <UnitsRow name="P/NP Total" value={totalUnitsTakenNotForGPA} />
            )}

            {unitsPresent(totalUnitsPassedNotForGPA) && showPNP && (
              <UnitsRow name="P/NP Passed" value={totalUnitsPassedNotForGPA} />
            )}
          </SubList>
        </dd>
      </>
    );
  }

  return null;
}

CumulativeUnits.displayName = "CumulativeUnits";
export default CumulativeUnits;
