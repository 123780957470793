import React from "react";
import PropTypes from "prop-types";
import { Page, PageTitle, PageHeader } from "./Page";
import { SingleColumnLayout, PageColumn } from "./PageColumns";
import { LargeSpacer } from "./VerticalSpacers";

export const SingleCardPage = ({ children, title, header }) => {
  return (
    <Page>
      <PageTitle title={title} />
      <PageHeader>{header}</PageHeader>
      <LargeSpacer />
      <SingleColumnLayout>
        <PageColumn>{children}</PageColumn>
      </SingleColumnLayout>
    </Page>
  );
};
SingleCardPage.displayName = "SingleCardPage";
SingleCardPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  header: PropTypes.node,
};
