import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { updateStateProperty } from "../../../helpers/state";

import Icon from "../../Icon/Icon";
import { ICON_TIMES_CIRCLE } from "../../Icon/IconTypes";
import PnpCalculator from "./PnpCalculator";
import PNPMessageWrapper from "./PNPMessageWrapper";
import ReduxProvider from "components/ReduxProvider";

const propTypes = {
  $scope: PropTypes.object.isRequired,
  degreeProgressFactory: PropTypes.object.isRequired,
};

class PnpCalculatorContainer extends React.Component {
  constructor(props) {
    super(props);
    const errorMessage = (
      <React.Fragment>
        <Icon name={ICON_TIMES_CIRCLE} />
        This estimator is unavailable. Please try again later.
      </React.Fragment>
    );
    this.state = {
      calculator: {},
      calculatedProjectedValues: {
        maxRatioBase: null,
        countedGPAUnits: null,
        countedNoGPAUnits: null,
        excessNoGPAUnits: null,
        percentage: null,
      },
      calculatedTotals: {
        totalGPAUnits: null,
        totalNoGPAUnits: null,
        totalSum: null,
        totalTransferUnits: null,
      },
      estimateButtonDisabled: true,
      inputStatus: {
        errored: false,
        estimateButtonDisabled: true,
        hasTransferUnitInput: false,
      },
      inputValues: {
        totalTransferUnits: null,
        totalGPAUnits: null,
        totalNoGPAUnits: null,
      },
      ratioCalculation: {
        errored: false,
        isLoading: true,
        message: null,
        show: false,
      },
      widgetConfig: {
        errored: false,
        errorMessage: errorMessage,
        isLoading: true,
        padding: false,
        title: "1/3 Passed (P) Grade Limit",
        visible: false,
      },
    };
    this.calculateProjectedValues = this.calculateProjectedValues.bind(this);
    this.calculateTotals = this.calculateTotals.bind(this);
    this.handleAngularBroadcast = this.handleAngularBroadcast.bind(this);
    this.handleEstimateButtonPressed =
      this.handleEstimateButtonPressed.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRatioCalculationButtonPressed =
      this.handleRatioCalculationButtonPressed.bind(this);
    this.isValidInput = this.isValidInput.bind(this);
    this.parseCalculatorValues = this.parseCalculatorValues.bind(this);
    this.updateStateFromInput = this.updateStateFromInput.bind(this);
    this.updateVisibility = this.updateVisibility.bind(this);
  }
  componentDidMount() {
    this.props.$scope.$on(
      "calcentral.custom.api.showPnpCalculator",
      this.handleAngularBroadcast
    );
  }
  calculateProjectedValues() {
    const projectedValues = { ...this.state.calculatedProjectedValues };
    const totals = { ...this.state.calculatedTotals };
    projectedValues.maxRatioBase = 120 - totals.totalTransferUnits;
    projectedValues.countedGPAUnits = Math.min(
      totals.totalGPAUnits,
      projectedValues.maxRatioBase
    );
    projectedValues.countedNoGPAUnits = Math.min(
      totals.totalNoGPAUnits,
      projectedValues.maxRatioBase - projectedValues.countedGPAUnits
    );
    projectedValues.excessNoGPAUnits =
      totals.totalNoGPAUnits - projectedValues.countedNoGPAUnits;

    const percentage = Math.round(
      (projectedValues.countedNoGPAUnits /
        (projectedValues.countedGPAUnits + projectedValues.countedNoGPAUnits)) *
        100
    );
    projectedValues.percentage = Math.max(0, percentage);

    updateStateProperty(this, {
      calculatedProjectedValues: { $set: projectedValues },
      inputStatus: { estimateButtonDisabled: { $set: true } },
    });
  }
  calculateTotals() {
    const calculatedTotals = { ...this.state.calculatedTotals };
    const calculatorValues = { ...this.state.calculator };
    const inputValues = { ...this.state.inputValues };
    let totalSum = 0;
    for (const name of Object.keys(inputValues)) {
      calculatedTotals[name] =
        calculatorValues[name] + (inputValues[name] || 0);
      totalSum += calculatedTotals[name];
    }
    calculatedTotals.totalSum = totalSum;
    updateStateProperty(this, { calculatedTotals: { $set: calculatedTotals } });
  }
  handleAngularBroadcast() {
    this.updateVisibility()
      .then(() => {
        return this.props.degreeProgressFactory.getPnpCalculatorValues();
      })
      .then((response) => {
        return this.parseCalculatorValues(response);
      })
      .then((calculatorValues) => {
        const totalUnits =
          calculatorValues.totalGPAUnits +
          calculatorValues.totalNoGPAUnits +
          calculatorValues.totalTransferUnits;
        const excessNoGPAUnits =
          calculatorValues.totalNoGPAUnits - calculatorValues.noGPARatioUnits;
        calculatorValues.hasExcessNoGPAUnits =
          totalUnits > 120 && excessNoGPAUnits > 0;
        return updateStateProperty(this, {
          calculator: { $set: calculatorValues },
        });
      })
      .then(() => {
        return this.calculateTotals();
      })
      .catch(() => {
        return updateStateProperty(this, {
          widgetConfig: { errored: { $set: true } },
        });
      })
      .finally(() => {
        return updateStateProperty(this, {
          widgetConfig: { isLoading: { $set: false } },
        });
      });
  }
  handleEstimateButtonPressed(event) {
    event.preventDefault();
    this.calculateProjectedValues();
  }
  handleInputChange(event) {
    this.updateStateFromInput(event).then(this.calculateTotals);
  }
  handleRatioCalculationButtonPressed(event) {
    event.preventDefault();
    updateStateProperty(this, { ratioCalculation: { show: { $set: true } } });

    this.props.degreeProgressFactory
      .getPnpCalculatorMessage()
      .then((response) => {
        const message = _.get(response, "data.descrlong");
        return updateStateProperty(this, {
          ratioCalculation: { message: { $set: message } },
        });
      })
      .catch(() => {
        return updateStateProperty(this, {
          ratioCalculation: { errored: { $set: true } },
        });
      })
      .finally(() => {
        return updateStateProperty(this, {
          ratioCalculation: { isLoading: { $set: false } },
        });
      });
  }
  isValidInput(input) {
    const inputValues = Object.values(input);
    let isValid = false;
    let hasNegativeTransferUnit = false;
    let hasTransferUnitInput = false;
    if (inputValues.some((value) => Number.isFinite(value))) {
      if (
        Number.isFinite(input.totalTransferUnits) &&
        parseFloat(input.totalTransferUnits) < 0
      ) {
        hasNegativeTransferUnit = true;
      } else {
        hasTransferUnitInput = Number.isFinite(input.totalTransferUnits);
        isValid = true;
      }
    }
    return {
      hasNegativeTransferUnit: hasNegativeTransferUnit,
      hasTransferUnitInput: hasTransferUnitInput,
      isValid: isValid,
    };
  }
  updateStateFromInput(event) {
    return new Promise((resolve) => {
      const { value, name } = event.target;
      const inputValues = { ...this.state.inputValues };
      inputValues[name] = parseFloat(value);
      const { hasNegativeTransferUnit, hasTransferUnitInput, isValid } =
        this.isValidInput(inputValues);
      updateStateProperty(this, {
        inputStatus: {
          errored: { $set: hasNegativeTransferUnit },
          estimateButtonDisabled: { $set: !isValid },
          hasTransferUnitInput: { $set: hasTransferUnitInput },
        },
        inputValues: { $set: inputValues },
      });
      resolve();
    });
  }
  updateVisibility() {
    return new Promise((resolve) => {
      updateStateProperty(this, { widgetConfig: { visible: { $set: true } } });
      resolve();
    });
  }
  parseCalculatorValues(response) {
    return new Promise((resolve) => {
      const values = _.get(response, "data");
      delete values.isLoading;
      const parsed = _.mapValues(values, (value) => {
        return parseFloat(value);
      });
      resolve(parsed);
    });
  }
  render() {
    return (
      <ReduxProvider>
        <PNPMessageWrapper>
          {(calculatorRatioHTML) => {
            if (calculatorRatioHTML) {
              return (
                <PnpCalculator
                  calculator={{ ...this.state.calculator }}
                  calculatedProjectedValues={{
                    ...this.state.calculatedProjectedValues,
                  }}
                  calculatedTotals={{ ...this.state.calculatedTotals }}
                  handleEstimateButtonPressed={this.handleEstimateButtonPressed}
                  handleInputChange={this.handleInputChange}
                  handleRatioCalculationButtonPressed={
                    this.handleRatioCalculationButtonPressed
                  }
                  inputStatus={{ ...this.state.inputStatus }}
                  ratioCalculation={{ ...this.state.ratioCalculation }}
                  widgetConfig={{ ...this.state.widgetConfig }}
                  calculatorRatioHTML={calculatorRatioHTML}
                />
              );
            } else {
              return null;
            }
          }}
        </PNPMessageWrapper>
      </ReduxProvider>
    );
  }
}

PnpCalculatorContainer.propTypes = propTypes;

export default PnpCalculatorContainer;
