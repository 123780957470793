import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BaseButton } from "components/ui/Buttons";
import { studentPropTypeShape } from "./propTypes";

const EnrollmentActionInstructions = styled.div`
  padding: var(--medium-spacing) 0;

  p {
    margin-bottom: var(--small-spacing);
  }
`;
EnrollmentActionInstructions.displayName = "EnrollmentActionInstructions";

const selectedStudentsEmailList = function (selectedStudents) {
  if (selectedStudents) {
    return selectedStudents
      .map((student) => {
        return student.email;
      })
      .join(",");
  } else {
    return "";
  }
};

export const AddressesSection = function ({
  displayedView,
  selectedStudents = [],
  setDisplayedView,
}) {
  if (selectedStudents) {
    return (
      <div>
        <EnrollmentActionInstructions>
          <p>
            <strong>
              {selectedStudents.length} student addresses ready to be copied.
            </strong>
          </p>
          <p>
            Copy the email addresses from the text field below, and paste them
            into your email application. For larger classes, scrolling may be
            needed to view all addresses. With FERPA in mind, use the BCC field
            as needed. Click Hide Addresses when finished.
          </p>
        </EnrollmentActionInstructions>
        <BaseButton
          onClick={() => {
            setDisplayedView("default");
          }}
          disabled={displayedView !== "addresses"}
          style={{ margin: "0 0 var(--large-spacing)" }}
        >
          Hide Addresses
        </BaseButton>
        <textarea
          style={{ height: "25em", width: "100%" }}
          defaultValue={selectedStudentsEmailList(selectedStudents)}
        ></textarea>
      </div>
    );
  } else {
    return null;
  }
};

AddressesSection.displayName = "AddressesSection";
AddressesSection.propTypes = {
  displayedView: PropTypes.string,
  selectedStudents: PropTypes.arrayOf(studentPropTypeShape),
  setDisplayedView: PropTypes.func,
};
