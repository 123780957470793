import {
  filterEnrollment,
  filterCCN,
  sortStudents,
} from "../RosterCard/functions";

export class StudentFilter {
  constructor(students, sections, searchFilters) {
    this.students = students;
    this.sections = sections;
    this.searchFilters = searchFilters;

    const { enrollStatus, section } = searchFilters || {};
    this.searchSection = section;
    this.enrollStatus = enrollStatus;
    this.useWaitlistCounts = enrollStatus === "waitlisted";
  }

  //////////////////////////////////////////////////////////////////////////////
  // Getters

  get filteredStudentResults() {
    const filteredStudents = this.getFilteredStudents();
    const stats = this.getStats();
    return { ...stats, shownStudents: filteredStudents };
  }

  //////////////////////////////////////////////////////////////////////////////
  // Functions

  getFilteredStudents() {
    const {
      section: searchOptionSection,
      enrollStatus: searchOptionEnrollStatus,
      sortColumn,
      sortDirection,
    } = this.searchFilters || {};

    const { ccn: searchOptionSectionCCN = "" } = searchOptionSection || {};

    if (this.students) {
      if (!this.searchFilters) {
        return this.students;
      } else {
        const filteredStudents = this.students
          ? [...this.students]
              .filter(filterEnrollment(searchOptionEnrollStatus))
              .filter(filterCCN(searchOptionSectionCCN))
              .sort(sortStudents(sortColumn, sortDirection))
          : [];

        return filteredStudents;
      }
    } else {
      return [];
    }
  }

  getStats() {
    const filteredStudents = this.getFilteredStudents();

    // calculate stats
    var openSeatsCount =
      this.searchSection === null
        ? this.primarySectionOpenSeats(this.sections, this.searchSection)
        : this.getOpenSeatCount(this.sections, this.searchSection);

    var shownStudentCount = this.getCount(filteredStudents);
    var totalStudentCount = this.getCount(this.students);

    return {
      shownStudentCount: shownStudentCount,
      totalStudentCount: totalStudentCount,
      openSeatsCount: openSeatsCount,
    };
  }

  // Returns length of array, or 0 if not an array
  getCount(array) {
    return Array.isArray(array) ? array.length : 0;
  }

  /**
   * Returns the number of available seats for the course, for the specified enrollment status
   * @param  {Array}   sections           all sections for the course
   * @param  {Object}  selectedSection    current selected section
   * @return {Number}                     Number of open enrollment or waitlist seats in entire course or selected section
   */
  getOpenSeatCount(sections, selectedSection) {
    if (sections === undefined) {
      return;
    }
    const useWaitlistCounts = this.useWaitlistCounts;
    const { ccn: selectedSectionId } = selectedSection || {};

    return sections.reduce(function (count, section) {
      const { ccn } = section;
      if (!selectedSectionId || selectedSectionId === ccn) {
        // eslint-disable-next-line no-extra-boolean-cast
        count += !!useWaitlistCounts
          ? section.waitlist_open
          : section.enroll_open;
      }

      return count;
    }, 0);
  }

  /**
   * Returns the number of open seats for the section provided
   * @param  {Array}    sections            All sections for the course
   * @param  {Object}   section             Section object
   * @return {Array}                        Filtered array of students
   */
  primarySectionOpenSeats(sections, section) {
    if (sections === undefined) {
      return;
    }

    const { ccn: sectionId } = section || {};

    return sections.reduce(function (count, section) {
      const { is_primary: isPrimary, ccn } = section;

      if (isPrimary && (!sectionId || sectionId === ccn)) {
        // eslint-disable-next-line no-extra-boolean-cast
        count += !!this.useWaitlistCounts
          ? section.waitlist_open
          : section.enroll_open;
      }

      return count;
    }, 0);
  }
}
