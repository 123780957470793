import React from "react";
import { react2angular } from "react2angular";

import ReduxProvider from "components/data/ReduxProvider";
import { FacultyResourcesCard } from "components/cards/FacultyResourcesCard";

function NgFacultyResourcesCard() {
  return (
    <ReduxProvider>
      <FacultyResourcesCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("facultyResourcesCard", react2angular(NgFacultyResourcesCard));
