import valuesToArray from '../../functions/valuesToArray';

import {
  FETCH_FINRES_LINKS_START,
  FETCH_FINRES_LINKS_SUCCESS,
  FETCH_FINRES_LINKS_FAILURE,
} from '../action-types';

export const fetchFinresLinksStart = () => ({
  type: FETCH_FINRES_LINKS_START,
});

export const fetchFinresLinksSuccess = links => ({
  type: FETCH_FINRES_LINKS_SUCCESS,
  value: links,
});

export const fetchFinresLinksFailure = error => ({
  type: FETCH_FINRES_LINKS_FAILURE,
  value: error,
});

export const fetchFinresLinks = () => {
  return (dispatch, getState) => {
    const { financialResourcesLinks } = getState();

    if (financialResourcesLinks.loaded || financialResourcesLinks.isLoading) {
      return new Promise((resolve, _reject) =>
        resolve(financialResourcesLinks)
      );
    } else {
      dispatch(fetchFinresLinksStart());

      fetch('/api/financial_aid/financial_resources', { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchFinresLinksFailure(failure));
          }
        })
        .then((data) => {
          dispatch({
            type: 'SET_API_LINKS',
            value: valuesToArray(data.links),
          });
          dispatch(fetchFinresLinksSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchFinresLinksFailure(failure));
          }
        });
    }
  };
};
