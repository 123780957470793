import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UsersTable from "./UsersTable";

export default connect(mapStateToProps)(StoredUsersTabPanel);

function StoredUsersTabPanel({ users }) {
  return <UsersTable data-testid="stored-users-tab-panel" users={users} />;
}
StoredUsersTabPanel.displayName = "StoredUsersTabPanel";
StoredUsersTabPanel.propTypes = {
  users: PropTypes.array,
};

function mapStateToProps({
  storedUsers: { data: { users: { saved = [] } = {} } = {} } = {},
}) {
  return { users: saved };
}
