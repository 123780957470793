import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import EmploymentAppointments from "components/../react/components/_user_overview/EmploymentAppointments/EmploymentAppointments";

const NgEmploymentAppointments = () => (
  <ReduxProvider>
    <EmploymentAppointments />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("employmentAppointments", react2angular(NgEmploymentAppointments));
