import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { BasicTable, HeaderRow, UnderlinedRow } from "components/ui/Table";
import { LargeSpacer } from "components/ui/VerticalSpacers";
import { serviceAlertShape } from "./serviceAlert.module.js";

import SpinnerIf from "components/ui/SpinnerIf.js";
import Pagination from "components/Pagination";

export default function AllServiceAlerts() {
  const [serviceAlerts, setServiceAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadState, setLoadState] = useState("pending");
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    fetch(`/api/service_alerts?page=${currentPage}`)
      .then((res) => res.json())
      .then((data) => {
        setServiceAlerts(data.service_alerts);
        setCurrentPage(data.current_page);
        setTotalPages(data.total_pages);
        setLoadState("success");
      });
  }, [currentPage]);

  return (
    <>
      <LargeSpacer />
      <Card>
        <CardTitle>
          <h2>Service Alerts</h2>
          <Link to="/service_alerts/new">New Service Alert</Link>
        </CardTitle>
        <CardBody>
          <SpinnerIf loading={loadState === "pending"}>
            <>
              <LargeSpacer />
              <BasicTable>
                <thead>
                  <HeaderRow>
                    <th>Title</th>
                    <th>Pub. Date</th>
                    <th>Splash</th>
                    <th>Banner</th>
                  </HeaderRow>
                </thead>
                <tbody>
                  {serviceAlerts.map((alert) => (
                    <ServiceAlertRow key={alert.id} serviceAlert={alert} />
                  ))}
                </tbody>
              </BasicTable>

              <LargeSpacer />
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                loadState={loadState}
                setLoadState={setLoadState}
              />
            </>
          </SpinnerIf>
        </CardBody>
      </Card>
    </>
  );
}
AllServiceAlerts.displayName = "AllServiceAlerts";

const ServiceAlertRow = ({
  serviceAlert: { id, title, publication_date, splash, banner },
}) => (
  <UnderlinedRow>
    <td>
      <Link to={`/service_alerts/${id}/edit`}>{title}</Link>
    </td>
    <td>{publication_date}</td>
    <td>{splash ? "true" : null}</td>
    <td>{banner ? "true" : null}</td>
  </UnderlinedRow>
);

ServiceAlertRow.displayName = "ServiceAlertRow";
ServiceAlertRow.propTypes = { serviceAlert: serviceAlertShape };
