import styled from "styled-components";

import { Link } from "react-router-dom";

const StatusItemLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: var(--small-spacing);
  padding: var(--large-spacing) var(--medium-spacing);
  color: var(--denim);

  background: white;
  text-decoration: none;
  border-bottom: 1px solid #eee;

  &:hover {
    background: var(--foam);
    text-decoration: none;
  }
`;

export default StatusItemLink;
