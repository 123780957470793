import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import fetchStoredUsers, { refreshStoredUsers } from "data/actions/storedUsers";
import getMessageCatalog from "data/actions/getMessageCatalog";
import UIDLookupForm from "components/UIDLookupForm";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import Spinner from "components/ui/Spinner";
import AlumniMessage from "./AlumniMessage";
import SavedUsers from "./SavedUsers";
import RecentUsers from "./RecentUsers";
import { advisorViewAsUID, viewAsUID } from "utils/requests";
import { useSlice } from "components/hooks/useSlice";
import { useRoles } from "components/hooks/useRoles";

export default function ViewAsCard() {
  const dispatch = useDispatch();

  const { loadState: messageCatalogLoadState } = useSlice("messageCatalog");
  const {
    loadState,
    data: { users: { saved: savedUsers, recent: recentUsers } = {} } = {},
  } = useSlice("storedUsers");

  const { isSuperuser, isViewer } = useSlice("myStatus");
  const csrfToken = useSlice("csrfToken");
  const isAdvisor = useRoles((roles) => roles.advisor);

  const [refresh, setRefresh] = useState(0);
  const refreshCard = () => setRefresh(refresh + 1);

  const isReady =
    messageCatalogLoadState === "success" &&
    (loadState === "success" || loadState === "reloading");

  useEffect(() => {
    if (csrfToken && (isViewer || isSuperuser || isAdvisor)) {
      if (refresh > 0) {
        dispatch(refreshStoredUsers());
      } else {
        dispatch(getMessageCatalog(csrfToken));
        dispatch(fetchStoredUsers());
      }
    }
  }, [dispatch, csrfToken, refresh, isViewer, isSuperuser, isAdvisor]);

  const isAdvisorOnly = isAdvisor && !isSuperuser && !isViewer;

  const viewAsUser = async (user) => {
    const req = isAdvisorOnly
      ? advisorViewAsUID(csrfToken)
      : viewAsUID(csrfToken);
    const res = await req(user.ldapUid);
    if (res.ok) {
      window.location = "/";
    }
  };

  if (isViewer || isSuperuser || isAdvisor) {
    return (
      <Card>
        <CardTitle>
          <h2>View As</h2>
        </CardTitle>
        <CardBody>
          {isReady ? (
            <>
              <AlumniMessage />
              <UIDLookupForm
                recentUsers={recentUsers}
                viewAsUser={viewAsUser}
              />

              {savedUsers.length > 0 && (
                <>
                  <MediumSpacer />
                  <SavedUsers
                    savedUsers={savedUsers}
                    refreshCard={refreshCard}
                    viewAsUser={viewAsUser}
                  />
                </>
              )}

              {recentUsers.length > 0 && (
                <>
                  <MediumSpacer />
                  <RecentUsers
                    recentUsers={recentUsers}
                    refreshCard={refreshCard}
                    viewAsUser={viewAsUser}
                  />
                </>
              )}
            </>
          ) : (
            <Spinner message="loading..." />
          )}
        </CardBody>
      </Card>
    );
  }
  return null;
}
ViewAsCard.displayName = "ViewAsCard";
