import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";
import { fetchAcademicProfile } from "data/slices/users/academicProfile";

import { Card, CardBody, CardTitle } from "components/ui/Card";
import ListBullets from "components/ui/ListBullets";
import Spinner from "components/ui/Spinner";
import styled from "styled-components";
import WithMessage from "components/WithMessage";

const AlignedListHeader = styled.h3`
  margin: 10px 10px 10px -15px;
`;
function GraduationChecklist() {
  const { currentUID, termName } = useSelector((state) => {
    const { currentUID } = state;

    const {
      academicProfile: {
        graduation: { undergraduate: { termName } = {} } = {},
      } = {},
    } = currentUserState(state) || {};

    return {
      currentUID,
      termName,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAcademicProfile(currentUID));
  }, [dispatch, currentUID]);

  if (termName === undefined) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardTitle>
        <h2>Graduation Checklist</h2>
      </CardTitle>
      <CardBody>
        <div style={{ padding: `var(--small-spacing) 0` }}>
          Your expected graduation term is:&nbsp;
          <strong>{termName}</strong>
        </div>

        <p>It is expected that you complete the following before graduation:</p>

        <ListBullets>
          <AlignedListHeader>Required Tasks</AlignedListHeader>
          <WithMessage set="28000" number="210">
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            )}
          </WithMessage>
        </ListBullets>

        <ListBullets>
          <AlignedListHeader>For Students with Loans</AlignedListHeader>
          <WithMessage set="28000" number="211">
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            )}
          </WithMessage>
        </ListBullets>

        <ListBullets>
          <AlignedListHeader>Recommended Tasks</AlignedListHeader>
          <WithMessage set="28000" number="212">
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            )}
          </WithMessage>
        </ListBullets>
      </CardBody>
    </Card>
  );
}

GraduationChecklist.propTypes = {
  termName: PropTypes.string,
  dispatch: PropTypes.func,
};

export default GraduationChecklist;
