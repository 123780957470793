import styled from "styled-components";

export const FieldWrapper = styled.div`
  margin-top: var(--large-spacing);
  display: flex;
  flex-direction: column;

  label {
    text-align: left;
    font-size: 13px;
  }
`;

export const CheckboxFieldWrapper = styled(FieldWrapper)`
  display: flex;
  align-items: center;
  flex-direction: row;

  label {
    margin: 0 var(--small-spacing);
  }
`;
