import {
  FETCH_STANDINGS_START,
  FETCH_STANDINGS_SUCCESS,
  FETCH_STANDINGS_FAILURE,
} from "../action-types";

export const fetchStandingsStart = () => ({
  type: FETCH_STANDINGS_START,
});

export const fetchStandingsSuccess = (standings) => ({
  type: FETCH_STANDINGS_SUCCESS,
  value: standings,
});

export const fetchStandingsFailure = (error) => ({
  type: FETCH_STANDINGS_FAILURE,
  value: error,
});

export const fetchStandings = () => {
  return (dispatch, getState) => {
    dispatch(fetchStandingsStart());

    const { myStandings } = getState();

    if (myStandings.loaded || myStandings.isLoading) {
      return new Promise((resolve, _reject) => resolve(myStandings));
    } else {
      const url = "/api/my/standings";
      fetch(url, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchStandingsFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchStandingsSuccess(data.feed));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchStandingsFailure(failure));
          }
        });
    }
  };
};
