import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import CSRFToken from "components/CSRFToken";
import MainContent from "components/MainContent";
import { LargeSpacer } from "components/ui/VerticalSpacers";

const NgMainContent = () => (
  <ReduxProvider>
    <CSRFToken />
    <LargeSpacer />
    <MainContent />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("mainContent", react2angular(NgMainContent));
