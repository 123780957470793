import styled from "styled-components";

export const AcademicSummaryTable = styled.table`
  width: 100%;
  text-align: left;

  .text-right {
    text-align: right;
  }

  thead tr th {
    color: var(--dove-gray);
    font-size: 14px;
  }
`;
