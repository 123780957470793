import styled from "styled-components";

export default styled.div`
  p {
    margin: 0;

    & + p {
      margin-top: 10px;
    }
  }
`;
