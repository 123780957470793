import React from "react";

import format from "date-fns/format";
import styled from "styled-components";

import { AccordionButton as BaseAccordionButton } from "@reach/accordion";
import "@reach/accordion/styles.css";
import { VisuallyHidden } from "@reach/visually-hidden";
import { bCalScheduleItemShape } from "components/propTypes/bCalScheduleItemShape";

export default function AccordionButton({ item }) {
  const { isAllDay, location, start: startTime, summary } = item;

  return (
    <AccordionButtonStyles>
      <VisuallyHidden>Show more information about {summary}</VisuallyHidden>

      {isAllDay ? (
        <div className="datetime allDay">
          all <br /> day
        </div>
      ) : (
        <div className="datetime time">
          <strong>{format(startTime.epoch * 1000, "h:mm")}</strong>
          <br />
          {format(startTime.epoch * 1000, "a").toUpperCase()}
        </div>
      )}

      <div className="summary">{summary}</div>

      {location && (
        <div className="location datelist-location text-light">{location}</div>
      )}
    </AccordionButtonStyles>
  );
}

AccordionButton.displayName = "AccordionButton";
AccordionButton.propTypes = {
  item: bCalScheduleItemShape,
};

const AccordionButtonStyles = styled(BaseAccordionButton)`
  border: none;
  line-height: 1.6;
  background: transparent;
  color: var(--base-color);
  font-family: var(--base-font-family);
  cursor: pointer;
  display: grid;
  grid-template-columns: 40px 1fr;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  .datetime {
    grid-row: 1 / 3;
    text-align: right;
    color: var(--dusty-gray);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;

    strong {
      color: var(--base-color);
    }

    &.allDay {
      text-transform: uppercase;
    }
  }

  .summary,
  .location {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .summary {
    font-weight: bold;
    font-size: 12px;
    padding-right: 15px;
  }

  .location {
    color: var(--dusty-gray);
    text-transform: uppercase;
    font-size: 10px;
    margin-top: 2px;
  }
`;
