import React from "react";

import FontAwesomeIcon from "./FontAwesomeIcon";
import { ICON_EXCLAMATION } from "./IconTypes";

const RedExclamationIcon = () => (
  <FontAwesomeIcon name={ICON_EXCLAMATION} color="red" />
);

export default RedExclamationIcon;
