import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const UserButtonStyles = styled.button`
  background: transparent;
  color: white;
  text-decoration: none;
  padding: 2px 3px;
  border-radius: 5px;
  border: 0;
  font-size: 12px;
  font-weight: 300;
  transition: background-color 300ms ease-out;

  &:focus,
  &:hover {
    background: var(--bismark);
    text-decoration: none;
  }

  span.spacer {
    height: 25px;
  }

  span:nth-child(2) {
    border: 1px solid #fff;
    border-radius: 25px;
    display: inline-block;
    height: 25px;
    margin-right: 8px;
    width: 25px;
  }

  > div {
    display: flex;
    align-items: center;
    height: 25px;
  }

  &.active {
    background: var(--bismark);
    text-decoration: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    > div {
      margin-bottom: calc(var(--small-spacing) + 1px);
    }
  }
`;

function UserButton({ onClick, children, active, node, ...rest }) {
  return (
    <UserButtonStyles
      data-testid="user-button"
      onClick={onClick}
      className={active ? "active" : null}
      ref={node}
      {...rest}
    >
      <div className="flex-container">
        <span className="spacer" />
        {children}
      </div>
    </UserButtonStyles>
  );
}

UserButton.propTypes = {
  firstName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  active: PropTypes.bool,
  node: PropTypes.any,
};
UserButton.displayName = "UserButton";
export default UserButton;
