import styled from "styled-components";

const AcademicProfileTable = styled.table`
  border-spacing: 0;
  width: 100%;

  // Using descendant selectors so only the direct child cells are targeted
  // preventing the styling from applying to sub-tables
  > tbody > tr > th {
    color: var(--dove-gray);
  }

  > tbody > tr > th,
  > tbody > tr > td {
    padding: 10px 0;
    border-bottom: 1px solid var(--gallery);
    vertical-align: top;
    text-align: left;
  }

  tr:last-child {
    th,
    td {
      border-bottom-width: 0;
    }
  }
`;

export default AcademicProfileTable;
