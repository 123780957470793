import React, { useState } from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";
import ProfileSubTable from "./ProfileSubTable";
import ButtonAsLink from "components/ui/ButtonAsLink";

import { formatGPACumulative } from "components/../react/helpers/gpa";

const GPAToggle = () => {
  const { gpa, currentlySummerVisitor } = useSelector((state) => {
    const {
      myStatus: {
        academicRoles: { current: { summerVisitor } = {} } = {},
      } = {},
    } = state;

    const { academicProfile: { gpa = [] } = {} } =
      currentUserState(state) || {};

    return {
      gpa: gpa || [],
      currentlySummerVisitor: summerVisitor,
    };
  });

  const hasNonLawRole = gpa.find((item) => item.role !== "law");

  const [visible, setVisible] = useState(false);

  if (!currentlySummerVisitor && hasNonLawRole) {
    return (
      <tr>
        <th>GPA</th>
        <td>
          {visible ? (
            <div>
              {gpa.length === 1 ? (
                formatGPACumulative(gpa[0])
              ) : (
                <ProfileSubTable>
                  <tbody>
                    {gpa.map((theGPA) => (
                      <tr key={theGPA.roleDescr}>
                        <th>{theGPA.roleDescr}</th>
                        <td>{formatGPACumulative(theGPA)}</td>
                      </tr>
                    ))}
                  </tbody>
                </ProfileSubTable>
              )}
              <>
                {" "}
                (
                <ButtonAsLink onClick={() => setVisible(false)}>
                  Hide
                </ButtonAsLink>
                )
              </>
            </div>
          ) : (
            <ButtonAsLink onClick={() => setVisible(true)}>
              Show GPA
            </ButtonAsLink>
          )}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

GPAToggle.displayName = "GPAToggle";
export default GPAToggle;
