import React from "react";
import PropTypes from "prop-types";
import { termFromId } from "components/../react/helpers/terms";

import InfoIconButton from "./DefaultEnrollment/InfoIconButton";
import Popover, { usePopover } from "components/Popover";
import WithAPILink from "components/ui/WithAPILink";

export default function TermLabel({ termId, includeEnrollmentCaps = false }) {
  const { semester, year } = termFromId(termId);
  const popover = usePopover();

  return (
    <div style={{ position: "relative" }}>
      <strong>{`${semester} ${year} Dates`}</strong> (Pacific Time){" "}
      {includeEnrollmentCaps && <InfoIconButton onClick={popover.open} />}
      {popover.isOpen && (
        <Popover
          ref={popover.ref}
          title="Enrollment Unit Caps"
          close={popover.close}
          inset={true}
        >
          Students are allowed to enroll in a maximum number of units depending
          on their enrollment phase and degree.{" "}
          <WithAPILink urlID="UC_SR_SS_ENRL_UNIT_CAPS" />
        </Popover>
      )}
    </div>
  );
}

TermLabel.propTypes = {
  termId: PropTypes.string,
  includeEnrollmentCaps: PropTypes.bool,
};
