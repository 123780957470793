import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import YellowExclamationTriangle from "./YellowExclamationTriangle";
import RedExclamationCircle from "./RedExclamationCircle";
import StatusItemLink from "./StatusItemLink";

export default connect(mapStateToProps)(HoldsStatusItem);

function HoldsStatusItem({ holds, errored, onClick }) {
  if (errored) {
    return (
      <StatusItemLink to="/academics" onClick={onClick}>
        <YellowExclamationTriangle />
        <div className="description">
          <strong>Holds:</strong> Currently Unavailable
        </div>
      </StatusItemLink>
    );
  } else if (holds.length) {
    return (
      <StatusItemLink to="/academics" onClick={onClick}>
        <RedExclamationCircle />
        <div className="description">
          <strong>Holds:</strong> {holds.length}
        </div>
      </StatusItemLink>
    );
  }

  return null;
}

HoldsStatusItem.propTypes = {
  holds: PropTypes.array,
  onClick: PropTypes.func,
  errored: PropTypes.bool,
};

function mapStateToProps({ myHolds: { holds = [], errored } = {} }) {
  return { holds, errored };
}
