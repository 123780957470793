import PropTypes from "prop-types";
import { connect } from "react-redux";
import qs from "querystringify";

export default connect(mapStateToProps)(TransformsAPILink);

function mapStateToProps({ currentRoute }) {
  return { currentRoute };
}

// Uses the render-props pattern to take a API link, appends referrer to the
// URL and transforms the elements ready for rendering into a normal <a>
// link by spreading the properties onto the link element (or a styled
// variation).
//
// Note: Should be used with AccessibleLinkContent to render "opens in new
// window" message automatically.
//
// This is a low-level UI component. Consider using an alternative like
// AccessibleAPILink or BlueButtonLink (which do this work by default) or
// implementing a variation.
//
// Example:
//  <WithAPILink urlID="SOME_API_LINK_ID">
//    {link => (
//      <TransformsAPILink link={link}>
//        {({ props, name, showNewWindow }) => (
//          <a {...props}>
//            <AccessibleLinkContent
//              link={link}
//              showNewWindow={showNewWindow}
//             />
//          </a>
//        )
//      </TransformsAPILink>
//    )}
//  <WithAPILink>
function TransformsAPILink({ children, link, currentRoute }) {
  const { url, ucFrom, ucUpdateCache, showNewWindow } = link;
  const href = appendReferrerToURL({
    url,
    ucFrom,
    ucUpdateCache,
    currentRoute,
  });
  const target = showNewWindow ? "_blank" : "_self";
  const rel = showNewWindow ? "noopener noreferrer" : null;
  const transformed = {
    disabled: link.disabled,
    title: link.title,
    href,
    target,
    rel,
    "data-testid": "api-link",
    "data-url-id": link.urlId,
  };

  return children({ text: link.name, showNewWindow, props: transformed });
}

TransformsAPILink.propTypes = {
  children: PropTypes.func,
  link: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    showNewWindow: PropTypes.bool,
    ucFrom: PropTypes.string,
    ucUpdateCache: PropTypes.string,
    url: PropTypes.string,
  }),
  currentRoute: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

// Transforms an incoming API link by appending "ucFrom" properties that Campus Solutions expects.
const appendReferrerToURL = ({ url, ucFrom, ucUpdateCache, currentRoute }) => {
  if (ucFrom) {
    const queryStringPrefix = url.includes("?") ? "&" : true;
    return (
      url +
      qs.stringify(
        {
          ucFrom: "CalCentral",
          ucFromText: currentRoute.name,
          ucFromLink: returnURL(currentRoute.url, ucUpdateCache),
        },
        queryStringPrefix
      )
    );
  }

  return url;
};

// the ucFromLink value may need to have a 'ucUpdateCache' parameter appended to it,
// if we get the value from the Link API the following will append it to the ucFromLink
const returnURL = (url, updateCache) => {
  const queryStringPrefixFromLink = url.includes("?") ? "&" : true;
  return updateCache && !url.includes("ucUpdateCache")
    ? url +
        qs.stringify({ ucUpdateCache: updateCache }, queryStringPrefixFromLink)
    : url;
};
