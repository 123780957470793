import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CalCentralUpdateCard from "components/CalCentralUpdateCard";
import DelegateLandingCard from "components/DelegateLandingCard";

import ServiceAlertRoutes from "components/routes/ServiceAlertRoutes";
import UserAuthRoutes from "components/routes/UserAuthRoutes";
import PageTitle from "components/PageTitle";

// MainContent is a react component that contains the React router, for pages
// whose content does not depend on Angular at all.
//
// To add a new page:
// 1) add a Route, with appropriate matching path under Routes in MainContent
// 2) add the path to the 'reactPaths' list in Angular's routeConfiguration
export default function MainContent() {
  return (
    <div className="main">
      <BrowserRouter>
        <Routes>
          <Route
            path="calcentral_update"
            element={
              <>
                <PageTitle title="CalCentral Update" />
                <CalCentralUpdateCard />
              </>
            }
          />

          <Route path="delegate_landing" element={<DelegateLandingCard />} />
          <Route path="service_alerts/*" element={<ServiceAlertRoutes />} />
          <Route path="user_auths/*" element={<UserAuthRoutes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
