import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { AwardIcon } from "components/ui/SVGIcons";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import { useSlice } from "components/hooks/useSlice";

const GreyAwardIcon = styled(AwardIcon)`
  color: var(--dusty-gray);
  height: 12px;
  margin-right: 7px;
  width: auto;
`;

export const SemesterAwardHonors = ({ semester }) => {
  const { profile: { awardHonors = [] } = {} } = useSlice("academics");

  const currentSemesterAwardHonors = awardHonors.filter(
    (award) => award.termId == semester.termId
  );

  const groupedSemesterAwardHonors = groupAwardHonorsByType(
    currentSemesterAwardHonors
  );

  if (currentSemesterAwardHonors.length == 0) {
    return null;
  } else {
    return (
      <>
        <MediumSpacer />

        {groupedSemesterAwardHonors.deansListAwardHonors.map(
          (awardHonor, index) => (
            <div key={index}>
              <GreyAwardIcon />
              {awardHonor.typeFormalDescription}
            </div>
          )
        )}

        {groupedSemesterAwardHonors.honorToDateAwardHonors.map(
          (awardHonor, index) => (
            <div key={index}>
              <GreyAwardIcon />
              {awardHonor.typeFormalDescription}
              {" - "}
              {awardHonor.termEndDateFormatted}
            </div>
          )
        )}

        {groupedSemesterAwardHonors.otherAwardHonors.map(
          (awardHonor, index) => (
            <div key={index}>
              <GreyAwardIcon />
              {awardHonor.typeFormalDescription}
            </div>
          )
        )}
      </>
    );
  }
};

SemesterAwardHonors.propTypes = {
  awardHonors: PropTypes.array,
  semester: PropTypes.object,
};

function groupAwardHonorsByType(awardHonors) {
  const deanListCodes = [
    "DEANS", // Dean's List
    "DLBUS", // Dean's List-Business
    "DLCED", // Dean's List-College of Environmental Design
    "DLCNR", // Dean's List-College of Natural Resources
    "DLCLS", // Dean's List-College Of Letters & Science
    "DLCCH", // Dean's List-College of Chemistry
    "DLCOE", // Dean's List-College of Engineering
    "DLCNR", // Dean's List-Rausser College of Natural Resources
  ];

  let deansListAwardHonors = [];
  let honorToDateAwardHonors = [];
  let otherAwardHonors = [];

  awardHonors.forEach((awardHonor) => {
    if (deanListCodes.includes(awardHonor.typeCode)) {
      deansListAwardHonors.push(awardHonor);
    } else if (awardHonor.typeCode == "HONRDT") {
      honorToDateAwardHonors.push(awardHonor);
    } else {
      otherAwardHonors.push(awardHonor);
    }
  });

  const sortedDeansListAwardHonors = deansListAwardHonors.sort(
    (a, b) => a.typeFormalDescription - b.typeFormalDescription
  );

  return {
    deansListAwardHonors: sortedDeansListAwardHonors,
    honorToDateAwardHonors: honorToDateAwardHonors,
    otherAwardHonors: otherAwardHonors,
  };
}
