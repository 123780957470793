import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import { ExternalLink } from "components/ui/ExternalLink";

export const SchedulePlannerLink = () => {
  const { schedulePlannerUrl } = useSelector((state) => {
    const {
      advising: { userId: advisingStudentUid },
    } = state;

    const {
      advisingStudentAcademicProfile: {
        plans: studentPlans = [],
        latestRegistrationTerm = {},
      } = {},
    } = advisingStudentState(state, advisingStudentUid) || {};

    const { id: currentRegistrationTermId = null } =
      latestRegistrationTerm || {};

    let schedulePlannerUrl = null;

    if (studentPlans && currentRegistrationTermId && advisingStudentUid) {
      let uniqueCareerCodes = getUniqueCareerCodes(studentPlans);
      let careerCode = uniqueCareerCodes[0];

      if (careerCode && currentRegistrationTermId && advisingStudentUid) {
        schedulePlannerUrl = [
          "/college_scheduler/advisor",
          careerCode,
          currentRegistrationTermId,
          advisingStudentUid,
        ].join("/");
      }
    }

    return {
      schedulePlannerUrl,
    };
  });

  const title = "View this student's planned schedule for this term.";

  if (schedulePlannerUrl) {
    return (
      <ExternalLink
        href={schedulePlannerUrl}
        title={title}
        target="_blank"
        rel="noopener noreferrer"
      >
        Schedule Planner
      </ExternalLink>
    );
  }
  return null;
};
SchedulePlannerLink.displayName = "SchedulePlannerLink";
SchedulePlannerLink.propTypes = {
  studentUid: PropTypes.string,
};

/**
 * Returns unique set of career codes extracted from collection of student plans
 * @param  {Array} studentPlans  iHub Academic Status student plans
 * @return {Array}               career codes
 */
const getUniqueCareerCodes = function (studentPlans) {
  var careerCodes = studentPlans.map(function (plan) {
    const { careerCode = null } = plan;
    return careerCode;
  });
  const compactedCareerCodes = careerCodes.filter(Boolean);
  const uniqCareerCodes = [...new Set(compactedCareerCodes)];
  return uniqCareerCodes;
};
