import React from "react";
import PropTypes from "prop-types";
import AccessibleLinkContent from "./AccessibleLinkContent";
import TransformsAPILink from "./TransformsAPILink";

// Takes an API link, transforms it for display, and renders accessible "opens
// in new window" message automatically.
export function APILink({
  as = "a",
  disabled,
  children,
  link,
  text: textOverride,
  showNewWindow: showNewWindowOverride,
  urlParameters = false,
  decoration,
}) {
  return (
    <TransformsAPILink link={{ ...link }} urlParameters={urlParameters}>
      {({ props, text, showNewWindow }) =>
        children
          ? children({
              props: { ...props, disabled },
              text: textOverride || text,
              showNewWindow: showNewWindowOverride || showNewWindow,
              decoration: decoration,
            })
          : React.createElement(
              as,
              { ...props, disabled },
              <AccessibleLinkContent
                text={textOverride || text}
                showNewWindow={showNewWindowOverride || showNewWindow}
                decoration={decoration}
              />
            )
      }
    </TransformsAPILink>
  );
}

APILink.displayName = "APILink";
APILink.propTypes = {
  link: PropTypes.object,
  children: PropTypes.func,
  decoration: PropTypes.node,
  disabled: PropTypes.bool,
  as: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]),
  text: PropTypes.string,
  showNewWindow: PropTypes.bool,
  urlParameters: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default APILink;
