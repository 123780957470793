import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import WithCSRFToken from "components/WithCSRFToken";
import VisuallyHidden from "components/ui/VisuallyHidden";
import { saveUser, deleteSavedUser } from "utils/requests";
import { refreshStoredUsers } from "data/actions/storedUsers";

export default connect()(UserStar);

function UserStar({ dispatch, user, afterToggleSave }) {
  const toggleSave = async (csrfToken, user) => {
    let success;

    if (user.saved) {
      success = await deleteSavedUser(csrfToken)(user);
    } else {
      success = await saveUser(csrfToken)(user);
    }

    if (success) {
      dispatch(refreshStoredUsers());

      if (afterToggleSave) {
        afterToggleSave(user);
      }
    }
  };

  return (
    <WithCSRFToken>
      {(csrfToken) => (
        <StarButton onClick={() => toggleSave(csrfToken, user)}>
          <VisuallyHidden>
            {user.saved ? "Delete" : "Save"}{" "}
            {`${user.firstName} ${user.lastName}`}
          </VisuallyHidden>
          <Star active={user.saved} />
        </StarButton>
      )}
    </WithCSRFToken>
  );
}

UserStar.displayName = "UserStar";
UserStar.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object,
  afterToggleSave: PropTypes.func,
};

const StarButton = styled.button`
  background: transparent;
  font-size: 10px;
  padding: 0;
  border: none;

  &:active,
  &:focus,
  &:hover {
    background: transparent;
  }

  .fa-stack {
    .fa-star-o {
      color: var(--dove-gray);
    }

    &:hover {
      .fa-star-o {
        color: var(--black);
      }
    }

    &.active {
      .fa-star {
        color: var(--yellow);
      }
    }

    &.inactive {
      .fa-star {
        color: white;
      }
    }
  }
`;

const Star = ({ active }) => (
  <span className={`fa-stack ${active ? "active" : "inactive"}`}>
    <i className="fa fa-star fa-stack-1x"></i>
    <i className="fa fa-star-o fa-stack-2x"></i>
  </span>
);

Star.propTypes = {
  active: PropTypes.bool.isRequired,
};
