import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTestMode } from "./useTestMode";

export function dispatchUserSlice(fetchFunction) {
  const isTestMode = useTestMode();
  const dispatch = useDispatch();
  const currentUID = useSelector((state) => state.currentUID);

  useEffect(() => {
    if (!isTestMode) {
      if (currentUID) {
        if (typeof fetchFunction === "function") {
          dispatch(fetchFunction(currentUID));
        } else if (typeof fetchFunction === "object") {
          fetchFunction.map((func) => dispatch(func(currentUID)));
        }
      }
    }
  }, [isTestMode, dispatch, currentUID]);
}
