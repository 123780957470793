import React from "react";
import LightText from "components/ui/LightText";
import { useUser } from "components/hooks/useUser";

export const Minor = () => {
  const { minors = [] } = useUser((user) => user.academicProfile);

  if (minors.length) {
    return (
      <>
        <dt data-testid="minors">{minors.length === 1 ? "Minor" : "Minors"}</dt>
        <dd>
          {minors.map((minorObj, index) => (
            <div key={index}>
              <LightText>{minorObj.college}</LightText>
              <div>{minorObj.description}</div>
              {minorObj.subPlans.map((subPlan, index) => (
                <div key={index} className="cc-widget-profile-indent">
                  {subPlan}
                </div>
              ))}
            </div>
          ))}
        </dd>
      </>
    );
  }

  return null;
};
Minor.displayName = "Minor";
