import React from "react";
import PropTypes from "prop-types";

import GradingLapseDeadlineColumns from "./GradingLapseDeadlineColumns";
import { indicateDisplayColumnIndex } from "./helpers";
import styles from "../SemestersCard.module.scss";

const GradingLapseDeadline = ({
  section,
  frozenDisplayColumnIndex,
  lapseDateDisplayColumnIndex,
  totalColumns,
}) => {
  const {
    is_primary_section: isPrimary = false,
    grading: {
      gradingLapseDeadlineDisplay: deadlineDisplay = false,
      gradingLapseDeadline: deadline = "",
      gradingBasis,
    },
  } = section;
  const showGradingLapseDeadline = !!(deadlineDisplay && deadline);
  const gradingBasisIsFrozen = gradingBasis == "FRZ";
  const showSingleColumn =
    (showGradingLapseDeadline && lapseDateDisplayColumnIndex === 0) ||
    (gradingBasisIsFrozen && frozenDisplayColumnIndex === 0);
  const displayColumnIndex = indicateDisplayColumnIndex({
    showGradingLapseDeadline,
    lapseDateDisplayColumnIndex,
    gradingBasisIsFrozen,
    frozenDisplayColumnIndex,
  });

  if (!isPrimary || (!showGradingLapseDeadline && !gradingBasisIsFrozen)) {
    return null;
  }

  if (showGradingLapseDeadline || gradingBasisIsFrozen) {
    return (
      <tr
        className={styles.backgroundOverride}
        data-testid="grading-lapse-deadline-row"
      >
        <GradingLapseDeadlineColumns
          deadline={deadline}
          displayColumnIndex={displayColumnIndex}
          gradingBasisIsFrozen={gradingBasisIsFrozen}
          showSingleColumn={showSingleColumn}
          showGradingLapseDeadline={showGradingLapseDeadline}
          totalColumns={totalColumns}
        />
      </tr>
    );
  } else {
    return null;
  }
};

GradingLapseDeadline.propTypes = {
  academicGuideGradesPolicy: PropTypes.object,
  section: PropTypes.object,
  frozenDisplayColumnIndex: PropTypes.number,
  lapseDateDisplayColumnIndex: PropTypes.number,
  totalColumns: PropTypes.number,
};

export default GradingLapseDeadline;
