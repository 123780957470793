// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button___drmCY{background:linear-gradient(var(--white), var(--gallery)),var(--white);border:2px solid var(--silver);border-radius:5px;color:var(--mine-shaft);cursor:pointer;display:inline;font-size:11px;font-weight:bold;line-height:1;padding:6px 10px}.button___drmCY:disabled,.button___drmCY[disabled]{background:var(--wild-sand);border:1px solid var(--silver);color:var(--silver);cursor:auto;opacity:1}.button___drmCY:disabled:focus,.button___drmCY:disabled:hover,.button___drmCY[disabled]:focus,.button___drmCY[disabled]:hover{background:var(--wild-sand);color:var(--silver)}.button___drmCY:focus,.button___drmCY:hover{background:linear-gradient(var(--white), var(--silver)),var(--silver);color:var(--mine-shaft);text-decoration:none}", "",{"version":3,"sources":["webpack://./src/components/Button.module.scss"],"names":[],"mappings":"AAAA,gBAcE,qEAAA,CACA,8BAAA,CACA,iBAAA,CACA,uBAAA,CACA,cAAA,CACA,cAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,gBAAA,CAtBA,mDAEE,2BAAA,CACA,8BAAA,CACA,mBAAA,CACA,WAAA,CACA,SAAA,CACA,8HAEE,2BAAA,CACA,mBAAA,CAaJ,4CAEE,qEAAA,CACA,uBAAA,CACA,oBAAA","sourcesContent":[".button {\n  &:disabled,\n  &[disabled] {\n    background: var(--wild-sand);\n    border: 1px solid var(--silver);\n    color: var(--silver);\n    cursor: auto;\n    opacity: 1;\n    &:focus,\n    &:hover {\n      background: var(--wild-sand);\n      color: var(--silver);\n    }\n  }\n  background: linear-gradient(var(--white), var(--gallery)), var(--white);\n  border: 2px solid var(--silver);\n  border-radius: 5px;\n  color: var(--mine-shaft);\n  cursor: pointer;\n  display: inline;\n  font-size: 11px;\n  font-weight: bold;\n  line-height: 1;\n  padding: 6px 10px;\n  &:focus,\n  &:hover {\n    background: linear-gradient(var(--white), var(--silver)), var(--silver);\n    color: var(--mine-shaft);\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button___drmCY"
};
export default ___CSS_LOADER_EXPORT___;
