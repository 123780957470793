import { dataSlice } from "data/utils/buildSlice";
import { dataThunk } from "data/utils/thunks";
import { userProfileQuery } from "data/queries/userProfile";
import { userGQLRequest } from "data/utils/requests";

// Note: This uses a dataThunk, instead of userDataThunk because it is
// the source of the current users UID
const key = "userProfile";
export const slice = dataSlice({ name: key });
export const { reducer: userProfile } = slice;
export const fetchUserProfile = dataThunk(key, slice, {
  request: userGQLRequest(userProfileQuery),
  dataHandler: ({ actions, dispatch }) =>
    ({ user }) => dispatch(actions.success(user))
});
