import { useSlice } from "./useSlice";

export function useAcademicRoles(callback = (roles) => roles) {
  const roles = useSlice("myStatus").academicRoles.current;
  return callback(roles);
}

export function useHistoricAcademicRoles(callback = (roles) => roles) {
  const roles = useSlice("myStatus").academicRoles.historical;
  return callback(roles);
}
