

import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import { NewAdmitResourcesCard } from "components/cards/NewAdmitResourcesCard";

const NgNewAdmitResourcesCard = () => (
  <ReduxProvider>
    <NewAdmitResourcesCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("newAdmitResourcesCard", react2angular(NgNewAdmitResourcesCard));
