import React from "react";
import PropTypes from "prop-types";

import styles from "./SemestersCard.module.scss";

const SemesterTotalUnits = ({ enrolledUnits, semester, waitlistedUnits }) => {
  if (semester.timeBucket === "past") {
    return null;
  }

  return (
    <table
      className={styles.semesterTotalUnitsTable}
      data-testid="semester-total-units-table"
    >
      <tbody>
        {!(enrolledUnits == undefined) && (
          <tr className={styles.backgroundOverride}>
            <td
              className={styles.semesterUnitsDataCell}
              data-testid="semester-total-enrolled-units"
            >
              My Enrolled Units: <strong>{enrolledUnits}</strong>
            </td>
          </tr>
        )}
        {!(waitlistedUnits == undefined) && (
          <tr className={styles.backgroundOverride}>
            <td
              className={styles.semesterUnitsDataCell}
              data-testid="semester-total-waitlisted-units"
            >
              {parseInt(waitlistedUnits) > 0 && (
                <a href={"/academics/semester/" + semester.slug}>
                  My Waitlisted Units: <strong>{waitlistedUnits}</strong>
                </a>
              )}
              {parseInt(waitlistedUnits) < 1 && (
                <span>
                  My Waitlisted Units: <strong>{waitlistedUnits}</strong>
                </span>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

SemesterTotalUnits.propTypes = {
  enrolledUnits: PropTypes.number,
  semester: PropTypes.object,
  waitlistedUnits: PropTypes.number,
};

export default SemesterTotalUnits;
