import React from "react";
import PropTypes from "prop-types";

import ArrowRightIcon from "components/../react/components/Icon/ArrowRightIcon";

import styles from "./SemestersCard.module.scss";

const SemesterHeader = ({ semester, isAdvisingView }) => {
  return (
    <div className={styles.semesterHeader} data-testid="semester-header">
      {semester.hasEnrollmentData &&
        !semester.hasWithdrawalData &&
        !semester.filteredForDelegate &&
        !isAdvisingView && (
          <h3 data-testid="semester-header-name">
            <a
              className={styles.leftAlign}
              data-testid="semester-header-name-link"
              href={"/academics/semester/" + semester.slug}
            >
              {semester.name}{" "}
              <ArrowRightIcon color={"denim-blue"}></ArrowRightIcon>
            </a>
          </h3>
        )}
      {((!semester.hasEnrollmentData && semester.hasStudyProgData) ||
        semester.hasWithdrawalData ||
        !semester.hasEnrollmentData ||
        semester.filteredForDelegate ||
        isAdvisingView) && (
        <h3 className={styles.leftAlign} data-testid="semester-header-name">
          {semester.name}
        </h3>
      )}
      {semester.hasWithdrawalData && (
        <div className={styles.status} data-testid="semester-header-withdrawal">
          <span>{semester.withdrawalStatus.withcnclTypeDescr}</span>&nbsp;
          <span>{semester.withdrawalStatus.withcnclFromDate}</span>
        </div>
      )}
      {semester.hasStudyProgData && (
        <span className={styles.notation} data-testid="semester-study-program">
          {semester.studyProg.studyprogTypeDescr}
        </span>
      )}
      {semester.hasEnrollmentData &&
        !semester.filteredForDelegate &&
        semester.timeBucket !== "past" &&
        !isAdvisingView && (
          <a
            className={`${styles.rightAlign} ${styles.textbooksLink}`}
            href={"/academics/booklist/" + semester.slug}
            data-testid="semester-header-textbooks-link"
          >
            Textbooks
          </a>
        )}
    </div>
  );
};

SemesterHeader.propTypes = {
  isAdvisingView: PropTypes.bool,
  semester: PropTypes.object,
};

export default SemesterHeader;
