import styled from "styled-components";

const PageStatus = styled.div`
  background: var(--pale-sky);

  @media screen and (min-width: 768px) {
    padding: 135px 0;
    display: grid;
    place-items: center;
  }
`;

export default PageStatus;
