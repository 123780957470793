import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchAcademicsDiploma } from "data/actions/academics/diplomaActions";
import Popover, { usePopover } from "components/Popover";
import InfoIcon from "components/InfoIcon";
import ButtonAsLink from "components/ui/ButtonAsLink";

import "./Diploma.scss";

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  diplomaEligible: PropTypes.bool,
  diplomaReady: PropTypes.bool,
  electronicDiplomaHelpMessage: PropTypes.string,
  electronicDiplomaNoticeMessage: PropTypes.string,
  electronicDiplomaReadyMessage: PropTypes.string,
  paperDiplomaMessage: PropTypes.string,
  paperDiplomaNoticeHeading: PropTypes.string,
  paperDiplomaNoticeMessage: PropTypes.string,
};

const Diploma = ({
  dispatch,
  diplomaEligible,
  diplomaReady,
  electronicDiplomaHelpMessage,
  electronicDiplomaNoticeMessage,
  electronicDiplomaReadyMessage,
  paperDiplomaMessage,
  paperDiplomaNoticeHeading,
  paperDiplomaNoticeMessage,
}) => {
  useEffect(() => {
    dispatch(fetchAcademicsDiploma());
  }, []);

  const paperDiplomaPopover = usePopover();
  const electronicDiplomaPopover = usePopover();

  if (diplomaEligible) {
    return (
      <div className="cc-section-block">
        <div className="content-entry">
          <div className="header-text">
            <span>Paper Diploma</span>&nbsp;
            <ButtonAsLink onClick={paperDiplomaPopover.open}>
              <InfoIcon />
            </ButtonAsLink>
          </div>
          <div style={{ position: `relative` }}>
            {paperDiplomaPopover.isOpen && (
              <Popover
                ref={paperDiplomaPopover.ref}
                title={paperDiplomaNoticeHeading}
                close={paperDiplomaPopover.close}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: paperDiplomaNoticeMessage,
                  }}
                />
              </Popover>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: paperDiplomaMessage,
              }}
            />
          </div>
        </div>

        <div className="content-entry">
          <div className="header-text">
            <span>Electronic Diploma</span>&nbsp;
            <ButtonAsLink onClick={electronicDiplomaPopover.open}>
              <InfoIcon />
            </ButtonAsLink>
          </div>
          <div style={{ position: `relative` }}>
            {electronicDiplomaPopover.isOpen && (
              <Popover
                ref={electronicDiplomaPopover.ref}
                title="Electronic Diploma"
                close={electronicDiplomaPopover.close}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: electronicDiplomaHelpMessage,
                  }}
                />
              </Popover>
            )}
            {!diplomaReady && (
              <div
                dangerouslySetInnerHTML={{
                  __html: electronicDiplomaNoticeMessage,
                }}
              />
            )}
            {diplomaReady && (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: electronicDiplomaReadyMessage,
                  }}
                />
                <div className="download-button-container">
                  <a
                    className="download-button"
                    href="/api/my/academics/diploma/single_sign_on"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Proceed to Download
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

Diploma.propTypes = propTypes;

const mapStateToProps = ({
  academics: {
    diploma: {
      diplomaEligible,
      diplomaReady,
      electronicDiplomaHelpMessage,
      electronicDiplomaNoticeMessage,
      electronicDiplomaReadyMessage,
      paperDiplomaMessage,
      paperDiplomaNoticeHeading,
      paperDiplomaNoticeMessage,
    },
  },
}) => {
  return {
    diplomaEligible,
    diplomaReady,
    electronicDiplomaHelpMessage,
    electronicDiplomaNoticeMessage,
    electronicDiplomaReadyMessage,
    paperDiplomaMessage,
    paperDiplomaNoticeHeading,
    paperDiplomaNoticeMessage,
  };
};

export default connect(mapStateToProps)(Diploma);
