import React from "react";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const Level = () => {
  const { isNonDegreeSeekingSummerVisitor, latestLevels } = useSelector(
    (state) => {
      const {
        advising: { userId: advisingStudentUid },
      } = state;

      const { advisingStudentAcademicProfile: { latestLevels = [] } = {} } =
        advisingStudentState(state, advisingStudentUid) || {};

      const {
        advising: {
          studentData: {
            academicRoles: {
              historical: { degreeSeeking, summerVisitor } = {},
            } = {},
          } = {},
        } = {},
      } = state;

      const isNonDegreeSeekingSummerVisitor = summerVisitor && !degreeSeeking;

      return {
        isNonDegreeSeekingSummerVisitor,
        latestLevels,
      };
    }
  );

  if (
    !isNonDegreeSeekingSummerVisitor &&
    latestLevels &&
    latestLevels.length > 0
  ) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>Level</StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {latestLevels.map((level, index) => (
            <div key={index}>
              <span>{level.description}</span>
            </div>
          ))}
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
Level.displayName = "Level";
