import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";

import Card from "components/../react/components/Card";
import fetchServiceAlerts from "data/actions/serviceAlertsActions";

// The "Update Card" is the destination of the "Learn More" link in the AlertBar
function CalCentralUpdateCard({
  fetchServiceAlert,
  serviceAlert,
  loadState,
  csrfToken,
}) {
  useEffect(() => {
    if (csrfToken) {
      fetchServiceAlert(csrfToken);
    }
  }, [csrfToken]);

  return (
    <Card title="CalCentral Update" loading={loadState === "pending"}>
      {serviceAlert ? (
        <>
          <h3 style={{ marginTop: `15px` }}>
            {format(
              parseISO(
                serviceAlert.publication_date || serviceAlert.publicationDate
              ),
              "MMM dd"
            )}{" "}
            - {serviceAlert.title}
          </h3>
          <div dangerouslySetInnerHTML={{ __html: serviceAlert.body }}></div>
        </>
      ) : (
        <div>
          <p>There are no updates at this time.</p>
        </div>
      )}
    </Card>
  );
}

CalCentralUpdateCard.propTypes = {
  csrfToken: PropTypes.string,
  fetchServiceAlert: PropTypes.func,
  serviceAlert: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    publication_date: PropTypes.string,
    publicationDate: PropTypes.string,
  }),
  loadState: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  fetchServiceAlert: (csrfToken) => {
    dispatch(fetchServiceAlerts(csrfToken));
  },
});

const mapStateToProps = ({
  csrfToken,
  serviceAlerts: { data = [], loadState },
}) => ({
  serviceAlert: data[0],
  loadState,
  csrfToken,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalCentralUpdateCard);
