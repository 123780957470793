import React from "react";
import { react2angular } from "react2angular";

import ReduxProvider from "components/ReduxProvider";

import APITestCard from "components/ApiTestCard";

const NgApiTestCard = () => (
  <ReduxProvider>
    <APITestCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("apiTestCard", react2angular(NgApiTestCard));
