// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".crossListings___qwf3A{margin-bottom:20px}.title___Gs8t9{font-size:15px;line-height:17px}", "",{"version":3,"sources":["webpack://./src/react/components/_academics/ClassInformationCard/CrossListings.module.scss"],"names":[],"mappings":"AAOA,uBACE,kBAAA,CAEF,eAPE,cAAA,CACA,gBAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n@mixin title2 {\n  font-size: 15px;\n  line-height: 17px;\n}\n\n.crossListings {\n  margin-bottom: 20px;\n}\n.title {\n  @include title2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crossListings": "crossListings___qwf3A",
	"title": "title___Gs8t9"
};
export default ___CSS_LOADER_EXPORT___;
