import { userAcademicProfile } from "data/queries/userAcademicProfile";
import { buildUserSlice } from "data/utils/buildSlice";
import { userDataThunk } from "data/utils/thunks";
import { userGQLRequest } from "data/utils/requests";

const key = "academicProfile";
export const slice = buildUserSlice(key);
export const { reducer: academicProfile } = slice;
export const fetchAcademicProfile = userDataThunk(key, slice, {
  request: userGQLRequest(userAcademicProfile),
});
