import React from "react";
import StyledNotice from "components/ui/StyledNotice";
import WithMessage from "components/WithMessage";

export default function AlumniMessage() {
  return (
    <StyledNotice icon="warning" background="yellow">
      <WithMessage set="32000" number="30">
        {(message) => (
          <div
            dangerouslySetInnerHTML={{
              __html: message.description,
            }}
          ></div>
        )}
      </WithMessage>
    </StyledNotice>
  );
}
AlumniMessage.displayName = "AlumniMessage";
