import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { BCalScheduleCard } from "components/cards/BCalScheduleCard";

const NgBCalScheduleCard = () => {
  return (
    <ReduxProvider>
      <BCalScheduleCard />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("bcalScheduleCard", react2angular(NgBCalScheduleCard));
