import React from "react";
import { react2angular } from "react2angular";
import { BConnectedConnectCard } from "components/cards/BConnectedConnectCard";
import ReduxProvider from "components/ReduxProvider";

function NgBConnectedConnectCard() {
  return (
    <ReduxProvider>
      <BConnectedConnectCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("bconnectedConnectCard", react2angular(NgBConnectedConnectCard));
