import { createSlice } from "@reduxjs/toolkit";

export const buildUserSlice = (name) =>
  dataSlice({
    name,
    prepare: (uid, data) => {
      return { payload: { uid, ...data } };
    },
  });

export const buildFinaidSlice = (name) =>
  dataSlice({
    name,
    prepare: ({ uid, finaidYearId, data }) => {
      return { payload: { uid, finaidYearId, ...data } };
    },
  });

export const buildCourseSlice = (name) => {
  return dataSlice({
    name,
    prepare: ({ courseId }, data = {}) => {
      return { payload: { courseId, ...data } };
    },
  });
};

export function dataSlice({ name, prepare = null }) {
  return createSlice({
    name: name,
    initialState: {
      loadState: "idle",
    },
    reducers: {
      start: {
        reducer: (state) => {
          return {
            ...state,
            loadState: "pending",
          };
        },
        prepare,
      },
      success: {
        reducer: (state, action) => {
          return {
            ...state,
            ...action.payload,
            loadState: "success",
          };
        },
        prepare,
      },
      failure: {
        reducer: (state, action) => {
          return {
            ...state,
            ...action.payload,
            loadState: "failure",
          };
        },
        prepare,
      },
    },
  });
}
