import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/ui/Spinner";

import { Card, CardTitle, CardBody } from "components/ui/Card";

import { isLoading } from "data/utils/statusHelpers";
import { fetchAdvisingResources } from "data/actions/advisingResources";
import { fetchAdvisingStudentData } from "data/actions/advisingStudentData";
import { fetchAdvisingStudentProfile } from "data/slices/users/advisingStudentProfile";
import { fetchAdvisingStudentAcademicProfile } from "data/slices/users/advisingStudentAcademicProfile";
import advisingStudentState from "utils/advisingStudentState";

import { AdvisingResources } from "components/cards/advising/StudentSummaryCard/AdvisingResources";
import { Career } from "components/cards/advising/StudentSummaryCard/Career";
import { ContactInformation } from "components/cards/advising/StudentSummaryCard/ContactInformation";
import { DegreesConferred } from "components/cards/advising/StudentSummaryCard/DegreesConferred";
import { DesignatedEmphases } from "components/cards/advising/StudentSummaryCard/DesignatedEmphases";
import { Level } from "components/cards/advising/StudentSummaryCard/Level";
import { LdapUID } from "components/cards/advising/StudentSummaryCard/LdapUID";
import { Majors } from "components/cards/advising/StudentSummaryCard/Majors";
import { Minors } from "components/cards/advising/StudentSummaryCard/Minors";
import { NameAndPhoto } from "components/cards/advising/StudentSummaryCard/NameAndPhoto";
import { StudentID } from "components/cards/advising/StudentSummaryCard/StudentID";

import { ExpectedGraduationTermUndergraduate } from "components/cards/advising/StudentSummaryCard/ExpectedGraduationTermUndergraduate";
import { ExpectedGraduationTermGradLaw } from "components/cards/advising/StudentSummaryCard/ExpectedGraduationTermGradLaw";

const StudentSummaryCard = () => {
  const { advisingStudentUid, dataIsLoading } = useSelector((state) => {
    const {
      advising: {
        userId: advisingStudentUid,
        studentData: { loadState: studentDataLoadState = "pending" } = {},
        resources: { loadState: advisingResourceLoadState = "pending" } = {},
      },
    } = state;

    const {
      advisingStudentAcademicProfile: {
        loadState: advisingStudentAcademicProfileLoadState,
      } = {},
      advisingStudentProfile: {
        loadState: advisingStudentProfileLoadState,
      } = {},
    } = advisingStudentState(state, advisingStudentUid) || {};

    const dataIsLoading = isLoading(advisingStudentAcademicProfileLoadState) ||
      isLoading(studentDataLoadState) ||
      isLoading(advisingStudentProfileLoadState) ||
      isLoading(advisingResourceLoadState);

    return { advisingStudentUid, dataIsLoading };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (advisingStudentUid) {
      dispatch(fetchAdvisingStudentAcademicProfile(advisingStudentUid));
      dispatch(fetchAdvisingStudentProfile(advisingStudentUid));
      dispatch(fetchAdvisingStudentData(advisingStudentUid));
      dispatch(fetchAdvisingResources(advisingStudentUid));
    }
  }, [dispatch, advisingStudentUid]);

  return (
    <Card>
      <CardTitle>
        <h2>Student Summary</h2>
      </CardTitle>
      {dataIsLoading && (
        <CardBody>
          <Spinner />
        </CardBody>
      )}
      {!dataIsLoading && (
        <CardBody>
          <NameAndPhoto />
          <StudentID />
          <LdapUID />
          <Majors />
          <Minors />
          <DesignatedEmphases />
          <Career />
          <Level />
          <ExpectedGraduationTermUndergraduate />
          <ExpectedGraduationTermGradLaw />
          <DegreesConferred />
          <ContactInformation />
          <AdvisingResources />
        </CardBody>
      )}
    </Card>
  );
};

StudentSummaryCard.displayName = "StudentSummaryCard";
export default StudentSummaryCard;
