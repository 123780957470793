import React from "react";
import PropTypes from "prop-types";
import FontAwesomeIcon from "./FontAwesomeIcon";

import { ICON_ARROW_RIGHT } from "./IconTypes";

const ArrowRightIcon = ({ color }) => (
  <FontAwesomeIcon name={ICON_ARROW_RIGHT} color={color} />
);

ArrowRightIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ArrowRightIcon;
