import React from "react";
import PropTypes from "prop-types";

import colors from "components/../react/colors";
import styles from "./StyledNotice.module.scss";

import BullhornIcon from "components/../react/components/Icon/BullhornIcon";
import InfoIcon from "components/../react/components/Icon/InfoIcon";

export default function StyledNotice({ icon, background, children }) {
  return (
    <div
      className={`${styles.noticeWrapper} ${styles[background]}`}
      data-testid="styled-notice"
    >
      <div className={styles.iconContainer}>
        {icon === "bullhorn" && <BullhornIcon />}
        {icon === "info" && <InfoIcon color={colors.dustyGray} />}
        {icon === "warning" && (
          <i
            className="fa fa-exclamation-triangle cc-icon-gold"
            aria-hidden="true"
          ></i>
        )}
      </div>
      <div className={styles.messageContainer}>{children}</div>
    </div>
  );
}

StyledNotice.propTypes = {
  icon: PropTypes.oneOf(["bullhorn", "info", "warning"]).isRequired,
  background: PropTypes.oneOf(["gray", "yellow"]).isRequired,
  children: PropTypes.node,
};
