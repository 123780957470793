import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import ActingAsBar from "components/ActingAsBar";

const NgActingAsBar = () => (
  <ReduxProvider>
    <ActingAsBar />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("actingAsBar", react2angular(NgActingAsBar));
