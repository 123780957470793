import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonAsLink from "components/ui/ButtonAsLink";
import TextStyles from "components/ui/TextStyles";

export default connect(mapStateToProps)(NoBconnected);

function mapStateToProps({ myStatus: { isActingAs } = {} }) {
  return {
    isActingAs,
  };
}

function NoBconnected({ isActingAs }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <TextStyles data-testid="no-bconnected-notice">
      <p>
        Want to see events from your bCal calendar?{" "}
        <a
          onClick={() => window.location="/api/google/request_authorization"}
          disabled={isActingAs}
        >
          Connect
        </a>{" "}
        CalCentral to your bConnected Google calendar account, then Accept.{" "}
        {!showMore && (
          <ButtonAsLink onClick={() => setShowMore(true)}>
            Show more
          </ButtonAsLink>
        )}
      </p>

      {showMore && (
        <>
          <p>
            Why connect? From your Dashboard, you will be able to see when you
            have new bMail email messages and bCal calendar events, as well as
            your tasks and today&apos;s events.
          </p>
          <p>
            Not ready? You can connect later on the{" "}
            <strong>
              <a href="/settings">Settings page</a>
            </strong>
            .
          </p>
        </>
      )}
    </TextStyles>
  );
}

NoBconnected.displayName = "NoBconnected";
NoBconnected.propTypes = {
  isActingAs: PropTypes.bool,
};
