import React from "react";
import PropTypes from "prop-types";
import DisclosureChevron from "components/../react/components/DisclosureChevron";

export default function EnrollmentExpander({ title, expanded, onClick }) {
  return (
    <div style={{ display: `flex`, justifyContent: `center` }}>
      <button className="cc-button-link" onClick={onClick}>
        {title} <DisclosureChevron expanded={expanded} />
      </button>
    </div>
  );
}

EnrollmentExpander.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
