import { gql } from "graphql-request";
export const advisingStudentAcademicProfileQuery = gql`
  query($uid: String) {
    user(uid: $uid) {
      degrees {
        college
        dateAwarded
        description
        designatedEmphases {
          careerDescription
          college
          formalDescription
          typeCategory
          typeCode
        }
        honors {
          formalDescription
        }
        isUndergrad
        majors {
          careerDescription
          college
          formalDescription
          typeCategory
          typeCode
        }
        minors {
          careerDescription
          college
          formalDescription
          typeCategory
          typeCode
        }
      }
      designatedEmphases {
        college
        description
        formalDescription
        subPlans
        typeCategory
        typeCode
        typeDescription
      }
      graduation {
        undergraduate {
          activeTermsWithEnrollmentAppointments
          appointmentsInGraduatingTerm
          showGraduationChecklist
          termName
        }
        gradOrLaw {
          expectedGraduationTerms {
            career {
              code
              description
              formalDescription
            }
            expectedGradTermIds
            expectedGradTermNames
            plans
            program
          }
        }
      }
      hasHolds
      ihubPersonServerErrored
      latestCareers
      latestLevels {
        careerCode
        description
      }
      latestRegistrationTerm {
        id
        description
      }
      majors {
        college
        description
        formalDescription
        subPlans
        typeCategory
        typeCode
        typeDescription
      }
      minors {
        college
        description
        formalDescription
        subPlans
        typeCategory
        typeCode
        typeDescription
      }
      plans {
        careerCode
        description
        formalDescription
      }
      termsInAttendance
    }
  }
`;
