import { createSlice } from "@reduxjs/toolkit";
import { slice as configSlice } from "./config";

const slice = createSlice({
  name: "csrfToken",
  initialState: null,
  extraReducers: {
    [configSlice.actions.success]: (_state, action) => {
      return action.payload.csrfToken;
    },
  },
});

export const { reducer: csrfToken } = slice;
