import React from "react";
import PropTypes from "prop-types";
import TransferCreditTitle from "./TransferCreditTitle";
import { ExamTransferCredit } from "./ExamTransferCredit";
import { TransferCreditContainer } from "./TransferCreditContainer";
import { TransferCreditTable } from "./TransferCreditTable";

export const GenericTransferCredit = (props) => {
  const { detailed, summary, isStudent, reportLink } = props;

  const showPointsColumn = hasGradePoints(detailed);
  const details = detailed || [];
  const totalPoints = details
    .map((detail) => detail.gradePoints)
    .reduce((prev, current) => prev + current, 0);

  if (summary && (detailed || hasTestUnits(props))) {
    return (
      <TransferCreditContainer
        className="cc-transfer-credit-summary"
        data-testid="generic-transfer-credit"
      >
        <TransferCreditTitle
          description={summary.careerDescr}
          isStudent={isStudent}
          reportLink={reportLink}
        />

        <div style={{ display: `flex`, gap: `15px` }}>
          <TransferCreditTable
            details={details}
            showPointsColumn={showPointsColumn}
            totalPoints={totalPoints}
          />
          <ExamTransferCredit summary={summary} />
        </div>
      </TransferCreditContainer>
    );
  } else {
    return null;
  }
};

GenericTransferCredit.displayName = "GenericTransferCredit";
GenericTransferCredit.propTypes = {
  detailed: PropTypes.array,
  summary: PropTypes.object,
  isStudent: PropTypes.bool,
  reportLink: PropTypes.object,
};

const hasGradePoints = (details) =>
  details && details.find((transfer) => transfer.gradePoints);

const hasTestUnits = (props) => {
  if (props.summary && props.summary.careerDescr !== "Undergraduate") {
    return false;
  }

  const { apTestUnits, ibTestUnits, alevelTestUnits } = props.summary;
  return [apTestUnits, ibTestUnits, alevelTestUnits].find(
    (unitCount) => unitCount > 0
  );
};
