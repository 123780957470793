import { courseTextbookRequestParams } from "utils/courseTextbookRequestParams";

export const routes = {
  academicAdvising: () => "/api/advising/my_advising",
  // academicAdvising: () => '/dummy/json/my_advising.json',

  academicRecords: () => "/api/my/academic_records",
  // academicRecords: () => '/dummy/json/my_academic_records.json',

  academicStandings: () => "/api/my/academics/standings",
  // academicStandings: () => '/dummy/json/academic_standings.json',

  advisingAcademicAdvising: ({ uid }) => `/api/advising/advising/${uid}`,
  // uid => `/dummy/json/my_advising.json`,

  advisorAcademicStandings: ({ uid }) => `/api/advising/standings/${uid}`,

  advisingHolds: ({ uid }) => `/api/advising/holds/${uid}`,
  // advisingHolds: uid => `/dummy/json/advising_holds.json`,

  advisingStudent: ({ uid }) => `/api/advising/student/${uid}`,
  // advisingStudent: uid => `/dummy/json/advising_student.json`,

  studentSuccess: ({ uid }) => `/api/advising/student_success/${uid}`,

  newAdmitResources: ({ _uid }) => "/api/my/new_admit_resources",

  advisingTermRegistrations: ({ uid }) =>
    `/api/advising/academics/status_and_holds/${uid}`,
  // advisingTermRegistrations: uid => `/dummy/json/advising_term_registrations.json`,

  campusLinks: () => "/api/my/campuslinks",
  // campusLinks: () => '/dummy/json/campuslinks.json',

  courses: () => "/api/my/classes",
  // courses: () => '/dummy/json/my_advising.json',

  enrollmentVerification: () => "/api/academics/enrollment_verification",

  financialAidSummary: () => "/api/my/financial_aid_summary",

  hasExamResults: () => "/api/academics/has_exam_results",

  examResults: () => "/api/academics/exam_results",

  transferCredit: ({ uid }) => `/api/advising/transfer_credit/${uid}`,

  academicHigherDegreeCommittees: () => "/api/my/academics/committees",

  pnpCalculatorValues: () => "/api/academics/pnp_calculator/calculator_values",

  pnpRatioMessage: () => "/api/academics/pnp_calculator/ratio_message",

  residency: () => "/api/my/residency",
  // residency: () => '/dummy/json/residency_resident.json',

  gradDegreeProgress: () => "/api/academics/degree_progress/grad",

  undergradDegreeProgress: () => "/api/academics/degree_progress/ugrd",

  holds: () => "/api/my/holds",
  // holds: () => '/dummy/json/holds_empty.json',
  // holds: () => '/dummy/json/holds_errored.json',
  // holds: () => '/dummy/json/holds_present.json',

  agreements: () => "/api/my/tasks/agreements",

  bCoursesTodos: () => "/api/my/tasks/b_courses_todos",

  checklistItems: () => "/api/my/tasks/checklist_items",

  housing: ({ finaidYearId }) => `/api/my/housing/${finaidYearId}`,
  facultyResources: () => `/api/campus_solutions/faculty_resources`,
  finaidAwards: ({ finaidYearId }) => `/api/my/awards/${finaidYearId}`,
  finaidProfile: ({ finaidYearId }) => `/api/my/finaid_profile/${finaidYearId}`,

  finaidCostOfAttendance: ({ finaidYearId }) =>
    `/api/campus_solutions/financial_aid_coa?aid_year=${finaidYearId}`,
  // finaidCostOfAttendance: () => `/dummy/json/financial_aid_data.json`,
  // finaidCostOfAttendance: () => `/dummy/json/financial_aid_data_summer_only.json`,
  // finaidCostOfAttendance: () => `/dummy/json/financial_aid_data_no_coa_data.json`,

  loanHistoryCumulative: () => `/api/my/loan_history_cumulative`,
  loanHistorySummary: () => `/api/my/loan_history_summary`,
  loanHistoryAidYears: () => `/api/my/loan_history_aid_years`,

  myStatus: () => "/api/my/status",

  config: () => "/api/config",

  billingActivity: () => "/api/campus_solutions/billing_activity",

  serverInfo: () => "/api/server_info",

  courseCaptures: (course) => {
    const [department, catalogNumber, termYear, termCode] = course.course_id
      .toUpperCase()
      .split("-");
    return `/api/media/${termYear}/${termCode}/${department}/${catalogNumber}`;
  },

  roster: (course) => `/api/academics/rosters/campus/${course.course_id}`,

  courseInfo: (course) => {
    const { term_id: termId, cs_course_id: csCourseId } = course;
    const sectionIds = course.sections.map((section) => section.ccn);
    const params = new URLSearchParams({ ids: sectionIds });
    return `/api/my/academics/terms/${termId}/courses/${csCourseId}/sections?${params}`;
  },

  courseTextbooks: (course, semester) => {
    const requestParams = courseTextbookRequestParams({
      currentCourse: course,
      currentSemester: semester,
    });

    return `/api/my/textbooks_details?${new URLSearchParams(requestParams)}`;
  },
};
