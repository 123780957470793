import { gql } from 'graphql-request';
import {
  SERVICE_ALERTS_START,
  SERVICE_ALERTS_SUCCESS,
  SERVICE_ALERTS_FAILURE,
} from '../action-types';

import graphqlClient from 'utils/graphqlClient';
const query = gql`
  {
    serviceAlerts {
      title
      splash
      banner
      id
      body
      snippet
      publicationDate
    }
  }
`;

export default function fetchServiceAlerts(csrfToken) {
  return (dispatch, getState) => {
    const { serviceAlerts } = getState();

    if (serviceAlerts.loadState === 'success') {
      return new Promise(resolve => resolve(serviceAlerts));
    } else if (serviceAlerts.loadState !== 'pending') {
      dispatch({ type: SERVICE_ALERTS_START });

      graphqlClient(csrfToken)
        .request(query)
        .then(({ serviceAlerts = [] }) => {
          dispatch({
            type: SERVICE_ALERTS_SUCCESS,
            value: serviceAlerts,
          });
        })
        .catch(error => {
          dispatch({
            type: SERVICE_ALERTS_FAILURE,
            value: error,
          });
        });
    }
  };
}
