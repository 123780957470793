import { useContext } from "react";
import { useParams } from "react-router-dom";
import { FakeFinaidYearContext } from "components/contexts/FakeFinaidYearContext";

export function useFinaidYearId() {
  const { finaidYearId } = useParams();
  const fakeFinaidYearId = useContext(FakeFinaidYearContext);

  if (finaidYearId) return finaidYearId;
  if (fakeFinaidYearId) return fakeFinaidYearId;

  return false;
}
