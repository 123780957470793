import styled from "styled-components";

import academicProgressReport from "components/../icons/academicprogressreport.svg";
import calculator from "components/../icons/calculator.svg";
import tools from "components/../icons/tools.svg";
import whatIf from "components/../icons/question-solid.svg";

export const SVGIcon = styled.div`
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 12px 11px;
  color: var(--dove-gray);
  width: 20px;
  height: 20px;
`;

export const APRIcon = styled(SVGIcon)`
  background-image: url("${academicProgressReport}");
`;

export const CalculatorIcon = styled(SVGIcon)`
  background-image: url("${calculator}");
`;

export const WhatIfIcon = styled(SVGIcon)`
  background-image: url("${whatIf}");
`;

export const PlannerIcon = styled(SVGIcon)`
  background-image: url("${tools}");
`;
