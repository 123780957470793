import React from "react";
import Popover, { usePopover } from "components/ui/Popover";
import ButtonAsLink from "components/ui/ButtonAsLink";
import InfoIcon from "components/ui/InfoIcon";
import WithMessage from "components/WithMessage";
import { useAcademicRoles } from "components/hooks/useAcademicRoles";
import { useUser } from "components/hooks/useUser";

export const Levels = () => {
  const isCurrentSummerVisitor = useAcademicRoles((r) => r.summerVisitor);
  const { latestLevels: levels = [] } = useUser((u) => u.academicProfile);

  const ugrdLevelPopover = usePopover();

  if (!isCurrentSummerVisitor && levels.length) {
    return (
      <>
        <dt data-testid="levels">Level</dt>
        <dd>
          {levels.map((level, index) => (
            <div key={index}>
              <span>
                {level.description}
                {level.careerCode === "UGRD" && (
                  <>
                    &nbsp;
                    <ButtonAsLink onClick={ugrdLevelPopover.open}>
                      <InfoIcon />
                    </ButtonAsLink>
                    <div style={{ position: `relative` }}>
                      {ugrdLevelPopover.isOpen && (
                        <WithMessage set="28000" number="322">
                          {(message) => (
                            <Popover
                              ref={ugrdLevelPopover.ref}
                              title={message.text}
                              close={ugrdLevelPopover.close}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: message.description,
                                }}
                              ></span>
                            </Popover>
                          )}
                        </WithMessage>
                      )}
                    </div>
                  </>
                )}
              </span>
            </div>
          ))}
        </dd>
      </>
    );
  }

  return null;
};

Levels.displayName = "Levels";
