import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useCurrentUID } from "./useCurrentUID";
import { useFinaidYearId } from "./useFinaidYearId";
import { useTestMode } from "./useTestMode";

export function dispatchUserFinaidSlice(fetchFunction) {
  const isTestMode = useTestMode();
  const dispatch = useDispatch();
  const uid = useCurrentUID();
  const finaidYearId = useFinaidYearId();

  useEffect(() => {
    if (!isTestMode) {
      if (uid) {
        dispatch(fetchFunction({ uid, finaidYearId }));
      }
    }
  }, [isTestMode, dispatch, uid, fetchFunction, finaidYearId]);
}
