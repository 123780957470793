import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { ModernBlueButtonLink } from "components/ui/ButtonLink";
import Spinner from "components/ui/Spinner";
import WithAPILink from "components/ui/WithAPILink";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import WithLinks from "components/WithLinks";
import ShowMore from "components/../react/components/ShowMore";
import Icon from "components/../react/components/Icon/Icon";
import { ICON_EXCLAMATION_TRIANGLE } from "components/../react/components/Icon/IconTypes";

import Balances from "./BillingSummaryCard/Balances";
import DueNow from "./BillingSummaryCard/DueNow";

import { getBillingActivity } from "data/slices/billingActivity";

export default connect(mapStateToProps)(BillingSummaryCard);

function mapStateToProps({
  myStatus: { canActOnFinances },
  billingActivity: { loadState } = {},
}) {
  return { canActOnFinances, loadState };
}

function BillingSummaryCard({ dispatch, loadState, canActOnFinances }) {
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getBillingActivity());
  }, []);

  return (
    <WithLinks>
      <Card>
        <CardTitle>
          <h2>Billing Summary</h2>

          {pathname === "/finances" && (
            <strong style={{ fontSize: `14px` }}>
              <Link to="/billing/details">
                View Transactions <i className="fa fa-arrow-right" />
              </Link>
            </strong>
          )}
        </CardTitle>

        {loadState === "pending" ? (
          <CardBody>
            <Spinner />
          </CardBody>
        ) : loadState === "failure" ? (
          <>
            <Icon name={ICON_EXCLAMATION_TRIANGLE} />
            There&apos;s a problem displaying your billing information. Please
            try again soon.
          </>
        ) : (
          <>
            <DueNow />
            <CardBody style={{ paddingBottom: `0` }}>
              <Balances />
              <PaymentLinkStyles>
                <ModernBlueButtonLink
                  disabled={!canActOnFinances}
                  href="/higher_one/higher_one_url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AccessibleLinkContent showNewWindow={true}>
                    Make Payment
                  </AccessibleLinkContent>
                </ModernBlueButtonLink>

                <a
                  className="pdfStatements"
                  disabled={!canActOnFinances}
                  href="/higher_one/higher_one_url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AccessibleLinkContent showNewWindow={true}>
                    View PDF Statement
                  </AccessibleLinkContent>
                </a>
              </PaymentLinkStyles>

              <ShowMore text="Learn more about Billing" view={<LearnMore />} />
            </CardBody>
          </>
        )}
      </Card>
    </WithLinks>
  );
}

BillingSummaryCard.propTypes = {
  dispatch: PropTypes.func,
  loadState: PropTypes.string,
  canActOnFinances: PropTypes.bool,
};

const LearnMore = () => {
  return (
    <LearnMoreStyles>
      <strong>Due Now</strong>
      <p>
        All charges currently due. Due Now also includes Overdue charges. Learn
        more about <WithAPILink urlID="UC_CX_FA_STU_BILL" />
      </p>
      <strong>Not Yet Due</strong>
      <p>
        All charges with a due date in the future. Learn more about{" "}
        <WithAPILink urlID="UC_CX_FA_STU_BILL" />
      </p>
      <strong>Overdue</strong>
      <p>
        All charges with a due date before today. Overdue charges can result in
        late fees and enrollment cancellation. Learn more about{" "}
        <WithAPILink urlID="UC_CX_FA_DEL_ACC" />
      </p>
      <strong>Making Payments</strong>
      <p>
        Make Payment links to the student payment portal where you can pay your
        bill. Learn more about <WithAPILink urlID="UC_CX_FA_PAY_OPTS" />
      </p>
      <strong>View PDF Statement</strong>
      <p>Links to PDF statements that are easy to download or print.</p>
    </LearnMoreStyles>
  );
};

const LearnMoreStyles = styled.div`
  padding-bottom: var(--large-spacing);

  p {
    margin: 0;
    margin-bottom: 20px;
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PaymentLinkStyles = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  border-bottom: 1px solid var(--gallery);
  padding: 15px 0;

  a.pdfStatements[disabled] {
    color: var(--dove-gray);
  }
`;
