import React from "react";
import { useSelector } from "react-redux";
import { Accordion } from "@reach/accordion";
import "@reach/accordion/styles.css";

import AccordionItem from "./AccordionItem";

function BCalScheduleItems() {
  const {
    date,
    items,
  } = useSelector((state) => {
    const {
      bCalSchedule: { date = null, items = [] } = {},
    } = state;

    return {
      date,
      items,
    };
  });

  return (
    <Accordion
      data-testid="bcal-schedule-items"
      collapsible
      style={{ padding: `0 var(--large-spacing)` }}
    >
      {items.map((item, index) => (
        <AccordionItem key={index} date={date} item={item} index={index} />
      ))}
    </Accordion>
  );
}

BCalScheduleItems.displayName = "BCalScheduleItems";
export default BCalScheduleItems;
