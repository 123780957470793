import React from "react";
import PropTypes from "prop-types";
import Spinner from "./Spinner";

export default function SpinnerIf({ loading, children, message }) {
  if (loading) {
    return <Spinner message={message} />;
  }

  return children;
}

SpinnerIf.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  message: PropTypes.string,
};
