import React from "react";
import PropTypes from "prop-types";
import Badge from "components/../react/components/Badge";
import DisclosureChevron from "components/../react/components/DisclosureChevron";
import WhiteChangedIcon from "../../Icon/WhiteChangedIcon";
import "./AwardComparison.scss";

const SectionHeader = ({ expanded, label, numberOfChanges }) => {
  return (
    <h3>
      <div className="headerContainer">
        <div>
          <DisclosureChevron
            style={{ marginRight: "10px" }}
            expanded={expanded}
          />
          {label}
        </div>
        {numberOfChanges > 0 && (
          <Badge
            count={numberOfChanges}
            backgroundColor="#F1A91E"
            color="#FFFFFF"
            style={{ marginLeft: `10px` }}
          >
            <WhiteChangedIcon className="headerIcon" />
          </Badge>
        )}
      </div>
    </h3>
  );
};

SectionHeader.displayName = "SectionHeader";
SectionHeader.propTypes = {
  expanded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  numberOfChanges: PropTypes.number,
};

export default SectionHeader;
