import {
  ADVISING_RESOURCES_REFRESH,
  ADVISING_RESOURCES_START,
  ADVISING_RESOURCES_SUCCESS,
} from "../action-types";

export function fetchAdvisingResources(uid) {
  return (dispatch, getState) => {
    const { advising: { resources = {} } = {} } = getState();

    if (
      resources.loadState === "pending" ||
      resources.loadState === "success"
    ) {
      if (resources.uid === uid) {
        return new Promise((resolve, _reject) => resolve(resources));
      } else {
        dispatch({
          type: ADVISING_RESOURCES_REFRESH,
          value: uid,
        });

        fetch(`/api/advising/resources/${uid}`)
          .then((res) => res.json())
          .then((data) =>
            dispatch({
              type: ADVISING_RESOURCES_SUCCESS,
              value: { ...data, uid: uid },
            })
          );
      }
    } else {
      dispatch({
        type: ADVISING_RESOURCES_START,
        value: uid,
      });

      fetch(`/api/advising/resources/${uid}`)
        .then((res) => res.json())
        .then((data) =>
          dispatch({
            type: ADVISING_RESOURCES_SUCCESS,
            value: { ...data, uid: uid },
          })
        );
    }
  };
}
