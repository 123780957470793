import React from "react";
import PropTypes from "prop-types";

import SingleNotification from "./SingleNotification";
import MultiNotifications from "./MultiNotifications";

const SourceAndTypeGroup = ({
  source,
  type,
  notifications,
  expanded,
  setExpanded,
}) => {
  if (notifications.length === 1) {
    return (
      <SingleNotification
        source={source}
        type={type}
        notification={notifications[0]}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    );
  } else {
    return (
      <MultiNotifications
        source={source}
        type={type}
        notifications={notifications}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    );
  }
};

SourceAndTypeGroup.propTypes = {
  source: PropTypes.string,
  type: PropTypes.string,
  notifications: PropTypes.array,
  expanded: PropTypes.string,
  setExpanded: PropTypes.func,
};

export default SourceAndTypeGroup;
