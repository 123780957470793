import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { fetchStatusAndHolds } from "data/actions/statusActions";

import RegistrationStatusItemsForTerm from "./RegistrationStatusItemsForTerm";

const RegistrationStatusItems = ({ dispatch, termRegistrations }) => {
  useEffect(() => {
    dispatch(fetchStatusAndHolds());
  }, []);

  return (
    <Fragment>
      {termRegistrations.map((reg) => (
        <RegistrationStatusItemsForTerm
          key={reg.termId}
          termRegistration={reg}
        />
      ))}
    </Fragment>
  );
};

RegistrationStatusItems.propTypes = {
  dispatch: PropTypes.func.isRequired,
  termRegistrations: PropTypes.array.isRequired,
};

const mapStateToProps = ({ myStatusAndHolds }) => {
  const { termRegistrations = [] } = myStatusAndHolds;

  return {
    termRegistrations,
  };
};

export default connect(mapStateToProps)(RegistrationStatusItems);
