import React from "react";
import PropTypes from "prop-types";
import APILink from "components/ui/APILink";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { ProgressLink, LinkSubTitle } from "./ProgressLinksUI";
import {
  APRIcon,
  CalculatorIcon,
  WhatIfIcon,
  PlannerIcon,
} from "./ProgressLinkIcons";

export function APRLink({ aprLink, aprFaqsLink }) {
  if (aprLink) {
    return (
      <ProgressLink title="Academic Progress Report" icon={<APRIcon />}>
        {aprLink.linkDescriptionDisplay && (
          <LinkSubTitle>
            {aprLink.linkDescription + " "}
            {aprFaqsLink && <APILink link={aprFaqsLink} />}
          </LinkSubTitle>
        )}

        <APILink link={aprLink} />
      </ProgressLink>
    );
  }

  return null;
}

APRLink.propTypes = {
  aprLink: PropTypes.object,
  aprFaqsLink: PropTypes.object,
};

export function APRWhatIfLink({ aprWhatIfLink }) {
  if (aprWhatIfLink) {
    return (
      <ProgressLink
        title="What-if Academic Progress Report"
        icon={<WhatIfIcon />}
      >
        {aprWhatIfLink.linkDescriptionDisplay && (
          <LinkSubTitle>{aprWhatIfLink.linkDescription}</LinkSubTitle>
        )}

        <APILink link={aprWhatIfLink} />
      </ProgressLink>
    );
  }

  return null;
}

APRWhatIfLink.propTypes = {
  aprWhatIfLink: PropTypes.object,
};

export function DegreePlannerLink({ degreePlannerLink }) {
  if (degreePlannerLink) {
    return (
      <ProgressLink title="Degree Planner" icon={<PlannerIcon />}>
        {degreePlannerLink.linkDescriptionDisplay && (
          <LinkSubTitle>{degreePlannerLink.linkDescription}</LinkSubTitle>
        )}

        <APILink link={degreePlannerLink} />
      </ProgressLink>
    );
  }

  return null;
}

DegreePlannerLink.propTypes = {
  degreePlannerLink: PropTypes.object,
};

export function PNPCalculatorButton({ isAdvisingStudentLookup, onClick }) {
  return (
    <ProgressLink title="1/3 Passed Grade Estimator" icon={<CalculatorIcon />}>
      <ButtonAsLink onClick={onClick} disabled={isAdvisingStudentLookup}>
        Estimate 1/3 PNP unit limits
      </ButtonAsLink>

      {isAdvisingStudentLookup && (
        <div>
          Use View-As to see this student&apos;s Passed(P) Grade Limit and use
          the estimator.
        </div>
      )}
    </ProgressLink>
  );
}

PNPCalculatorButton.propTypes = {
  isAdvisingStudentLookup: PropTypes.bool,
  onClick: PropTypes.func,
};
