import styled from "styled-components";
import info from "icons/info.svg";

const InfoIcon = styled.span`
  background-image: url("${info}");
  background-position-y: center;
  background-repeat: no-repeat;
  margin-left: 5px;
  padding-left: 12px;
  margin-left: 0;
  margin-bottom: 0;
`;

export default InfoIcon;
