import React from "react";

import styles from "./iconWrapper.module.scss";

function CanvasIcon() {
  return (
    <div className={styles.iconWrapper}>
      <div className="cc-icon cc-icon-bcourses"></div>
    </div>
  );
}

export default CanvasIcon;
