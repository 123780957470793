import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import unavailableBear from "assets/images/photo_unavailable_bear_72x96.svg";
import ButtonAsLink from "components/ui/ButtonAsLink";

import { hasNoPrivileges, hasPhoneOnly, viewable } from "utils/delegates";
import { delegateViewAsUID } from "utils/requests";

export default connect(mapStateToProps)(DelegateStudent);

function mapStateToProps({ csrfToken, currentUID }) {
  return { csrfToken, currentUID };
}

function DelegateStudent({ csrfToken, currentUID, student }) {
  const { fullName, uid } = student;
  const isViewable = viewable(student);
  const selfDelegating = uid === currentUID;
  const accessable = uid && isViewable && !selfDelegating;
  const viewAs = async () => {
    const req = await delegateViewAsUID(csrfToken)(uid);
    if (req.ok) {
      window.location = "/";
    }
  };

  return (
    <DelegateStudentStyles>
      {accessable ? (
        <ButtonAsLink onClick={viewAs}>
          <DelegateStudentImageStyles alt={`${fullName}'s photo`} />
        </ButtonAsLink>
      ) : (
        <DelegateStudentImageStyles alt={`${fullName}'s photo`} />
      )}

      {accessable ? (
        <ButtonAsLink onClick={viewAs}>{fullName}</ButtonAsLink>
      ) : (
        <div>
          <strong>{fullName}</strong>
          {accessMessage(student, selfDelegating) && (
            <p>{accessMessage(student, selfDelegating)}</p>
          )}
        </div>
      )}
    </DelegateStudentStyles>
  );
}

DelegateStudent.propTypes = {
  csrfToken: PropTypes.string,
  currentUID: PropTypes.string,
  student: PropTypes.shape({
    fullName: PropTypes.string,
    uid: PropTypes.string,
  }),
};

const DelegateStudentImageStyles = styled.div`
  background-image: url("${unavailableBear}");
  height: 96px;
  width: 72px;
`;

function accessMessage(student, selfDelegating) {
  if (hasNoPrivileges(student)) {
    return "No Privileges";
  } else if (hasPhoneOnly(student)) {
    return "By Phone and In-Person Only";
  } else if (selfDelegating) {
    return "Cannot Delegate for Self";
  } else if (!student.uid) {
    return "Account Issue";
  }
}

const DelegateStudentStyles = styled.li`
  display: flex;
  gap: var(--large-spacing);

  button {
    font-size: 16px;
  }

  strong {
    font-size: 16px;
  }

  p {
    font-size: 12px;
  }
`;
