import React from "react";
import SummerDeadlinesTable from "./SummerDeadlinesTable";

import { presentationShape, deadlineShape } from "../propTypes";
import TimeCell from "../TimeCell";
import DayOfWeek from "./DayOfWeek";

function SummerDeadlineRow({ deadline }) {
  const { session, addDeadline } = deadline;

  return (
    <tr>
      <td>{session}</td>
      <td>
        <DayOfWeek time={addDeadline} />
      </td>
      <td>
        <TimeCell time={addDeadline} />
      </td>
    </tr>
  );
}

SummerDeadlineRow.propTypes = {
  deadline: deadlineShape,
};

export default function SummerDeadlines({
  presentation: { constraints: { deadlines = [] } = {} },
}) {
  return (
    <SummerDeadlinesTable>
      {deadlines.map((deadline) => (
        <SummerDeadlineRow key={deadline.session} deadline={deadline} />
      ))}
    </SummerDeadlinesTable>
  );
}

SummerDeadlines.propTypes = {
  presentation: presentationShape,
};
