export function viewable({ privileges }) {
  if (
    privileges.financial ||
    privileges.viewEnrollments ||
    privileges.viewGrades
  ) {
    return true;
  }

  return false;
}

export function hasNoPrivileges({ privileges }) {
  if (
    privileges.financial === false &&
    privileges.viewEnrollments === false &&
    privileges.viewGrades === false &&
    privileges.phone === false
  ) {
    return true;
  }

  return false;
}

export function hasPhoneOnly({ privileges }) {
  if (
    privileges.financial === false &&
    privileges.viewEnrollments === false &&
    privileges.viewGrades === false &&
    privileges.phone === true
  ) {
    return true;
  }

  return false;
}
