import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { connect } from "react-redux";
import {
  fetchAdvisorUndergradDegreeProgress,
  fetchAdvisorGraduateDegreeProgress,
} from "data/actions/advisorDegreeProgress";

import { fetchAdvisingStudentData } from "data/actions/advisingStudentData";

import AdvisorGraduateDegreeProgressCard from "./DegreeProgress/AdvisorGraduateDegreeProgressCard";
import AdvisorUndergradDegreeProgressCard from "./DegreeProgress/AdvisorUndergradDegreeProgressCard";

import Spinner from "components/ui/Spinner";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvisorDegreeProgressLoader);

function mapStateToProps(state) {
  const {
    advising: {
      undergradDegreeProgress: {
        loadState: undergradLoadState = "pending",
        progresses: undergradDegreeProgresses = [],
      } = {},
      graduateDegreeProgress: {
        degreeProgress: graduateDegreeProgresses = [],
        loadState: graduateLoadState = "pending",
      },
      studentData: {
        academicRoles: {
          current: { grad: isCurrentGrad, law: isCurrentLaw } = {},
          historical: { ugrd: isHistoricalUndergrad } = {},
        } = {},
      } = {},
    } = {},
    myStatus: { roles: { advisor: isAdvisor } = {} },
  } = state;

  const loadState =
    undergradLoadState === "success" && graduateLoadState === "success"
      ? "success"
      : "pending";

  return {
    graduateDegreeProgresses,
    undergradDegreeProgresses,
    loadState,
    isAdvisor,
    isCurrentGrad,
    isCurrentLaw,
    isHistoricalUndergrad,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (uid) => {
      dispatch(fetchAdvisingStudentData(uid));
      dispatch(fetchAdvisorUndergradDegreeProgress(uid));
      dispatch(fetchAdvisorGraduateDegreeProgress(uid));
    },
  };
}

// This component loads the data necessary for the degree progress cards
// (specifically for advisors).
//
// The uid is pulled from the path `/user/overview/:uid`
//
// If "showGraduateCard" is true, it will always take priority over the
// Undergrad version of the card
function AdvisorDegreeProgressLoader({
  fetchData,
  graduateDegreeProgresses,
  undergradDegreeProgresses,
  loadState,
  isAdvisor,
  isCurrentGrad,
  isCurrentLaw,
  isHistoricalUndergrad,
}) {
  let { uid } = useParams();

  useEffect(() => {
    if (isAdvisor) {
      fetchData(uid);
    }
  }, [isAdvisor, uid]);

  if (loadState === "pending") {
    return <Spinner />;
  }

  const showGraduateCard =
    isCurrentGrad || isCurrentLaw || graduateDegreeProgresses.length > 0;

  const showUndergradCard =
    isHistoricalUndergrad || undergradDegreeProgresses.length > 0;

  if (showGraduateCard) {
    return <AdvisorGraduateDegreeProgressCard />;
  }

  if (showUndergradCard) {
    return <AdvisorUndergradDegreeProgressCard />;
  }

  return null;
}

AdvisorDegreeProgressLoader.propTypes = {
  fetchData: PropTypes.func,
  graduateDegreeProgresses: PropTypes.array,
  undergradDegreeProgresses: PropTypes.array,
  loadState: PropTypes.string,
  isAdvisor: PropTypes.bool,
  isCurrentGrad: PropTypes.bool,
  isCurrentLaw: PropTypes.bool,
  isHistoricalUndergrad: PropTypes.bool,
};
