import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import NotificationsCard from "components/../react/components/_dashboard/NotificationsCard/NotificationsCard";

const NgNotificationsCard = () => (
  <ReduxProvider>
    <NotificationsCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("notificationsCard", react2angular(NgNotificationsCard));
