import React from "react";
import PropTypes from "prop-types";

export const ValueOrDash = ({ value }) => (value ? value : <>&mdash;</>);

ValueOrDash.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ValueOrDash;
