import React from "react";
import PropTypes from "prop-types";
import { Item } from "./Item";

export const ItemGroup = ({ itemGroup = [] }) => {
  if (itemGroup.length > 0) {
    return (
      <>
        {itemGroup.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </>
    );
  }

  return null;
};

ItemGroup.displayName = "ItemGroup";
ItemGroup.propTypes = {
  itemGroup: PropTypes.array,
};
