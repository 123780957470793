import React from "react";
import PropTypes from "prop-types";

export default function SummerDeadlinesTable({ children }) {
  return (
    <div className="cc-table">
      <table className="table">
        <thead>
          <tr>
            <th width="44">Law Add/Drop Deadlines</th>
            <th width="23%"></th>
            <th width="33%"></th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

SummerDeadlinesTable.propTypes = {
  children: PropTypes.node.isRequired,
};
