import React, { useState } from "react";
import PropTypes from "prop-types";

import MissingPhoto from "./MissingPhoto";

import styled from "styled-components";
import unavailableBear from "assets/images/photo_unavailable_bear_72x96.svg";

const DelegateStudentImageStyles = styled.div`
  background-image: url("${unavailableBear}");
  height: 96px;
  width: 72px;
`;

const ProfilePicture = (props) => {
  const [error, setError] = useState(false);
  const {
    isDelegate,
    fullName,
    isStudentSummaryView = false,
    ldapUid = null,
  } = props;

  let imgSrc = "/api/my/photo";
  if (isStudentSummaryView && ldapUid) {
    imgSrc = `api/photo/${ldapUid}`;
  }
  if (isDelegate) {
    return (
      <DelegateStudentImageStyles alt={`${fullName}'s photo`} />
    );
  } else if (error) {
    return <MissingPhoto isStudentSummaryView={isStudentSummaryView} />;
  } else {
    return (
      <img
        src={imgSrc}
        alt={`${fullName}'s Profile Picture`}
        onError={() => setError(true)}
        width="72"
        height="96"
      />
    );
  }
};

ProfilePicture.displayName = "ProfilePicture";
ProfilePicture.propTypes = {
  isDelegate: PropTypes.bool,
  fullName: PropTypes.string,
  isStudentSummaryView: PropTypes.bool,
  ldapUid: PropTypes.string,
};

export default ProfilePicture;
