// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header___ZsMoL{display:flex;justify-content:space-between;align-items:center;margin-left:10px;margin-right:20px;margin-bottom:8px}.header___ZsMoL .title___DGDMW{color:var(--thunderbird);font-size:16px}.header___ZsMoL .label___rHceo{text-align:right;color:var(--dusty-gray);font-weight:700;font-size:12px}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/OverdueTasks/OverdueTasksHeader.module.scss"],"names":[],"mappings":"AAIA,gBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA,CAEA,+BACE,wBAXW,CAYX,cAAA,CAGF,+BACE,gBAAA,CACA,uBAAA,CACA,eAAA,CACA,cAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n$danger-color: var(--thunderbird);\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 10px;\n  margin-right: 20px;\n  margin-bottom: 8px;\n\n  .title {\n    color: $danger-color;\n    font-size: 16px;\n  }\n\n  .label {\n    text-align: right;\n    color: var(--dusty-gray);\n    font-weight: 700;\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header___ZsMoL",
	"title": "title___DGDMW",
	"label": "label___rHceo"
};
export default ___CSS_LOADER_EXPORT___;
