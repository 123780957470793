import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { react2angular } from "react2angular";
import BillingSummaryCard from "../cards/BillingSummaryCard";
import ReduxProvider from "../ReduxProvider";

function NgBillingSummaryCard() {
  return (
    <Router>
      <ReduxProvider>
        <BillingSummaryCard />
      </ReduxProvider>
    </Router>
  );
}

angular
  .module("calcentral.react")
  .component("billingSummaryCard", react2angular(NgBillingSummaryCard));
