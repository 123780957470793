import React, { useState } from "react";
import PropTypes from "prop-types";

import ButtonAsLink from "components/ui/ButtonAsLink";
import Icon from "./Icon/Icon";
import { ICON_CHEVRON_DOWN, ICON_CHEVRON_UP } from "./Icon/IconTypes";

const propTypes = {
  text: PropTypes.string.isRequired,
  view: PropTypes.node.isRequired,
};

export default function ShowMore({ text, view }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div style={{ padding: `var(--large-spacing)`, textAlign: `center` }}>
        <ButtonAsLink onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <>
              Show Less <Icon name={ICON_CHEVRON_UP} />
            </>
          ) : (
            <>
              {text} <Icon name={ICON_CHEVRON_DOWN} />
            </>
          )}
        </ButtonAsLink>
      </div>

      {expanded && view}
    </>
  );
}

ShowMore.propTypes = propTypes;
