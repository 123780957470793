import React from "react";
import { Field } from "formik";
import { Link } from "react-router-dom";

import Form from "components/ui/Form";

import {
  FieldWrapper,
  CheckboxFieldWrapper,
} from "components/ui/FieldWrappers";

import FieldError from "components/ui/FieldError";

export default function ServiceAlertForm() {
  return (
    <Form>
      <FieldWrapper>
        <label htmlFor="title">Title</label>
        <Field id="title" name="title" style={{ width: `100%` }} />
        <FieldError name="title" />
      </FieldWrapper>

      <FieldWrapper>
        <label htmlFor="body">Body HTML</label>
        <Field as="textarea" id="body" name="body" />
        <FieldError name="body" />
      </FieldWrapper>

      <FieldWrapper>
        <label htmlFor="publication_date">Publication Date</label>
        <Field
          id="publication_date"
          name="publication_date"
          placeholder="MM/DD/YYYY"
          type="date"
        />
        <FieldError name="publication_date" />
      </FieldWrapper>

      <CheckboxFieldWrapper>
        <Field type="checkbox" name="banner" id="banner" />
        <label htmlFor="banner">Banner</label>
        <FieldError name="banner" />
      </CheckboxFieldWrapper>

      <CheckboxFieldWrapper>
        <Field type="checkbox" name="splash" id="splash" />
        <label htmlFor="v">Splash</label>
      </CheckboxFieldWrapper>

      <CheckboxFieldWrapper>
        <input type="submit" value="Save" />
        <Link to="/service_alerts" style={{ marginLeft: `15px` }}>
          cancel
        </Link>
      </CheckboxFieldWrapper>
    </Form>
  );
}
ServiceAlertForm.displayName = "ServiceAlertForm";
