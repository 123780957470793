import styled from "styled-components";

const SplashContainer = styled.div`
  margin: 0 auto;
  background-color: white;
  max-width: 450px;
  padding: 20px 20px 30px;
  border-radius: 5px;
`;

export default SplashContainer;
