import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import { InactiveNoticeCard } from "components/cards/InactiveNoticeCard";

const NgInactiveNoticeCard = () => (
  <ReduxProvider>
    <InactiveNoticeCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("inactiveNoticeCard", react2angular(NgInactiveNoticeCard));
