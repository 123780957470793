export function courseTextbookRequestParams({
  currentSemester: semester,
  currentCourse: course,
}) {
  const primaries = course.sections.filter((s) => s.is_primary_section);
  const sectionNumbers = semester.campusSolutionsTerm
    ? primaries.map((section) => section.ccn)
    : primaries.map((section) => section.section_number);

  return sectionNumbers.length > 0
    ? {
        "sectionNumbers[]": sectionNumbers,
        department: course.dept_code,
        courseCatalog: course.courseCatalog,
        slug: semester.slug,
      }
    : false;
}
