import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";

export default function InfoIconButton({ onClick }) {
  return (
    <button className="cc-button-link" onClick={onClick}>
      <span
        className="cc-icon-info"
        style={{ marginLeft: 0, marginBottom: 0 }}
      />

      <VisuallyHidden>More Information</VisuallyHidden>
    </button>
  );
}

InfoIconButton.propTypes = {
  onClick: PropTypes.func,
};
