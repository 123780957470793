// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskHeader___L52QL{padding:10px;padding-right:20px;display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/TaskHeader.module.scss","webpack://./src/react/stylesheets/_variables.scss"],"names":[],"mappings":"AAGA,oBACE,YCCe,CAAA,kBAAA,CDCf,YAAA,CACA,6BAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n@import 'src/react/stylesheets/mixins';\n\n.taskHeader {\n  padding: $medium-spacing;\n  padding-right: 20px;\n  display: flex;\n  justify-content: space-between;\n}\n","@import './foundation_overrides';\n\n// Base variables\n$tiny-spacing: 2px;\n$small-spacing: 5px;\n$medium-spacing: 10px;\n$large-spacing: 15px;\n\n$small-border-radius: 5px;\n\n// Typography:\n$base-font-color: var(--mine-shaft);\n$color-text-alt: var(--dove-gray);\n$color-text-link: var(--denim);\n\n$base-font-family: Arial, sans-serif;\n\n$base-font-size: 13px;\n\n$hairline-border: 1px solid var(--gallery);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskHeader": "taskHeader___L52QL"
};
export default ___CSS_LOADER_EXPORT___;
