import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StyledMessage from "components/StyledMessage";

function CAEnrollmentAckMessage({ message }) {
  return (
    <StyledMessage>
      <div dangerouslySetInnerHTML={{ __html: message.descrlong }} />
    </StyledMessage>
  );
}

CAEnrollmentAckMessage.propTypes = {
  message: PropTypes.shape({
    descrlong: PropTypes.string,
  }),
};

const mapStateToProps = ({ currentUID, users }) => {
  const {
    enrollmentResources: {
      messages: { calgrantEnrollmentAcknowledgement } = {},
    } = {},
  } = users[currentUID] || {};
  return { message: calgrantEnrollmentAcknowledgement };
};

export default connect(mapStateToProps)(CAEnrollmentAckMessage);
