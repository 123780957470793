import styled from "styled-components";

const TinyText = styled.p`
  font-size: var(--tiny-font-size);
  line-height: var(--tiny-line-height);
  margin-top: var(--medium-spacing);
  margin-bottom: var(--medium-spacing);
`;

export default TinyText;
