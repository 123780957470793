import styled from "styled-components";

const Table = styled.table`
  background: none;
  font-size: var(--base-font-size);
  margin: 0;
  border: none;
  border-spacing: 0;
  width: 100%;

  thead {
    background: none;
  }

  tr:nth-of-type(2n) {
    background: none;
  }

  tr td,
  tr th {
    font-size: var(--base-font-size);
    line-height: inherit;
    background: none;
    padding: 0;
  }

  thead,
  tfoot,
  tbody {
    tr td,
    tr th {
      font-size: var(--base-font-size);
      line-height: inherit;
      background: none;
      padding: 0;
    }
  }

  tbody tr {
    th,
    td {
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
    }
  }
`;

export default Table;
