import React from "react";
import LightText from "components/ui/LightText";
import { useUser } from "components/hooks/useUser";

export function Emphases() {
  const { designatedEmphases: emphases = [] } = useUser(
    (user) => user.academicProfile
  );

  if (emphases.length) {
    const title =
      emphases.length === 1 ? "Designated Emphasis" : "Designated Emphases";
    return (
      <>
        <dt data-testid="emphases">{title}</dt>
        <dd>
          {emphases.map((emphasis, index) => (
            <div key={index}>
              <LightText>{emphasis.college}</LightText>
              <div>{emphasis.description}</div>
              {emphasis.subPlans.map((subPlan, index) => (
                <div key={index} className="cc-widget-profile-indent">
                  {subPlan}
                </div>
              ))}
            </div>
          ))}
        </dd>
      </>
    );
  }

  return null;
}

Emphases.displayName = "Emphases";
