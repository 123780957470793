import { gql } from "graphql-request";
export const advisingStudentProfileQuery = gql`
  query ($uid: String) {
    user(uid: $uid) {
      campusSolutionsId
      firstName
      lastName
      fullName
      pronouns
      roles
      sid
      uid
    }
  }
`;
