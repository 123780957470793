// Profile
export const UC_CX_PROFILE = "UC_CX_PROFILE";
export const UC_CX_PROFILE_SFF = "UC_CX_PROFILE_SFF";

// Enrollment Card
export const UC_CX_GT_GRADEOPT_ADD = "UC_CX_GT_GRADEOPT_ADD";
export const UC_CX_GT_SSCNTENRL_ADD = "UC_CX_GT_SSCNTENRL_ADD";
export const UC_CX_GT_SSCNTENRL_VIEW = "UC_CX_GT_SSCNTENRL_VIEW";
export const UC_CX_GT_SSCNTENRL_UPD = "UC_CX_GT_SSCNTENRL_UPD";
export const UC_CX_GT_SRLATEDROP_ADD = "UC_CX_GT_SRLATEDROP_ADD";
export const UC_CX_GT_SRWITHDRL_ADD = "UC_CX_GT_SRWITHDRL_ADD";
export const UC_CX_GT_UCBX_VIEW = "UC_CX_GT_UCBX_VIEW";
export const UC_SR_SS_BERK_ACAD_GUIDE = "UC_SR_SS_BERK_ACAD_GUIDE";
export const UC_SR_SS_BERK_TIME = "UC_SR_SS_BERK_TIME";
export const UC_SR_SS_ENRL_RULES = "UC_SR_SS_ENRL_RULES";
export const UC_SR_SS_ENRL_CAL = "UC_SR_SS_ENRL_CAL";
export const UC_SR_SS_ENRL_FAQS = "UC_SR_SS_ENRL_FAQS";
export const UC_SR_SS_ENRL_FPFCAT = "UC_SR_SS_ENRL_FPFCAT";
export const UC_SR_SS_ENROLLMENT_CENTER = "UC_SR_SS_ENROLLMENT_CENTER";
export const UC_SR_SS_ENROLLMENT_CENTER_SFF = "UC_SR_SS_ENROLLMENT_CENTER_SFF";
export const UC_SR_SS_EXT_ACCOUNT = "UC_SR_SS_EXT_ACCOUNT";
export const UC_SR_SS_EXT_INFO = "UC_SR_SS_EXT_INFO";
export const UC_SR_SS_FINAL_EXAM_SCH = "UC_SR_SS_FINAL_EXAM_SCH";
export const UC_SR_SS_HAAS_OLR = "UC_SR_SS_HAAS_OLR";
export const UC_SR_SS_HAAS_GRAD_REQ_EWMBA = "UC_SR_SS_HAAS_GRAD_REQ_EWMBA";
export const UC_SR_SS_HAAS_CRSE_PLAN_EWMBA = "UC_SR_SS_HAAS_CRSE_PLAN_EWMBA";
export const UC_SR_SS_HAAS_NON_HAAS_EWMBA = "UC_SR_SS_HAAS_NON_HAAS_EWMBA";
export const UC_SR_SS_HAAS_GRAD_REQ_MBA = "UC_SR_SS_HAAS_GRAD_REQ_MBA";
export const UC_SR_SS_HAAS_AREAS_OF_EMPH_MB = "UC_SR_SS_HAAS_AREAS_OF_EMPH_MB";
export const UC_SR_SS_HAAS_NON_HAAS_MBA = "UC_SR_SS_HAAS_NON_HAAS_MBA";
export const UC_SR_SS_MBA_CLASS_SEARCH = "UC_SR_SS_MBA_CLASS_SEARCH";
export const UC_SR_SS_SUMM_DEADLINES = "UC_SR_SS_SUMM_DEADLINES";
export const UC_SR_SS_TERM_WITH_CANC = "UC_SR_SS_TERM_WITH_CANC";


// academic profile
export const UC_SR_EXP_GRAD_TERM_CHG_REQ = "UC_SR_EXP_GRAD_TERM_CHG_REQ";
