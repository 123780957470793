import React from "react";
import WithMessage from "components/WithMessage";
import StyledMessage from "components/StyledMessage";

export default function SIRNextSteps() {
  return (
    <WithMessage set="24000" number="154">
      {(message) => (
        <StyledMessage>
          <div
            dangerouslySetInnerHTML={{
              __html: message.description,
            }}
          ></div>
        </StyledMessage>
      )}
    </WithMessage>
  );
}
