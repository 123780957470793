import React from "react";
import { useUser } from "components/hooks/useUser";
import { useAcademicRoles } from "components/hooks/useAcademicRoles";

export const Careers = () => {
  const isCurrentSummerVisitor = useAcademicRoles((r) => r.summerVisitor);
  const { latestCareers: careers = [] } = useUser((u) => u.academicProfile);

  if (!isCurrentSummerVisitor && careers.length) {
    const title = careers.length === 1 ? "Academic Career" : "Academic Careers";
    return (
      <>
        <dt data-testid="academic-careers">{title}</dt>
        <dd>
          {careers.map((career) => (
            <div key={career}>{career}</div>
          ))}
        </dd>
      </>
    );
  }
  return null;
};

Careers.displayName = "Careers";
