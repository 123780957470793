// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".task___jPgA5{border-top:1px solid var(--gallery)}.task___jPgA5:last-child{border-bottom:1px solid var(--gallery)}.task___jPgA5:hover{background:var(--foam)}.task___jPgA5.noActionNecessary___PPFCv{color:#999}.header___tE4_G{padding:10px 20px 20px 10px;display:flex;justify-content:space-between}.header___tE4_G .title___bm1SZ{flex:1;font-weight:bold}.header___tE4_G .icon___n2hR7{width:30px}.header___tE4_G .date___SAEeV{width:85px;text-align:right;margin-right:10px}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/Task.module.scss"],"names":[],"mappings":"AAGA,cACE,mCAAA,CAEA,yBACE,sCAAA,CAGF,oBACE,sBAAA,CAGF,wCACE,UAAA,CAIJ,gBACE,2BAAA,CAEA,YAAA,CACA,6BAAA,CAEA,+BACE,MAAA,CACA,gBAAA,CAGF,8BACE,UAAA,CAGF,8BACE,UAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n@import 'src/react/stylesheets/mixins';\n\n.task {\n  border-top: 1px solid var(--gallery);\n\n  &:last-child {\n    border-bottom: 1px solid var(--gallery);\n  }\n\n  &:hover {\n    background: var(--foam);\n  }\n\n  &.noActionNecessary {\n    color: #999;\n  }\n}\n\n.header {\n  padding: 10px 20px 20px 10px;\n\n  display: flex;\n  justify-content: space-between;\n\n  .title {\n    flex: 1;\n    font-weight: bold;\n  }\n\n  .icon {\n    width: 30px;\n  }\n\n  .date {\n    width: 85px;\n    text-align: right;\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"task": "task___jPgA5",
	"noActionNecessary": "noActionNecessary___PPFCv",
	"header": "header___tE4_G",
	"title": "title___bm1SZ",
	"icon": "icon___n2hR7",
	"date": "date___SAEeV"
};
export default ___CSS_LOADER_EXPORT___;
