import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ItemIfAccess from "./ItemIfAccess";

export default connect(mapStateTopProps)(FinaidSummary);

function mapStateTopProps({ myStatus: { delegateActingAsUid } }) {
  return {
    isDelegate: !!delegateActingAsUid,
  };
}

function FinaidSummary({ isDelegate }) {
  if (isDelegate) {
    return <ItemIfAccess urlID="UC_FA_FINRES_FA_SUMMARY_DELEGA" />;
  }

  return <ItemIfAccess urlID="UC_FA_FINRES_FA_SUMMARY" />;
}

FinaidSummary.propTypes = {
  isDelegate: PropTypes.bool,
};
