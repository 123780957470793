// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aidYear___xeZE4{border-bottom:1px solid var(--silver)}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/IncompleteFinancialAidTasks/AidYearTasks.module.scss"],"names":[],"mappings":"AAEA,iBACE,qCAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.aidYear {\n  border-bottom: 1px solid var(--silver);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aidYear": "aidYear___xeZE4"
};
export default ___CSS_LOADER_EXPORT___;
