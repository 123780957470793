import React from "react";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const LdapUID = () => {
  const { uid } = useSelector((state) => {
    const { advising: { userId: advisingStudentUID } = {} } = state;

    const { advisingStudentProfile: { uid = "" } = {} } =
      advisingStudentState(state, advisingStudentUID) || {};

    return {
      uid,
    };
  });

  if (uid) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>UID</StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          <strong>{uid}</strong>
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
LdapUID.displayName = "LdapUID";
