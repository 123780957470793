import { buildFinaidSlice } from "data/utils/buildSlice";
import { finaidDataThunk } from "data/utils/thunks";
import { routes } from "data/routes";
import { finaidRestRequest } from "data/utils/requests";

const key = "housing";

export const slice = buildFinaidSlice(key);
export const { reducer: housing } = slice;
export const fetchHousing = finaidDataThunk(key, {
  request: finaidRestRequest({ url: routes[key] }),
  dataHandler:
    ({ actions, dispatch, uid, finaidYearId }) =>
    (data) => {
      const { housing = {} } = data || {};
      return dispatch(actions.success({ uid, finaidYearId, data: housing }));
    },
  slice,
});
