'use strict';

var _ = require('lodash');

import isEmpty from 'functions/isEmpty';

angular.module('calcentral.controllers').controller('AcademicRecordsController', function(academicRecordsFactory, apiService, $q, $scope, $window) {
  $scope.hasExamResults = null;
  $scope.lawOfficialTranscript = {
    error: false,
    postParams: {},
    postURL: '',
    postURLHover: 'Transcript in electronic PDF format for Law students',
    isLoading: true,
    defaultRequestLink: 'http://registrar.berkeley.edu/academic-records/transcripts-diplomas'
  };
  $scope.lawTranscriptLink = {
    link: 'http://www.law.berkeley.edu/php-programs/registrar/forms/transcriptrequestform.php',
    title: 'Transcript in printed format for Law students'
  };
  $scope.lawUnofficialTranscriptLink = {};
  $scope.officialTranscript = {
    error: false,
    postParams: {},
    postURL: '',
    postURLHover: 'Transcript for Undergraduate or Graduate Students',
    isLoading: true,
    defaultRequestLink: 'http://registrar.berkeley.edu/academic-records/transcripts-diplomas'
  };
  $scope.ucbxTranscriptLink = {
    link: 'http://extension.berkeley.edu/static/studentservices/transcripts/#ordertranscripts',
    title: 'Transcript for UCB Extension Concurrent Enrollment students'
  };

  /*
   * Constructs a post request to Credentials Solutions, as outlined by the Credentials Solutions documentation seen in
   * https://confluence.ets.berkeley.edu/confluence/display/MYB/Academic+Records.
   */
  var postRequest = function(url, data) {
    var html = document.implementation.createDocument(null, 'HTML');
    var head = document.createElement('HEAD');
    var body = document.createElement('BODY');
    var form = document.createElement('FORM');
    form.setAttribute('name', 'transcriptRequestForm');
    form.action = url;
    form.method = 'post';
    if (data) {
      _.forOwn(data, function(value, key) {
        var input = document.createElement('INPUT');
        input.type = 'hidden';
        input.name = key.toUpperCase();
        input.value = value;
        form.appendChild(input);
      });
    }
    body.appendChild(form);
    html.documentElement.appendChild(head);
    html.documentElement.appendChild(body);
    var parser = new XMLSerializer();
    var newWindow = $window.open('');
    newWindow.document.write(parser.serializeToString(html));
    newWindow.document.getElementsByName('transcriptRequestForm')[0].submit();
  };

  var parseTranscriptData = function(response) {
    const {
      data: {
        lawUnofficialTranscriptLink,
        officialTranscriptRequestData: {
          postURL: officialTranscriptPostURL,
          postParams: officialTranscriptPostParams,
        } = {},
      }
    } = response || {};

    if (isEmpty(officialTranscriptPostURL)) {
      $scope.officialTranscript.error = true;
      $scope.lawOfficialTranscript.error = true;
    }

    $scope.lawUnofficialTranscriptLink = lawUnofficialTranscriptLink;
    $scope.officialTranscript.postURL = officialTranscriptPostURL;
    $scope.officialTranscript.postParams = officialTranscriptPostParams;
    // For law the fice code and dates need to be set.
    var lawPostParams = Object.assign({}, officialTranscriptPostParams);
    lawPostParams.fice = lawPostParams.lawFice;
    lawPostParams.attfr = lawPostParams.lawAttfr;
    lawPostParams.attto = lawPostParams.lawAttto;
    $scope.lawOfficialTranscript.postURL = officialTranscriptPostURL;
    $scope.lawOfficialTranscript.postParams = lawPostParams;
  };

  var parseTestHistory = function(response) {
    var hasExamResults = _.get(response, 'data.hasExamResults');
    _.set($scope, 'hasExamResults', hasExamResults);
  };

  $scope.requestTranscript = function() {
    if (apiService.user.profile.features.transcriptRequestLinkCredSolutions) {
      postRequest($scope.officialTranscript.postURL, $scope.officialTranscript.postParams);
    } else {
      $window.open($scope.officialTranscript.defaultRequestLink, '_blank');
    }
  };

  $scope.requestLawTranscript = function() {
    if (apiService.user.profile.features.transcriptRequestLinkCredSolutions) {
      postRequest($scope.lawOfficialTranscript.postURL, $scope.lawOfficialTranscript.postParams);
    } else {
      $window.open($scope.lawOfficialTranscript.defaultRequestLink, '_blank');
    }
  };

  var loadAcademicRecordsData = function() {
    var transcriptDataRequest = academicRecordsFactory.getTranscriptData().then(parseTranscriptData);
    var testHistoryRequest = academicRecordsFactory.getExamResultsExists().then(parseTestHistory);
    var requests = [transcriptDataRequest, testHistoryRequest];

    $q.all(requests)
    .finally(function() {
      $scope.officialTranscript.isLoading = false;
    });
  };

  loadAcademicRecordsData();
});
