import React from "react";
import { Semester } from "./Semester";
import { useSlice } from "components/hooks/useSlice";

export const Semesters = () => {
  const { semesters } = useSlice("myAcademics");
  const { hasStudentHistory } = useSlice("myStatus");
  const sortedSemesters = [...semesters].sort(ascendingTermId);

  if (semesters.length && hasStudentHistory) {
    return (
      <>
        {sortedSemesters.map((semester) => (
          <Semester key={semester.slug} semester={semester} />
        ))}
      </>
    );
  } else {
    return null;
  }
};

const ascendingTermId = (a, b) => {
  if (a.termId > b.termId) {
    return 1;
  } else if (a.termId < b.termId) {
    return -1;
  }

  return 0;
};
