import styled from "styled-components";
import { DefinitionList } from "components/ui/DefinitionList";

export const SubList = styled(DefinitionList)`
  display: grid;
  grid-template-columns: 50% 50%;

  dt {
    font-weight: bold;
    color: var(--dove-gray);
  }

  dd {
    margin: 0;
  }

  // display: flex;
  // flex-direction: column;
  // gap: var(--sm);

  // > div {
  //   border-bottom: 1px solid limegreen;
  //   display: flex;

  //   dt {
  //     width: 60%;
  //   }

  //   dd {

  //     width: 40%;
  //   }
  // }
`;
