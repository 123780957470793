// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("icons/info.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-text{color:#999;font-weight:bold}.content-entry{margin-bottom:10px}.download-button-container{margin-top:10px}.download-button{background-color:var(--gallery);border:1px solid var(--silver);border-radius:5px;color:var(--mine-shaft);cursor:pointer;display:inline;font-size:11px;font-weight:bold;line-height:1;padding:6px 10px}.download-button:focus,.download-button:hover{color:var(--mine-shaft);text-decoration:none}.electronic-diploma-info{margin:10px 0}.icon-info{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-y:center;background-repeat:no-repeat;margin-left:5px;padding-left:12px}", "",{"version":3,"sources":["webpack://./src/components/Diploma.scss"],"names":[],"mappings":"AAAA,aACE,UAAA,CACA,gBAAA,CAGF,eACE,kBAAA,CAGF,2BACE,eAAA,CAGF,iBACE,+BAAA,CACA,8BAAA,CACA,iBAAA,CACA,uBAAA,CACA,cAAA,CACA,cAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,gBAAA,CACA,8CAEE,uBAAA,CACA,oBAAA,CAIJ,yBACE,aAAA,CAGF,WACE,wDAAA,CACA,4BAAA,CACA,2BAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".header-text {\n  color: #999;\n  font-weight: bold;\n}\n\n.content-entry {\n  margin-bottom: 10px;\n}\n\n.download-button-container {\n  margin-top: 10px;\n}\n\n.download-button {\n  background-color: var(--gallery);\n  border: 1px solid var(--silver);\n  border-radius: 5px;\n  color: var(--mine-shaft);\n  cursor: pointer;\n  display: inline;\n  font-size: 11px;\n  font-weight: bold;\n  line-height: 1;\n  padding: 6px 10px;\n  &:focus,\n  &:hover {\n    color: var(--mine-shaft);\n    text-decoration: none;\n  }\n}\n\n.electronic-diploma-info {\n  margin: 10px 0;\n}\n\n.icon-info {\n  background-image: url('icons/info.svg');\n  background-position-y: center;\n  background-repeat: no-repeat;\n  margin-left: 5px;\n  padding-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
