export const FETCH_ACADEMICS_DIPLOMA_START = "FETCH_ACADEMICS_DIPLOMA_START";
export const FETCH_ACADEMICS_DIPLOMA_SUCCESS =
  "FETCH_ACADEMICS_DIPLOMA_SUCCESS";
export const FETCH_ACADEMICS_DIPLOMA_FAILURE =
  "FETCH_ACADEMICS_DIPLOMA_FAILURE";
export const FETCH_ACADEMICS_STUDENT_RESOURCES_START =
  "FETCH_ACADEMICS_STUDENT_RESOURCES_START";
export const FETCH_ACADEMICS_STUDENT_RESOURCES_SUCCESS =
  "FETCH_ACADEMICS_STUDENT_RESOURCES_SUCCESS";
export const FETCH_ACADEMICS_STUDENT_RESOURCES_FAILURE =
  "FETCH_ACADEMICS_STUDENT_RESOURCES_FAILURE";
export const FETCH_ADVISING_ACADEMICS_START = "FETCH_ADVISING_ACADEMICS_START";
export const FETCH_ADVISING_ACADEMICS_SUCCESS =
  "FETCH_ADVISING_ACADEMICS_SUCCESS";
export const FETCH_ADVISING_STATUS_AND_HOLDS_START =
  "FETCH_ADVISING_STATUS_AND_HOLDS_START";
export const FETCH_ADVISING_STATUS_AND_HOLDS_SUCCESS =
  "FETCH_ADVISING_STATUS_AND_HOLDS_SUCCESS";
export const FETCH_ADVISING_STATUS_AND_HOLDS_FAILURE =
  "FETCH_ADVISING_STATUS_AND_HOLDS_FAILURE";
export const FETCH_AGREEMENTS_START = "FETCH_AGREEMENTS_START";
export const FETCH_AGREEMENTS_SUCCESS = "FETCH_AGREEMENTS_SUCCESS";
export const FETCH_AGREEMENTS_FAILURE = "FETCH_AGREEMENTS_FAILURE";
export const FETCH_APPOINTMENTS_START = "FETCH_APPOINTMENTS_START";
export const FETCH_APPOINTMENTS_SUCCESS = "FETCH_APPOINTMENTS_SUCCESS";
export const FETCH_BCAL_SCHEDULE_START = "FETCH_BCAL_SCHEDULE_START";
export const FETCH_BCAL_SCHEDULE_SUCCESS = "FETCH_BCAL_SCHEDULE_SUCCESS";
export const FETCH_BCAL_SCHEDULE_FAILURE = "FETCH_BCAL_SCHEDULE_FAILURE";
export const FETCH_BCOURSES_ACTIVITIES_START =
  "FETCH_BCOURSES_ACTIVITIES_START";
export const FETCH_BCOURSES_ACTIVITIES_SUCCESS =
  "FETCH_BCOURSES_ACTIVITIES_SUCCESS";
export const FETCH_BCOURSES_ACTIVITIES_FAILURE =
  "FETCH_BCOURSES_ACTIVITIES_FAILURE";
export const FETCH_BCOURSES_TODOS_START = "FETCH_BCOURSES_TODOS_START";
export const FETCH_BCOURSES_TODOS_SUCCESS = "FETCH_BCOURSES_TODOS_SUCCESS";
export const FETCH_BCOURSES_TODOS_FAILURE = "FETCH_BCOURSES_TODOS_FAILURE";
export const FETCH_BILLING_ITEMS_START = "FETCH_BILLING_ITEMS_START";
export const FETCH_BILLING_ITEMS_SUCCESS = "FETCH_BILLING_ITEMS_SUCCESS";
export const FETCH_BILLING_ITEMS_FAILURE = "FETCH_BILLING_ITEMS_FAILURE";
export const FETCH_BILLING_ITEM_START = "FETCH_BILLING_ITEM_START";
export const FETCH_BILLING_ITEM_SUCCESS = "FETCH_BILLING_ITEM_SUCCESS";
export const FETCH_BILLING_ITEM_FAILURE = "FETCH_BILLING_ITEM_FAILURE";
export const FETCH_CHECKLIST_ITEMS_START = "FETCH_CHECKLIST_ITEMS_START";
export const FETCH_CHECKLIST_ITEMS_SUCCESS = "FETCH_CHECKLIST_ITEMS_SUCCESS";
export const FETCH_CHECKLIST_ITEMS_FAILURE = "FETCH_CHECKLIST_ITEMS_FAILURE";
export const FETCH_EFT_ENROLLMENT_START = "FETCH_EFT_ENROLLMENT_START";
export const FETCH_EFT_ENROLLMENT_SUCCESS = "FETCH_EFT_ENROLLMENT_SUCCESS";
export const FETCH_EFT_ENROLLMENT_FAILURE = "FETCH_EFT_ENROLLMENT_FAILURE";
export const FETCH_ENROLLMENTS_START = "FETCH_ENROLLMENTS_START";
export const FETCH_ENROLLMENTS_SUCCESS = "FETCH_ENROLLMENTS_SUCCESS";
export const FETCH_ENROLLMENTS_FAILURE = "FETCH_ENROLLMENTS_FAILURE";
export const FETCH_FINAID_PROFILE_START = "FETCH_FINAID_PROFILE_START";
export const FETCH_FINAID_PROFILE_SUCCESS = "FETCH_FINAID_PROFILE_SUCCESS";
export const FETCH_FINAID_PROFILE_FAILURE = "FETCH_FINAID_PROFILE_FAILURE";
export const FETCH_FINRES_LINKS_START = "FETCH_FINRES_LINKS_START";
export const FETCH_FINRES_LINKS_SUCCESS = "FETCH_FINRES_LINKS_SUCCESS";
export const FETCH_FINRES_LINKS_FAILURE = "FETCH_FINRES_LINKS_FAILURE";
export const FETCH_HOLDS_START = "FETCH_HOLDS_START";
export const FETCH_HOLDS_SUCCESS = "FETCH_HOLDS_SUCCESS";
export const FETCH_HOLDS_FAILURE = "FETCH_HOLDS_FAILURE";
export const FETCH_LAW_AWARDS_START = "FETCH_LAW_AWARDS_START";
export const FETCH_LAW_AWARDS_SUCCESS = "FETCH_LAW_AWARDS_SUCCESS";
export const FETCH_LAW_AWARDS_FAILURE = "FETCH_LAW_AWARDS_FAILURE";
export const FETCH_MY_ACADEMICS_START = "FETCH_MY_ACADEMICS_START";
export const FETCH_MY_ACADEMICS_SUCCESS = "FETCH_MY_ACADEMICS_SUCCESS";
export const FETCH_MY_ACADEMICS_FAILURE = "FETCH_MY_ACADEMICS_FAILURE";
export const FETCH_MY_UP_NEXT_START = "FETCH_MY_UP_NEXT_START";
export const FETCH_MY_UP_NEXT_SUCCESS = "FETCH_MY_UP_NEXT_SUCCESS";
export const FETCH_MY_UP_NEXT_FAILURE = "FETCH_MY_UP_NEXT_FAILURE";
export const FETCH_PROFILE_START = "FETCH_PROFILE_START";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const FETCH_REGISTRATIONS_START = "FETCH_REGISTRATIONS_START";
export const FETCH_REGISTRATIONS_SUCCESS = "FETCH_REGISTRATIONS_SUCCESS";
export const FETCH_REGISTRATIONS_FAILURE = "FETCH_REGISTRATIONS_FAILURE";
export const SERVICE_ALERTS_START = "SERVICE_ALERTS_START";
export const SERVICE_ALERTS_SUCCESS = "SERVICE_ALERTS_SUCCESS";
export const SERVICE_ALERTS_FAILURE = "SERVICE_ALERTS_FAILURE";
export const FETCH_SIR_STATUS_START = "FETCH_SIR_STATUS_START";
export const FETCH_SIR_STATUS_SUCCESS = "FETCH_SIR_STATUS_SUCCESS";
export const FETCH_SIR_STATUS_FAILURE = "FETCH_SIR_STATUS_FAILURE";
export const FETCH_STANDINGS_START = "FETCH_STANDINGS_START";
export const FETCH_STANDINGS_SUCCESS = "FETCH_STANDINGS_SUCCESS";
export const FETCH_STANDINGS_FAILURE = "FETCH_STANDINGS_FAILURE";
export const FETCH_STATUS_AND_HOLDS_START = "FETCH_STATUS_AND_HOLDS_START";
export const FETCH_STATUS_AND_HOLDS_SUCCESS = "FETCH_STATUS_AND_HOLDS_SUCCESS";
export const FETCH_STATUS_AND_HOLDS_FAILURE = "FETCH_STATUS_AND_HOLDS_FAILURE";
export const FETCH_TRANSFER_CREDIT_START = "FETCH_TRANSFER_CREDIT_START";
export const FETCH_TRANSFER_CREDIT_SUCCESS = "FETCH_TRANSFER_CREDIT_SUCCESS";
export const FETCH_TRANSFER_CREDIT_FAILURE = "FETCH_TRANSFER_CREDIT_FAILURE";
export const FETCH_WEB_MESSAGES_START = "FETCH_WEB_MESSAGES_START";
export const FETCH_WEB_MESSAGES_SUCCESS = "FETCH_WEB_MESSAGES_SUCCESS";
export const FETCH_WEB_MESSAGES_FAILURE = "FETCH_WEB_MESSAGES_FAILURE";
export const SET_CURRENT_ROUTE_PROPERTIES = "SET_CURRENT_ROUTE_PROPERTIES";
export const SET_TARGET_USER_ID = "SET_TARGET_USER_ID";

export const USER_AUTH_START = "USER_AUTH_START";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_AUTH_FAILURE = "USER_AUTH_FAILURE";

export const ENROLLMENT_RESOURCES_START = "ENROLLMENT_RESOURCES_START";
export const ENROLLMENT_RESOURCES_SUCCESS = "ENROLLMENT_RESOURCES_SUCCESS";
export const ENROLLMENT_RESOURCES_FAILURE = "ENROLLMENT_RESOURCES_FAILURE";

export const API_LINKS_START = "API_LINKS_START";
export const API_LINKS_SUCCESS = "API_LINKS_SUCCESS";
export const API_LINKS_FAILURE = "API_LINKS_FAILURE";
export const SET_API_LINK = "SET_API_LINK";
export const SET_API_LINKS = "SET_API_LINKS";

export const BILLING_ACTIVITY_START = "BILLING_ACTIVITY_START";
export const BILLING_ACTIVITY_SUCCESS = "BILLING_ACTIVITY_SUCCESS";
export const BILLING_ACTIVITY_FAILURE = "BILLING_ACTIVITY_FAILURE";

export const MESSAGE_CATALOG_START = "MESSAGE_CATALOG_START";
export const MESSAGE_CATALOG_SUCCESS = "MESSAGE_CATALOG_SUCCESS";
export const MESSAGE_CATALOG_FAILURE = "MESSAGE_CATALOG_FAILURE";

export const PUBLIC_LINKS_SET = "PUBLIC_LINKS_SET";
export const PUBLIC_LINKS_START = "PUBLIC_LINKS_START";
export const PUBLIC_LINKS_SUCCESS = "PUBLIC_LINKS_SUCCESS";

export const TUITION_COHORT_START = "TUITION_COHORT_START";
export const TUITION_COHORT_SUCCESS = "TUITION_COHORT_SUCCESS";
export const TUITION_COHORT_FAILURE = "TUITION_COHORT_FAILURE";

export const ADVISOR_STUDENT_DATA_REFRESH = "ADVISOR_STUDENT_DATA_REFRESH";
export const ADVISOR_STUDENT_DATA_START = "ADVISOR_STUDENT_DATA_START";
export const ADVISOR_STUDENT_DATA_SUCCESS = "ADVISOR_STUDENT_DATA_SUCCESS";
export const ADVISING_RESOURCES_START = "ADVISING_RESOURCES_START";
export const ADVISING_RESOURCES_SUCCESS = "ADVISING_RESOURCES_SUCCESS";
export const ADVISING_RESOURCES_REFRESH = "ADVISING_RESOURCES_REFRESH";

export const ADVISOR_GRADUATE_DEGREE_PROGRESS_REFRESH =
  "ADVISOR_GRADUATE_DEGREE_PROGRESS_REFRESH";
export const ADVISOR_GRADUATE_DEGREE_PROGRESS_START =
  "ADVISOR_GRADUATE_DEGREE_PROGRESS_START";
export const ADVISOR_GRADUATE_DEGREE_PROGRESS_SUCCESS =
  "ADVISOR_GRADUATE_DEGREE_PROGRESS_SUCCESS";
export const ADVISOR_UNDERGRAD_DEGREE_PROGRESS_REFRESH =
  "ADVISOR_UNDERGRAD_DEGREE_PROGRESS_REFRESH";
export const ADVISOR_UNDERGRAD_DEGREE_PROGRESS_START =
  "ADVISOR_UNDERGRAD_DEGREE_PROGRESS_START";
export const ADVISOR_UNDERGRAD_DEGREE_PROGRESS_SUCCESS =
  "ADVISOR_UNDERGRAD_DEGREE_PROGRESS_SUCCESS";

export const GET_CLASSES_START = "GET_CLASSES_START";
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
export const GET_CLASSES_FAILURE = "GET_CLASSES_FAILURE";

export const GET_GROUPS_START = "GET_GROUPS_START";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILURE = "GET_CLASSES_FAILURE";
