import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProfileSubTable from "./ProfileSubTable";
import DiplomaContent from "../Diploma";

import { fetchAcademicsDiploma } from "data/actions/academics/diplomaActions";

const Diploma = () => {
  const { diplomaEligible } = useSelector((state) => {
    const {
      academics: {
        diploma: { diplomaEligible },
      },
    } = state || {};

    return { diplomaEligible };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAcademicsDiploma());
  }, [dispatch]);

  if (diplomaEligible) {
    return (
      <tr>
        <th>Diploma</th>
        <td>
          <ProfileSubTable>
            <tbody>
              <tr>
                <td>
                  <DiplomaContent />
                </td>
              </tr>
            </tbody>
          </ProfileSubTable>
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

Diploma.displayName = "Diploma";
export default Diploma;
