import styled from "styled-components";
import {
  BaseButtonStyles,
  BlueButtonStyles,
  ModernBlueButtonStyles,
} from "./BaseButtonStyles";

export const BaseButtonLink = styled.a`
  ${BaseButtonStyles}
`;

export const BlueButtonLink = styled(BaseButtonLink)`
  ${BlueButtonStyles}
`;

export const ModernBlueButtonLink = styled(BlueButtonLink)`
  ${ModernBlueButtonStyles}
`;
