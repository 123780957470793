import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import AlumniOverlay from "components/../react/components/_alumni/AlumniOverlay/AlumniOverlay";

const AlumniOverlayContainer = () => (
  <ReduxProvider>
    <AlumniOverlay />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("alumniOverlay", react2angular(AlumniOverlayContainer));
