import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import TasksCard from "components/../react/components/_dashboard/TasksCard/TasksCard";

const NgTasksCard = () => (
  <ReduxProvider>
    <TasksCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("tasksCard", react2angular(NgTasksCard));
