import {
  SET_CURRENT_ROUTE_PROPERTIES,
  FETCH_APPOINTMENTS_START,
  FETCH_APPOINTMENTS_SUCCESS,
} from "../../action-types";

export default function appointments(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_ROUTE_PROPERTIES:
      return {};
    case FETCH_APPOINTMENTS_START:
      return { ...state, isLoading: true, error: null };
    case FETCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        ...action.value,
        loaded: true,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}
