import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";
import LightText from "components/ui/LightText";

const Major = () => {
  const { majors } = useSelector((state) => {
    const { academicProfile: { majors = [] } = {} } =
      currentUserState(state) || {};

    return { majors };
  });

  if (majors.length) {
    return (
      <tr>
        <th>{majors.length === 1 ? "Major" : "Majors"}</th>
        <td>
          {majors.map((major, index) => (
            <div key={index}>
              <LightText>{major.college}</LightText>
              <div>{major.description}</div>
              {major.subPlans.map((subPlan, index) => (
                <div key={index} className="cc-widget-profile-indent">
                  {subPlan}
                </div>
              ))}
            </div>
          ))}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

Major.displayName = "Major";
export default Major;
