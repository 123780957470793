import {
  SET_CURRENT_ROUTE_PROPERTIES,
  ADVISOR_GRADUATE_DEGREE_PROGRESS_REFRESH,
  ADVISOR_GRADUATE_DEGREE_PROGRESS_START,
  ADVISOR_GRADUATE_DEGREE_PROGRESS_SUCCESS,
} from "../../action-types";

export default function graduateDegreeProgress(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_ROUTE_PROPERTIES:
      return {};
    case ADVISOR_GRADUATE_DEGREE_PROGRESS_REFRESH:
      return { uid: action.value, loadState: "pending" };
    case ADVISOR_GRADUATE_DEGREE_PROGRESS_START:
      return { ...state, loadState: "pending" };
    case ADVISOR_GRADUATE_DEGREE_PROGRESS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { feed: { degreeProgress = [] } = {} } = action.value;
      return { ...state, degreeProgress, loadState: "success" };
    default:
      return state;
  }
}
