import React from "react";
import { Route, Routes } from "react-router-dom";

import RequireSuperuser from "../RequireSuperuser";
import PageTitle from "components/PageTitle";
import UserAuths from "components/UserAuths/UserAuths";
import NewUserAuth from "components/UserAuths/NewUserAuth";
import EditUserAuth from "components/UserAuths/EditUserAuth";

export default function UserAuthRoutes() {
  return (
    <Routes>
      <Route
        path="new"
        element={
          <RequireSuperuser>
            <PageTitle title="New User Auth" />
            <NewUserAuth />
          </RequireSuperuser>
        }
      />

      <Route
        path=":id/edit"
        element={
          <RequireSuperuser>
            <PageTitle title="Edit User Auth" />
            <EditUserAuth />
          </RequireSuperuser>
        }
      />

      <Route
        path=""
        element={
          <RequireSuperuser>
            <PageTitle title="User Auths" />
            <UserAuths />
          </RequireSuperuser>
        }
      />
    </Routes>
  );
}
