import React from "react";
import PropTypes from "prop-types";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import AidYearTaskCount from "components/../react/components/_finances/AidYearTaskCount";

const NgAidYearTasksCount = ({ year }) => (
  <ReduxProvider>
    <AidYearTaskCount year={year} />
  </ReduxProvider>
);

NgAidYearTasksCount.propTypes = {
  year: PropTypes.string,
};

angular
  .module("calcentral.react")
  .component("aidYearTaskCount", react2angular(NgAidYearTasksCount));
