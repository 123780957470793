import React from "react";
import { react2angular } from "react2angular";
import NoBConnected from "components/../react/components/_dashboard/bConnected/NoBconnected";
import ReduxProvider from "../ReduxProvider";

function NgNoBConnected() {
  return (
    <ReduxProvider>
      <NoBConnected />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("noBconnected", react2angular(NgNoBConnected));
