import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import exclamationCircle from "icons/exclamation-circle.svg";

const HoldsStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;

  img {
    margin-right: 6px;
    margin-top: 3px;
  }
`;

function HoldsWarning({ hasHolds }) {
  if (hasHolds) {
    return (
      <HoldsStyles>
        <img src={exclamationCircle} height="12" width="12" />
        <div>
          You have a hold that may affect your ability to enroll in classes.
          View your &quot;Active Holds&quot; for more details.
        </div>
      </HoldsStyles>
    );
  }

  return null;
}

HoldsWarning.propTypes = {
  hasHolds: PropTypes.bool,
};

const mapStateToProps = ({ currentUID, users }) => {
  const { enrollmentResources: { hasHolds } = {} } = users[currentUID] || {};

  return {
    hasHolds,
  };
};

export default connect(mapStateToProps)(HoldsWarning);
