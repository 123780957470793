import PropTypes from "prop-types";
import styled from "styled-components";

import chevronUp from "../../icons/chevron-up.svg";
import chevronDown from "../../icons/chevron-down.svg";

export const ChevronCircleIcon = styled.i`
  width: 20px;
  height: 20px;
  border-width: 0;
  border-radius: 10px;
  display: inline-block;

  background-color: ${({ hoverActive }) =>
    hoverActive === "true" ? "var(--link-water)" : "transparent"};
  background-repeat: no-repeat;
  background-position: center center;

  [data-reach-accordion-item][data-state="open"] && {
    background-image: url("${chevronUp}");
  }

  [data-reach-accordion-item][data-state="collapsed"] && {
    background-image: url("${chevronDown}");
  }

  &:hover,
  &:focus {
    background-color: var(--link-water);
  }
`;

ChevronCircleIcon.propTypes = {
  hoverActive: PropTypes.string,
};
