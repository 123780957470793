import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";

const ClassNotesLink = ({ href }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      View Class Notes
      <VisuallyHidden>, this link opens in new window</VisuallyHidden>
    </a>
  );
};

ClassNotesLink.propTypes = {
  href: PropTypes.string,
  applicationLayer: PropTypes.string,
};

export default ClassNotesLink;
