// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/images/alum_landing_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messageText___kvF6f{font-size:13px;text-align:center;padding-left:30px;padding-right:30px}.cardDiv___KBiyJ{text-align:center}.logo___UtBN4{width:200px;padding-top:25px;padding-bottom:25px}.showCheck___XT1Sp{margin-top:20px;font-size:13px}.showCheck___XT1Sp .showCheckbox___oG90e{padding-top:20px}.lightbox___o9FIr{background:#4d6d8c;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;position:fixed;width:100%;height:100%;display:flex;justify-content:center;align-items:center;left:0px;top:0px;z-index:100}", "",{"version":3,"sources":["webpack://./src/react/components/_alumni/AlumniOverlay/AlumniOverlay.module.scss"],"names":[],"mappings":"AAEA,qBACE,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,kBAAA,CAGF,iBACE,iBAAA,CAGF,cACE,WAAA,CACA,gBAAA,CACA,mBAAA,CAGF,mBACE,eAAA,CACA,cAAA,CAEA,yCACE,gBAAA,CAIJ,kBACE,kBAAA,CACA,wDAAA,CACA,2BAAA,CACA,qBAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CACA,OAAA,CACA,WAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.messageText {\n  font-size: 13px;\n  text-align: center;\n  padding-left: 30px;\n  padding-right: 30px;\n}\n\n.cardDiv {\n  text-align: center;\n}\n\n.logo {\n  width: 200px;\n  padding-top: 25px;\n  padding-bottom: 25px;\n}\n\n.showCheck {\n  margin-top: 20px;\n  font-size: 13px;\n\n  .showCheckbox {\n    padding-top: 20px;\n  }\n}\n\n.lightbox {\n  background: #4d6d8c;\n  background-image: url('assets/images/alum_landing_bg.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  left: 0px;\n  top: 0px;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageText": "messageText___kvF6f",
	"cardDiv": "cardDiv___KBiyJ",
	"logo": "logo___UtBN4",
	"showCheck": "showCheck___XT1Sp",
	"showCheckbox": "showCheckbox___oG90e",
	"lightbox": "lightbox___o9FIr"
};
export default ___CSS_LOADER_EXPORT___;
