import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import colors from "components/../react/colors";
import BullhornIcon from "components/../react/components/Icon/BullhornIcon";
import InfoIcon from "components/../react/components/Icon/InfoIcon";
import warning from "icons/warning.svg";

export default function StyledNotice({ icon, background, children }) {
  return (
    <NoticeStyles background={background} data-testid="styled-notice">
      <div className="iconContainer">
        {icon === "bullhorn" && <BullhornIcon />}
        {icon === "info" && <InfoIcon color={colors.dustyGray} />}
        {icon === "warning" && <img src={warning} alt="" />}
      </div>
      <div className="messageContainer">{children}</div>
    </NoticeStyles>
  );
}

StyledNotice.displayName = "StyledNotice";
StyledNotice.propTypes = {
  icon: PropTypes.oneOf(["bullhorn", "info", "warning"]).isRequired,
  background: PropTypes.oneOf(["gray", "yellow"]).isRequired,
  children: PropTypes.node,
};

const colorVars = {
  gray: `gallery`,
  yellow: `colonial-white`,
};

const NoticeStyles = styled.div`
  padding: var(--md);
  margin-bottom: var(--lg);
  border-radius: var(--small-border-radius);
  display: flex;
  background-color: var(--${({ background }) => colorVars[background]});

  .iconContainer {
    width: 24px;
    margin-top: 2px;
    flex-shrink: 0;
  }

  .messageContainer {
    div {
      p {
        margin-bottom: 10px;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
