import PropTypes from "prop-types";

export const columnHeaderPropTypeShape = PropTypes.shape({
  instruction_format: PropTypes.string,
  primary_group_key: PropTypes.string,
});

export const columnPropTypeShape = PropTypes.shape({
  section_number: PropTypes.string,
});

export const sectionPropTypeShape = PropTypes.shape({
  ccn: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.string),
  enroll_count: PropTypes.number,
  enroll_limit: PropTypes.number,
  enroll_open: PropTypes.number,
  is_primary: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  section_label: PropTypes.string,
  waitlist_count: PropTypes.number,
  waitlist_limit: PropTypes.number,
  waitlist_open: PropTypes.number,
});

export const studentPropTypeShape = PropTypes.shape({
  columns: PropTypes.arrayOf(columnPropTypeShape),
  email: PropTypes.string,
  enroll_status: PropTypes.string,
  first_name: PropTypes.string,
  grade_option: PropTypes.string,
  id: PropTypes.string,
  last_name: PropTypes.string,
  login_id: PropTypes.string,
  majors: PropTypes.arrayOf(PropTypes.string),
  profile_url: PropTypes.string,
  section_ccns: PropTypes.array,
  sections: PropTypes.arrayOf(sectionPropTypeShape),
  student_id: PropTypes.string,
  terms_in_attendance: PropTypes.string,
  units: PropTypes.string,
  waitlist_position: PropTypes.number,
});
