import styled from "styled-components";

export const SmallSpacer = styled.div`
  margin-top: var(--small-spacing);
`;

export const MediumSpacer = styled.div`
  margin-top: var(--medium-spacing);
`;

export const LargeSpacer = styled.div`
  margin-top: var(--large-spacing);
`;

export const XLSpacer = styled.div`
  margin-top: var(--xl-spacing);
`;
