import { filterByTextOnObject } from "../RosterCard/ngFilter";
import isEmpty from "functions/isEmpty";

/**
 * Indicates if the student matches the enrollment status
 * @param  {Object} student       student object
 * @param  {String} enrollStatus  enrollment status (e.g. 'all', enrolled', 'waitlisted')
 * @return {Boolean}              true or false
 */
export const doesStudentMatchEnrollStatus = function (student, enrollStatus) {
  const { enroll_status } = student || {};

  switch (enrollStatus) {
    case "enrolled": {
      return enroll_status === "E";
    }
    case "waitlisted": {
      return enroll_status === "W";
    }
    default: {
      return true;
    }
  }
};

/**
 * Fail Safe Array Filter
 * @param  {Array}    array         collection being filtered
 * @param  {Function} callback      function used to determine if the item should be included in the array (boolean return)
 * @param  {mixed}    callbackArg   argument sent to the callback with the array item
 * @return {Array}                  filtered array result
 */
export const failsafeArrayFilter = function (array, callback, callbackArg) {
  if (array) {
    if (!callbackArg) {
      return array;
    }

    return array.filter(function (arrayItem) {
      return callback(arrayItem, callbackArg);
    });
  } else {
    return [];
  }
};

/**
 * Returns array with AngularJS text filter applied
 *
 * @param  {Array}  array             Any array of objects
 * @param  {String} textFilterString  text used for search
 * @return {Array}                    Array of filtered results
 */
export const textFilter = function (array, textFilterString) {
  if (isEmpty(textFilterString)) {
    return array;
  } else {
    return array.filter(filterByTextOnObject(textFilterString));
  }
};

/**
 * Indicates if student is in a section
 * @param  {Object} student   student object containing array of section CCNs / IDs
 * @param  {Object} section   class section
 * @return {Boolean}          true or false
 */
export const isStudentInSection = function (student, section) {
  return !section ? true : student.section_ccns.indexOf(section.ccn) !== -1;
};

/**
 * Returns section matching specified id
 * @param {Array} sections section collection
 * @param {String} sectionId section ID (ccn) used to identify section
 * @returns Section object
 */
export const findSection = function (sections, sectionId) {
  if (Array.isArray(sections) && sections.length > 0) {
    if (sectionId === undefined) {
      return undefined;
    } else {
      return sections.find(function (section) {
        return section.ccn == sectionId;
      });
    }
  } else {
    return undefined;
  }
};

/**
 * Provides message sections that should display based on type of list displayed
 * @param {Boolean} isWaitlistedMode indicates if student list is a waitlist
 */
export const getMessageSections = function (isWaitlistMode, courseDept) {
  if (courseDept === "LAW") {
    // no promote, enroll, remove, or drop for law courses
    return [];
  } else {
    if (isWaitlistMode) {
      return ["Promote", "Enroll", "Remove"];
    } else {
      return ["Drop"];
    }
  }
};

/**
 * Returns students specific in selectedStudentIds set
 * @param {Array} students collection of students
 * @param {Set} selectedStudentIds Set of student IDs
 */
export const getSelectedStudents = function (students, selectedStudentIds) {
  if (students === undefined) {
    return undefined;
  }
  const isSelected = (currentStudent) => {
    return selectedStudentIds.has(currentStudent.student_id);
  };
  return students.filter(isSelected);
};

/**
 * Returns true if message section displayed
 * @param {String} displayedView label for current message display
 * @returns Boolean
 */
export const isMessageDisplay = function (displayedView) {
  return ["promote", "enroll", "remove", "drop"].indexOf(displayedView) !== -1;
};

/**
 * Returns student toggle function
 * @param {Set} selectedStudents collection of selected student objects
 * @param {Function} setSelectedStudents useState hook function
 * @returns {Function} used to toggle the state of selected students
 */
export const selectedStudentToggleFactory = function (
  selectedStudents,
  setSelectedStudents
) {
  const selectedStudentsCopy = new Set(selectedStudents);
  return (student_id) => {
    if (selectedStudentsCopy.has(student_id)) {
      selectedStudentsCopy.delete(student_id);
    } else {
      selectedStudentsCopy.add(student_id);
    }
    setSelectedStudents(selectedStudentsCopy);
  };
};

export const selectAllStudentsToggleFactory = function (
  setSelectedStudents,
  students
) {
  return function (selectAll) {
    let selectedStudentsCopy = new Set();
    if (selectAll) {
      if (students) {
        const studentIds = students.map((student) => {
          return student.student_id;
        });
        selectedStudentsCopy = new Set(studentIds);
      }
    }
    setSelectedStudents(selectedStudentsCopy);
  };
};

/**
 * Performs functions when the section select drop-down is modified
 * @param {Boolean} isWaitlistMode indicates if the list being viewed is a waitlist
 * @param {Function} setSelectedSectionId function used to set selected section id
 * @param {Function} setAccessibilityAlert function used to set accessibility alert
 * @param {Function} setSelectedStudentIds function used to set selected student ids
 * @returns Function used with section select menu
 */
export const updateSelectedSectionFactory = function (
  isWaitlistMode,
  setAccessibleAlert,
  setSelectedSectionId,
  setSelectedStudentIds
) {
  return function (selectedSectionId) {
    const listNoun = isWaitlistMode ? "Waitlist" : "Enrollments";
    setAccessibleAlert(`${listNoun} filtered by selected section`);
    setTimeout(() => {
      setAccessibleAlert("");
    }, 2000);
    setSelectedStudentIds(new Set());
    setSelectedSectionId(selectedSectionId);
  };
};
