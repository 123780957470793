import { gql } from 'graphql-request';
import {
  SET_API_LINKS,
  API_LINKS_START,
  API_LINKS_SUCCESS,
} from '../action-types';

import graphqlClient from 'utils/graphqlClient';

const query = gql`
  {
    user {
      links {
        urlId
        name
        title
        url
        comments
        ucFrom
        ucFromLink
        ucFromText
        showNewWindow
        isCampusSolutionsLink
        ucUpdateCache
      }
    }
  }
`;

export default function getUserLinks() {
  return (dispatch, getState) => {
    const { apiLinks, csrfToken } = getState();

    if (apiLinks.loadState === 'success' || apiLinks.loadState === 'pending') {
      return new Promise((resolve, _reject) => resolve(apiLinks));
    } else if (apiLinks.loadState !== 'pending') {
      dispatch({ type: API_LINKS_START });

      graphqlClient(csrfToken)
        .request(query)
        .then(({ user: { links = [] } = {} }) => {
          dispatch({
            type: SET_API_LINKS,
            value: links,
          });

          dispatch({
            type: API_LINKS_SUCCESS,
          });
        });
    }
  };
}
