import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DashedList from "components/ui/DashedList";
import Pipe from "components/ui/Pipe";
import VisuallyHidden from "components/ui/VisuallyHidden";
import WithAPILink from "components/ui/WithAPILink";
import HasAccessTo from "./HasAccessTo";
import ItemIfAccess from "./ItemIfAccess";

export default connect(mapStateToProps)(DirectDeposit);

function mapStateToProps({
  myEftEnrollment: {
    data: { eftStatus = false, accountNumber: eftAccountNumber } = {},
  } = {},
}) {
  const hasEftAccess = eftStatus === "active" && eftAccountNumber;

  return {
    hasEftAccess,
    eftAccountNumber,
  };
}

function DirectDeposit({ hasEftAccess, eftAccountNumber }) {
  return (
    <ItemIfAccess urlID="UC_FA_FINRES_DIRECT_DEP">
      <WithAPILink urlID="UC_FA_FINRES_DIRECT_DEP" />
      <HasAccessTo
        urlIDs={[
          "UC_FA_FINRES_DIRECT_DEP_MANAGE",
          "UC_FA_FINRES_DIRECT_DEP_ENRL",
        ]}
      >
        <Pipe />
        <strong>
          {hasEftAccess ? (
            <WithAPILink urlID="UC_FA_FINRES_DIRECT_DEP_MANAGE" />
          ) : (
            <WithAPILink urlID="UC_FA_FINRES_DIRECT_DEP_ENRL" />
          )}
        </strong>
      </HasAccessTo>

      <DashedList>
        {hasEftAccess ? (
          <>
            <li style={{ color: `var(--jewel)` }}>
              Status: <i className="fa fa-check"></i> Active
            </li>
            <li style={{ color: `var(--jewel)` }}>
              Account <span aria-hidden="true">#: </span>
              <VisuallyHidden>Number</VisuallyHidden>
              {eftAccountNumber}
            </li>
          </>
        ) : (
          <li>Status: Not Active</li>
        )}
      </DashedList>
    </ItemIfAccess>
  );
}

DirectDeposit.propTypes = {
  hasEftAccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  eftAccountNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
