import React from "react";
import { GenericTransferCredit } from "./GenericTransferCredit";
import LawTransferCredit from "./LawTransferCredit";

import "./TransferCredit.scss";

import { useRoles } from "components/hooks/useRoles";
import { useSlice } from "components/hooks/useSlice";

export const TransferCredit = () => {
  const { semesters, studentLinks, advisorLinks } = useSlice("myAcademics");
  const { graduate, undergraduate, law } = useSlice("myTransferCredit");

  const isStudent = useRoles((roles) => roles.student);

  const reportLink = () => {
    const links = advisorLinks || studentLinks;
    if (links) {
      return links.tcReportLink;
    }
  };

  return (
    <>
      {!law.detailed && !law.summary && (
        <GenericTransferCredit
          {...undergraduate}
          isStudent={isStudent}
          reportLink={reportLink()}
        />
      )}

      <GenericTransferCredit
        {...graduate}
        isStudent={isStudent}
        reportLink={reportLink()}
      />

      <LawTransferCredit
        {...law}
        semesters={semesters}
        isStudent={isStudent}
        reportLink={reportLink()}
      />
    </>
  );
};
