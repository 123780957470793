import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "featureFlags",
  initialState: {
    testMode: false,
  },
  reducers: {
    enableTestMode: (state) => {
      return { ...state, testMode: true };
    },
  },
});

export const { enableTestMode } = slice.actions;
export const { reducer: featureFlags } = slice;
