import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import usePopover from "functions/usePopover";

import ButtonAsLink from "components/ui/ButtonAsLink";
import closeX from "components/../icons/close-x.svg";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const PopoverStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .container {
    border: 1px solid #ccc;
    background: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    padding: var(--lg);
    ${(props) =>
      props.$inset ? `margin: var(--xl) var(--lg) var(--lg) var(--lg);` : null}
  }

  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 13px;
      margin-top: 0;
    }

    button {
      height: 10px;
    }
  }

  .body {
    div > *:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    padding-left: var(--large-spacing);
  }
`;

const Popover = React.forwardRef(
  ({ close, title, children, inset = false }, ref) => {
    const [buttonRef, setButtonFocus] = useFocus();

    useEffect(() => {
      setButtonFocus();
    }, []);

    return (
      <PopoverStyles
        ref={ref}
        role="dialog"
        aria-labelledby="popoverTitle"
        aria-describedby="popoverBody"
        $inset={inset}
      >
        <div className="container">
          <div className="header">
            <h2 id="popoverTitle">{title}</h2>
            <ButtonAsLink ref={buttonRef} onClick={() => close()}>
              <img src={closeX} alt="Close" />
            </ButtonAsLink>
          </div>

          <div className="body" id="popoverBody">
            {children}
          </div>
        </div>
      </PopoverStyles>
    );
  }
);

Popover.displayName = "Popover";
Popover.propTypes = {
  close: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inset: PropTypes.bool,
};

export default Popover;
export { usePopover };
