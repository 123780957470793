import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import StyledMessage from "components/StyledMessage";

function EnrollmentInfoLaw({ message }) {
  return (
    <StyledMessage>
      <div dangerouslySetInnerHTML={{ __html: message.descrlong }} />
    </StyledMessage>
  );
}

EnrollmentInfoLaw.propTypes = {
  message: PropTypes.shape({
    descrlong: PropTypes.string,
  }),
};

function mapStateToProps({ currentUID, users }) {
  const { enrollmentResources: { messages: { lawInfo } = {} } = {} } =
    users[currentUID] || {};

  return { message: lawInfo };
}

export default connect(mapStateToProps)(EnrollmentInfoLaw);
