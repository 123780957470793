// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconWrapper___xTkCH{width:30px;font-size:19px}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/Icons/iconWrapper.module.scss"],"names":[],"mappings":"AAAA,qBACE,UAAA,CACA,cAAA","sourcesContent":[".iconWrapper {\n  width: 30px;\n  font-size: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": "iconWrapper___xTkCH"
};
export default ___CSS_LOADER_EXPORT___;
