export const delegateToReducerIfKey = (reducer, key) => (state, action) => {
  if (action.payload && action.payload[key]) {
    return {
      ...state,
      [action.payload[key]]: reducer(state[action.payload[key]], action),
    };
  }

  return state;
};
