import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { isLoaded } from "data/utils/statusHelpers";

import BulletList from "components/ui/BulletList";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import WithAPILink from "components/ui/WithAPILink";

import HasAccessTo from "./FinancialResourcesCard/HasAccessTo";
import ItemIfAccess from "./FinancialResourcesCard/ItemIfAccess";
import ResourceContainer from "./FinancialResourcesCard/ResourceContainer";

import DirectDeposit from "./FinancialResourcesCard/DirectDeposit";
import TuitionAndFPP from "./FinancialResourcesCard/TuitionAndFPP";
import EmergencyLoans from "./FinancialResourcesCard/EmergencyLoans";
import Form1098T from "./FinancialResourcesCard/Form1098T";
import FinaidSummary from "./FinancialResourcesCard/FinaidSummary";
import SummerEstimator from "./FinancialResourcesCard/SummerEstimator";

import warningIcon from "icons/warning.svg";

import { fetchFinresLinks } from "data/actions/financialResourcesLinksActions";
import { fetchEftEnrollment } from "data/actions/eftEnrollmentActions";
import { fetchSirStatus } from "data/actions/sirStatusActions";
import { getMyStatus } from "data/slices/myStatus";

export default function FinancialResourcesCard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyStatus());
    dispatch(fetchFinresLinks());
    dispatch(fetchEftEnrollment());
    dispatch(fetchSirStatus());
  }, [dispatch]);

  const { errored, ready } = useSelector((state) => {
    const {
      financialResourcesLinks: { loaded: finResLoaded, error: finResError },
      myEftEnrollment: { loaded: eftLoaded, error: eftError },
      myStatus: {
        loadState: statusLoadState,
        error: myStatusError,
        delegateActingAsUid
      },
      sirStatus: { loaded: sirStatusLoaded, error: sirStatusError },
    } = state;

    const statusIsLoaded = isLoaded(statusLoadState);

    return {
      errored: !!(
        finResError ||
        eftError ||
        myStatusError ||
        !!(sirStatusError && !delegateActingAsUid)
      ),
      ready:
        finResLoaded &&
        eftLoaded &&
        statusIsLoaded &&
        (sirStatusLoaded || delegateActingAsUid),
    };
  });

  return (
    <Card>
      <CardTitle>
        <h2>Financial Resources</h2>
      </CardTitle>
      <CardBody>
        {errored ? (
          <div className="FinancialResources__container">
            <img
              src={warningIcon}
              style={{ marginBottom: "3px", marginRight: "5px" }}
            />
            There is a problem displaying this information. Please try again
            later.
          </div>
        ) : ready ? (
          <FinancialResourcesContent />
        ) : (
          <Spinner />
        )}
      </CardBody>
    </Card>
  );
}

const FinancialResourcesContent = () => (
  <>
    <ResourceContainer
      title="Top Resources"
      expanded={true}
      urlIDs={[
        "UC_FA_FINRES_DELEGATE",
        "UC_FA_FINRES_DIRECT_DEP",
        "UC_FA_FINRES_DIRECT_DEP_ENRL",
        "UC_FA_FINRES_DIRECT_DEP_MANAGE",
        "UC_FA_FINRES_TUIT_AND_FPP",
        "UC_FA_FINRES_ACTIVATE_FPP",
        "UC_FA_FINRES_EMERG_LOAN",
        "UC_FA_FINRES_EMERG_LOAN_APPLY",
        "UC_FA_FINRES_FAFSA_VERIFY",
        "UC_FA_FINRES_1098T",
        "UC_FA_FINRES_1098T_VIEW",
        "UC_FA_FINRES_FA_SUMMARY",
        "UC_FA_FINRES_FA_SUMMARY_DELEGA",
        "UC_FA_FINRES_IGRAD",
      ]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_DELEGATE" />
        <DirectDeposit />
        <TuitionAndFPP />
        <EmergencyLoans />
        <ItemIfAccess urlID="UC_FA_FINRES_FAFSA_VERIFY" />
        <Form1098T />
        <FinaidSummary />
        <ItemIfAccess urlID="UC_FA_FINRES_IGRAD" />
      </BulletList>
    </ResourceContainer>
    <ResourceContainer
      title="Billing and Payments"
      urlIDs={[
        "UC_FA_FINRES_DELEGATE",
        "UC_FA_FINRES_PAYMENT_OPTIONS",
        "UC_FA_FINRES_TUITION_AND_FEES",
        "UC_FA_FINRES_BILLING_FAQ",
        "UC_FA_FINRES_DIRECT_DEP",
        "UC_FA_FINRES_DIRECT_DEP_MANAGE",
        "UC_FA_FINRES_DIRECT_DEP_ENRL",
        "UC_FA_FINRES_TUIT_AND_FPP",
        "UC_FA_FINRES_1098T",
        "UC_FA_FINRES_1098T_VIEW",
      ]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_DELEGATE" />
        <ItemIfAccess urlID="UC_FA_FINRES_PAYMENT_OPTIONS" />
        <ItemIfAccess urlID="UC_FA_FINRES_TUITION_AND_FEES" />
        <ItemIfAccess urlID="UC_FA_FINRES_BILLING_FAQ" />

        <DirectDeposit />
        <TuitionAndFPP />
        <Form1098T />
      </BulletList>
    </ResourceContainer>
    <ResourceContainer
      title="Financial Aid Forms and Information"
      urlIDs={[
        "UC_FA_FINRES_FAFSA_VERIFY",
        "UC_FA_FINRES_FA_FORMS",
        "UC_FA_FINRES_FAFSA",
        "UC_FA_FINRES_DREAM_ACT",
        "UC_FA_FINRES_EMERG_LOAN",
        "UC_FA_FINRES_EMERG_LOAN_APPLY",
        "UC_FA_FINRES_FA_SUMMARY",
        "UC_FA_FINRES_FA_SUMMARY_DELEGA",
        "UC_FA_FINRES_FASO",
        "UC_FA_FINRES_COA",
        "UC_FA_FINRES_GRAD_FIN_SUPPORT",
        "UC_FA_FINRES_WORK_STUDY",
        "UC_FA_FINRES_ADVOCATE",
        "UC_FA_FINRES_INTERNATIONAL",
      ]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_FAFSA_VERIFY" />
        <ItemIfAccess urlID="UC_FA_FINRES_FA_FORMS" />
        <ItemIfAccess urlID="UC_FA_FINRES_FAFSA" />
        <ItemIfAccess urlID="UC_FA_FINRES_DREAM_ACT" />
        <EmergencyLoans />
        <FinaidSummary />
        <ItemIfAccess urlID="UC_FA_FINRES_FASO" />
        <ItemIfAccess urlID="UC_FA_FINRES_COA" />
        <ItemIfAccess urlID="UC_FA_FINRES_GRAD_FIN_SUPPORT" />
        <ItemIfAccess urlID="UC_FA_FINRES_WORK_STUDY" />
        <ItemIfAccess urlID="UC_FA_FINRES_ADVOCATE" />
        <ItemIfAccess urlID="UC_FA_FINRES_INTERNATIONAL" />
      </BulletList>
    </ResourceContainer>
    <ResourceContainer
      title="Financial Aid Loans"
      urlIDs={[
        "UC_FA_FINRES_NSLDS",
        "UC_FA_FINRES_LOAN_CALCULATOR",
        "UC_FA_FINRES_FED_LOANS",
        "UC_FA_FINRES_STATE_INST_LOANS",
        "UC_FA_FINRES_LEAVING_CAL",
        "UC_FA_FINRES_EMERG_LOAN",
        "UC_FA_FINRES_EMERG_LOAN_APPLY",
      ]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_NSLDS" />
        <ItemIfAccess urlID="UC_FA_FINRES_LOAN_CALCULATOR" />
        <ItemIfAccess urlID="UC_FA_FINRES_FED_LOANS" />
        <ItemIfAccess urlID="UC_FA_FINRES_STATE_INST_LOANS" />
        <ItemIfAccess urlID="UC_FA_FINRES_LEAVING_CAL" />
        <EmergencyLoans />
      </BulletList>
    </ResourceContainer>
    <ResourceContainer
      title="Financial Planning and Literacy"
      urlIDs={["UC_FA_FINRES_BEARS_FIN_SUCCESS", "UC_FA_FINRES_IGRAD"]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_BEARS_FIN_SUCCESS" />
        <ItemIfAccess urlID="UC_FA_FINRES_IGRAD" />
        <ItemIfAccess urlID="UC_FA_FINRES_CASHCOURSE" />
      </BulletList>
    </ResourceContainer>
    <ResourceContainer
      title="Cal 1 Card"
      urlIDs={[
        "UC_FA_FINRES_CAL1CARD",
        "UC_FA_FINRES_MEAL_PLAN",
        "UC_FA_FINRES_MEAL_PLAN_LEARN",
      ]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_CAL1CARD" />
        <ItemIfAccess urlID="UC_FA_FINRES_MEAL_PLAN" />
        <ItemIfAccess urlID="UC_FA_FINRES_MEAL_PLAN_LEARN" />
      </BulletList>
    </ResourceContainer>
    <ResourceContainer
      title="Withdrawal and Cancellation"
      urlIDs={["UC_FA_FINRES_LEAVING_CAL", "UC_FA_FINRES_WITHDRAW"]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_LEAVING_CAL" />
        <ItemIfAccess urlID="UC_FA_FINRES_WITHDRAW" />
      </BulletList>
    </ResourceContainer>
    <ResourceContainer
      title="Summer Sessions"
      urlIDs={[
        "UC_FA_FINRES_SUMMER_FEES",
        "UC_FA_FINRES_SUMMER_WITHDRAW",
        "UC_FA_FINRES_SUMMER_SCHEDULE",
        "UC_FA_FINRES_SUMMER_WEB",
        "UC_FA_FINRES_SUMMER_ESTIMATOR",
      ]}
    >
      <BulletList>
        <ItemIfAccess urlID="UC_FA_FINRES_SUMMER_FEES" />
        <ItemIfAccess urlID="UC_FA_FINRES_SUMMER_WITHDRAW" />
        <ItemIfAccess urlID="UC_FA_FINRES_SUMMER_SCHEDULE" />
        <ItemIfAccess urlID="UC_FA_FINRES_SUMMER_WEB" />
        <SummerEstimator />
      </BulletList>
    </ResourceContainer>
    <HasAccessTo urlIDs={["UC_FA_FINRES_CAL_STUDNT_CENTRL"]}>
      <p style={{ margin: `10px 0 0 15px` }}>
        <strong>Have a question?</strong>{" "}
        <WithAPILink urlID="UC_FA_FINRES_CAL_STUDNT_CENTRL" />
      </p>
    </HasAccessTo>
  </>
);
