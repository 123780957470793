import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { fetchFacultyResources } from "data/slices/users/facultyResources";
import currentUserState from "utils/currentUserState";
import APILink from "components/ui/APILink";
import { BulletList } from "components/ui/Lists";
import OutboundLink from "../ui/OutboundLink";

export function FacultyResourcesCard() {
  const dispatch = useDispatch();

  const { isFaculty } = useSelector((state) => {
    const {
      myStatus: {
        roles: { faculty: isFaculty },
      },
    } = state || {};

    return { isFaculty };
  });

  const currentUID = useSelector((state) => state.currentUID);
  const facultyResources = useSelector((state) => {
    const { facultyResources } = currentUserState(state);
    return facultyResources;
  });

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchFacultyResources(currentUID));
    }
  }, [dispatch, currentUID]);

  const {
    feed: {
      resources: {
        acadAccomodationsHubFaculty = {},
        eformsReviewCenter = {},
        scarabLogin = {},
        workCenter = {},
        campusSupportServices = {},
      } = {},
    } = {},
  } = facultyResources || {};

  const showActionCenter = eformsReviewCenter.url || scarabLogin.url;

  if (isFaculty) {
    return (
      <Card data-testid="faculty-resources-card">
        <CardTitle>
          <h2>Faculty Resources Card</h2>
        </CardTitle>
        <CardBody>
          {showActionCenter && (
            <>
              <h3>Action Center</h3>
              <BulletList>
                {eformsReviewCenter.url && (
                  <li>
                    <APILink link={eformsReviewCenter} />: Concurrent Enrollment
                    Requests
                  </li>
                )}

                {workCenter.url && (
                  <li>
                    <APILink link={workCenter} />: Manage eForms
                  </li>
                )}

                {scarabLogin.url && (
                  <li>
                    <APILink link={scarabLogin} />: View students&apos; letters
                    of accommodations
                  </li>
                )}
              </BulletList>

              <h3>Classes and Schedule</h3>
              <BulletList>
                <li>
                  <OutboundLink href="https://classes.berkeley.edu">
                    Schedule of Classes
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink href="http://guide.berkeley.edu">
                    Course Catalog
                  </OutboundLink>
                  : Berkeley Academic Guide
                </li>
              </BulletList>

              <h3>Campus Services</h3>
              <BulletList>
                <li>
                  <CampusSupportServicesLink link={campusSupportServices} />
                </li>
              </BulletList>

              <h3>Other</h3>
              <BulletList>
                <li>
                  <OutboundLink href="https://dac.berkeley.edu/resources/faculty">
                    Disability Access & Compliance
                  </OutboundLink>
                </li>
                {acadAccomodationsHubFaculty.url && (
                  <li>
                    <APILink link={acadAccomodationsHubFaculty} />
                  </li>
                )}
                <li>
                  <OutboundLink href="https://rtl.berkeley.edu/services-programs/bcourses">
                    bCourses
                  </OutboundLink>
                  : Getting started and resources
                </li>
                <li>
                  <OutboundLink href="https://rtl.berkeley.edu/services-programs/student-response-systems-clickers">
                    Clickers
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink href="https://rtl.berkeley.edu/services-programs/course-capture">
                    Course Capture
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink href="https://rtl.berkeley.edu/services-programs/course-evaluations">
                    Course Evaluations
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink href="https://rtl.berkeley.edu/services-programs/diy-media">
                    <abbr title="Do it yourself">DIY</abbr> Media
                  </OutboundLink>
                  : Flip your classroom
                </li>
                <li>
                  <OutboundLink href="https://rtl.berkeley.edu/services-programs/academic-innovation-studio-ais">
                    Academic Innovation Studio
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink href="https://teaching.berkeley.edu/">
                    Center for Teaching & Learning
                  </OutboundLink>
                </li>
              </BulletList>
            </>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
}

function CampusSupportServicesLink({ link }) {
  if (link.linkDescriptionDisplay) {
    return (
      <span>
        {link.linkDescription} <APILink link={link} />.
      </span>
    );
  } else {
    return <APILink link={link} />;
  }
}

CampusSupportServicesLink.propTypes = {
  link: PropTypes.object,
};
