/**
 * Native replacement for LoDash _.sortBy and _.orderBy
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_sortby-and-_orderby
 *
 * The native sort modifies the array in place. `_.orderBy` and `_.sortBy` do not,
 * so we use `.concat()` to copy the array, then sort.
 *
 * fruits.concat().sort(sortBy("name"));
 * => [
 *   {name:"apple", amount: 4},
 *   {name:"banana", amount: 2},
 *   {name:"mango", amount: 1},
 *   {name:"pineapple", amount: 2}
 * ]
 *
 * @param {string} key property of each object used to sort
 * @returns {Array}
 */
export default function sortBy(key) {
  return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
};
