import styled from "styled-components";

export const UnstyledList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
`;

export default UnstyledList;
