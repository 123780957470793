import React, { useState } from "react";

import styled from "styled-components";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { ErrorMessage } from "components/ui/ErrorMessage";
import VisuallyHidden from "@reach/visually-hidden";
import { BlueButtonLink } from "components/ui/ButtonLink";
import { BaseButton, BlueButton } from "components/ui/Buttons";

import { mediumBreakpoint } from "config/media";

import { useDispatch, useSelector } from "react-redux";
import { slice as statusSlice } from "data/slices/myStatus";

export const BConnectedConnectCard = () => {
  const dispatch = useDispatch();
  const [showValidation, setShowValidation] = useState(false);
  const [disconnectError, setDisconnectError] = useState(undefined);

  const disableValidation = () => {
    setShowValidation(false);
  };
  const enableValidation = () => {
    setShowValidation(true);
  };

  const {
    actingAsUid,
    advisorActingAsUid,
    csrfToken,
    delegateActingAsUid,
    googleEmail,
    hasGoogleAccessToken,
  } = useSelector((state) => {
    const {
      csrfToken,
      myStatus: {
        actingAsUid,
        advisorActingAsUid,
        delegateActingAsUid,
        googleEmail,
        hasGoogleAccessToken,
      } = {},
    } = state;

    return {
      actingAsUid,
      advisorActingAsUid,
      csrfToken,
      delegateActingAsUid,
      googleEmail,
      hasGoogleAccessToken,
    };
  });

  const [connected, setConnected] = useState(hasGoogleAccessToken);

  function addGoogleOAuth() {
    window.location = "/api/google/request_authorization";
  }

  // Remove OAuth permissions for Google for the currently logged in user
  async function removeOAuth() {
    try {
      const response = await fetch("/api/google/remove_authorization", {
        method: "POST",
        headers: { "X-CSRF-Token": csrfToken },
      });
      if (response.ok) {
        const updateData = { hasGoogleAccessToken: false };
        dispatch(statusSlice.actions.success(updateData));
        setConnected(false);
      } else {
        setDisconnectError(true);
      }
    } catch (error) {
      setDisconnectError(true);
    }
  }

  const isConnected = connected && googleEmail;

  const actionDisabled =
    actingAsUid || advisorActingAsUid || delegateActingAsUid;

  return (
    <Card data-testid="section-overview-card">
      <CardTitle>
        <h2>bConnected</h2>
      </CardTitle>
      <CardBody>
        <BConnectedConnectCardContainer>
          {disconnectError && (
            <div className="error-container">
              <ErrorMessage message="An error occurred when attempting to disconnect." />
            </div>
          )}
          <div className="notice">
            {!isConnected && (
              <div>
                <p>
                  Connect your bConnected account to display bConnected mail,
                  calendar, tasks, and drive information in CalCentral.
                </p>
                <p>
                  Please log out of any personal Google accounts before you
                  connect.
                </p>
                <p>
                  You have the option to disconnect after successfully
                  connecting.
                </p>
              </div>
            )}
            {isConnected && (
              <div>
                Connected as:{" "}
                <span className="google-email">{googleEmail}</span>
              </div>
            )}
          </div>
          <div className="actions">
            {isConnected && (
              <>
                {!showValidation && (
                  <div className="text-center">
                    <BaseButton
                      disabled={actionDisabled}
                      onClick={enableValidation}
                    >
                      Disconnect <VisuallyHidden>Google access</VisuallyHidden>
                    </BaseButton>
                  </div>
                )}
                {showValidation && (
                  <div>
                    <div>
                      <strong>Are you sure?</strong>
                    </div>
                    <div className="validation-buttons">
                      <BlueButton onClick={removeOAuth}>Yes</BlueButton>
                      <BaseButton onClick={disableValidation}>No</BaseButton>
                    </div>
                  </div>
                )}
              </>
            )}
            {!isConnected && (
              <div className="text-center">
                <BlueButtonLink
                  disabled={actionDisabled}
                  onClick={addGoogleOAuth}
                >
                  Connect <VisuallyHidden>Google Access</VisuallyHidden>
                </BlueButtonLink>
              </div>
            )}
          </div>
        </BConnectedConnectCardContainer>
      </CardBody>
    </Card>
  );
};

BConnectedConnectCard.displayName = "BConnectedSetupCard";

const BConnectedConnectCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  div.notice {
    flex-basis: 75%;
    order: 0;
  }

  div.actions {
    flex-basis: 25%;
    order: 1;
    padding: var(--sm) var(--sm) var(--sm) 0;
  }

  p {
    margin: 0 0 var(--lg) 0;
  }

  div.error-container {
    margin: 0 0 var(--md) 0;
  }

  .text-center {
    text-align: center;
  }

  .validation-buttons {
    display: inline;
  }

  div.google-email {
    color: var(--dusty-gray);
  }

  @media (max-width: ${mediumBreakpoint - 1}px) {
    span.google-email {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    div.notice {
      flex: 100%;
    }
    div.actions {
      flex: 100%;
    }
  }
`;
