import React from "react";

export default function TableHeaders() {
  return (
    <thead>
      <tr>
        <th width="19%">UID</th>
        <th width="23%">SID</th>
        <th>Name</th>
      </tr>
    </thead>
  );
}
TableHeaders.displayName = "TableHeaders";
