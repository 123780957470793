import React from "react";
import { useAcademicRoles } from "components/hooks/useAcademicRoles";
import { useRoles } from "components/hooks/useRoles";
import { useUser } from "components/hooks/useUser";

const formatGPACumulative = (gpa) => {
  if (gpa.role === "law") {
    return "N/A";
  } else {
    return parseFloat(gpa.cumulativeGPA).toFixed(3);
  }
};

export function GPA() {
  const currentlySummerVisitor = useAcademicRoles((r) => r.summerVisitor);
  const isJointLawStudent = useAcademicRoles((r) => r.lawJointDegree);
  const isLawStudent = useRoles((roles) => roles.law);
  const { gpa = [] } = useUser((user) => user.academicProfile);

  if (gpa && gpa.length) {
    const nonLawGPARole = gpa.find((item) => item.role !== "law");

    if (
      !currentlySummerVisitor &&
      nonLawGPARole &&
      !isLawStudent &&
      !isJointLawStudent
    ) {
      return (
        <>
          <dt>Cumulative GPA</dt>
          <dd>
            {gpa.length === 1 ? (
              formatGPACumulative(gpa[0])
            ) : (
              <dl>
                {gpa.map((theGPA) => (
                  <div key={theGPA.roleDescr}>
                    <dt>{theGPA.roleDescr}</dt>
                    <dd>{formatGPACumulative(theGPA)}</dd>
                  </div>
                ))}
              </dl>
            )}
          </dd>
        </>
      );
    }
  }

  return null;
}

GPA.displayName = "GPA";
