import React from "react";
import styled from "styled-components";
import format from "date-fns/format";

import { AccordionPanel as BaseAccordionPanel } from "@reach/accordion";

import { UnstyledList } from "components/ui/Lists";

import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import { BaseButtonLink } from "components/ui/ButtonLink";
import { bCalScheduleItemShape } from "../../propTypes/bCalScheduleItemShape";

function formatTime(time) {
  return format(time * 1000, "M/d/yy h:mm a").toLowerCase();
}

const AccordionPanelStyles = styled.div`
  font-size: 11px;
  margin-top: 5px;
  margin-left: 40px;

  display: flex;
  flex-direction: column;
  gap: var(--medium-spacing);
  align-items: flex-start;

  dl.timeRange {
    display: grid;
    grid-template-columns: 15% 85%;
    width: 100%;
    margin: 0;

    dd {
      margin: 0;
    }
  }

  .indentedPair {
    > *:last-child {
      margin-left: 5px;
    }
  }
`;

export default function AccordionPanel({
  item: {
    attendees = [],
    end: endTime,
    hangoutLink: hangoutHref,
    htmlLink: href,
    isAllDay,
    organizer,
    start: startTime,
  },
}) {
  return (
    <BaseAccordionPanel>
      <AccordionPanelStyles>
        {isAllDay ? (
          <div>{format(startTime.epoch * 1000, "EEE, MMMM d")}</div>
        ) : (
          <dl className="timeRange" style={{ lineHeight: `1.3` }}>
            <dt className="header">Start:</dt>
            <dd>{formatTime(startTime.epoch)}</dd>
            <dt className="header">End:</dt>
            <dd>{formatTime(endTime.epoch)}</dd>
          </dl>
        )}

        {hangoutHref && (
          <div>
            <i className="fa fa-video-camera"></i>{" "}
            <a href={hangoutHref} target="_blank" rel="noopener noreferrer">
              <AccessibleLinkContent showNewWindow={true}>
                Join Hangout
              </AccessibleLinkContent>
            </a>
          </div>
        )}

        {organizer && (
          <div className="indentedPair">
            <span>Organizer:</span>
            <div>{organizer}</div>
          </div>
        )}

        {attendees.length > 0 && (
          <div className="indentedPair">
            <span>Invitees:</span>
            <UnstyledList style={{ lineHeight: `1.3` }}>
              {attendees.map((attendee, index) => (
                <li className="ellipsis" key={index}>
                  {attendee}
                </li>
              ))}
            </UnstyledList>
          </div>
        )}

        {href && (
          <BaseButtonLink target="_blank" rel="noopener noreferrer" href={href}>
            <AccessibleLinkContent showNewWindow={true}>
              View in bCal
            </AccessibleLinkContent>
          </BaseButtonLink>
        )}
      </AccordionPanelStyles>
    </BaseAccordionPanel>
  );
}

AccordionPanel.displayName = "AccordionPanel";
AccordionPanel.propTypes = {
  item: bCalScheduleItemShape,
};
