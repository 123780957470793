import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";

import LightText from "components/ui/LightText";

const Emphases = () => {
  const { emphases } = useSelector((state) => {
    const { academicProfile: { designatedEmphases: emphases = [] } = {} } =
      currentUserState(state) || {};

    return { emphases };
  });

  if (emphases.length) {
    return (
      <tr>
        <th>
          {emphases.length === 1
            ? "Designated Emphasis"
            : "Designated Emphases"}
        </th>
        <td>
          {emphases.map((emphasis, index) => (
            <div key={index}>
              <LightText>{emphasis.college}</LightText>
              <div>{emphasis.description}</div>
              {emphasis.subPlans.map((subPlan, index) => (
                <div key={index} className="cc-widget-profile-indent">
                  {subPlan}
                </div>
              ))}
            </div>
          ))}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

Emphases.displayName = "Emphases";
export default Emphases;
