import React from "react";
import { react2angular } from "react2angular";

import ReduxProvider from "components/ReduxProvider";
import AwardComparison from "components/../react/components/_finances/AwardComparison/AwardComparison";

const NgAwardComparison = () => (
  <ReduxProvider>
    <AwardComparison />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("awardComparison", react2angular(NgAwardComparison));
