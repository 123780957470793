import React from "react";
import { react2angular } from "react2angular";

import ReduxProvider from "components/data/ReduxProvider";
import { StudentResourcesCard } from "components/cards/StudentResourcesCard";

function NgStudentResourcesCard() {
  return (
    <ReduxProvider>
      <StudentResourcesCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("studentResourcesCard", react2angular(NgStudentResourcesCard));
