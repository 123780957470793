import React from "react";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const DesignatedEmphases = () => {
  const { designatedEmphases } = useSelector((state) => {
    const {
      advising: { userId: advisingStudentUid },
    } = state;

    const {
      advisingStudentAcademicProfile: { designatedEmphases = [] } = {},
    } = advisingStudentState(state, advisingStudentUid) || {};

    return {
      designatedEmphases,
    };
  });

  if (designatedEmphases && designatedEmphases.length > 0) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>
            {designatedEmphases.length === 1
              ? "Designated Emphasis"
              : "Designated Emphases"}
          </StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {designatedEmphases.map((designatedEmphasis, index) => (
            <div key={index}>
              <div style={{ color: "var(--dusty-gray)" }}>
                {designatedEmphasis.college}
              </div>
              <div>{designatedEmphasis.description}</div>
              {designatedEmphasis.subPlans.map((subPlan, index) => (
                <div key={index}>{subPlan}</div>
              ))}
            </div>
          ))}
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
DesignatedEmphases.displayName = "DesignatedEmphases";
