// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messageText___jknNA{font-size:13px}.buttonDiv___lc2na{text-align:center}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/AlumniHomepage/AlumniHomepage.module.scss"],"names":[],"mappings":"AAEA,qBACE,cAAA,CAGF,mBACE,iBAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.messageText {\n  font-size: 13px;\n}\n\n.buttonDiv {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageText": "messageText___jknNA",
	"buttonDiv": "buttonDiv___lc2na"
};
export default ___CSS_LOADER_EXPORT___;
