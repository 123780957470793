import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { usdFilter } from "components/../react/helpers/filters";
import BasicTable from "components/ui/BasicTable";

export default connect(mapStateToProps)(Balances);

function mapStateToProps({
  billingActivity: {
    summary: { amountDueNow, chargesNotYetDue, totalUnpaidBalance },
  },
}) {
  return { amountDueNow, chargesNotYetDue, totalUnpaidBalance };
}

function Balances({ amountDueNow, chargesNotYetDue, totalUnpaidBalance }) {
  return (
    <BasicTable>
      <tbody>
        <BillingRow>
          <td>Due Now</td>
          <td>{usdFilter(amountDueNow)}</td>
        </BillingRow>
        <BillingRow>
          <td>Not Yet Due</td>
          <td>{usdFilter(chargesNotYetDue)}</td>
        </BillingRow>
        <TotalsRow>
          <td>Total Unpaid Balance</td>
          <td>{usdFilter(totalUnpaidBalance)}</td>
        </TotalsRow>
      </tbody>
    </BasicTable>
  );
}

Balances.propTypes = {
  amountDueNow: PropTypes.number,
  chargesNotYetDue: PropTypes.number,
  totalUnpaidBalance: PropTypes.number,
};

const BillingRow = styled.tr`
  td {
    height: 32px;
    vertical-align: top;

    &:last-child {
      width: 100px;
      text-align: right;
    }
  }
`;

const TotalsRow = styled(BillingRow)`
  td {
    vertical-align: center;
  }

  td:first-child {
    font-weight: bold;
    padding: 10px 0;
  }

  td:last-child {
    text-align: right;
    padding: 10px 0;
    border-top: 1px solid var(--gallery);
  }
`;
