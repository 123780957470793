// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badge___Z_vKP{color:var(--san-juan);background-color:var(--mystic);padding:3px}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/IncompleteTasks/IncompleteDueSoonBadges.module.scss"],"names":[],"mappings":"AAAA,eACE,qBAAA,CACA,8BAAA,CACA,WAAA","sourcesContent":[".badge {\n  color: var(--san-juan);\n  background-color: var(--mystic);\n  padding: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "badge___Z_vKP"
};
export default ___CSS_LOADER_EXPORT___;
