export const xsmallBreakpoint = 0; // all mobile devices
export const smallBreakpoint = 576; // mobile devices (not sure which one's this big)
export const mediumBreakpoint = 768; // ipad, ipad pro, ipad mini, etc
export const largeBreakpoint = 992; // smaller laptops
export const xlargeBreakpoint = 1200; // laptops and desktops

// Must be ordered small to large. Other code assumes this.
export const breakpoints = [
  { xsmall: 0 },
  { small: 576 },
  { medium: 768 },
  { large: 992 },
  { xlarge: 1200 },
];

export const orderedBreakpointNames = breakpoints.map(
  (bp) => Object.getOwnPropertyNames(bp)[0]
);
