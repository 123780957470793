import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";

import TuitionStabilityCard from "components/TuitionStabilityCard";

function NgTuitionStabilityCard() {
  return (
    <ReduxProvider>
      <TuitionStabilityCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("tuitionStabilityCard", react2angular(NgTuitionStabilityCard));
