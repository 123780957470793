// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormActions___HJesq{margin-top:15px;display:flex}.FormActions___HJesq a{margin-left:10px}", "",{"version":3,"sources":["webpack://./src/components/FormActions.module.scss"],"names":[],"mappings":"AAAA,qBACE,eAAA,CACA,YAAA,CAEA,uBACE,gBAAA","sourcesContent":[".FormActions {\n  margin-top: 15px;\n  display: flex;\n\n  a {\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormActions": "FormActions___HJesq"
};
export default ___CSS_LOADER_EXPORT___;
