import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Table from "components/ui/Table";
import UserStar from "./UserStar";
import SortButton from "./SortButton";
import { Link } from "react-router-dom";
import { ASC, DESC } from "utils/sorting";

const UserTableStyles = styled(Table)`
  --base-font-size: 12px;

  thead tr,
  tbody tr {
    th,
    td {
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
    }
  }

  thead tr th {
    padding-left: var(--small-spacing);

    > div {
      display: flex;
      align-items: center;
    }
  }

  thead tr,
  tbody tr {
    td,
    th {
      &.star {
        padding: 0;
        text-align: center;

        div {
          justify-content: center;
        }
      }
    }
  }
`;

export default function UsersTable({ users, afterToggleSave }) {
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(ASC);

  const updateSort = (sortKey) => () => {
    if (sortKey === sortBy) {
      if (sortDirection === ASC) {
        setSortDirection(DESC);
      } else {
        setSortDirection(ASC);
      }
    } else {
      setSortBy(sortKey);
      setSortDirection(ASC);
    }
  };

  const sortedUsers = [...users].sort(function (a, b) {
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (sortBy === "") {
      return 0;
    } else if (sortBy === "saved") {
      return -compare(aValue, bValue, sortDirection);
    }

    return compare(aValue, bValue, sortDirection);
  });

  return (
    <UserTableStyles>
      <thead>
        <tr>
          <th>
            <SortButton
              description="First Name"
              sortId="firstName"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onClick={updateSort("firstName")}
            />
          </th>
          <th>
            <SortButton
              description="Last Name"
              sortId="lastName"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onClick={updateSort("lastName")}
            />
          </th>
          <th>
            <SortButton
              description="ID"
              sortId="studentId"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onClick={updateSort("studentId")}
            />
          </th>
          <th className="star">
            <SortButton
              description="Saved"
              sortId="saved"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onClick={updateSort("saved")}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map((user) => (
          <tr key={user.ldapUid}>
            <td>
              <Link to={`/user/overview/${user.ldapUid}`}>
                {user.firstName}
              </Link>
            </td>
            <td>
              <Link to={`/user/overview/${user.ldapUid}`}>{user.lastName}</Link>
            </td>
            <td>{user.studentId}</td>
            <td className="star">
              <UserStar user={user} afterToggleSave={afterToggleSave} />
            </td>
          </tr>
        ))}
      </tbody>
    </UserTableStyles>
  );
}

UsersTable.displayName = "UsersTable";
UsersTable.propTypes = {
  afterToggleSave: PropTypes.func,
  users: PropTypes.array,
};

// Compare two values compatible with Array.prototype.sort(), but allow directionality
// Direction can by 'ASC' or 'DESC'
function compare(a, b, direction) {
  if (a > b) {
    return direction === ASC ? 1 : -1;
  } else if (a < b) {
    return direction === ASC ? -1 : 1;
  }

  return 0;
}
