import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import WithAPILink from "components/ui/WithAPILink";
import HasAccessTo from "./HasAccessTo";

export default connect(mapStateToProps)(SummerEstimator);

function mapStateToProps({ sirStatus: { sirStatuses = [] } = {} }) {
  return {
    isUndergradSIR:
      sirStatuses &&
      sirStatuses.some((status) => status.config.ucSirImageCd === "UGRD"),
  };
}

function SummerEstimator({ isUndergradSIR }) {
  const children = (
    <li>
      <WithAPILink urlID="UC_FA_FINRES_SUMMER_ESTIMATOR" />
      <div style={{ color: `var(--dusty-gray)` }}>
        Estimate your cost and financial aid for Summer Sessions, Summer Abroad,
        or Global Internships
      </div>
    </li>
  );

  /* Summer Estimator is available to users who have an UGRD Sir,
     but may not have any other attributes required to access the link */
  if (isUndergradSIR) {
    return children;
  }

  return (
    <HasAccessTo urlIDs={["UC_FA_FINRES_SUMMER_ESTIMATOR"]}>
      {children}
    </HasAccessTo>
  );
}

SummerEstimator.propTypes = {
  isUndergradSIR: PropTypes.bool,
};
