import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { delegateToReducerIfKey } from "data/utils/delegateToReducerIfKey";

import { academicAdvising } from "./users/academicAdvising";
import { academicHigherDegreeCommittees } from "./users/academicHigherDegreeCommittees";
import { academicProfile } from "./users/academicProfile";
import { academicRecords } from "./users/academicRecords";
import { academicStandings } from "./users/academicStandings";
import { advisingAcademicAdvising } from "./users/advisingAcademicAdvising";
import { advisingAcademicStandings } from "./users/advisingAcademicStandings";
import { advisingStudent } from "./users/advisingStudent";
import { advisingStudentAcademicProfile } from "./users/advisingStudentAcademicProfile";
import { advisingStudentProfile } from "./users/advisingStudentProfile";
import { advisingStudentSuccess } from "./users/advisingStudentSuccess";
import { advisingTermRegistrations } from "./users/advisingTermRegistrations";
import { advisingHolds } from "./users/advisingHolds";
import { agreements } from "./users/agreements";
import { bCoursesTodos } from "./users/bCoursesTodos";
import { campusLinks } from "./users/campusLinks";
import { checklistItems } from "./users/checklistItems";
import { courses } from "./users/courses";
import { enrollments } from "./users/enrollments";
import { enrollmentVerification } from "./users/enrollmentVerification";
import { facultyResources } from "./users/facultyResources";
import { finaid } from "./users/finaid";
import { financialAidSummary } from "./users/financialAidSummary";
import { gradDegreeProgress } from "./users/gradDegreeProgress";
import { hasExamResults } from "./users/hasExamResults";
import { examResults } from "./users/examResults";
import { holds } from "./users/holds";
import { loanHistoryAidYears } from "./users/loanHistoryAidYears";
import { loanHistoryCumulative } from "./users/loanHistoryCumulative";
import { loanHistorySummary } from "./users/loanHistorySummary";
import { newAdmitResources } from "./users/newAdmitResources";
import { pnpCalculatorValues } from "./users/pnpCalculatorValues";
import { pnpRatioMessage } from "./users/pnpRatioMessage";
import { residency } from "./users/residency";
import { transferCredit } from "./users/transferCredit";
import { undergradDegreeProgress } from "./users/undergradDegreeProgress";

const userReducer = combineReducers({
  academicAdvising,
  academicHigherDegreeCommittees,
  academicProfile,
  academicRecords,
  academicStandings,
  advisingAcademicAdvising,
  advisingAcademicStandings,
  advisingTermRegistrations,
  advisingHolds,
  advisingStudent,
  advisingStudentAcademicProfile,
  advisingStudentProfile,
  advisingStudentSuccess,
  agreements,
  bCoursesTodos,
  campusLinks,
  checklistItems,
  courses,
  enrollments,
  enrollmentVerification,
  facultyResources,
  finaid,
  financialAidSummary,
  hasExamResults,
  holds,
  examResults,
  loanHistoryAidYears,
  loanHistoryCumulative,
  loanHistorySummary,
  newAdmitResources,
  pnpCalculatorValues,
  pnpRatioMessage,
  residency,
  transferCredit,
  undergradDegreeProgress,
  gradDegreeProgress,
});

const byUser = createSlice({
  name: "byUser",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addDefaultCase(delegateToReducerIfKey(userReducer, "uid"));
  },
});

const { reducer } = byUser;

export default reducer;
