import React from "react";
import { Route, Routes } from "react-router-dom";

import RequireAuthor from "components/RequireAuthor";
import PageTitle from "components/PageTitle";

import AllServiceAlerts from "components/ServiceAlerts/AllServiceAlerts";
import NewServiceAlert from "components/ServiceAlerts/NewServiceAlert";
import EditServiceAlert from "components/ServiceAlerts/EditServiceAlert";

export default function ServiceAlertRoutes() {
  return (
    <Routes>
      <Route
        path=":id/edit"
        element={
          <RequireAuthor>
            <PageTitle title="Edit Service Alert" />
            <EditServiceAlert />
          </RequireAuthor>
        }
      />

      <Route
        path="new"
        element={
          <RequireAuthor>
            <PageTitle title="New Service Alert" />
            <NewServiceAlert />
          </RequireAuthor>
        }
      />

      <Route
        path=""
        element={
          <RequireAuthor>
            <PageTitle title="Service Alerts" />
            <AllServiceAlerts />
          </RequireAuthor>
        }
      />
    </Routes>
  );
}
