import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import ClassInformationContainer from "components/../react/components/_academics/ClassInformationCard/ClassInformationContainer";

const NgClassInformationCard = () => (
  <ReduxProvider>
    <Router>
      <ClassInformationContainer />
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("classInformationCard", react2angular(NgClassInformationCard));
