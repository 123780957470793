import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BaseButtonLink } from "components/ui/ButtonLink";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";

import styles from "./TaskDetail.module.scss";
import APILink from "components/ui/APILink";
import Linkify from "react-linkify";

const TaskDetail = ({ task }) => {
  const {
    actionText,
    actionURL,
    departmentName,
    description,
    hasUpload,
    uploadURL,
    url,
    organizationName,
  } = task;
  const descriptionStyles = `cc-break-word cc-clearfix cc-text-pre-line ${styles.description}`;

  return (
    <div className={styles.wrapper}>
      {departmentName && (
        <p>
          <strong>From {departmentName}</strong>
        </p>
      )}

      <div className={descriptionStyles}>
        <Linkify properties={{ target: "_blank", rel: "noopener noreferrer" }}>
          {description}
        </Linkify>
      </div>

      {organizationName && (
        <div style={{ marginTop: `15px` }}>
          <strong>Organization:</strong> {organizationName}
        </div>
      )}

      {url && <APILink link={url} text="Respond" as={RespondButtonLink} />}

      {actionURL && (
        <ActionButtonLink
          href={actionURL}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AccessibleLinkContent text={actionText} showNewWindow={true} />
        </ActionButtonLink>
      )}

      {hasUpload && (
        <APILink link={uploadURL} text="Upload" as={UploadButtonLink} />
      )}
    </div>
  );
};

TaskDetail.propTypes = {
  task: PropTypes.shape({
    actionText: PropTypes.string,
    actionURL: PropTypes.string,
    departmentName: PropTypes.string,
    description: PropTypes.string,
    dueDate: PropTypes.string,
    hasUpload: PropTypes.bool,
    organizationName: PropTypes.string,
    uploadURL: PropTypes.object,
    url: PropTypes.object,
  }),
};

export default TaskDetail;

const UploadButtonLink = styled(BaseButtonLink)`
  margin-top: 10px;
  display: inline-block;
`;

const RespondButtonLink = styled(UploadButtonLink)`
  text-align: center;
`;

const ActionButtonLink = styled(BaseButtonLink)`
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 10px;
`;
