import graphqlClient from "utils/graphqlClient";

export const dataRequest =
  ({ url }) =>
  async ({ dispatch, actions }) => {
    try {
      const res = await fetch(url());
      const data = await res.json();

      if (res.ok) {
        return data;
      }

      dispatch(actions.failure(data));
    } catch (error) {
      dispatch(actions.failure());
    }
  };

export const finaidRestRequest =
  ({ url }) =>
  async ({ uid, finaidYearId }) => {
    const res = await fetch(url({ uid, finaidYearId }));
    const json = await res.json();
    return json;
  };

export const userGQLRequest =
  (query) =>
  ({ csrfToken, dataHandler, failureHandler, uid }) => {
    return graphqlClient(csrfToken)
      .request(query, { uid })
      .then(dataHandler)
      .catch(failureHandler);
  };

export const userRestRequest =
  ({ url }) =>
  ({ dispatch, actions, uid }) => {
    fetch(url({ uid }))
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            dispatch(actions.success(uid, data));
          });
        } else {
          dispatch(actions.failure(uid));
        }
      })
      .catch(() => {
        dispatch(
          actions.failure(uid, {
            status: "error",
            statusText: "unexpected error occurred",
          })
        );
      });
  };
