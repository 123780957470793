import React from "react";
import { Degree } from "./Degree";
import { useUser } from "components/hooks/useUser";

export const Degrees = () => {
  const { degrees = [] } = useUser((user) => user.academicProfile);

  if (degrees.length) {
    return (
      <>
        <dt data-testid="degrees">
          {degrees.length === 1 ? "Degree" : "Degrees"} Conferred
        </dt>
        <dd
          style={{ display: `flex`, flexDirection: `column`, gap: `var(--lg)` }}
        >
          {degrees.map((degree, index) => (
            <Degree key={index} degree={degree} />
          ))}
        </dd>
      </>
    );
  }

  return null;
};

Degrees.displayName = "Degrees";
