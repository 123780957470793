import React from "react";
import PropTypes from "prop-types";
import { useCurrentBreakpointName } from "react-socks";
import styled from "styled-components";
import { largeBreakpoint, mediumBreakpoint } from "config/media";

const BaseLayout = styled.div`
  gap: var(--lg);

  @media only screen and (min-width: ${mediumBreakpoint + 1}px) {
    display: grid;
  }

  @media only screen and (max-width: ${mediumBreakpoint}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const EvenThirdsLayout = styled(BaseLayout)`
  grid-template-columns: repeat(3, minmax(0, 390px));

  @media only screen and (max-width: ${largeBreakpoint}px) {
    grid-template-columns: repeat(2, minmax(0, 489px));
  }
`;

export const TwoToOneLayout = styled(BaseLayout)`
  @media only screen and (min-width: ${mediumBreakpoint + 1}px) {
    grid-template-columns: ${(props) =>
      props.reverse ? `2fr 1fr` : `1fr 2fr`};
  }
`;

export const SingleColumnLayout = styled(BaseLayout)`
  grid-template-columns: 1fr 10fr 1fr;

  > div {
    grid-column: 2;
  }
`;

export const CampusLayout = styled(BaseLayout)`
  @media only screen and (min-width: ${mediumBreakpoint + 1}px) {
    grid-template-columns: 1fr 3fr;
  }
`;

export const PageColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--lg);
`;

export const ColumnAtBreakpoint = ({ children, breakpointName }) => {
  const currentBreakpoint = useCurrentBreakpointName();

  if (currentBreakpoint == breakpointName) {
    return <PageColumn>{children}</PageColumn>;
  }

  return children;
};
ColumnAtBreakpoint.displayName = "ColumnAtBreakpoint";
ColumnAtBreakpoint.propTypes = {
  children: PropTypes.node,
  breakpointName: PropTypes.string,
};

export const ColumnUnlessBreakpoint = ({ children, breakpointName }) => {
  const currentBreakpoint = useCurrentBreakpointName();

  if (currentBreakpoint == breakpointName) {
    return children;
  }

  return <PageColumn>{children}</PageColumn>;
};
ColumnUnlessBreakpoint.displayName = "ColumnUnlessBreakpoint";
ColumnUnlessBreakpoint.propTypes = {
  children: PropTypes.node,
  breakpointName: PropTypes.string,
};
