import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import schedulePlanner from "icons/schedule-planner.svg";

function SchedulePlannerLink({
  careerCode,
  termId,
  isViewingAs,
  useRightArrow,
}) {
  const onClick = (event) => {
    if (isViewingAs) {
      event.preventDefault();
      alert(`While viewing someone's information, you can't visit this link.`);
    }
  };

  return (
    <a
      href={`/college_scheduler/student/${careerCode}/${termId}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {useRightArrow ? null : <img src={schedulePlanner} />} Schedule Planner{" "}
      {useRightArrow ? <i className="fa fa-arrow-right"></i> : null}
    </a>
  );
}

SchedulePlannerLink.propTypes = {
  careerCode: PropTypes.string,
  termId: PropTypes.string,
  isViewingAs: PropTypes.bool,
  useRightArrow: PropTypes.bool,
};

function mapStateToProps({ myStatus: { actingAsUid: isViewingAs } = {} }) {
  return {
    isViewingAs: !!isViewingAs,
  };
}

export default connect(mapStateToProps)(SchedulePlannerLink);
