import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ErrorMessage } from "formik";

export default function FieldError({ name }) {
  return (
    <ErrorMessage name={name}>
      {(msg) => <ErrorStyles>{msg}</ErrorStyles>}
    </ErrorMessage>
  );
}

const ErrorStyles = styled.div`
  color: var(--thunderbird);
`;

FieldError.propTypes = {
  name: PropTypes.string,
};
