import PropTypes from "prop-types";
import React from "react";

import Icon from "../../Icon/Icon";
import { ICON_EXCLAMATION } from "../../Icon/IconTypes";

import "../../../stylesheets/box_model.scss";
import "../../../stylesheets/text.scss";

import styled from "styled-components";

const SmallText = styled.div`
  font-size: 11px;
`;

export default function CurrentGradePercentage({
  pnpPercentage,
  hasExcessNoGPAUnits,
  calculatorRatioHTML,
}) {
  const icon = pnpPercentage > 33 ? <Icon name={ICON_EXCLAMATION} /> : null;
  return (
    <div>
      <h3 className="cc-react-text--normal cc-react--no-margin">
        Your current Passed (P) grade percentage:
      </h3>
      <h2 className="cc-react--no-margin">
        {icon} {`${Math.round(pnpPercentage)}%`}
      </h2>
      <h4 className="cc-react-text--normal">
        (33% maximum allowed at graduation)
      </h4>
      {renderExcessUnitsMessage(hasExcessNoGPAUnits)}

      <SmallText
        dangerouslySetInnerHTML={{
          __html: calculatorRatioHTML,
        }}
      />
    </div>
  );
}

CurrentGradePercentage.propTypes = {
  hasExcessNoGPAUnits: PropTypes.bool,
  pnpPercentage: PropTypes.number,
  calculatorRatioHTML: PropTypes.string,
};

const renderExcessUnitsMessage = (hasExcessNoGPAUnits) => {
  if (hasExcessNoGPAUnits) {
    return (
      <p className="cc-react-text--small">{`Since you've reached 120 units, excess P/NP units are not factored in this percentage.`}</p>
    );
  } else {
    return null;
  }
};
