import React from "react";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const StudentID = () => {
  const { campusSolutionsId, studentId } = useSelector((state) => {
    const { advising: { userId: advisingStudentUID } = {} } = state;

    const {
      advisingStudentProfile: { campusSolutionsId = "", studentId = "" } = {},
    } = advisingStudentState(state, advisingStudentUID) || {};

    return {
      campusSolutionsId,
      studentId,
    };
  });

  if (studentId || campusSolutionsId) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>SID</StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          <strong>{studentId || campusSolutionsId}</strong>
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
StudentID.displayName = "StudentID";
