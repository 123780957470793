import React from "react";
import { react2angular } from "react2angular";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import TeachingButtonGroup from "components/TeachingButtonGroup";

const NgTeachingButtonGroup = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="/academics/teaching-semester/:semesterSlug/class/:classId/:category"
          element={<TeachingButtonGroup />}
        />
        <Route
          path="/academics/teaching-semester/:semesterSlug/class/:classId"
          element={<TeachingButtonGroup />}
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("teachingButtonGroup", react2angular(NgTeachingButtonGroup));
