import {
  FETCH_MY_ACADEMICS_START,
  FETCH_MY_ACADEMICS_SUCCESS,
  FETCH_MY_ACADEMICS_FAILURE,
} from "../action-types";

export const fetchMyAcademicsStart = () => ({
  type: FETCH_MY_ACADEMICS_START,
});

export const fetchMyAcademicsSuccess = (academics) => ({
  type: FETCH_MY_ACADEMICS_SUCCESS,
  value: academics,
});

export const fetchMyAcademicsFailure = (error) => ({
  type: FETCH_MY_ACADEMICS_FAILURE,
  value: error,
});

export const fetchMyAcademics = () => {
  return (dispatch, getState) => {
    const { myAcademics } = getState();

    if (myAcademics.loaded || myAcademics.isLoading) {
      return new Promise((resolve, _reject) => resolve(myAcademics));
    } else {
      dispatch(fetchMyAcademicsStart());

      // const url = '/dummy/json/academics.json';
      // const url = '/dummy/json/academics_reserved_seats.json';
      const url = "/api/my/academics";
      fetch(url, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchMyAcademicsFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchMyAcademicsSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchMyAcademicsFailure(failure));
          }
        });
    }
  };
};
