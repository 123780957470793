import React from "react";
import StyledMessage from "components/StyledMessage";

import WithAPILink from "components/ui/WithAPILink";

import {
  UC_SR_SS_BERK_ACAD_GUIDE,
  UC_SR_SS_BERK_TIME,
  UC_SR_SS_FINAL_EXAM_SCH,
  UC_SR_SS_EXT_ACCOUNT,
  UC_SR_SS_EXT_INFO,
  UC_CX_GT_UCBX_VIEW,
  UC_CX_GT_SRWITHDRL_ADD,
} from "functions/urlIds";

export default function EnrollmentInfo() {
  const links = [
    UC_SR_SS_BERK_ACAD_GUIDE,
    UC_SR_SS_BERK_TIME,
    UC_SR_SS_FINAL_EXAM_SCH,
    UC_SR_SS_EXT_ACCOUNT,
    UC_SR_SS_EXT_INFO,
    UC_CX_GT_UCBX_VIEW,
    UC_CX_GT_SRWITHDRL_ADD,
  ];

  return (
    <StyledMessage>
      <p>
        <strong>Resources</strong>
      </p>
      <ul>
        {links.map((link) => (
          <li key={link}>
            <WithAPILink urlID={link} />
          </li>
        ))}
      </ul>
    </StyledMessage>
  );
}
