import { dataRequest } from "data/utils/requests";
import { routes } from "data/routes";

export const userSliceData = ({ state, key, uid }) => {
  const { byUser: users } = state;
  const { [key]: slice = {} } = users[uid] || {};
  return slice;
};

export const finaidDataThunk = (
  key,
  {
    slice: { actions } = {},
    start = ({ actions, dispatch, ...rest }) => dispatch(actions.start(rest)),
    request,
    dataHandler = ({ actions, dispatch, ...rest }) =>
      (data) =>
        dispatch(actions.success({ ...rest, data })),
  }
) => {
  return ({ uid, finaidYearId }) => {
    return (dispatch, getState) => {
      const state = getState();
      const { csrfToken } = state;
      const userSlice = userSliceData({ state, key: "finaid", uid });
      const { [key]: slice = {} } = userSlice[finaidYearId] || {};

      if (["success", "failure", "pending"].includes(slice.loadState)) {
        return new Promise((resolve, _reject) => resolve(slice));
      }

      const options = { actions, dispatch, csrfToken, uid, finaidYearId };

      start(options);
      request(options).then(dataHandler(options));
    };
  };
};

export const userDataThunk = (key, slice, options = {}) => {
  return (uid) => {
    const { actions } = slice;

    const {
      dataHandler = ({ actions, dispatch, uid }) =>
        ({ user }) =>
          dispatch(actions.success(uid, user)),
      failureHandler = ({ actions, dispatch, uid }) =>
        () =>
          dispatch(
            actions.failure(uid, {
              status: "error",
              statusText: "unexpected error occurred",
            })
          ),
      start = ({ actions, dispatch, uid }) =>
        dispatch(actions.start(uid)),
      request,
    } = options;

    return (dispatch, getState) => {
      const state = getState();
      const { csrfToken } = state;
      const slice = userSliceData({ state, key, uid });

      if (["success", "failure", "pending"].includes(slice.loadState)) {
        return new Promise((resolve, _reject) => resolve(slice));
      }

      const options = {
        actions,
        csrfToken,
        dataHandler: dataHandler({actions, dispatch, uid}),
        dispatch,
        failureHandler: failureHandler({actions, dispatch, uid}),
        uid,
      };

      start(options);
      request(options);
    };
  };
};

export const dataThunk =
  (key, slice, options = {}) =>
  () => {
    const { actions } = slice;

    const {
      start = ({ actions, dispatch }) => {
        return dispatch(actions.start());
      },
      request = dataRequest({ url: routes[key] }),
      dataHandler = ({ actions, dispatch }) =>
        (data) =>
          dispatch(actions.success(data)),
    } = options;

    return (dispatch, getState) => {
      const { [key]: slice, csrfToken } = getState();

      if (["failure", "success", "pending"].includes(slice.loadState)) {
        return new Promise((resolve, _reject) => resolve(slice));
      }

      const options = { actions, dispatch, csrfToken };

      start(options);
      request(options).then(dataHandler(options));
    };
  };

export const courseSliceData = ({ state, key, courseId }) => {
  const { byCourse: courses } = state;
  const { [key]: slice = {} } = courses[courseId] || {};
  return slice;
};

const courseDefaults = {
  start: ({ actions, dispatch, ...rest }) => {
    return dispatch(actions.start(rest));
  },
  request:
    (key) =>
    async ({ dispatch, actions, course, semester }) => {
      const courseId = course.course_id;

      try {
        const res = await fetch(routes[key](course, semester));

        if (res.ok) {
          return await res.json();
        } else {
          return dispatch(actions.failure({ courseId }));
        }
      } catch (error) {
        return dispatch(actions.failure({ courseId }));
      }
    },
  dataHandler:
    ({ dispatch, actions, courseId }) =>
    (data) => {
      dispatch(actions.success({ courseId }, data));
    },
};

export const courseDataThunk =
  (key, slice, options = {}) =>
  (course, semester) => {
    const courseId = course.course_id;
    const { actions } = slice;

    const {
      start = courseDefaults.start,
      request = courseDefaults.request(key),
      dataHandler = courseDefaults.dataHandler,
    } = options;

    return (dispatch, getState) => {
      const state = getState();
      const { csrfToken } = state;
      const slice = courseSliceData({ state, key, courseId });

      if (["failure", "success", "pending"].includes(slice.loadState)) {
        return new Promise((resolve, _reject) => resolve(slice));
      }

      const options = {
        actions,
        dispatch,
        csrfToken,
        courseId,
        course,
        semester,
      };

      start(options);
      request(options).then(dataHandler(options));
    };
  };
