// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dateLabel___G832p{display:flex;flex-direction:column;text-align:center;width:40px;margin-right:15px;padding-top:15px}.month___noZsi,.year___fciNN{font-size:11px;line-height:13px;color:var(--dove-gray)}.day___vEJ7a{font-size:15px;color:var(--dove-gray)}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/NotificationsCard/DateLabel.module.scss","webpack://./src/react/stylesheets/_variables.scss"],"names":[],"mappings":"AAEA,mBACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,iBCDc,CDEd,gBCFc,CDKhB,6BAEE,cAAA,CACA,gBAAA,CACA,sBAAA,CAGF,aACE,cAAA,CACA,sBAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.dateLabel {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  width: 40px;\n  margin-right: $large-spacing;\n  padding-top: $large-spacing;\n}\n\n.month,\n.year {\n  font-size: 11px;\n  line-height: 13px;\n  color: var(--dove-gray);\n}\n\n.day {\n  font-size: 15px;\n  color: var(--dove-gray);\n}\n","@import './foundation_overrides';\n\n// Base variables\n$tiny-spacing: 2px;\n$small-spacing: 5px;\n$medium-spacing: 10px;\n$large-spacing: 15px;\n\n$small-border-radius: 5px;\n\n// Typography:\n$base-font-color: var(--mine-shaft);\n$color-text-alt: var(--dove-gray);\n$color-text-link: var(--denim);\n\n$base-font-family: Arial, sans-serif;\n\n$base-font-size: 13px;\n\n$hairline-border: 1px solid var(--gallery);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateLabel": "dateLabel___G832p",
	"month": "month___noZsi",
	"year": "year___fciNN",
	"day": "day___vEJ7a"
};
export default ___CSS_LOADER_EXPORT___;
