import {
  FETCH_BCAL_SCHEDULE_START,
  FETCH_BCAL_SCHEDULE_SUCCESS,
  FETCH_BCAL_SCHEDULE_FAILURE,
} from "../action-types";

export const fetchBCalScheduleStart = () => ({
  type: FETCH_BCAL_SCHEDULE_START,
});

export const fetchBCalScheduleSuccess = (bCalSchedule) => ({
  type: FETCH_BCAL_SCHEDULE_SUCCESS,
  value: bCalSchedule,
});

export const fetchBCalScheduleFailure = (error) => ({
  type: FETCH_BCAL_SCHEDULE_FAILURE,
  value: error,
});

export const fetchBCalSchedule = () => {
  return (dispatch, getState) => {
    const { bCalSchedule } = getState();

    if (bCalSchedule.loaded || bCalSchedule.isLoading) {
      return new Promise((resolve, _reject) => resolve(bCalSchedule));
    } else {
      dispatch(fetchBCalScheduleStart());

      const api_url = "/api/my/bcal_schedule";
      // const api_url = '/dummy/json/bcal_schedule.json';

      fetch(api_url, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText:
                "An error has occurred obtaining your calendar items.",
            };
            dispatch(fetchBCalScheduleFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchBCalScheduleSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchBCalScheduleFailure(failure));
          }
        });
    }
  };
};
