import React from "react";
import { useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";

import { UC_CX_PROFILE, UC_CX_PROFILE_SFF } from "../../functions/urlIds";
import WithAPILink from "components/ui/WithAPILink";

const SMALL_FORM_FACTOR_MAX_WIDTH = 519;

const PersonalProfileLink = () => {
  const { isDelegate, isApplicant, isStudent } = useSelector((state) => {
    const {
      myStatus: {
        delegateActingAsUid,
        roles: { student: isStudent, applicant: isApplicant },
      },
    } = state || {};
    const isDelegate = !!delegateActingAsUid;

    return { isDelegate, isApplicant, isStudent };
  });

  const urlID =
    useWindowWidth() > SMALL_FORM_FACTOR_MAX_WIDTH
      ? UC_CX_PROFILE
      : UC_CX_PROFILE_SFF;

  if (!isDelegate && (isStudent || isApplicant)) {
    return (
      <div>
        <WithAPILink
          urlID={urlID}
          text="Personal Profile"
          style={{ color: `var(--denim)` }}
        />
      </div>
    );
  }
  return null;
};

PersonalProfileLink.displayName = "PersonalProfileLink";

export default PersonalProfileLink;
