import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTestMode } from "components/hooks/useTestMode";

export function dispatchFunction(dispatchFunction) {
  const dispatch = useDispatch();
  const isTestMode = useTestMode();

  useEffect(() => {
    if (!isTestMode) {
      if (typeof dispatchFunction === "function") {
        dispatch(dispatchFunction());
      } else if (typeof dispatchFunction === "object") {
        dispatchFunction.map((func) => dispatch(func()));
      }
    }
  }, [isTestMode, dispatch, dispatchFunction]);
}
