import React from "react";
import PropTypes from "prop-types";
import { BaseButton } from "components/ui/Buttons";
import { Breakpoint } from "react-socks";

export const MessageButton = function ({
  messageSection,
  messageButtonsDisabled,
  setDisplayedView,
}) {
  const messageSectionId = messageSection.toLowerCase();
  return (
    <BaseButton
      style={{ marginRight: "var(--small-spacing)" }}
      onClick={() => {
        setDisplayedView(messageSectionId);
      }}
      disabled={messageButtonsDisabled}
    >
      <Breakpoint tagName="span" medium up>
        Request to
      </Breakpoint>{" "}
      {messageSection}
    </BaseButton>
  );
};

MessageButton.displayName = "MessageButton";
MessageButton.propTypes = {
  messageSection: PropTypes.string,
  messageButtonsDisabled: PropTypes.bool,
  setDisplayedView: PropTypes.func,
};
