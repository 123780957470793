import React from "react";
import LightText from "components/ui/LightText";
import { useUser } from "components/hooks/useUser";

export const Major = () => {
  const { majors = [] } = useUser((user) => user.academicProfile);

  if (majors.length) {
    return (
      <>
        <dt data-testid="majors">{majors.length === 1 ? "Major" : "Majors"}</dt>
        <dd>
          {majors.map((major, index) => (
            <div key={index}>
              <LightText>{major.college}</LightText>
              <div>{major.description}</div>
              {major.subPlans.map((subPlan, index) => (
                <div key={index} className="cc-widget-profile-indent">
                  {subPlan}
                </div>
              ))}
            </div>
          ))}
        </dd>
      </>
    );
  }

  return null;
};

Major.displayName = "Major";
