import React from "react";
import PropTypes from "prop-types";

import GreenCheckCircle from "components/../react/components/Icon/GreenCheckCircle";
import YellowWarningSign from "components/../react/components/Icon/YellowWarningSign";
import RedExclamationIcon from "components/../react/components/Icon/RedExclamationIcon";

const RegistrationStatusIcon = ({ severity }) => {
  switch (severity) {
    case "normal":
      return <GreenCheckCircle style={{ marginRight: "5px" }} />;
    case "notice":
      return <YellowWarningSign style={{ marginRight: "4px" }} />;
    case "warning":
      return <RedExclamationIcon />;
    default:
      return null;
  }
};

RegistrationStatusIcon.propTypes = {
  severity: PropTypes.string,
};

export default RegistrationStatusIcon;
