import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink as RouterNavLink, Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const mapReactOnlyProps = ({ reactOnly }) => ({ reactOnly });

const NavLink = connect(mapReactOnlyProps)(
  ({ reactOnly, to, currentPath, children }) => {
    if (reactOnly) {
      return <RouterNavLink to={to}>{children}</RouterNavLink>;
    } else {
      return (
        <RouterLink to={to} className={currentPath === to ? "active" : null}>
          {children}
        </RouterLink>
      );
    }
  }
);

const NavigationStyles = styled.nav`
  background: var(--calypso);
  color: white;
  padding: 5px 10px;
  display: flex;

  a {
    color: inherit;
    text-decoration: none;
    padding: 2px 5px;
    display: inline;
    height: 25px;
    line-height: 20px;

    .fa {
      margin-right: 5px;
    }

    &:hover,
    &.active {
      background: var(--hoki);
      border-radius: 5px;
      text-decoration: none;
    }

    &.active {
      font-weight: bold;
    }
  }

  a + a {
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 5px;

    a {
      display: block;
      margin-top: 5px;
    }

    a + a {
      margin: 5px 0 0;
    }
  }
`;

function Navigation({
  currentPath,
  hasCampusTab,
  hasDashboardTab,
  hasAcademicsTab,
  hasToolboxTab,
  hasFinancialsTab,
}) {
  return (
    <NavigationStyles>
      {hasDashboardTab && (
        <NavLink to="/dashboard" currentPath={currentPath}>
          <i className="fa fa-home"></i>
          My Dashboard
        </NavLink>
      )}

      {hasAcademicsTab && (
        <NavLink to="/academics" currentPath={currentPath}>
          <i className="fa fa-book"></i>
          My Academics
        </NavLink>
      )}

      {hasFinancialsTab && (
        <NavLink to="/finances" currentPath={currentPath}>
          <i className="fa fa-usd"></i>
          My Finances
        </NavLink>
      )}

      {hasCampusTab && (
        <NavLink to="/campus" currentPath={currentPath}>
          <i className="fa fa-th-large"></i>
          My Campus
        </NavLink>
      )}

      {hasToolboxTab && (
        <NavLink to="/toolbox" currentPath={currentPath}>
          <i className="fa fa-wrench"></i>
          My Toolbox
        </NavLink>
      )}
    </NavigationStyles>
  );
}

Navigation.propTypes = {
  currentPath: PropTypes.string,
  hasDashboardTab: PropTypes.bool,
  hasAcademicsTab: PropTypes.bool,
  hasCampusTab: PropTypes.bool,
  hasToolboxTab: PropTypes.bool,
  hasFinancialsTab: PropTypes.bool,
};

const mapStateToProps = ({
  currentRoute: { pathname },
  myStatus: {
    hasDashboardTab,
    hasAcademicsTab,
    hasToolboxTab,
    hasCampusTab,
    hasFinancialsTab,
  },
}) => ({
  currentPath: pathname,
  hasDashboardTab,
  hasAcademicsTab,
  hasCampusTab,
  hasToolboxTab,
  hasFinancialsTab,
});

export default connect(mapStateToProps)(Navigation);
