import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BaseButton } from "components/ui/Buttons";
import VisuallyHidden from "components/ui/VisuallyHidden";

import { ASC } from "utils/sorting";
import { SortAsc, SortDesc, SortInactive } from "./SortArrows";

const SortButtonStyles = styled(BaseButton)`
  display: flex;
  align-items: center;

  --button-padding: 0;
  --button-border-width: 0;
  --button-border-radius: 0;
  --button-background: transparent;
  --button-focus-background: transparent;
`;

export default function SortButton({
  description,
  sortId,
  sortBy,
  sortDirection,
  onClick,
}) {
  if (sortId === sortBy) {
    if (sortDirection === ASC) {
      return (
        <SortButtonStyles onClick={onClick}>
          {description} <VisuallyHidden>(Sorted ascending)</VisuallyHidden>
          <VisuallyHidden>Sort by {description} descending</VisuallyHidden>
          <SortAsc />
        </SortButtonStyles>
      );
    } else {
      return (
        <SortButtonStyles onClick={onClick}>
          {description} <VisuallyHidden>(Sorted descending)</VisuallyHidden>
          <VisuallyHidden>Sort by {description} ascending</VisuallyHidden>
          <SortDesc />
        </SortButtonStyles>
      );
    }
  } else {
    return (
      <SortButtonStyles onClick={onClick}>
        {description}
        <VisuallyHidden>Sort by {description}</VisuallyHidden>
        <SortInactive />
      </SortButtonStyles>
    );
  }
}

SortButton.displayName = "SortButton";
SortButton.propTypes = {
  description: PropTypes.string,
  sortId: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onClick: PropTypes.func,
};
