import {
  ENROLLMENT_RESOURCES_START,
  ENROLLMENT_RESOURCES_SUCCESS,
  ENROLLMENT_RESOURCES_FAILURE,
} from "../../action-types";

export default function enrollmentResources(state = {}, action) {
  switch (action.type) {
    case ENROLLMENT_RESOURCES_START:
      return { status: "pending" };
    case ENROLLMENT_RESOURCES_SUCCESS:
      return { ...state, ...action.value, status: "success" };
    case ENROLLMENT_RESOURCES_FAILURE:
      return { ...state, status: "failure" };
    default:
      return state;
  }
}
