import React from "react";
import PropTypes from "prop-types";
import { AcademicSummaryTable } from "components/cards/AcademicSummaryCard/AcademicSummaryTable";

export function TransferCreditTable({
  details,
  summary,
  showPointsColumn,
  totalPoints,
}) {
  if (details.length > 0) {
    return (
      <AcademicSummaryTable>
        <thead>
          <tr>
            <th>Institution</th>
            <th className="text-right">Units</th>
            {showPointsColumn && <th className="text-right">Points</th>}
          </tr>
        </thead>
        <tbody>
          {details.map((transfer, index) => (
            <tr key={transfer.school + index}>
              <td>{transfer.school}</td>
              <td className="text-right">{transfer.units.toFixed(3)}</td>
              {showPointsColumn && (
                <td className="text-right">
                  {transfer.gradePoints.toFixed(3)}
                </td>
              )}
            </tr>
          ))}

          {summary && (
            <tr>
              <td className="text-right">Totals:</td>
              <td className="TranferCredit__unit-total">
                {summary.totalTransferUnits.toFixed(3)}
              </td>
              {showPointsColumn && (
                <td className="TranferCredit__unit-total">
                  {totalPoints.toFixed(3)}
                </td>
              )}
            </tr>
          )}
        </tbody>
      </AcademicSummaryTable>
    );
  }

  return null;
}

TransferCreditTable.propTypes = {
  details: PropTypes.array,
  summary: PropTypes.object,
  showPointsColumn: PropTypes.bool,
  totalPoints: PropTypes.number,
};
