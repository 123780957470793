import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getMyStatus } from "data/slices/myStatus";
import { fetchAcademicsStudentResources } from "data/actions/academics/studentResourcesActions";

import APILink from "components/ui/APILink";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { BulletList } from "components/ui/Lists";
import styled from "styled-components";
import Spinner from "components/ui/Spinner";

export function StudentResourcesCard() {
  const {
    areStudentResourcesLoading,
    error,
    isDegreeSeeking,
    isStatusLoading,
    isSummerVisitor,
    resources,
    userRoles,
  } = useSelector((state) => {
    const {
      myStatus: {
        isLoading: isStatusLoading = false,
        roles: userRoles = null,
        academicRoles: {
          historical: {
            summerVisitor: isSummerVisitor = false,
            degreeSeeking: isDegreeSeeking = false,
          } = {},
        } = {},
      } = {},
      academics: {
        studentResources: {
          error,
          isLoading: areStudentResourcesLoading = false,
          feed: { resources = [] } = {},
        } = {},
      } = {},
    } = state;

    return {
      areStudentResourcesLoading,
      error,
      isDegreeSeeking,
      isStatusLoading,
      isSummerVisitor,
      resources,
      userRoles,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyStatus());
    dispatch(fetchAcademicsStudentResources());
  }, [dispatch]);

  const isNonDegreeSeekingSummerVisitor = isSummerVisitor && !isDegreeSeeking;

  const isLoading = areStudentResourcesLoading && isStatusLoading;
  const isVisible =
    userRoles &&
    userRoles.student &&
    (userRoles.undergrad ||
      userRoles.graduate ||
      userRoles.law ||
      userRoles.concurrentEnrollmentStudent);

  if (!isVisible) {
    return null;
  } else {
    return (
      <Card data-testid="student-resources-card">
        <CardTitle>
          <h2>Student Resources</h2>
        </CardTitle>
        <CardBody>
          {isLoading ? (
            <Spinner />
          ) : error ? (
            <>{error}</>
          ) : (
            <ResourceStyles>
              {resources.map((resource) => (
                <ResourceSection key={resource.section} resource={resource} />
              ))}

              {!isNonDegreeSeekingSummerVisitor && (
                <div>
                  <h3>Financial Aid Forms</h3>
                  <p>
                    Financial aid forms can be found in My Finances under
                    Financial Resources
                  </p>
                </div>
              )}
            </ResourceStyles>
          )}
        </CardBody>
      </Card>
    );
  }
}

const ResourceStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

StudentResourcesCard.displayName = "StudentResourcesCard";

function SupportResourceLink({ link }) {
  if (link.linkDescriptionDisplay) {
    return (
      <span>
        {link.linkDescription} <APILink link={link} />.
      </span>
    );
  } else {
    return <APILink link={link} />;
  }
}

SupportResourceLink.propTypes = {
  link: PropTypes.object,
};

function DefaultLinkLayout({ link }) {
  if (link.linkDescriptionDisplay) {
    return (
      <span>
        <APILink link={link} />: {link.linkDescription}
      </span>
    );
  } else {
    return <APILink link={link} />;
  }
}

DefaultLinkLayout.propTypes = {
  link: PropTypes.object,
};

function ResourceSection({ resource: { section, links } }) {
  return (
    <div data-testid="student-resources-section" key={section}>
      <h3 style={{ margin: 0 }}>{section}</h3>
      <BulletList>
        {links.map((link) => {
          const displayLink = (link) => {
            switch (link.urlId) {
              case "UC_CC_SUPPORT_RESOURCE_STUDENT":
                return <SupportResourceLink link={link} />;
              default:
                return <DefaultLinkLayout link={link} />;
            }
          };

          return <li key={link.urlId}>{displayLink(link)}</li>;
        })}
      </BulletList>
    </div>
  );
}

ResourceSection.propTypes = {
  resource: PropTypes.object,
};
