import React, { useEffect } from "react";
import PropTypes from "prop-types";
import StyledNotice from "components/../react/components/StyledNotice";
import ReadMore from "components/ui/ReadMore";
import { connect } from "react-redux";
import { fetchEnrollmentResources } from "data/actions/academics/enrollmentResourcesActions";

export default connect(mapStateToProps, mapDispatchToProps)(ClassInfoNotice);

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (uid) => dispatch(fetchEnrollmentResources(uid)),
  };
}

function mapStateToProps({ currentUID, users }) {
  const {
    enrollmentResources: { presentations = [], status: loadStatus } = {},
  } = users[currentUID] || {};

  return { currentUID, presentations, loadStatus };
}

function ClassInfoNotice({
  fetchData,
  currentUID,
  termId,
  presentations = [],
  loadStatus,
}) {
  useEffect(() => {
    if (currentUID) {
      fetchData(currentUID);
    }
  }, [currentUID]);

  if (loadStatus === "success" && presentations.length) {
    const presentation = presentations.find((item) => item.termId === termId);
    const { classInfoMessage: { description: html } = {} } = presentation || {};

    if (html) {
      return (
        <StyledNotice background="yellow" icon="bullhorn">
          <ReadMore html={html} />
        </StyledNotice>
      );
    }
  }

  return null;
}

ClassInfoNotice.propTypes = {
  fetchData: PropTypes.func,
  currentUID: PropTypes.string,
  loadStatus: PropTypes.string,
  termId: PropTypes.string,
  presentations: PropTypes.arrayOf(
    PropTypes.shape({
      termId: PropTypes.string,
      classInfoNotice: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
};
