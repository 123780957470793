import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

import { GraduationCapIcon } from "components/ui/Icons";
import { GrayRibbonIcon } from "components/ui/Icons";
const GrayGraduationCapIcon = styled(GraduationCapIcon)`
  margin-right: 5px;
  color: var(--dove-gray);
`;

import { format, parseISO } from "date-fns";

export const DegreesConferred = () => {
  const { degrees } = useSelector((state) => {
    const {
      advising: { userId: advisingStudentUid },
    } = state;

    const { advisingStudentAcademicProfile: { degrees = [] } = {} } =
      advisingStudentState(state, advisingStudentUid) || {};

    return {
      degrees,
    };
  });

  if (degrees && degrees.length > 0) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>Degree Conferred</StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {
            <div>
              <div>
                {degrees.map((degree, index) => (
                  <StudentSummaryDegree
                    key={index}
                    degree={degree}
                    index={index}
                  />
                ))}
              </div>
            </div>
          }
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
DegreesConferred.displayName = "DegreesConferred";

const StudentSummaryDegree = ({ degree, index }) => {
  const honors = degree.honors || [];
  return (
    <div className="Degree" key={index}>
      <div className="Degree__icon-container">
        {degree.majors[0].typeCode === "CRT" ? (
          <GrayRibbonIcon />
        ) : (
          <GrayGraduationCapIcon />
        )}
      </div>
      <div className="Degree__body">
        <div className="Degree__description">
          {degree.description} <span>in</span> {degreeMajors(degree)}
        </div>

        {degree.designatedEmphases.length > 0 && (
          <div className="Degree__emphases">
            {degree.designatedEmphases.map((emphasis, index) => (
              <span key={index}>{emphasis.formalDescription}</span>
            ))}
          </div>
        )}
        {
          <div className="Degree__awarded-on">
            Awarded {formattedAwardDate(degree)}
          </div>
        }

        {degree.isUndergrad && (
          <Fragment>
            <div>{degree.college}</div>

            {honors.map((honor, index) => (
              <div key={index}>{honor.formalDescription}</div>
            ))}

            {degree.minors.map((minor, index) => (
              <div key={index}>{minor.formalDescription}</div>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};
StudentSummaryDegree.displayName = "StudentSummaryDegree";
StudentSummaryDegree.propTypes = {
  degree: PropTypes.object,
  index: PropTypes.number,
};

export const degreeMajors = (degree) =>
  degree.majors.map((major) => major.formalDescription).join(", ");

const formattedAwardDate = (degree) => {
  return format(parseISO(degree.dateAwarded), `${"MMM"} d, y`);
};
