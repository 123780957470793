import React from "react";
import PropTypes from "prop-types";
import { parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

export const FormattedTimeCell = ({ zonedTime }) => {
  const formattedDate = format(zonedTime, "MMM d");
  const formattedTime = format(zonedTime, "h:mma");

  return (
    <div
      style={{
        display: `flex`,
        justifyContent: "space-between",
        paddingRight: `10px`,
      }}
    >
      <span>{formattedDate}</span>
      <span>{formattedTime.toLowerCase()}</span>
    </div>
  );
};

FormattedTimeCell.propTypes = {
  zonedTime: PropTypes.instanceOf(Date),
};

const TimeCell = ({ time }) => {
  if (time) {
    const zonedTime = utcToZonedTime(parseISO(time), "America/Los_Angeles");
    return <FormattedTimeCell zonedTime={zonedTime} />;
  }

  return null;
};

TimeCell.propTypes = {
  time: PropTypes.string,
};

export default TimeCell;
