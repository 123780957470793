import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";
import { NewWindowIcon } from "./SVGIcons";

export const AccessibleLinkContent = ({
  children,
  text,
  showNewWindow,
  decoration,
}) => (
  <>
    {children || text}
    {showNewWindow && (
      <>
        <VisuallyHidden>, this link opens in new tab</VisuallyHidden>
        <NewWindowIcon />
      </>
    )}
    {decoration && <> {decoration}</>}
  </>
);

AccessibleLinkContent.displayName = "AccessibleLinkContent";
AccessibleLinkContent.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  decoration: PropTypes.node,
  showNewWindow: PropTypes.bool,
};

export default AccessibleLinkContent;
