import {
  FETCH_APPOINTMENTS_START,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_ADVISING_ACADEMICS_START,
  FETCH_ADVISING_ACADEMICS_SUCCESS,
} from "../action-types";

export const SET_TARGET_USER_ID = "SET_TARGET_USER_ID";

export const setTargetUserId = (id) => ({
  type: SET_TARGET_USER_ID,
  value: id,
});

export const fetchAppointmentsStart = () => ({
  type: FETCH_APPOINTMENTS_START,
});

export const fetchAppointmentsSuccess = (appointments) => ({
  type: FETCH_APPOINTMENTS_SUCCESS,
  value: appointments,
});

export const fetchAppointments = (userId) => {
  return (dispatch, getState) => {
    const { advising: { appointments = {} } = {} } = getState();

    if (appointments.loaded || appointments.isLoading) {
      return new Promise((resolve, _reject) => resolve(appointments));
    } else {
      dispatch(fetchAppointmentsStart());
      fetch(`/api/advising/employment_appointments/${userId}`, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          dispatch(fetchAppointmentsSuccess(data));
        });
    }
  };
};

export const fetchAdvisingAcademicsStart = () => ({
  type: FETCH_ADVISING_ACADEMICS_START,
});

export const fetchAdvisingAcademicsSuccess = (student) => ({
  type: FETCH_ADVISING_ACADEMICS_SUCCESS,
  value: student,
});
