import React from "react";
import PropTypes from "prop-types";

import orangeChangedIconSvg from "icons/changed-orange.svg";

const OrangeChangedIcon = ({ className }) => {
  return (
    <img
      src={orangeChangedIconSvg}
      className={className}
      role="icon"
      style={{ backgroundColor: `white` }}
    />
  );
};

OrangeChangedIcon.displayName = "OrangeChangedIcon";
OrangeChangedIcon.propTypes = {
  className: PropTypes.string,
  // style: PropTypes.string,
};

export default OrangeChangedIcon;
