import React from "react";
import styled from "styled-components";
import { parseISO, format } from "date-fns";

import { serviceAlertProps } from "./serviceAlert.module.js";

export default function ServiceAlert({ title, body, publication_date }) {
  let formatted_date = "";

  try {
    formatted_date = format(parseISO(publication_date), "MMM dd");
  } catch (err) {
    formatted_date = "Date";
  }

  return (
    <ServiceAlertStyles>
      <div className="newsLabel">CalCentral News</div>
      <div className="alert">
        <div className="date">{formatted_date}</div>
        <div className="content">
          <h2 className="title">{title}</h2>
          <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </ServiceAlertStyles>
  );
}

ServiceAlert.displayName = "ServiceAlert";
ServiceAlert.propTypes = serviceAlertProps;

const ServiceAlertStyles = styled.div`
  background: white;
  border-radius: 5px;
  max-width: 450px;
  padding: 20px 20px 25px;
  margin: 0 auto;

  .newsLabel {
    color: #666;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .alert {
    display: flex;

    .title {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
    }

    .date {
      color: var(--mine-shaft);
      font-size: 15px;
      font-weight: 700;
      flex: 1;
    }

    .content {
      flex: 5;
    }

    .body {
      p {
        line-height: 1.3;
        margin: 15px 0 0;
      }
    }
  }
`;
