import buildDataActions from "../../build-data-actions";

import {
  FETCH_ACADEMICS_STUDENT_RESOURCES_START,
  FETCH_ACADEMICS_STUDENT_RESOURCES_SUCCESS,
  FETCH_ACADEMICS_STUDENT_RESOURCES_FAILURE,
} from "../../action-types";

const { loadData: fetchAcademicsStudentResources } = buildDataActions({
  url: "/api/campus_solutions/student_resources",
  key: "academics.studentResources",
  start_const: FETCH_ACADEMICS_STUDENT_RESOURCES_START,
  success_const: FETCH_ACADEMICS_STUDENT_RESOURCES_SUCCESS,
  failure_const: FETCH_ACADEMICS_STUDENT_RESOURCES_FAILURE,
});

export { fetchAcademicsStudentResources };
