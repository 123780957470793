export const ICON_ARROW_RIGHT = "arrow-right";
export const ICON_CERTIFICATE = "certificate";
export const ICON_CHECKMARK = "check";
export const ICON_CHEVRON_DOWN = "chevron-down";
export const ICON_CHEVRON_UP = "chevron-up";
export const ICON_EXCLAMATION = "exclamation-circle";
export const ICON_EXCLAMATION_TRIANGLE = "exclamation-triangle";
export const ICON_GRADUATION = "graduation-cap";
export const ICON_PRINT = "print";
export const ICON_TIMES_CIRCLE = "times-circle";
export const ICON_CHECK_CIRCLE = "check-circle";
export const ICON_WARNING = "warning";
