import {
  ENROLLMENT_RESOURCES_START,
  ENROLLMENT_RESOURCES_SUCCESS,
} from 'data/action-types';

import { userEnrollmentResourcesPath } from 'functions/paths';
import valuesToArray from 'functions/valuesToArray';

export function fetchEnrollmentResources(uid) {
  return (dispatch, getState) => {
    const { users } = getState();
    const { enrollmentResources = {} } = users[uid] || {};

    if (enrollmentResources.status === 'success') {
      return new Promise((resolve, _reject) => resolve(enrollmentResources));
    } else {
      dispatch({ type: ENROLLMENT_RESOURCES_START, uid: uid });

      fetch(userEnrollmentResourcesPath(uid))
        .then(response => response.json())
        .then(data => {
          // Extract the links from the enrollment resources and set them in
          // their own area in the state
          const { links = {} } = data;
          dispatch({
            type: 'SET_API_LINKS',
            value: valuesToArray(links),
          });

          dispatch({
            uid: uid,
            type: ENROLLMENT_RESOURCES_SUCCESS,
            value: data,
          });
        });
    }
  };
}
