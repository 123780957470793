// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title___hPIuZ{font-size:15px;padding:25px 10px 0}.title___hPIuZ span{font-weight:normal}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/CompletedTasks/CategoryTitle.module.scss"],"names":[],"mappings":"AAAA,eACE,cAAA,CACA,mBAAA,CAEA,oBACE,kBAAA","sourcesContent":[".title {\n  font-size: 15px;\n  padding: 25px 10px 0;\n\n  span {\n    font-weight: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title___hPIuZ"
};
export default ___CSS_LOADER_EXPORT___;
