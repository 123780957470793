import React from "react";
import PropTypes from "prop-types";

import chevronDownLarge from "icons/chevron-down-large.svg";

const DisclosureChevron = ({ expanded, onClick, style = {} }) => {
  const expandedStyle = expanded ? { transform: `scaleY(-1)` } : {};
  const mergedStyle = { ...style, ...expandedStyle };

  return (
    <img
      src={chevronDownLarge}
      style={mergedStyle}
      onClick={onClick}
      data-testid="disclosure-chevron"
    />
  );
};

DisclosureChevron.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default DisclosureChevron;
