'use strict';

angular.module('calcentral.services').factory('sirService', function($window) {

  // displays currency without decimals when no decimal values present
  // otherwise formats with
  var formatCurrency = function(dollarAmount) {
    let formattedDollarAmount = '';
    if ($window.Math.floor(dollarAmount) == dollarAmount ) {
      formattedDollarAmount = '$' + new Intl.NumberFormat('en-US', {
        currency: 'USD',
        style: 'decimal',
      }).format(dollarAmount);
    } else {
      formattedDollarAmount = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        style: 'currency',
      }).format(dollarAmount);
    }
    return formattedDollarAmount;
  };

  return {
    formatCurrency: formatCurrency
  };
});
