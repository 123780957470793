import React from "react";
import PropTypes from "prop-types";

export default function DeadlinesTable({ children }) {
  return (
    <div className="cc-table">
      <table className="table">
        <thead>
          <tr>
            <th>Deadline</th>
            <th width="33%">Deadline Date</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

DeadlinesTable.propTypes = {
  children: PropTypes.node.isRequired,
};
