import { gql } from "graphql-request";
export const userEnrollments = gql`
  query ($uid: String) {
    user(uid: $uid) {
      enrollments {
        hasEnrollments
      }
    }
  }
`;
