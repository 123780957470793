import {
  API_LINKS_START,
  API_LINKS_SUCCESS,
  SET_API_LINK,
  SET_API_LINKS,
} from "../action-types";

export default function apiLinks(state = {}, action) {
  switch (action.type) {
    case API_LINKS_START:
      return { ...state, loadState: "pending" };
    case API_LINKS_SUCCESS:
      return { ...state, loadState: "success" };
    case SET_API_LINK:
      return { ...state, [action.value.urlId]: action.value };
    case SET_API_LINKS:
      return {
        ...state,
        ...action.value.reduce((acc, current) => {
          if (current) {
            return { ...acc, [current.urlId]: current };
          }

          return acc;
        }, {}),
      };
    default:
      return state;
  }
}
