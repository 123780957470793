import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  ChevronCircleIcon,
} from "components/ui/ChevronAccordion";

export const AdmitResource = ({ title, children }) => (
  <AdmitAccordionItem>
    {({ hoverActive, setHoverActive }) => (
      <>
        <AdmitAccordionButton
          hoverActive={hoverActive}
          setHoverActive={setHoverActive}
        >
          <h3>{title}</h3>
          <ChevronCircleIcon $hoverActive={hoverActive} />
        </AdmitAccordionButton>
        <AccordionPanel style={{ padding: `var(--lg) 0` }}>
          {children}
        </AccordionPanel>
      </>
    )}
  </AdmitAccordionItem>
);

AdmitResource.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const AdmitAccordionItem = styled(AccordionItem)`
  padding: var(--sm) 0;
  border-top: 1px solid var(--gallery);

  &:last-child {
    border-bottom: 1px solid var(--gallery);
  }
`;

const AdmitAccordionButton = styled(AccordionButton)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
