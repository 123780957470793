import React from "react";
import PropTypes from "prop-types";
import { nbsp } from "functions/symbols";

const ClassSchedules = ({ isInactiveTerm, section, semesterClass }) => {
  if (isInactiveTerm) {
    return null;
  }

  const displaySchedulesCollection = (section, schedules) => {
    return schedules.map((schedule) => {
      return {
        instructionalFormat: section.instruction_format,
        schedule: schedule,
      };
    });
  };

  const allSectionSchedules = (semesterClass, section) => {
    const primaryClassSectionId = section.ccn;

    let collectedSchedules = {
      oneTime: [],
      recurring: [],
    };

    let filterPrimaryOrSecondary = (section) => {
      return (
        section.associated_primary_id === primaryClassSectionId ||
        section.ccn === primaryClassSectionId
      );
    };

    if (semesterClass.sections.length > 0) {
      let primaryAndSecondarySections = semesterClass.sections.filter(
        filterPrimaryOrSecondary
      );

      primaryAndSecondarySections.map((section) => {
        if (section && section.schedules) {
          if (section.schedules.oneTime.length > 0) {
            collectedSchedules.oneTime = collectedSchedules.oneTime.concat(
              displaySchedulesCollection(section, section.schedules.oneTime)
            );
          }
          if (section.schedules.recurring.length > 0) {
            collectedSchedules.recurring = collectedSchedules.recurring.concat(
              displaySchedulesCollection(section, section.schedules.recurring)
            );
          }
        }
      });
    }
    return collectedSchedules;
  };
  const schedulesCollection = allSectionSchedules(semesterClass, section);

  const { oneTime: oneTimeSchedules = [], recurring: recurringSchedules = [] } =
    schedulesCollection;

  if (
    !section ||
    (oneTimeSchedules.length < 1 && recurringSchedules.length < 1)
  ) {
    return null;
  }

  const scheduleWrapper = {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "nowrap",
  };
  const instructionFormatContainer = {
    order: 1,
    width: "40px",
  };
  const scheduleContainer = {
    order: 2,
  };

  return (
    <>
      {recurringSchedules.length > 0 &&
        recurringSchedules.map((scheduleObj, scheduleIndex) => (
          <React.Fragment key={scheduleIndex}>
            <div
              style={scheduleWrapper}
              data-testid={
                "semester-classes-section-class-schedule-" + scheduleIndex
              }
            >
              <div style={instructionFormatContainer}>
                {scheduleObj.instructionalFormat}
              </div>
              <div style={scheduleContainer}>
                {scheduleObj.schedule.schedule}
              </div>
            </div>
          </React.Fragment>
        ))}
      {oneTimeSchedules.length > 0 &&
        oneTimeSchedules.map((scheduleObj, scheduleIndex) => (
          <React.Fragment key={scheduleIndex}>
            <div
              style={scheduleWrapper}
              data-testid={
                "semester-classes-section-class-schedule-" + scheduleIndex
              }
            >
              <div style={instructionFormatContainer}>
                {scheduleObj.instructionalFormat}
                {nbsp}
              </div>
              <div style={scheduleContainer}>
                <div>
                  {scheduleObj.schedule.date} {scheduleObj.schedule.time}
                </div>
                {(scheduleObj.schedule.buildingName ||
                  scheduleObj.schedule.roomNumber) && (
                  <div>
                    {scheduleObj.schedule.buildingName}{" "}
                    {scheduleObj.schedule.roomNumber}
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
    </>
  );
};

ClassSchedules.propTypes = {
  isInactiveTerm: PropTypes.bool,
  section: PropTypes.object,
  semesterClass: PropTypes.object,
};

export default ClassSchedules;
