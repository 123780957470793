import { gql } from 'graphql-request';
import {
  PUBLIC_LINKS_SET,
  PUBLIC_LINKS_START,
  PUBLIC_LINKS_SUCCESS,
} from '../action-types';

import graphqlClient from 'utils/graphqlClient';

const query = gql`
  {
    links {
      urlId
      name
      title
      url
      comments
      ucFrom
      ucFromLink
      ucFromText
      showNewWindow
      isCampusSolutionsLink
      ucUpdateCache
    }
  }
`;

export default function getPublicLinks() {
  return (dispatch, getState) => {
    const { publicLinks, csrfToken } = getState();

    if (publicLinks.loadState === 'success' || publicLinks.loadState === 'pending') {
      return new Promise((resolve, _reject) => resolve(publicLinks));
    } else if (publicLinks.loadState !== 'pending') {
      dispatch({ type: PUBLIC_LINKS_START });

      graphqlClient(csrfToken)
        .request(query)
        .then(({ links = [] }) => {
          dispatch({
            type: PUBLIC_LINKS_SET,
            value: links,
          });

          dispatch({
            type: PUBLIC_LINKS_SUCCESS,
          });
        });
    }
  };
}
