import {
  TUITION_COHORT_START,
  TUITION_COHORT_SUCCESS,
  TUITION_COHORT_FAILURE,
} from "../action-types";

export default function tuitionCohort(state = {}, action) {
  switch (action.type) {
    case TUITION_COHORT_START:
      return { ...state, loadState: "pending" };
    case TUITION_COHORT_SUCCESS:
      return { ...state, ...action.value, loadState: "success" };
    case TUITION_COHORT_FAILURE:
      return { ...state, loadState: "error" };
    default:
      return state;
  }
}
