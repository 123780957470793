import { gql } from "graphql-request";
export const userProfileQuery = gql`
  query ($uid: String) {
    user(uid: $uid) {
      campusSolutionsId
      firstName
      fullName
      lastName
      legalFirstName
      legalLastName
      legalFullName
      pronouns
      roles
      sid
      uid
    }
  }
`;
