import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SemesterClass from "./SemesterClass";
import styles from "./SemestersCard.module.scss";

const SemesterClasses = ({ isAdvisingView, canViewGrades, semester }) => {
  const isInactiveTerm = semester.timeBucket === "past";

  return (
    <div className={styles.semesterClasses} data-testid="semester-classes">
      {!semester.hasEnrolledClasses &&
        !semester.hasWithdrawalData &&
        !semester.hasStudyProgData && (
          <div
            className={styles.noEnrollmentsNotice}
            data-testid="semester-classes-no-enrolled"
          >
            You are currently not enrolled in any classes for {semester.name}.
          </div>
        )}
      {semester.hasEnrolledClasses &&
        !semester.hasWithdrawalData &&
        !semester.hasStudyProgData && (
          <div data-testid="enrolled-classes">
            {semester.hasEnrolledClasses && !semester.hasWithdrawalData && (
              <table
                className={styles.classesTable}
                data-testid="semester-classes-table"
              >
                <thead>
                  <tr>
                    <th scope="col">Class</th>
                    <th scope="col">Title</th>
                    {!canViewGrades && (
                      <th scope="col" style={{ textAlign: "center" }}>
                        Units
                      </th>
                    )}
                    {canViewGrades && (
                      <>
                        <th
                          scope="col"
                          title="Units"
                          style={{ textAlign: "center" }}
                        >
                          Units
                        </th>
                        <th
                          scope="col"
                          title="Grade"
                          style={{ textAlign: "center" }}
                        >
                          Grade
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {semester.classes.map((semesterClass) => (
                    <SemesterClass
                      key={semesterClass.cs_course_id}
                      isInactiveTerm={isInactiveTerm}
                      isAdvisingView={isAdvisingView}
                      semesterClass={semesterClass}
                      canViewGrades={canViewGrades}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
    </div>
  );
};

SemesterClasses.propTypes = {
  isAdvisingView: PropTypes.bool,
  canViewGrades: PropTypes.bool,
  semester: PropTypes.object,
};

function mapState({ myStatus }) {
  const { canViewGrades = false } = myStatus;

  return { canViewGrades };
}

export default connect(mapState)(SemesterClasses);
