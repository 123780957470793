import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const formatNote = (note) =>
  `${note.note} ${note.termName}: ${note.classDescription}`;
const formatAward = (award) => award.description;

const AwardsLink = ({ awardCount }) => {
  const summaryPath = "/academics/academic_summary";

  if (awardCount === 1) {
    return <a href={summaryPath}>Academic Award</a>;
  } else {
    return <a href={summaryPath}>Academic Awards</a>;
  }
};
AwardsLink.displayName = "AwardsLink";
AwardsLink.propTypes = {
  awardCount: PropTypes.number,
};

const LawAwards = ({ showLink }) => {
  const { awards, transcriptNotes } = useSelector((state) => {
    const { myLawAwards: { awards, transcriptNotes } = {} } = state || {};

    return {
      awards: awards || [],
      transcriptNotes: transcriptNotes || [],
    };
  });

  const awardsAndNotes = [...awards, ...transcriptNotes];
  const awardCount = awardsAndNotes.length;

  if (awardCount) {
    return (
      <tr data-testid="law-academic-awards">
        <th>Academic Awards</th>
        <td>
          {showLink ? (
            <AwardsLink awardCount={awardCount} />
          ) : (
            <Fragment>
              {awards.map((award, index) => (
                <div key={index}>{formatAward(award)}</div>
              ))}
              {transcriptNotes.map((note, index) => (
                <div key={index}>{formatNote(note)}</div>
              ))}
            </Fragment>
          )}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

LawAwards.displayName = "LawAwards";
LawAwards.propTypes = {
  showLink: PropTypes.bool,
};
LawAwards.defaultProps = {
  showLink: false,
};

export default LawAwards;
