/**
 * Launcher controller
 */

angular
  .module('calcentral.controllers')
  .controller('LauncherController', function(apiService, $scope) {
    var userIsPreSir = function() {
      let isApplicant = apiService.user.profile.roles.applicant;
      let isReleasedAdmit = apiService.user.profile.roles.releasedAdmit;
      let isPostSir = apiService.user.profile.roles.postSir;
      let isStudent = apiService.user.profile.roles.student;
      let isPreSir =
        !isStudent && !isPostSir && (isApplicant || isReleasedAdmit);
      return isPreSir;
    };

    $scope.$watch('api.user.profile.roles', function() {
      if (apiService.user.profile.roles) {
        $scope.userIsPreSir = userIsPreSir();
      }
    });
  });
