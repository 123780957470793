// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categoryHeader___lq9y5{display:flex;justify-content:space-between;align-items:center;padding:10px;padding-right:15px}.aidYearLabel___FB_q3{font-weight:normal}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/IncompleteTasks/CategoryHeader.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,kBAAA","sourcesContent":[".categoryHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n  padding-right: 15px;\n}\n\n.aidYearLabel {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryHeader": "categoryHeader___lq9y5",
	"aidYearLabel": "aidYearLabel___FB_q3"
};
export default ___CSS_LOADER_EXPORT___;
