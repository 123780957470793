import {
  SET_CURRENT_ROUTE_PROPERTIES,
  ADVISOR_STUDENT_DATA_REFRESH,
  ADVISOR_STUDENT_DATA_START,
  ADVISOR_STUDENT_DATA_SUCCESS,
} from "../../action-types";

export default function (state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_ROUTE_PROPERTIES:
      return {};
    case ADVISOR_STUDENT_DATA_REFRESH:
      return { uid: action.value, loadState: "pending" };
    case ADVISOR_STUDENT_DATA_START:
      return { ...state, uid: action.value, loadState: "pending" };
    case ADVISOR_STUDENT_DATA_SUCCESS:
      return { ...state, ...action.value, loadState: "success" };
    default:
      return state;
  }
}
