// Native version of LoDash iSEmpty
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty
//
// Checks if value is an empty object or collection.
//
// Note: Does not support evaluating a Set or a Map
//
// console.log(isEmpty(null))
// output: true
// console.log(isEmpty(''))
// output: true
// console.log(isEmpty({}))
// output: true
// console.log(isEmpty([]))
// output: true
// console.log(isEmpty({a: '1'}))
// output: false

export default function isEmpty(obj) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}
