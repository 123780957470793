import React from "react";
import ReduxProvider from "components/ReduxProvider";
import { react2angular } from "react2angular";
import FinancialResourcesCard from "components/cards/FinancialResourcesCard";

const NgFinancialResourcesCard = () => {
  return (
    <ReduxProvider>
      <FinancialResourcesCard />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("financialResources", react2angular(NgFinancialResourcesCard));
