import React from "react";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";

import { FinaidProfileBody } from "./FinancialAidAndScholarshipsProfileCard/FinaidProfileBody";

import { fetchFinaidProfile } from "data/slices/users/finaid/finaidProfile";
import { useFinaidYear } from "components/hooks/useFinaidYear";
import { dispatchUserFinaidSlice } from "components/hooks/dispatchUserFinaidSlice";

export function FinancialAidAndScholarshipsProfileCard() {
  dispatchUserFinaidSlice(fetchFinaidProfile);
  const { loadState } = useFinaidYear((year) => year.finaidProfile || {});

  return (
    <Card data-testid="finaid-scholarships-profile-card">
      <CardTitle>
        <h2>Financial Aid & Scholarships Profile</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && <FinaidProfileBody />}
      </CardBody>
    </Card>
  );
}
