import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  columnHeaderPropTypeShape,
  studentPropTypeShape,
  sectionPropTypeShape,
} from "./propTypes";

import VisuallyHidden from "@reach/visually-hidden";
import Alert from "@reach/alert";

import Spinner from "components/ui/Spinner";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { ViewControls } from "./ViewControls";
import { AddressesSection } from "./AddressesSection";
import { DefaultSection } from "./DefaultSection";
import { MessagesSection } from "./MessagesSection";
import { StudentListOptions } from "./StudentListOptions";
import { StudentFilter } from "./StudentFilter";
import {
  findSection,
  getMessageSections,
  getSelectedStudents,
  isMessageDisplay,
  selectedStudentToggleFactory,
  selectAllStudentsToggleFactory,
  updateSelectedSectionFactory,
} from "./StudentListHelpers";

export default function StudentList({
  columnHeaders,
  course,
  courseId,
  enrollmentStatus,
  loadState,
  sections,
  semester,
  students,
  title,
}) {
  const { course_code: courseCode, dept: courseDept } = course;
  const { name: semesterName } = semester;

  const [accessibleAlert, setAccessibleAlert] = useState(undefined);
  const [displayedView, setDisplayedView] = useState("default");
  const isWaitlistMode = enrollmentStatus == "waitlisted";
  const messageSections = getMessageSections(isWaitlistMode, courseDept);

  // setup selected students
  const [selectedStudentIds, setSelectedStudentIds] = useState(new Set());
  const toggleSelectedStudent = selectedStudentToggleFactory(
    selectedStudentIds,
    setSelectedStudentIds
  );
  const messageButtonsDisabled = selectedStudentIds.size == 0;

  // setup selected section
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const sectionOptions = [{ ccn: "", name: "All Sections" }, ...sections];
  const selectedSection = findSection(sections, selectedSectionId);
  const updateSelectedSection = updateSelectedSectionFactory(
    isWaitlistMode,
    setAccessibleAlert,
    setSelectedSectionId,
    setSelectedStudentIds
  );

  const [sortColumn, setSortColumn] = useState("last_name");
  const [sortDirection, setSortDirection] = useState("ASC");

  const searchFilters = {
    section: selectedSection,
    enrollStatus: enrollmentStatus,
    sortColumn,
    sortDirection,
    setSortColumn,
    setSortDirection,
  };

  const studentFilter = new StudentFilter(students, sections, searchFilters);
  const filteredStudentResults = studentFilter.filteredStudentResults;

  const toggleAllSelectedStudents = selectAllStudentsToggleFactory(
    setSelectedStudentIds,
    filteredStudentResults.shownStudents
  );
  const selectedStudents = getSelectedStudents(students, selectedStudentIds);

  return (
    <Card data-testid={`class-information-enrollment-card-${enrollmentStatus}`}>
      <CardTitle>
        <h2>{title}</h2>

        <StudentListOptions
          courseId={courseId}
          enrollmentStatus={enrollmentStatus}
          sectionOptions={sectionOptions}
          selectedSectionId={selectedSectionId}
          updateSelectedSection={updateSelectedSection}
        />
      </CardTitle>
      <CardBody>
        {loadState === "pending" && (
          <div style={{ textAlign: `center` }}>
            <Spinner />
            <br />
            Downloading rosters. This may take a minute for larger classes.
          </div>
        )}

        {loadState === "failure" && (
          <div style={{ textAlign: `center` }}>
            There was an error retrieving enrollment data.
          </div>
        )}

        {loadState === "success" && (
          <>
            <VisuallyHidden data-testid="accessibleAlert">
              <Alert type="assertive">{accessibleAlert}</Alert>
            </VisuallyHidden>

            <ViewControls
              displayedView={displayedView}
              filteredStudents={filteredStudentResults}
              messageButtonsDisabled={messageButtonsDisabled}
              messageSections={messageSections}
              setDisplayedView={setDisplayedView}
            />

            <div>
              {displayedView === "default" && (
                <DefaultSection
                  columnHeaders={columnHeaders}
                  isWaitlistMode={isWaitlistMode}
                  searchFilters={searchFilters}
                  selectedStudentIds={selectedStudentIds}
                  students={filteredStudentResults.shownStudents}
                  toggleSelectedStudent={toggleSelectedStudent}
                  toggleAllSelectedStudents={toggleAllSelectedStudents}
                />
              )}
              {isMessageDisplay(displayedView) && (
                <MessagesSection
                  courseCode={courseCode}
                  displayedView={displayedView}
                  selectedStudents={selectedStudents}
                  semesterName={semesterName}
                  setDisplayedView={setDisplayedView}
                  searchFilters={searchFilters}
                />
              )}
              {displayedView === "addresses" && (
                <AddressesSection
                  displayedView={displayedView}
                  selectedStudents={selectedStudents}
                  setDisplayedView={setDisplayedView}
                />
              )}
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
}

StudentList.displayName = "StudentList";
StudentList.propTypes = {
  columnHeaders: PropTypes.arrayOf(columnHeaderPropTypeShape),
  course: PropTypes.object,
  courseId: PropTypes.string,
  enrollmentStatus: PropTypes.string,
  loadState: PropTypes.string,
  sections: PropTypes.arrayOf(sectionPropTypeShape),
  semester: PropTypes.shape({
    name: PropTypes.string,
  }),
  students: PropTypes.arrayOf(studentPropTypeShape),
  title: PropTypes.string,
};
