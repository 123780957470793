import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import GraduationChecklist from "components/GraduationChecklist";

const NgAcademicsGraduationChecklist = () => (
  <ReduxProvider>
    <GraduationChecklist />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "academicsGraduationChecklist",
    react2angular(NgAcademicsGraduationChecklist)
  );
