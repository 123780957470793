import styled from "styled-components";
import {
  Accordion as ReachAccordion,
  AccordionButton as ReachAccordionButtion,
  AccordionItem as ReachAccordionItem,
  AccordionPanel as ReachAccordionPanel,
} from "@reach/accordion";
import "@reach/accordion/styles.css";

export const Accordion = styled(ReachAccordion)``;
Accordion.displayName = "Accordion";

export const AccordionButton = styled(ReachAccordionButtion)`
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  width: 100%;
  color: var(--base-color);
`;
AccordionButton.displayName = "AccordionButton";

export const AccordionItem = styled(ReachAccordionItem)`
  ${(props) => props.focused && "background-color: var(--foam);"}
`;
AccordionItem.displayName = "AccordionItem";

export const AccordionPanel = ReachAccordionPanel;
