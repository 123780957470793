import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchBCalSchedule } from "data/actions/bCalScheduleActions";
import { getMyStatus } from "data/slices/myStatus";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import NoBconnected from "components/cards/BCalScheduleCard/NoBconnected";
import BCalScheduleItems from "components/cards/BCalScheduleCard/BCalScheduleItems";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";

export function BCalScheduleCard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBCalSchedule());
    dispatch(getMyStatus());
  }, [dispatch]);

  const {
    items,
    isLoading,
    error,
    hasGoogleAccessToken,
    officialBmailAddress,
  } = useSelector((state) => {
    const {
      bCalSchedule: { items = [], isLoading, error = null } = {},
      myStatus: {
        hasGoogleAccessToken = false,
        officialBmailAddress = null,
      } = {},
    } = state;

    return {
      items,
      isLoading,
      error,
      hasGoogleAccessToken,
      officialBmailAddress,
    };
  });

  const hasItems = items.length > 0;

  return (
    <Card data-testid="bcal-schedule-card">
      <CardTitle>
        <h2>bCal Schedule</h2>
      </CardTitle>

      {isLoading ? (
        <CardBody>
          <Spinner />
        </CardBody>
      ) : error ? (
        <CardBody>error</CardBody>
      ) : hasItems ? (
        <BCalScheduleItems />
      ) : hasGoogleAccessToken ? (
        <CardBody>
          You have no events scheduled for the rest of the day.
        </CardBody>
      ) : officialBmailAddress ? (
        <CardBody>
          <NoBconnected />
        </CardBody>
      ) : (
        <CardBody>
          Our records indicate that you do not currently have a bConnected
          account (UC Berkeley email and calendar). Visit{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mybconnected.berkeley.edu/manage/account/create_account"
          >
            <AccessibleLinkContent showNewWindow={true}>
              bConnected
            </AccessibleLinkContent>
          </a>{" "}
          to create your bConnected account.
        </CardBody>
      )}
    </Card>
  );
}
BCalScheduleCard.displayName = "BCalScheduleCard";
