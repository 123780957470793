'use strict';
var _ = require('lodash');
import getUserLinks from 'data/actions/getUserLinks';
import getPublicLinks from 'data/actions/getPublicLinks';

/**
 * CS Link Factory, retrieves links from Campus Solutions.
 */
angular.module('calcentral.factories').factory('csLinkFactory', function(apiService, $ngRedux) {
  var csLinkUrl = '/api/campus_solutions/link';
  // var csLinkUrl = '/dummy/json/campus_solutions_link.json';
  // var csLinkUrl = '/dummy/json/campus_solutions_link_empty.json';

  var getLink = function(options) {
    var url = csLinkUrl + '?urlId=' + options.urlId;
    _.forEach(options.placeholders, function(value, key) {
      url += '&placeholders[' + key + ']=' + value;
    });
    return apiService.http.request(options, url);
  };

  var getAllUserLinks = function() {
    $ngRedux.dispatch(getUserLinks());
  };

  var getAllPublicLinks = function() {
    $ngRedux.dispatch(getPublicLinks());
  };

  return {
    getLink: getLink,
    getUserLinks: getAllUserLinks,
    getPublicLinks: getAllPublicLinks,
  };

});
