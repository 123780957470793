import React, { useState } from "react";
import PropTypes from "prop-types";
import EnrollmentCard from "./EnrollmentCard";
import StyledMessage from "components/StyledMessage";
import EnrollmentInfoExpander from "./EnrollmentInfoExpander";
import HoldsWarning from "./HoldsWarning";
import { LargeSpacer, MediumSpacer } from "components/ui/VerticalSpacers";
import EnrollmentPeriods from "./DefaultEnrollment/EnrollmentPeriods";
import Deadlines from "./DefaultEnrollment/Deadlines";
import TermLabel from "./TermLabel";
import EnrollmentCenterButton from "./EnrollmentCenterButton";
import WithAPILink from "components/ui/WithAPILink";
import {
  UC_SR_SS_MBA_CLASS_SEARCH,
  UC_SR_SS_HAAS_OLR,
  UC_SR_SS_HAAS_GRAD_REQ_EWMBA,
  UC_SR_SS_HAAS_CRSE_PLAN_EWMBA,
  UC_SR_SS_HAAS_NON_HAAS_EWMBA,
  UC_SR_SS_HAAS_GRAD_REQ_MBA,
  UC_SR_SS_HAAS_AREAS_OF_EMPH_MB,
  UC_SR_SS_HAAS_NON_HAAS_MBA,
} from "functions/urlIds";

import { termInstructionShape, presentationShape } from "./propTypes";

const NewWindowLink = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

NewWindowLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

const ExecMBAInfo = () => (
  <StyledMessage>
    <p>
      Please refer to the{" "}
      <NewWindowLink href="http://www.haas.berkeley.edu/EMBA/">
        Program Office website
      </NewWindowLink>{" "}
      for calendars, FAQs, resources, and Bear Necessities archives.
    </p>
    <p>
      For additional questions, please contact the{" "}
      <NewWindowLink href="http://www.haas.berkeley.edu/EMBA/contacts/">
        Program Office
      </NewWindowLink>
      .
    </p>
  </StyledMessage>
);

const FullTimeInfo = () => (
  <StyledMessage>
    <h2>Academic Planning</h2>
    <ul>
      <li>
        <WithAPILink urlID={UC_SR_SS_HAAS_GRAD_REQ_MBA} />
      </li>
      <li>
        <WithAPILink urlID={UC_SR_SS_HAAS_AREAS_OF_EMPH_MB} />
      </li>
    </ul>

    <h2>MBA Electives</h2>
    <ul>
      <li>
        <WithAPILink urlID={UC_SR_SS_HAAS_OLR} />
      </li>
    </ul>

    <h2>Non-Haas Electives</h2>
    <ul>
      <li>
        <WithAPILink urlID={UC_SR_SS_HAAS_NON_HAAS_MBA} />
      </li>
      <li>
        <WithAPILink urlID={UC_SR_SS_MBA_CLASS_SEARCH} />
      </li>
    </ul>
  </StyledMessage>
);

const EveningWeekendInfo = () => (
  <StyledMessage>
    <h2>Academic Planning</h2>
    <ul>
      <li>
        <WithAPILink urlID={UC_SR_SS_HAAS_GRAD_REQ_EWMBA} />
      </li>
      <li>
        <WithAPILink urlID={UC_SR_SS_HAAS_CRSE_PLAN_EWMBA} />
      </li>
    </ul>
    <h2>EWMBA Electives</h2>
    <ul>
      <li>
        <WithAPILink urlId={UC_SR_SS_HAAS_OLR} />
      </li>
    </ul>
    <h2>Non-Haas Electives</h2>
    <ul>
      <li>
        <WithAPILink urlID={UC_SR_SS_HAAS_NON_HAAS_EWMBA} />
      </li>
      <li>
        <WithAPILink urlID={UC_SR_SS_MBA_CLASS_SEARCH} />
      </li>
    </ul>
  </StyledMessage>
);

export default function MBAEnrollmentCard({ presentations, termInstructions }) {
  const [learnMoreExpanded, setLearnMoreExpanded] = useState(false);

  return (
    <EnrollmentCard
      presentations={presentations}
      termInstructions={termInstructions}
    >
      {({ presentation, enrollmentPeriods }) => {
        const termId = presentation.termId;
        return (
          <>
            <HoldsWarning termId={termId} />

            {presentation.design === "haasFullTimeMba" && <FullTimeInfo />}
            {presentation.design === "haasExecMba" && <ExecMBAInfo />}
            {presentation.design === "haasEveningWeekendMba" && (
              <EveningWeekendInfo />
            )}

            {presentation.design != "haasExecMba" && (
              <>
                <LargeSpacer />

                <EnrollmentInfoExpander
                  title="Non-Haas Enrollment Enrollment"
                  onClick={() => setLearnMoreExpanded(!learnMoreExpanded)}
                  expanded={learnMoreExpanded}
                />

                {learnMoreExpanded && (
                  <>
                    <LargeSpacer />
                    <TermLabel termId={termId} />
                    <MediumSpacer />
                    <EnrollmentPeriods enrollmentPeriods={enrollmentPeriods} />
                    <LargeSpacer />
                    <Deadlines presentation={presentation} />
                    <LargeSpacer />

                    <p>
                      You may change your non-Haas classes during or after your
                      enrollment period.
                    </p>
                    <p>
                      Do not drop or change Haas classes here. Use{" "}
                      <NewWindowLink href="https://olr.haas.berkeley.edu/">
                        OLR
                      </NewWindowLink>{" "}
                      instead.
                    </p>

                    <div style={{ display: `flex`, justifyContent: `center` }}>
                      <EnrollmentCenterButton name="Non-Haas Enrollment Center" />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        );
      }}
    </EnrollmentCard>
  );
}

MBAEnrollmentCard.propTypes = {
  presentations: PropTypes.arrayOf(presentationShape),
  termInstructions: PropTypes.arrayOf(termInstructionShape),
};
