import React from "react";
import PropTypes from "prop-types";

import whiteChangedIconSvg from "icons/changed-white.svg";

const WhiteChangedIcon = ({ className }) => {
  return <img src={whiteChangedIconSvg} className={className} role="icon" />;
};

WhiteChangedIcon.displayName = "WhiteChangedIcon";
WhiteChangedIcon.propTypes = {
  className: PropTypes.string,
};

export default WhiteChangedIcon;
