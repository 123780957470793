import { userEnrollments } from "data/queries/userEnrollments";
import { buildUserSlice } from "data/utils/buildSlice";
import { userDataThunk } from "data/utils/thunks";
import { userGQLRequest } from "data/utils/requests";

const key = "enrollments";
export const slice = buildUserSlice(key);
export const { reducer: enrollments } = slice;
export const fetchEnrollments = userDataThunk(key, slice, {
  request: userGQLRequest(userEnrollments),
  dataHandler: ({ actions, dispatch, uid }) =>
    ({ user: { enrollments = [] } = {} }) => {
      dispatch(actions.success(uid, enrollments));
    }
});
