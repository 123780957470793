import React from "react";

import advisingStudentState from "utils/advisingStudentState";
import { useSelector } from "react-redux";

import { UserIcon } from "components/ui/Icons";

import {
  ColumnOne,
  ColumnTwo,
  IconWrapper,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";
import ProfilePicture from "components/AcademicProfile/ProfilePicture";
import APILink from "components/ui/APILink";

import { Pronouns } from "components/cards/advising/StudentSummaryCard/Pronouns";
import { ViewAs } from "components/cards/advising/StudentSummaryCard/ViewAs";

export const NameAndPhoto = () => {
  const { advisingStudentUID, fullName } = useSelector((state) => {
    const { advising: { userId: advisingStudentUID } = {} } = state;

    const { advisingStudentProfile: { fullName } = {} } =
      advisingStudentState(state, advisingStudentUID) || {};

    return {
      advisingStudentUID,
      fullName,
    };
  });

  if (advisingStudentUID) {
    return (
      <StyledRow>
        <ColumnOne>
          <ProfilePicture isStudentSummaryView ldapUid={advisingStudentUID} />
        </ColumnOne>
        <ColumnTwo>
          <strong>{fullName}</strong>
          <Pronouns />
          <ViewAs ldapUid={advisingStudentUID} />
          <div>
            <IconWrapper>
              <UserIcon />
            </IconWrapper>
            <APILink
              link={{
                url: `https://boa.berkeley.edu/student/${advisingStudentUID}`,
                description: "View Student in BOA",
                showNewWindow: true,
              }}
              text={"View in BOA"}
            />
          </div>
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
NameAndPhoto.displayName = "NameAndPhoto";
