import { createSlice } from "@reduxjs/toolkit";

const billingActivity = createSlice({
  name: "billingActivity",
  initialState: {
    loadState: "pending",
  },
  reducers: {
    start: (state) => {
      state.loadState = "pending";
    },
    success: (state, action) => {
      return {
        ...state,
        ...action.payload,
        loadState: "success",
      };
    },
    failure: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loadState: "failure",
      };
    },
  },
});

const { actions, reducer } = billingActivity;

export const { start, success, failure } = actions;

export function getBillingActivity() {
  return (dispatch, getState) => {
    const { billingActivity: slice } = getState();

    if (slice.loadState === "success" || slice.loadState === "failure") {
      return new Promise((resolve, _reject) => resolve(slice));
    } else {
      dispatch(actions.start());

      fetch("/api/campus_solutions/billing_activity")
        .then((res) => res.json())
        .then(({ feed }) => dispatch(actions.success(feed)))
        .catch((error) => {
          if (error.response) {
            dispatch(
              actions.failure({
                status: error.response.status,
                statusText: error.response.statusText,
              })
            );
          }
        });
    }
  };
}

export default reducer;
