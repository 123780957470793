import React from "react";
import { parseISO, format } from "date-fns";
import styled from "styled-components";

import { serviceAlertShape } from "./serviceAlert.module.js";

const formatDate = (dateString) => {
  try {
    return format(parseISO(dateString), "MMM dd");
  } catch (e) {
    // No-op
  }
};

const SplashStyles = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: var(--large-spacing);

  .date {
    flex: 1;
    color: var(--mine-shaft);
    font-size: 15px;
    font-weight: 700;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  > div {
    flex: 5;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 0;
    }

    .body {
      p {
        margin: var(--large-spacing) 0 0;
      }

      img {
        max-width: 100%;
      }
    }
  }
`;

const SplashAlert = ({ serviceAlert: { title, body, publicationDate } }) => (
  <SplashStyles data-testid="splash-alert">
    <strong className="date">{formatDate(publicationDate)}</strong>
    <div>
      <div className="title">{title}</div>
      <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  </SplashStyles>
);

SplashAlert.displayName = "SplashAlert";
SplashAlert.propTypes = {
  serviceAlert: serviceAlertShape,
};

export default SplashAlert;
