import {
  FETCH_WEB_MESSAGES_START,
  FETCH_WEB_MESSAGES_SUCCESS,
  FETCH_WEB_MESSAGES_FAILURE,
} from 'data/action-types';

export const fetchWebMessagesStart = () => ({
  type: FETCH_WEB_MESSAGES_START,
});

export const fetchWebMessagesSuccess = value => ({
  type: FETCH_WEB_MESSAGES_SUCCESS,
  value: value,
});

export const fetchWebMessagesFailure = error => ({
  type: FETCH_WEB_MESSAGES_FAILURE,
  value: error,
});

export const fetchWebMessages = () => {
  return (dispatch, getState) => {
    const { myWebMessages } = getState();

    if (myWebMessages.loaded || myWebMessages.isLoading) {
      return new Promise((resolve, _reject) => resolve(myWebMessages));
    } else {
      dispatch(fetchWebMessagesStart());

      fetch('/api/my/tasks/web_messages', { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchWebMessagesFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchWebMessagesSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchWebMessagesFailure(failure));
          }
        });
    }
  };
};
