import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import postHeaders from "utils/postHeaders";

import { format, parseISO } from "date-fns";

import { BlueButton } from "components/ui/Buttons";

function ActingAsBar() {
  const {
    actingAsUid,
    advisorActingAsUid,
    csrfToken,
    delegateActingAsUid,
    firstLoginAt,
    fullName,
    uid,
  } = useSelector((state) => {
    const {
      csrfToken,
      myStatus: {
        actingAsUid,
        advisorActingAsUid,
        delegateActingAsUid,
        firstLoginAt,
      } = {},
      userProfile: {
        fullName,
        uid
      } = {},
    } = state || {};

    return {
      actingAsUid,
      advisorActingAsUid,
      csrfToken,
      delegateActingAsUid,
      firstLoginAt,
      fullName,
      uid,
    };
  });

  const stop = async () => {
    let stopUrl;
    let redirectTo;

    if (actingAsUid) {
      stopUrl = "/stop_act_as";
      redirectTo = "/toolbox";
    } else if (advisorActingAsUid) {
      stopUrl = "/stop_advisor_act_as";
      redirectTo = "/dashboard";
    } else if (delegateActingAsUid) {
      stopUrl = "/stop_delegate_act_as";
      redirectTo = "/toolbox";
    }

    await fetch(stopUrl, { method: "POST", headers: postHeaders(csrfToken) });
    window.location.pathname = redirectTo;
  };

  if (actingAsUid || advisorActingAsUid || delegateActingAsUid) {
    return (
      <ActingAsStyles>
        {actingAsUid ? (
          <div>
            You&apos;re currently viewing as {fullName} ({uid}),{" "}
            {firstLoginAt ? (
              <>
                first logged in on {format(parseISO(firstLoginAt), "MM/dd/yy")}
              </>
            ) : (
              <>who has never logged in to CalCentral</>
            )}
          </div>
        ) : (
          <div>You are viewing {fullName}&apos;s information.</div>
        )}

        <BlueButton onClick={() => stop()}>Stop</BlueButton>
      </ActingAsStyles>
    );
  }

  return null;
}

ActingAsBar.propTypes = {
  csrfToken: PropTypes.string,
  actingAsUid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  advisorActingAsUid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  delegateActingAsUid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  firstLoginAt: PropTypes.string,
  fullName: PropTypes.string,
  uid: PropTypes.string,
};

export default ActingAsBar;

const ActingAsStyles = styled.div`
  background-color: var(--white);
  border-top: 2px solid var(--buttercup);
  line-height: 27px;
  padding: var(--medium-spacing) var(--large-spacing);

  @media screen {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media print {
    position: relative;
    border-top-color: var(--black);
  }
`;
