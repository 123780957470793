import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserAuth } from "data/actions/userAuthActions";

function RequireAuthor({ currentUID, loadAuthorization, isAuthor, children }) {
  useEffect(() => {
    loadAuthorization(currentUID);
  }, [currentUID]);

  if (isAuthor) {
    return children;
  }

  return null;
}

RequireAuthor.propTypes = {
  currentUID: PropTypes.string,
  loadAuthorization: PropTypes.func,
  isAuthor: PropTypes.bool,
  children: PropTypes.node,
};

const mapStateToProps = ({ currentUID, users }) => {
  const { userAuth: { is_author: isAuthor, is_superuser: isSuperuser } = {} } =
    users[currentUID] || {};
  return { currentUID, isAuthor: isAuthor || isSuperuser };
};

const mapDispatchToProps = (dispatch) => ({
  loadAuthorization: (uid) => dispatch(getUserAuth(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuthor);
