export function fetchAdvisorUndergradDegreeProgress(uid) {
  return (dispatch, getState) => {
    const {
      advising: { undergradDegreeProgress = {} },
    } = getState();

    if (
      undergradDegreeProgress.loadState === 'pending' ||
      undergradDegreeProgress.loadState === 'success'
    ) {
      if (undergradDegreeProgress.uid === uid) {
        return new Promise((resolve, _reject) =>
          resolve(undergradDegreeProgress)
        );
      } else {
        dispatch({
          type: 'ADVISOR_UNDERGRAD_DEGREE_PROGRESS_REFRESH',
          value: uid,
        });

        fetch(`/api/advising/degree_progress/ugrd/${uid}`)
          .then(res => res.json())
          .then(json =>
            dispatch({
              type: 'ADVISOR_UNDERGRAD_DEGREE_PROGRESS_SUCCESS',
              value: { ...json, uid: uid },
            })
          );
      }
    } else {
      dispatch({ type: 'ADVISOR_UNDERGRAD_DEGREE_PROGRESS_START', value: uid });

      fetch(`/api/advising/degree_progress/ugrd/${uid}`)
        .then(res => res.json())
        .then(json =>
          dispatch({
            type: 'ADVISOR_UNDERGRAD_DEGREE_PROGRESS_SUCCESS',
            value: { ...json, uid: uid },
          })
        );
    }
  };
}

export function fetchAdvisorGraduateDegreeProgress(uid) {
  return (dispatch, getState) => {
    const {
      advising: { graduateDegreeProgress = {} },
    } = getState();

    if (
      graduateDegreeProgress.loadState === 'pending' ||
      graduateDegreeProgress.loadState === 'success'
    ) {
      if (graduateDegreeProgress.uid === uid) {
        return new Promise((resolve, _reject) =>
          resolve(graduateDegreeProgress)
        );
      } else {
        dispatch({
          type: 'ADVISOR_GRADUATE_DEGREE_PROGRESS_REFRESH',
          value: uid,
        });

        fetch(`/api/advising/degree_progress/ugrd/${uid}`)
          .then(res => res.json())
          .then(json =>
            dispatch({
              type: 'ADVISOR_GRADUATE_DEGREE_PROGRESS_SUCCESS',
              value: { ...json, uid: uid },
            })
          );
      }
    } else {
      dispatch({ type: 'ADVISOR_GRADUATE_DEGREE_PROGRESS_START', value: uid });

      fetch(`/api/advising/degree_progress/grad/${uid}`)
        .then(res => res.json())
        .then(json =>
          dispatch({
            type: 'ADVISOR_GRADUATE_DEGREE_PROGRESS_SUCCESS',
            value: { ...json, uid: uid },
          })
        );
    }
  };
}
