import styled from "styled-components";

import ucBerkeleyBayView from "assets/images/uc_berkeley_bay_view.jpg";

const PreviewBox = styled.div`
  background-attachment: fixed;
  background-image: url("${ucBerkeleyBayView}");
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--xl-spacing);
`;
PreviewBox.displayName = "PreviewBox";

export default PreviewBox;
