import storedUsers from '../reducers/storedUsers';

export default function fetchStoredUsers() {
  return function(dispatch, getState) {
    const { storedUsers: { loadState } = {} } = getState();

    if (loadState == 'pending' || loadState == 'success') {
      return new Promise((resolve, _reject) => resolve(storedUsers));
    } else {
      dispatch({ type: 'STORED_USERS_START' });

      fetch(`/api/view_as/my_stored_users`)
        .then((res) => res.json())
        .then((data) => dispatch({ type: 'STORED_USERS_SUCCESS', data }));
    }
  };
}

export function refreshStoredUsers() {
  return function(dispatch, getState) {
    const { storedUsers: { loadState } = {} } = getState();

    if (loadState == 'reloading') {
      return new Promise((resolve, _reject) => resolve(storedUsers));
    } else {
      dispatch({ type: 'STORED_USERS_RELOAD' });

      fetch(`/api/view_as/my_stored_users`)
        .then((res) => res.json())
        .then((data) => dispatch({ type: 'STORED_USERS_SUCCESS', data }));
    }
  };
}
