import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import mapStateToCSRF from "utils/mapStateToCSRF";
import { deleteRecentUsers, saveUser } from "utils/requests";

import ButtonAsLink from "components/ui/ButtonAsLink";
import TableStyles from "./TableStyles";
import TableHeaders from "./TableHeaders";

function RecentUsers({ csrfToken, recentUsers, viewAsUser, refreshCard }) {
  const deleteRecent = async () => {
    const success = await deleteRecentUsers(csrfToken)();
    if (success) {
      refreshCard();
    }
  };

  const save = async (user) => {
    const success = await saveUser(csrfToken)(user);
    if (success) {
      refreshCard();
    }
  };

  return (
    <>
      <div>
        <strong>Recent Users</strong> (
        <ButtonAsLink onClick={deleteRecent}>clear all</ButtonAsLink>)
      </div>
      <TableStyles>
        <TableHeaders />
        <tbody>
          {recentUsers.map((user, index) => (
            <tr key={index}>
              <td>
                <ButtonAsLink onClick={() => viewAsUser(user)}>
                  {user.ldapUid}
                </ButtonAsLink>
              </td>
              <td>{user.studentId ? user.studentId : "none"}</td>
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td>
                {!user.saved && (
                  <ButtonAsLink onClick={() => save(user)}>save</ButtonAsLink>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </TableStyles>
    </>
  );
}

RecentUsers.displayName = "RecentUsers";
RecentUsers.propTypes = {
  csrfToken: PropTypes.string,
  recentUsers: PropTypes.array,
  viewAsUser: PropTypes.func,
  refreshCard: PropTypes.func,
};

export default connect(mapStateToCSRF)(RecentUsers);
