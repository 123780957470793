import React from "react";
import PropTypes from "prop-types";
import { useSlice } from "components/hooks/useSlice";

const formatNote = (note) =>
  `${note.note} ${note.termName}: ${note.classDescription}`;
const formatAward = (award) => award.description;

const AwardsLink = ({ awardCount }) => {
  const summaryPath = "/academics/academic_summary";

  if (awardCount === 1) {
    return <a href={summaryPath}>Academic Award</a>;
  } else {
    return <a href={summaryPath}>Academic Awards</a>;
  }
};
AwardsLink.displayName = "AwardsLink";
AwardsLink.propTypes = {
  awardCount: PropTypes.number,
};

export const LawAwards = ({ showLink }) => {
  const { awards = [], transcriptNotes = [] } = useSlice("myLawAwards");
  const awardsAndNotes = [...awards, ...transcriptNotes];
  const awardCount = awardsAndNotes.length;

  if (awardCount) {
    return (
      <>
        <dt data-testid="law-academic-awards">Academic Awards</dt>
        <dd>
          {showLink ? (
            <AwardsLink awardCount={awardCount} />
          ) : (
            <>
              {awards.map((award, index) => (
                <div key={index}>{formatAward(award)}</div>
              ))}
              {transcriptNotes.map((note, index) => (
                <div key={index}>{formatNote(note)}</div>
              ))}
            </>
          )}
        </dd>
      </>
    );
  }

  return null;
};

LawAwards.displayName = "LawAwards";
LawAwards.propTypes = {
  showLink: PropTypes.bool,
};
LawAwards.defaultProps = {
  showLink: false,
};
