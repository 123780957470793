import getJSON from "../utils/getJSON";

const headers = () => ({
  "Content-Type": "application/json",
  "x-csrf-token": document
    .querySelector("meta[name=csrf-token]")
    .getAttribute("content"),
});

const apiRequest = (method) => (path) => (values) => {
  return fetch(path, {
    method: method,
    headers: headers(),
    body: JSON.stringify(values),
  }).then((response) =>
    response.json().then((data) => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    })
  );
};

const post = apiRequest("POST");
const put = apiRequest("PUT");

const destroy = (path) => {
  return fetch(path, {
    method: "DELETE",
    headers: headers(),
  });
};

const findUserAuth = (uid) => getJSON(`/api/user_auths?uid=${uid}`);

const createUserAuth = (values) =>
  post("/api/user_auths")({ user_auth: values });

const updateUserAuth = (id, values) =>
  put(`/api/user_auths/${id}`)({ user_auth: values });

const destroyUserAuth = (id) => destroy(`/api/user_auths/${id}`);

export { findUserAuth, createUserAuth, updateUserAuth, destroyUserAuth };
