import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LightText from "components/ui/LightText";

import styled from "styled-components";
import { ClockIcon } from "components/ui/Icons";
import { useHistoricAcademicRoles } from "components/hooks/useAcademicRoles";
import { useUser } from "components/hooks/useUser";
export const GreyClockIcon = styled(ClockIcon)`
  color: var(--gumbo);
  margin-right: 4px;
`;

export const UndergraduateGraduation = ({ showCheckListLink }) => {
  const { degreeSeeking, summerVisitor } = useHistoricAcademicRoles(
    (roles) => roles
  );
  const {
    graduation: { undergraduate: undergraduateGraduation } = {},
    termsInAttendance,
  } = useUser((user) => user.academicProfile);

  const isNonDegreeSeekingSummerVisitor = summerVisitor && !degreeSeeking;

  if (
    undergraduateGraduation === null ||
    undergraduateGraduation === undefined
  ) {
    return null;
  }

  const {
    appointmentsInGraduatingTerm = false,
    showGraduationChecklist,
    termName,
  } = undergraduateGraduation;

  if (termName && !isNonDegreeSeekingSummerVisitor) {
    return (
      <>
        <dt data-testid="undergraduate-graduation">Terms Information</dt>
        <dd>
          {termsInAttendance && (
            <div className="cc-section-block">
              <LightText>Terms in Attendance</LightText>
              <div>{termsInAttendance}</div>
            </div>
          )}
          <div className="cc-section-block">
            <LightText>Expected Graduation</LightText>
            <span>
              {appointmentsInGraduatingTerm && <GreyClockIcon />}
              <strong>
                <span>{termName}</span>
              </strong>
            </span>
            {showCheckListLink && (
              <div className="cc-widget-profile-footnote">
                {showGraduationChecklist ? (
                  <Fragment>
                    <a href="/academics/graduation_checklist">
                      <strong>View Graduation Checklist</strong>
                    </a>
                    <br />

                    <Fragment>
                      {termName}
                      &nbsp;will be your final term to complete all degree
                      requirements. If you have questions, please contact your
                      College Advisor.
                    </Fragment>
                  </Fragment>
                ) : (
                  <Fragment>
                    Consult your college advisor with questions or concerns.
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </dd>
      </>
    );
  } else {
    return null;
  }
};

UndergraduateGraduation.displayName = "UndergraduateGraduation";
UndergraduateGraduation.propTypes = {
  showCheckListLink: PropTypes.bool,
};
