"use strict";

import { fetchFinancialAidProfile } from "data/actions/financialAid/financialAidProfileActions";

angular
  .module("calcentral.controllers")
  .controller(
    "FinaidProfileController",
    function ($scope, $ngRedux, $routeParams) {
      $scope.finaidProfile = {};

      $ngRedux.subscribe(() => {
        const {
          financialAid: {
            profile: { [$routeParams.finaidYearId]: finaidYearProfile = {} },
          },
        } = $ngRedux.getState();

        const clonedFinaidYearProfile = structuredClone(finaidYearProfile);

        $scope.finaidProfile = transformYearProfile(clonedFinaidYearProfile);
        $scope.finaidProfile.isLoading = !!clonedFinaidYearProfile.isLoading;
      });

      $ngRedux.dispatch(
        fetchFinancialAidProfile($routeParams.finaidYearId || "")
      );
    }
  );

function transformYearProfile(yearProfile) {
  if (yearProfile.isLoading === false && !yearProfile.error) {
    const aidYearId = parseInt(yearProfile.aidYear);

    return {
      ...yearProfile,
      itemGroups: yearProfile.itemGroups.map((group) =>
        group.filter(filterItems(aidYearId)).map(transformTitles(aidYearId))
      ),
    };
  }

  return yearProfile;
}

const filterItems = (finaidYearId) => (item) => {
  if (finaidYearId >= 2025) {
    if (item.title === "Berkeley Parent Contribution") {
      return false;
    }

    if (item.title === "Family Members in College") {
      return false;
    }
  }

  return true;
};

const transformTitles = (finaidYearId) => (item) => {
  if (finaidYearId >= 2025) {
    if (item.title.match("EFC")) {
      return {
        title: item.title
          .replace("EFC", "SAI")
          .replace("Expected Family Contribution", "Student Aid Index"),
        value: item.value && item.value.replace("$ ", ""),
      };
    }
  }

  return item;
};
