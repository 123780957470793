import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export function ProgressLink({ title, icon, children }) {
  return (
    <LinkStyles>
      {icon}

      <div className="body">
        <div className="title">{title}</div>
        {children}
      </div>
    </LinkStyles>
  );
}

ProgressLink.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
};

const LinkStyles = styled.div`
  display: flex;

  .body {
    flex: 1;
  }

  .title {
    color: var(--dove-gray);
    display: flex;
    font-size: 13px;
    font-weight: bold;
  }
`;

export const LinkSubTitle = styled.div`
  color: var(--mine-shaft);
  font-size: 11px;
  font-weight: 400;
`;
