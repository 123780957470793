import asyncRequest from "./asyncRequest";

const post = asyncRequest("POST");
const put = asyncRequest("PUT");

export function deleteRecentUsers(csrfToken) {
  return async function () {
    const res = await post(`/api/view_as/delete_all_recent`, csrfToken)();
    return res.ok;
  };
}

export function deleteSavedUsers(csrfToken) {
  return async function () {
    const res = await post(`/api/view_as/delete_all_saved`, csrfToken)();
    return res.ok;
  };
}

export function deleteSavedUser(csrfToken) {
  return async function (user) {
    const res = await post(
      `/api/view_as/delete_saved`,
      csrfToken
    )({
      uid: user.ldapUid.toString(),
    });

    return res.ok;
  };
}

export function saveUser(csrfToken) {
  return async function (user) {
    const res = await post(
      `/api/view_as/store_user_as_saved`,
      csrfToken
    )({
      uid: user.ldapUid.toString(),
    });

    const { success } = await res.json();
    return success;
  };
}

export function viewAsUID(csrfToken) {
  return async function (uid) {
    return await post("/act_as", csrfToken)({ uid });
  };
}

export function advisorViewAsUID(csrfToken) {
  return async function (uid) {
    return await post("/advisor_act_as", csrfToken)({ uid });
  };
}

export function delegateViewAsUID(csrfToken) {
  return async function (uid) {
    return await post("/delegate_act_as", csrfToken)({ uid });
  };
}

export function saveRecentUser(csrfToken) {
  return async function (user) {
    const res = await post(
      `/api/view_as/store_user_as_recent`,
      csrfToken
    )({
      uid: user.ldapUid.toString(),
    });

    const { success } = await res.json();
    return success;
  };
}

export function updateServiceAlert(csrfToken) {
  return async function (id, values) {
    const res = await put(
      `/api/service_alerts/${id}`,
      csrfToken
    )({
      service_alert: values,
    });

    return res;
  };
}
