import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import SemesterStatuses from "components/../react/components/_academics/StatusAndHoldsCard/SemesterStatuses";

const NgSemesterStatuses = () => (
  <ReduxProvider>
    <SemesterStatuses />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("semesterStatuses", react2angular(NgSemesterStatuses));
