import styled from "styled-components";
import "icons/blue-bullet.svg";

import bulletIcon1 from "icons/bullet-icon-level-1.svg";
import bulletIcon2 from "icons/bullet-icon-level-2.svg";
import bulletIcon3 from "icons/bullet-icon-level-3.svg";

export const BulletList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    position: relative;
    list-style-type: none;
    line-height: 20px;
    margin-left: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -20px;
    width: 13px;
    height: 13px;
  }

  li:before {
    background-image: url(${bulletIcon1});
  }

  ul {
    li:before {
      background-image: url(${bulletIcon2});
    }

    ul {
      li:before {
        background-image: url(${bulletIcon3});
      }
    }
  }
`;
BulletList.displayName = "BulletList";

export const DashedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:before {
    content: "–";
    margin-right: 5px;
  }
`;
DashedList.displayName = "DashedList";

export const UnorderedIconList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  flex-direction: column;
  gap: var(--md);
`;
UnorderedIconList.displayName = "UnorderedIconList";

export const UnstyledList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
`;
UnstyledList.displayName = "UnstyledList";

export const ListBullets = styled.div`
  margin: 10px 10px 0 15px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li::before {
      color: var(--hoki);
      content: "\\25A0";
      float: left;
      margin-left: -16px;
      margin-top: -2px;
      padding-right: 8px;
    }
  }
`;
ListBullets.displayName = "ListBullets";
