import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import ProfilePicture from "./ProfilePicture";
import { Pronouns } from "./Pronouns";

const NameHeading = styled.h3`
  font-size: 16px;
`;

const NameAndPhoto = () => {
  const { fullName, isDelegate } = useSelector((state) => {
    const {
      myStatus: { delegateActingAsUid } = {},
      userProfile: { fullName } = {},
    } = state;
    const isDelegate = !!delegateActingAsUid;

    return { fullName, isDelegate };
  });

  return (
    <tr className="NameAndPhoto">
      <th>
        <ProfilePicture isDelegate={isDelegate} />
      </th>
      <td>
        <NameHeading>{fullName}</NameHeading>
        <Pronouns />
      </td>
    </tr>
  );
};

NameAndPhoto.displayName = "NameAndPhoto";
export default NameAndPhoto;
