// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".finalExams___EOoP4{margin-top:20px}.title___kojr1{font-size:15px;line-height:17px}.finalsTable___grLjI tbody tr th{white-space:nowrap;padding-right:15px;vertical-align:top;font-weight:normal;color:var(--dove-gray)}", "",{"version":3,"sources":["webpack://./src/react/components/_academics/ClassInformationCard/FinalExams.module.scss"],"names":[],"mappings":"AAKA,oBACE,eAAA,CAGF,eARE,cAAA,CACA,gBAAA,CAaE,iCACE,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["@mixin title2 {\n  font-size: 15px;\n  line-height: 17px;\n}\n\n.finalExams {\n  margin-top: 20px;\n}\n\n.title {\n  @include title2;\n}\n\n.finalsTable {\n  tbody {\n    tr th {\n      white-space: nowrap;\n      padding-right: 15px;\n      vertical-align: top;\n      font-weight: normal;\n      color: var(--dove-gray);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finalExams": "finalExams___EOoP4",
	"title": "title___kojr1",
	"finalsTable": "finalsTable___grLjI"
};
export default ___CSS_LOADER_EXPORT___;
