import PropTypes from "prop-types";
import { serverDateShape } from "./serverDateShape";

export const bCalScheduleItemShape = PropTypes.shape({
  isAllDay: PropTypes.bool,
  location: PropTypes.string,
  start: serverDateShape,
  end: serverDateShape,
  summary: PropTypes.string,
});
