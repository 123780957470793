import {
  SET_CURRENT_ROUTE_PROPERTIES,
  FETCH_ADVISING_STATUS_AND_HOLDS_START,
  FETCH_ADVISING_STATUS_AND_HOLDS_SUCCESS,
  FETCH_ADVISING_STATUS_AND_HOLDS_FAILURE,
} from "../../action-types";

export default function statusAndHolds(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_ROUTE_PROPERTIES:
      return {};
    case FETCH_ADVISING_STATUS_AND_HOLDS_START:
      return { ...state, isLoading: true, error: null };
    case FETCH_ADVISING_STATUS_AND_HOLDS_SUCCESS:
      return {
        ...state,
        ...action.value,
        loaded: true,
        isLoading: false,
        error: null,
      };

    case FETCH_ADVISING_STATUS_AND_HOLDS_FAILURE:
      return { ...state, isLoading: false, error: action.value };

    default:
      return state;
  }
}
