import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import useStatusPopover from "./useStatusPopover";

const PopoverContainerStyles = styled.div`
  position: relative;
  display: flex;

  // TODO: remove after React transition
  z-index: 90;
`;

function PopoverContainer({ children }) {
  const { isOpen, toggle, ref, triggerRef } = useStatusPopover();

  return (
    <PopoverContainerStyles>
      {children({
        isOpen,
        toggle,
        triggerRef,
        ref,
      })}
    </PopoverContainerStyles>
  );
}

PopoverContainer.propTypes = {
  children: PropTypes.func,
};

export default PopoverContainer;
