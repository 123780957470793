import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { ViewAsCard } from "components/cards/ViewAsCard";

function NgViewAsCard() {
  return (
    <ReduxProvider>
      <div style={{ marginTop: `15px`, marginRight: `15px` }}>
        <ViewAsCard />
      </div>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("viewAsCard", react2angular(NgViewAsCard));
