import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { usdFilter } from "components/../react/helpers/filters";

export default connect(mapStateToProps)(DueNow);

function mapStateToProps({
  billingActivity: {
    summary: { amountDueNow, pastDueAmount },
  },
}) {
  return { amountDueNow, pastDueAmount };
}

function DueNow({ amountDueNow, pastDueAmount }) {
  const pastDue = parseFloat(pastDueAmount);

  return (
    <DueStyles>
      Due Now
      <h2>{usdFilter(amountDueNow)}</h2>
      {pastDue > 0 && <p>Includes Overdue: {usdFilter(pastDueAmount)}</p>}
    </DueStyles>
  );
}

DueNow.propTypes = {
  amountDueNow: PropTypes.number,
  pastDueAmount: PropTypes.number,
};

const DueStyles = styled.div`
  background-color: var(--foam);
  padding: var(--large-spacing);

  h2 {
    font-size: 26px;
  }

  p {
    color: var(--thunderbird)
    margin: 0;
  }
`;
