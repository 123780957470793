import styled from "styled-components";

import selectArrow from "components/../assets/images/select_arrow_20x28.svg";

export const InlineSelect = styled.select`
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: url("${selectArrow}") no-repeat right center,
    linear-gradient(var(--white), var(--gallery));
  border: 1px solid var(--silver);
  border-radius: 5px;
  cursor: pointer;
  display: inline;
  height: 25px;
  overflow: hidden;
  padding: 4px 25px 5px 10px;
  width: auto;
`;
InlineSelect.displayName = "InlineSelect";
