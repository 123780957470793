import React from "react";

import { useSelector } from "react-redux";

import APILink from "components/ui/APILink";
import { SchedulePlannerLink } from "./SchedulePlannerLink";
import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const AdvisingResources = () => {
  const { resourceLinks } = useSelector((state) => {
    const {
      advising: { resources = {} },
    } = state;
    const { resourceLinks = [] } = resources;
    return {
      resourceLinks,
    };
  });

  if (resourceLinks.length > 0) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>Advising Resources </StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {resourceLinks.map((link, i) => {
            return (
              <APILink
                key={i}
                link={{
                  ...link,
                  showNewWindow: true,
                }}
                text={name}
              />
            );
          })}
          <SchedulePlannerLink />
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
AdvisingResources.displayName = "AdvisingResources";
