import React from "react";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { fetchNewAdmitResources } from "data/slices/users/newAdmitResources";
import { useUser } from "components/hooks/useUser";
import { useSlice } from "components/hooks/useSlice";
import { APILink } from "components/ui/APILink";
import { Accordion } from "components/ui/ChevronAccordion";
import { AdmitResource } from "components/cards/NewAdmitResourcesCard/AdmitResource";
import {
  AdmitBulletLinks,
  FlexBulletList,
} from "./NewAdmitResourcesCard/AdmitBulletLinks";

export function NewAdmitResourcesCard() {
  dispatchUserSlice(fetchNewAdmitResources);

  const { delegateActingAsUid = false, roles: { releasedAdmit } = {} } =
    useSlice("myStatus");
  const newAdmitResources = useUser((user) => user.newAdmitResources || {});
  const {
    visible,
    links: {
      map: { status: { url } = {} } = {},
      finAid = {},
      admissions = {},
      entryLevelWriting = {},
      general = {},
      firstYearPathways = {},
    } = {},
    admissionsEvaluator,
    showEntryLevelWriting,
    showFirstYearPathwayOptions,
  } = newAdmitResources;
  const showCard = !delegateActingAsUid && releasedAdmit && visible;

  if (showCard) {
    return (
      <Card>
        <CardTitle>
          <h2>New Admit Resources</h2>
        </CardTitle>
        <CardBody>
          <p>
            Important admissions documents and forms are available in{" "}
            <a href={url}>MAP@berkeley.edu.</a>
          </p>
          <Accordion collapsible multiple>
            <AdmitResource title="Financial Aid &amp; Scholarships">
              <AdmitBulletLinks links={finAid} />
            </AdmitResource>

            <AdmitResource title="Admissions Changes">
              <AdmitBulletLinks links={admissions} />

              {admissionsEvaluator.name && admissionsEvaluator.email && (
                <>
                  <h4>Admissions Officer</h4>
                  <p>{admissionsEvaluator.name}</p>
                  <a href={`mailto:${admissionsEvaluator.email}`}>
                    {admissionsEvaluator.email}
                  </a>
                  <br />
                  <br />
                  <p>
                    Contact if you have questions regarding your conditions of
                    admission.
                  </p>
                </>
              )}
            </AdmitResource>

            {showFirstYearPathwayOptions && (
              <AdmitResource title="First-Year Pathways">
                <FlexBulletList>
                  {firstYearPathways?.selectionForm?.url && (
                    <li>
                      <APILink link={firstYearPathways.selectionForm} />
                      <p>
                        Sign up for a pathway while space is available. Learn
                        about <APILink link={firstYearPathways.pathwaysInfo} />.
                      </p>
                    </li>
                  )}

                  {firstYearPathways?.pathwaysFinAid?.url && (
                    <li>
                      <APILink link={firstYearPathways.pathwaysFinAid} />
                      {firstYearPathways.pathwaysFinAid.linkDescription && (
                        <p>
                          {firstYearPathways.pathwaysFinAid.linkDescription}
                        </p>
                      )}
                    </li>
                  )}
                </FlexBulletList>
              </AdmitResource>
            )}

            <AdmitResource title="Your Questions Answered Here">
              <AdmitBulletLinks links={general} />
            </AdmitResource>

            {showEntryLevelWriting && (
              <AdmitResource title="Entry Level Writing Update">
                <AdmitBulletLinks links={entryLevelWriting} />
              </AdmitResource>
            )}
          </Accordion>
        </CardBody>
      </Card>
    );
  }

  return null;
}
