import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import closeXIcon from "icons/close-x.svg";

const Popover = styled.div`
  position: absolute;
  top: 2rem;
  left: 0;
  border: 1px solid #ccc;
  background: white;
  width: 100%;
  z-index: 10;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  padding: 15px;

  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 13px;
      margin-top: 0;
    }

    button {
      height: 10px;
    }
  }
`;

function EarlyDropPopover({ message, setPopoverOpen, popoverRef }) {
  return (
    <Popover ref={popoverRef}>
      <div className="header">
        <h2>Early Drop Deadline</h2>
        <button
          className="cc-button-link"
          onClick={() => setPopoverOpen(false)}
        >
          <img src={closeXIcon} />
        </button>
      </div>

      <div
        dangerouslySetInnerHTML={{
          __html: message.descrlong,
        }}
      />
    </Popover>
  );
}

EarlyDropPopover.propTypes = {
  popoverRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  setPopoverOpen: PropTypes.func,
  message: PropTypes.shape({
    descrlong: PropTypes.string,
    messageText: PropTypes.string,
  }),
};

const mapStateToProps = ({ currentUID, users }) => {
  const { enrollmentResources: { messages: { earlyDropDeadline } = {} } = {} } =
    users[currentUID] || {};
  return { message: earlyDropDeadline };
};

export default connect(mapStateToProps)(EarlyDropPopover);
