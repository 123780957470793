import React from "react";
import PropTypes from "prop-types";
import APILink from "./APILink";
import WithAPILinkData from "./WithAPILinkData";

export default function WithAPILink({ children, urlID, as = "a", text }) {
  return (
    <WithAPILinkData urlID={urlID}>
      {(link) => (
        <APILink link={link} as={as} text={text}>
          {children}
        </APILink>
      )}
    </WithAPILinkData>
  );
}

WithAPILink.propTypes = {
  children: PropTypes.func,
  urlID: PropTypes.string,
  as: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]),
  text: PropTypes.string,
};
