import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import { FinancialAidAndScholarshipsProfileCard } from "components/cards/FinancialAidAndScholarshipsProfileCard";

const NgFinancialAidAndScholarshipsProfileCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="/finances/finaid/:finaidYearId"
          element={<FinancialAidAndScholarshipsProfileCard />}
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "financialAidAndScholarshipsProfileCard",
    react2angular(NgFinancialAidAndScholarshipsProfileCard)
  );
