import { buildFinaidSlice } from "data/utils/buildSlice";
import { finaidDataThunk } from "data/utils/thunks";
import { routes } from "data/routes";
import { finaidRestRequest } from "data/utils/requests";

const key = "finaidProfile";

export const slice = buildFinaidSlice(key);
export const { reducer: finaidProfile } = slice;
export const fetchFinaidProfile = finaidDataThunk(key, {
  request: finaidRestRequest({ url: routes[key] }),
  dataHandler:
    ({ actions, dispatch, uid, finaidYearId }) =>
    ({ finaidProfile }) => {
      dispatch(actions.success({ uid, finaidYearId, data: finaidProfile }));
    },
  slice,
});
