import { advisingStudentProfileQuery } from "data/queries/advisingStudentProfile";
import { buildUserSlice } from "data/utils/buildSlice";
import { userDataThunk } from "data/utils/thunks";
import { userGQLRequest } from "data/utils/requests";

const key = "advisingStudentProfile";
export const slice = buildUserSlice(key);
export const { reducer: advisingStudentProfile } = slice;
export const fetchAdvisingStudentProfile = userDataThunk(key, slice, {
  request: userGQLRequest(advisingStudentProfileQuery),
});
