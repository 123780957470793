import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import getUserLinks from "data/actions/getUserLinks";
import WithAPILink from "components/ui/WithAPILink";

import "./SectionIncompleteGradingStatus.scss";

/**
 * Component for displaying a sections incomplete grading status
 *
 * Note: When updating this component, please also update academicSectionIncompleteGradingStatus
 * which shares this functionality.
 *
 * React2Angular components do not work properly within ng-repeat-start and ng-repeat-end loops,
 * so an AngularJS directive was needed.
 *
 * TODO: Replace with this component once all cards using academicSectionIncompleteGradingStatus
 * have been refactored into React components.
 */
export default function SectionIncompleteGradingStatus({
  gradingBasis,
  gradingLapseDeadline,
  gradingLapseDeadlineDisplay,
  lapseDateDisplayColumnIndex,
  frozenDisplayColumnIndex,
  totalColumns,
}) {
  const dispatch = useDispatch();

  const { csrfToken } = useSelector((state) => {
    const { config: { csrfToken } = {} } = state;
    return { csrfToken };
  });

  useEffect(() => {
    if (csrfToken) {
      dispatch(getUserLinks(csrfToken));
    }
  }, [dispatch, csrfToken]);

  const showGradingLapseDeadline =
    gradingLapseDeadlineDisplay && gradingLapseDeadline;
  const gradingBasisIsFrozen = gradingBasis === "FRZ";
  const columnIndexes = [...Array(totalColumns).keys()];
  const showSingleColumn =
    (showGradingLapseDeadline && lapseDateDisplayColumnIndex === 0) ||
    (gradingBasisIsFrozen && frozenDisplayColumnIndex === 0);
  const displayColumnIndex =
    (showGradingLapseDeadline && lapseDateDisplayColumnIndex) ||
    (gradingBasisIsFrozen && frozenDisplayColumnIndex);

  const showStatus = () => {
    return (
      <>
        {showGradingLapseDeadline && (
          <>
            <WithAPILink
              urlID="UC_CX_ACAD_GUIDE_GRADES"
              text="Student Completion Deadline is 30 days before"
            />
            {": " + gradingLapseDeadline}
          </>
        )}
        {gradingBasisIsFrozen && (
          <WithAPILink urlID="UC_CX_ACAD_GUIDE_GRADES" text="Frozen" />
        )}
      </>
    );
  };

  if (showGradingLapseDeadline || gradingBasisIsFrozen) {
    return (
      <tr data-testid="academic-summary-semester-section-incomplete-grading-status">
        {showSingleColumn && (
          <td
            className="SectionIncompleteGradingStatus__table_cell"
            colSpan={totalColumns}
          >
            {showStatus()}
          </td>
        )}
        {!showSingleColumn &&
          columnIndexes.map((index) => (
            <td
              className="SectionIncompleteGradingStatus__table_cell"
              key={index}
            >
              {displayColumnIndex == index && showStatus()}
              {displayColumnIndex !== index && "\u00A0"}
            </td>
          ))}
      </tr>
    );
  } else {
    return null;
  }
}

SectionIncompleteGradingStatus.propTypes = {
  gradingBasis: PropTypes.string.isRequired,
  gradingLapseDeadline: PropTypes.string,
  gradingLapseDeadlineDisplay: PropTypes.bool.isRequired,
  lapseDateDisplayColumnIndex: PropTypes.number.isRequired,
  frozenDisplayColumnIndex: PropTypes.number.isRequired,
  totalColumns: PropTypes.number,
};
