import React from "react";
import PropTypes from "prop-types";
import AccessibleLinkContent from "./AccessibleLinkContent";

export default function OutboundLink({ children, href, ...rest }) {
  return (
    <a href={href} {...rest} target="_blank" rel="noopener noreferrer">
      <AccessibleLinkContent showNewWindow={true}>
        {children}
      </AccessibleLinkContent>
    </a>
  );
}

OutboundLink.displayName = "OutboundLink";
OutboundLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};
