import React, { useState } from "react";
import PropTypes from "prop-types";
import { columnHeaderPropTypeShape } from "./propTypes";
import VisuallyHidden from "components/ui/VisuallyHidden";

import { useCurrentWidth } from "react-socks";
import { largeBreakpoint, mediumBreakpoint } from "config/media";

import styled from "styled-components";
import { CCTable } from "components/ui/Table";

export const StyledSortButton = styled.button`
  margin: 0;
  padding: 0;
`;

export const EnrollmentTableContainer = styled.div`
  border: 3px solid var(--gallery);
`;

export const EnrollmentTable = styled(CCTable)`
  thead {
    background-color: var(--dove-gray);
    margin: var(--sm);

    tr {
      td,
      th {
        padding: var(--sm);
        color: var(--white);
        background-color: var(--dove-gray);
        vertical-align: bottom;

        button {
          background: transparent;
          text-align: left;
          width: 100%;
          border: none;
          color: inherit;
        }
      }
    }
  }

  tbody {
    tr {
      td,
      th {
        padding: var(--sm);
      }

      &:nth-child(even) {
        td,
        th {
          background: var(--gallery);
        }
      }
    }
  }
`;

export const EnrollmentTableHeader = function ({
  columnHeaders,
  isWaitlistMode,
  setSortColumn,
  setSortDirection,
  sortDirection,
  sortColumn,
  toggleAllSelectedStudents,
}) {
  const browserWidth = useCurrentWidth();

  const waitlistPositionColumns = isWaitlistMode
    ? [{ id: "waitlist_position", label: "Pos" }]
    : [];

  const sortByPath = (column) => (_event) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSortColumn(column);
      setSortDirection("ASC");
    }
  };

  const staticColumns1 = [
    { id: "last_name", label: "Name", breakpoint: undefined },
    { id: "email", label: "Email", breakpoint: undefined },
    { id: "majors", label: "Majors", breakpoint: mediumBreakpoint },
  ];

  const dynamicColumns = columnHeaders.map(function (column, columnIndex) {
    return {
      id: `columns[${columnIndex}].section_number`,
      label: column.instruction_format,
      breakpoint: mediumBreakpoint,
    };
  });

  const staticColumns2 = [
    { id: "terms_in_attendance", label: "Terms", breakpoint: largeBreakpoint },
    { id: "units", label: "Units", breakpoint: largeBreakpoint },
    { id: "grade_option", label: "Grade", breakpoint: largeBreakpoint },
    { id: "student_id", label: "SID", breakpoint: largeBreakpoint },
  ];

  const columns = waitlistPositionColumns
    .concat(staticColumns1)
    .concat(dynamicColumns)
    .concat(staticColumns2);

  const [allSelected, setAllSelected] = useState(false);

  const toggle = () => {
    setAllSelected(!allSelected);
    toggleAllSelectedStudents(!allSelected);
  };

  return (
    <thead>
      <tr>
        <th scope="col">
          <VisuallyHidden>Selectors</VisuallyHidden>
          <input
            checked={allSelected}
            data-testid="enrollment-table-toggle-all"
            type="checkbox"
            name="toggleAll"
            onChange={toggle}
          />
        </th>

        {columns &&
          columns.map((column, columnIndex) => {
            const columnBreakpoint = column.breakpoint;
            if (columnBreakpoint && browserWidth < columnBreakpoint) {
              return null;
            }
            return (
              <th scope="col" key={columnIndex}>
                <SortButton
                  column={column.id}
                  sortByPath={sortByPath}
                  sortDirection={sortDirection}
                  sortColumn={sortColumn}
                >
                  <strong>{column.label}</strong>
                </SortButton>
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

EnrollmentTableHeader.propTypes = {
  columnHeaders: PropTypes.arrayOf(columnHeaderPropTypeShape),
  isWaitlistMode: PropTypes.bool,
  setSortColumn: PropTypes.func,
  setSortDirection: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  toggleAllSelectedStudents: PropTypes.func,
};

export const SortButton = function ({
  children,
  column,
  sortByPath,
  sortColumn,
  sortDirection,
}) {
  return (
    <StyledSortButton onClick={sortByPath(column)}>
      {children}{" "}
      <SortIndicator
        column={column}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
    </StyledSortButton>
  );
};

SortButton.propTypes = {
  children: PropTypes.node,
  column: PropTypes.string,
  sortByPath: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
};

function SortIndicator({ column, sortColumn, sortDirection }) {
  if (column === sortColumn) {
    if (sortDirection === "ASC") {
      return <span className="fa fa-caret-down" />;
    } else if (sortDirection === "DESC") {
      return <span className="fa fa-caret-up" />;
    }
  }
  return null;
}

SortIndicator.propTypes = {
  column: PropTypes.string,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
};
