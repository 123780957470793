// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sectionHeader___b9kxi{padding:5px 10px 5px 6px;display:flex;justify-content:space-between;color:var(--dusty-gray);border-left:4px solid rgba(0,0,0,0)}.sectionHeader___b9kxi.leftBorder___Qn6Fd{border-left:4px solid var(--silver)}.columnLabel___wyrTR{font-size:12px;font-weight:700}.columnLabel___wyrTR:first-child{margin-left:34px}.columnLabel___wyrTR:last-child{margin-right:20px;text-align:right}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/SectionHeader.module.scss"],"names":[],"mappings":"AAEA,uBACE,wBAAA,CACA,YAAA,CACA,6BAAA,CACA,uBAAA,CACA,mCAAA,CAEA,0CACE,mCAAA,CAIJ,qBACE,cAAA,CACA,eAAA,CAEA,iCACE,gBAAA,CAGF,gCACE,iBAAA,CACA,gBAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.sectionHeader {\n  padding: 5px 10px 5px 6px;\n  display: flex;\n  justify-content: space-between;\n  color: var(--dusty-gray);\n  border-left: 4px solid transparent;\n\n  &.leftBorder {\n    border-left: 4px solid var(--silver);\n  }\n}\n\n.columnLabel {\n  font-size: 12px;\n  font-weight: 700;\n\n  &:first-child {\n    margin-left: 34px;\n  }\n\n  &:last-child {\n    margin-right: 20px;\n    text-align: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionHeader": "sectionHeader___b9kxi",
	"leftBorder": "leftBorder___Qn6Fd",
	"columnLabel": "columnLabel___wyrTR"
};
export default ___CSS_LOADER_EXPORT___;
