import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

const TeachingButtonLink = ({ to, children }) => {
  const className = "cc-button cc-academics-class-info-category-button";
  const activeClassName = "cc-button-selected cc-button-selected-roster";
  return (
    <NavLink
      exact
      to={to}
      className={className}
      activeClassName={activeClassName}
      role="tab"
      aria-current="true"
    >
      {children}
    </NavLink>
  );
};

TeachingButtonLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

function TeachingButtonGroup({
  classInfoEnrollmentTabEnabled,
  hasRosterAccess,
}) {
  if (hasRosterAccess) {
    const { semesterSlug, classId } = useParams();
    const classUrl = `/academics/teaching-semester/${semesterSlug}/class/${classId}`;
    const buttonsShown = classInfoEnrollmentTabEnabled ? 3 : 2;

    return (
      <ul className={`cc-button-group cc-even-${buttonsShown}`} role="tablist">
        <li>
          <TeachingButtonLink to={classUrl}>Class Info</TeachingButtonLink>
        </li>

        {classInfoEnrollmentTabEnabled && (
          <li>
            <TeachingButtonLink to={`${classUrl}/enrollment`}>
              Enrollment
            </TeachingButtonLink>
          </li>
        )}

        <li>
          <TeachingButtonLink to={`${classUrl}/roster`}>
            Roster
          </TeachingButtonLink>
        </li>
      </ul>
    );
  }

  return null;
}

TeachingButtonGroup.propTypes = {
  classInfoEnrollmentTabEnabled: PropTypes.bool,
  hasRosterAccess: PropTypes.bool,
};

function mapStateToProps({
  myStatus: { features: { classInfoEnrollmentTab } = {} } = {},
  myAcademics: { hasRosterAccess = false } = {},
}) {
  return {
    hasRosterAccess,
    classInfoEnrollmentTabEnabled: classInfoEnrollmentTab,
  };
}

export default connect(mapStateToProps)(TeachingButtonGroup);
