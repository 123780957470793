import React from "react";
import PropTypes from "prop-types";

import { ExamRow } from "./ExamRow";
import { AcademicSummaryTable } from "components/cards/AcademicSummaryCard/AcademicSummaryTable";

const sum = (acc, value) => acc + value;
const totalExamUnits = ({
  apTestUnits,
  ibTestUnits,
  alevelTestUnits,
  otherCreditUnits,
}) => {
  return [apTestUnits, ibTestUnits, alevelTestUnits, otherCreditUnits].reduce(
    sum
  );
};

export const ExamTransferCredit = ({ summary }) => {
  if (totalExamUnits(summary) > 0) {
    const { apTestUnits, ibTestUnits, alevelTestUnits, otherCreditUnits } =
      summary;

    return (
      <AcademicSummaryTable>
        <thead>
          <tr>
            <th>Exam/Other Credits</th>
            <th className="text-right">Units</th>
          </tr>
        </thead>
        <tbody>
          <ExamRow name="Advanced Placement (AP)" value={apTestUnits} />
          <ExamRow
            name="International Baccalaureate (IB)"
            value={ibTestUnits}
          />
          <ExamRow
            name="GCE Advanced-Level (A-level)"
            value={alevelTestUnits}
          />
          <ExamRow name="Other Credit" value={otherCreditUnits} />

          <tr>
            <td className="text-right">Total Exam Units:</td>
            <td className="text-right">
              <strong>{totalExamUnits(summary).toFixed(3)}</strong>
            </td>
          </tr>
        </tbody>
      </AcademicSummaryTable>
    );
  }

  return null;
};

ExamTransferCredit.propTypes = {
  summary: PropTypes.object,
};
