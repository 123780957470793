import { gql } from 'graphql-request';
import graphqlClient from 'utils/graphqlClient';

import {
  TUITION_COHORT_START,
  TUITION_COHORT_SUCCESS,
  TUITION_COHORT_FAILURE,
} from '../action-types';

export function getTuitionCohort() {
  const query = gql`
    {
      user {
        tuitionCohort {
          startTerm
          endTerm
          messageHTML
        }
      }
    }
  `;

  return (dispatch, getState) => {
    const { csrfToken, tuitionCohort } = getState();

    if (
      tuitionCohort.loadState === 'pending' ||
      tuitionCohort.loadState === 'success' ||
      tuitionCohort.loadState === 'failure'
    ) {
      return new Promise((resolve, _reject) => resolve(tuitionCohort));
    } else {
      dispatch({ type: TUITION_COHORT_START });

      graphqlClient(csrfToken)
        .request(query)
        .then(({ user: { tuitionCohort } = {} }) => {
          dispatch({ type: TUITION_COHORT_SUCCESS, value: tuitionCohort });
        })
        .catch(_err => {
          dispatch({ type: TUITION_COHORT_FAILURE });
        });
    }
  };
}
