import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import fetchServiceAlerts from "data/actions/serviceAlertsActions";

import SplashContainer from "components/ui/SplashContainer";
import SplashAlert from "components/ServiceAlerts/SplashAlert";
import SpinnerIf from "components/ui/SpinnerIf";

export default connect(mapStateToProps, mapDispatchToProps)(SplashPageAlerts);

function SplashPageAlerts({
  fetchServiceAlerts,
  serviceAlerts,
  loadState,
  csrfToken,
}) {
  useEffect(() => {
    if (csrfToken) {
      fetchServiceAlerts(csrfToken);
    }
  }, [csrfToken]);

  const splashAlerts = serviceAlerts.filter((alert) => alert.splash);

  return (
    <SpinnerIf loading={loadState === "pending"}>
      {splashAlerts.length > 0 && (
        <SplashContainer>
          <div className="cc-splash-page-note-title">CalCentral News</div>
          {splashAlerts.map((alert) => (
            <SplashAlert key={alert.id} serviceAlert={alert} />
          ))}
        </SplashContainer>
      )}
    </SpinnerIf>
  );
}

SplashPageAlerts.propTypes = {
  csrfToken: PropTypes.string,
  fetchServiceAlerts: PropTypes.func,
  serviceAlerts: PropTypes.array,
  loadState: PropTypes.string,
};

function mapStateToProps({
  csrfToken,
  serviceAlerts: { data = [], loadState = "pending" },
}) {
  return {
    serviceAlerts: data,
    loadState,
    csrfToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchServiceAlerts(csrfToken) {
      dispatch(fetchServiceAlerts(csrfToken));
    },
  };
}
