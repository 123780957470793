// Campus Solution IDs coming from the API are strings. Convert them with this
// map function to integers so they are sortable in the Student Lookup Card.
function sortableStudentId(user) {
  return { ...user, studentId: parseInt(user.studentId) };
}

export default function storedUsers(state = {}, action) {
  switch (action.type) {
    case "STORED_USERS_START":
      return { ...state, loadState: "pending" };
    case "STORED_USERS_SUCCESS":
      return {
        ...state,
        data: {
          users: {
            saved: action.data.users.saved.map(sortableStudentId),
            recent: action.data.users.recent.map(sortableStudentId),
          },
        },
        loadState: "success",
      };
    case "STORED_USERS_RELOAD":
      return { ...state, loadState: "reloading" };
    default:
      return state;
  }
}
