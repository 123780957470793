import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import StudentList from "./ClassInformationEnrollmentCard/StudentList";

import { fetchRoster } from "data/slices/courses/roster";

export const ClassInformationEnrollmentCard = function ({ course, semester }) {

  const dispatch = useDispatch();
  const courseId = course.course_id;

  useEffect(() => {
    if (course) {
      dispatch(fetchRoster(course));
    }
  }, [dispatch, course]);

  const { loadState, students, columnHeaders, rosterSections } = useSelector(
    (state) => {
      const {
        roster: {
          students = [],
          sections: rosterSections = [],
          loadState = "idle",
          columnHeaders,
        } = {},
      } = state.byCourse[courseId] || {};

      return { loadState, students, columnHeaders, rosterSections };
    }
  );

  const groupBy = (objArray, property) => {
    return objArray.reduce((accumulator, currentValue) => {
      const key = currentValue[property];
      const curGroup = accumulator[key] ? accumulator[key] : [];

      return { ...accumulator, [key]: [...curGroup, currentValue] };
    }, {});
  };

  const groupedStudents = groupBy(students, "enroll_status");
  const enrolledStudents = groupedStudents["E"];
  const waitlistedStudents = groupedStudents["W"];

  return (
    <>
      <StudentList
        columnHeaders={columnHeaders}
        course={course}
        courseId={courseId}
        enrollmentStatus="waitlisted"
        loadState={loadState}
        sections={rosterSections}
        semester={semester}
        students={waitlistedStudents}
        title="Wait List"
      />
      <StudentList
        columnHeaders={columnHeaders}
        course={course}
        courseId={courseId}
        enrollmentStatus="enrolled"
        loadState={loadState}
        sections={rosterSections}
        semester={semester}
        students={enrolledStudents}
        title="Enrollment List"
      />
    </>
  );
};

ClassInformationEnrollmentCard.displayName = "ClassInformationEnrollmentCard";
ClassInformationEnrollmentCard.propTypes = {
  course: PropTypes.shape({
    title: PropTypes.string,
    sections: PropTypes.array,
    course_id: PropTypes.string,
  }),
  semester: PropTypes.shape({
    name: PropTypes.string,
  }),
};
