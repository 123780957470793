import {
  ADVISOR_STUDENT_DATA_START,
  ADVISOR_STUDENT_DATA_SUCCESS,
} from '../action-types';

export function fetchAdvisingStudentData(uid) {
  return (dispatch, getState) => {
    const { advising: { studentData = {} } = {} } = getState();

    if (
      studentData.loadState === 'pending' ||
      studentData.loadState === 'success'
    ) {
      if (studentData.uid === uid) {
        return new Promise((resolve, _reject) => resolve(studentData));
      } else {
        dispatch({
          type: 'ADVISOR_STUDENT_DATA_REFRESH',
          value: uid,
        });

        fetch(`/api/advising/student/${uid}`)
          .then(res => res.json())
          .then(data =>
            dispatch({
              type: ADVISOR_STUDENT_DATA_SUCCESS,
              value: { ...data, uid: uid },
            })
          );
      }
    } else {
      dispatch({
        type: ADVISOR_STUDENT_DATA_START,
        value: uid,
      });

      fetch(`/api/advising/student/${uid}`)
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: ADVISOR_STUDENT_DATA_SUCCESS,
            value: { ...data, uid: uid },
          })
        );
    }
  };
}
