import React, { useState } from "react";
import PropTypes from "prop-types";

import { termFromId } from "components/../react/helpers/terms";

import { MediumSpacer, LargeSpacer } from "components/ui/VerticalSpacers";

import EnrollmentCard from "./EnrollmentCard";
import TermLabel from "./TermLabel";
import Deadlines from "./DefaultEnrollment/Deadlines";
import EnrollmentPeriods from "./DefaultEnrollment/EnrollmentPeriods";
import HoldsWarning from "./HoldsWarning";
import EnrollmentInfoExpander from "./EnrollmentInfoExpander";
import SummerEnrollmentMessage from "./DefaultEnrollment/SummerEnrollmentMessage";
import LearnMore from "./DefaultEnrollment/LearnMore";
import EnrollmentActions from "./DefaultEnrollment/EnrollmentActions";
import BulletList from "components/ui/BulletList";
import WithAPILink from "components/ui/WithAPILink";

export default function DefaultEnrollmentCard({
  presentations = [],
  termInstructions = [],
}) {
  const [learnMoreExpanded, setLearnMoreExpanded] = useState(false);

  return (
    <EnrollmentCard
      presentations={presentations}
      termInstructions={termInstructions}
    >
      {({ presentation, enrollmentPeriods, calgrantAckRequired }) => {
        const termId = presentation.termId;
        const careerCode = presentation.academicCareerCode;
        const term = termFromId(termId);
        const isSummer = term.semester === "Summer";

        return (
          <>
            <HoldsWarning termId={termId} />
            <TermLabel termId={termId} includeEnrollmentCaps={true} />
            <MediumSpacer />

            <EnrollmentPeriods
              enrollmentPeriods={enrollmentPeriods}
              isSummer={isSummer}
            />

            <LargeSpacer />

            {isSummer ? (
              <SummerEnrollmentMessage />
            ) : (
              <Deadlines presentation={presentation} />
            )}

            <LargeSpacer />

            <EnrollmentActions
              calgrantAckRequired={calgrantAckRequired}
              termId={termId}
              careerCode={careerCode}
            />

            <hr />

            <BulletList>
              <li>
                <WithAPILink urlID="UC_SR_SS_ENRL_GRADING_BASIS" />
              </li>
              <li>
                <WithAPILink urlID="UC_SR_SS_ENRL_ADD_DROP_SWAP" />
              </li>
              <li>
                <WithAPILink urlID="UC_SR_SS_ENRL_SCHEDULE_PLANNER" />
              </li>
            </BulletList>
            <LargeSpacer />

            <EnrollmentInfoExpander
              title="Learn More About Enrollment"
              onClick={() => setLearnMoreExpanded(!learnMoreExpanded)}
              expanded={learnMoreExpanded}
            />

            {learnMoreExpanded && (
              <LearnMore term={term} presentation={presentation} />
            )}
          </>
        );
      }}
    </EnrollmentCard>
  );
}

const presentationShape = PropTypes.shape({});

const termInstructionShape = PropTypes.shape({});

DefaultEnrollmentCard.propTypes = {
  presentations: PropTypes.arrayOf(presentationShape),
  termInstructions: PropTypes.arrayOf(termInstructionShape),
};
