import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UsersTable from "./UsersTable";

export default connect(mapStateToProps)(RecentUsersTabPanel);

function RecentUsersTabPanel({ users }) {
  return <UsersTable data-testid="recent-users-tab-panel" users={users} />;
}

RecentUsersTabPanel.displayName = "RecentUsersTabPanel";
RecentUsersTabPanel.propTypes = {
  users: PropTypes.array,
};

function mapStateToProps({
  storedUsers: { data: { users: { recent = [] } = {} } = {} } = {},
}) {
  return { users: recent };
}
