import React from "react";
import PropTypes from "prop-types";
import { react2angular } from "react2angular";

import ReduxProvider from "components/ReduxProvider";
import { ClassInformationEnrollmentCard } from "components/cards/ClassInformationEnrollmentCard";

const NgClassInformationEnrollmentCard = ({ course, semester}) => (
  <ReduxProvider>
    <ClassInformationEnrollmentCard course={course} semester={semester} />
  </ReduxProvider>
);

NgClassInformationEnrollmentCard.propTypes = {
  course: PropTypes.shape({
    title: PropTypes.string,
    sections: PropTypes.array,
    course_id: PropTypes.string,
  }),
  semester: PropTypes.shape({
    name: PropTypes.string,
  }),
};

// note: react2angular components cannot start with 'data'
angular
  .module("calcentral.react")
  .component(
    "classInformationEnrollmentCard",
    react2angular(NgClassInformationEnrollmentCard)
  );
