import React, { Fragment } from "react";
import { HubTermLegacyNote } from "./HubTermLegacyNote";
import { TransferCredit } from "./TransferCredit/TransferCredit";
import { Semesters } from "./Semesters";
import { SemestersSummary } from "./SemestersSummary";
import { useSlice } from "components/hooks/useSlice";
import VerticallySpaced from "components/ui/VerticallySpaced";

const Enrollment = () => {
  const { semesters } = useSlice("myAcademics");
  const { hasStudentHistory } = useSlice("myStatus");
  const { law, graduate, undergraduate } = useSlice("myTransferCredit");

  const hasCredit = [law, graduate, undergraduate].some((i) => i.detailed);
  const showEnrollment =
    (semesters.length > 0 && hasStudentHistory) || hasCredit;

  if (showEnrollment) {
    return (
      <Fragment>
        <h3 data-testid="academic-summary-enrollment-header">Enrollment</h3>

        <VerticallySpaced className="cc-academic-summary">
          <HubTermLegacyNote />
          <TransferCredit />
          <Semesters />
          <SemestersSummary />
        </VerticallySpaced>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default Enrollment;
