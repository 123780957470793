import React from "react";
import PropTypes from "prop-types";

import { enrollmentPeriodShape } from "../propTypes";
import { UC_SR_SS_SUMM_DEADLINES } from "functions/urlIds";
import TimeCell from "../TimeCell";
import WithAPILink from "components/ui/WithAPILink";

import MissingAppointmentMessage from "../MissingAppointmentMessage";

function EnrollmentPeriodRow({ enrollmentPeriod }) {
  const {
    name,
    beginTime: { pacific: beginTime },
    endTime: { pacific: endTime },
  } = enrollmentPeriod;

  const shortName = name.replace("Period", "").replace("Begins", "");

  return (
    <tr>
      <td>{shortName}</td>
      <td>
        <TimeCell time={beginTime} />
      </td>
      <td>
        <TimeCell time={endTime} />
      </td>
    </tr>
  );
}

EnrollmentPeriodRow.propTypes = {
  enrollmentPeriod: enrollmentPeriodShape,
};

function SummerPeriodRow({ enrollmentPeriod }) {
  const {
    beginTime: { pacific: beginTime },
  } = enrollmentPeriod;

  return (
    <tr>
      <td>Enrollment for All Sessions</td>
      <td>
        <TimeCell time={beginTime} />
      </td>
      <td>
        <WithAPILink urlID={UC_SR_SS_SUMM_DEADLINES} />
      </td>
    </tr>
  );
}

SummerPeriodRow.propTypes = {
  enrollmentPeriod: enrollmentPeriodShape,
};

export default function EnrollmentPeriods({ enrollmentPeriods, isSummer }) {
  if (enrollmentPeriods.length) {
    return (
      <div className="cc-table">
        <table className="table">
          <thead>
            <tr>
              <th>Period</th>
              <th width="33%">Start</th>
              <th width="33%">End</th>
            </tr>
          </thead>
          <tbody>
            {isSummer ? (
              <SummerPeriodRow enrollmentPeriod={enrollmentPeriods[0]} />
            ) : (
              enrollmentPeriods.map((period, index) => (
                <EnrollmentPeriodRow key={index} enrollmentPeriod={period} />
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }

  return <MissingAppointmentMessage />;
}

EnrollmentPeriods.propTypes = {
  enrollmentPeriods: PropTypes.arrayOf(enrollmentPeriodShape),
  isSummer: PropTypes.bool,
};
