import { buildUserSlice } from "data/utils/buildSlice";
import { userDataThunk } from "data/utils/thunks";
import { userRestRequest } from "data/utils/requests";
import { routes } from "data/routes";

// TODO: Migrate to advisingStudentProfile GraphQL query and deprecate
const key = "advisingStudent";
export const slice = buildUserSlice(key);
export const { reducer: advisingStudent } = slice;
export const fetchAdvisingStudent = userDataThunk(key, slice, {
  request: userRestRequest({ url: routes[key] }),
});
