import React from "react";
import PropTypes from "prop-types";

export const Item = ({ item }) => {
  const { value = false, values = [], title } = item;

  if (value || values.length) {
    return (
      <div className="item">
        <div className="term">{title}</div>
        {value && <div className="termValue">{value}</div>}
        {values.length > 0 && (
          <div className="termValue">
            {values.map((value, index) => {
              return <div key={index}>{value.subvalue.join(" • ")}</div>;
            })}
          </div>
        )}
      </div>
    );
  }

  return null;
};

Item.displayName = "Item";
Item.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.array,
  }),
};
