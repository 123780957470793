import styled from "styled-components";

const BasicTable = styled.table`
  border-spacing: 0;
  width: 100%;

  thead,
  tbody {
    th,
    td {
      padding: inherit;
    }
  }
`;

export default BasicTable;
