import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import getMessageCatalog from "data/actions/getMessageCatalog";

import WithMessage from "components/WithMessage";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import styled from "styled-components";

import ursaMajor from "assets/images/ursa_major.svg";

const InactiveCardWrapper = styled.div`
  font-size: 16px;
  margin: 80px auto;
  width: 625px;

  h2 {
    color: var(--dove-gray);
    font-size: 20px;
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: var(--large-spacing);
  }

  p {
    margin-bottom: var(--medium-spacing);
    margin-top: 0;
  }

  ul {
    margin: var(--medium-spacing) 0 var(--medium-spacing) var(--large-spacing);
    list-style-type: circle;
    padding-left: 0;
  }

  img {
    display: block;
    margin: auto;
  }
`;

export const InactiveNoticeCard = () => {
  const dispatch = useDispatch();

  const { csrfToken } = useSelector((state) => {
    const { config: { csrfToken } = {} } = state;
    return { csrfToken };
  });

  useEffect(() => {
    if (csrfToken) {
      dispatch(getMessageCatalog(csrfToken));
    }
  }, [dispatch, csrfToken]);

  return (
    <InactiveCardWrapper>
      <WithMessage set="32550" number="1">
        {(message) => (
          <>
            <Card data-testid="inactive-notice-card">
              <CardTitle>
                <h2>{message.text}</h2>
              </CardTitle>
              <CardBody>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message.description,
                      }}
                    ></div>
                  </div>
                  <img
                    src={ursaMajor}
                    alt="Ursa Major, also known as the Great Bear, is a constellation in the northern sky."
                    title="Ursa Major, also known as the Great Bear, is a constellation in the northern sky."
                    width="200"
                    height="175"
                  />
                </div>
              </CardBody>
            </Card>
          </>
        )}
      </WithMessage>
    </InactiveCardWrapper>
  );
};

InactiveNoticeCard.displayName = "InactiveNoticeCard";
