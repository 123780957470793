import React from "react";
import styled from "styled-components";

const YellowIconStyles = styled.i`
  color: var(--buttercup);
  font-size: 14px;
`;

const YellowExclamationTriangle = () => (
  <YellowIconStyles className="fa fa-exclamation-triangle" />
);
YellowExclamationTriangle.displayName = "YellowExclamationTriangle";
export default YellowExclamationTriangle;
