"use strict";

/**
 * Controller for page displaying notice to users with no active campus
 * affiliations.
 */
angular
  .module("calcentral.controllers")
  .controller("InactiveUserController", function (apiService) {
    apiService.util.setTitle("Inactive User");
  });
