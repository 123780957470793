import {
  FETCH_HOLDS_START,
  FETCH_HOLDS_SUCCESS,
  FETCH_HOLDS_FAILURE,
} from "../action-types";

export const fetchHoldsStart = () => ({
  type: FETCH_HOLDS_START,
});

export const fetchHoldsSuccess = (holds) => ({
  type: FETCH_HOLDS_SUCCESS,
  value: holds,
});

export const fetchHoldsFailure = (error) => ({
  type: FETCH_HOLDS_FAILURE,
  value: error,
});

export const fetchHolds = () => {
  return (dispatch, getState) => {
    dispatch(fetchHoldsStart());

    const { myHolds } = getState();

    if (myHolds.loaded || myHolds.isLoaded) {
      return new Promise((resolve, _reject) => resolve(myHolds));
    } else {
      return fetch("/api/my/holds", { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchHoldsFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchHoldsSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchHoldsFailure(failure));
          }
        });
    }
  };
};
