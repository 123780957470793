import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  ClockIcon,
  GreenCheck,
  YellowWarning,
  RefreshIcon,
} from "components/ui/Icons";
import UnstyledList from "components/ui/UnstyledList";
import BasicTable from "components/ui/BasicTable";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import VerticallySpaced from "components/ui/VerticallySpaced";
import {
  APRLink,
  APRWhatIfLink,
  DegreePlannerLink,
  PNPCalculatorButton,
} from "./Undergrad/Links";
import DegreeProgressNotice from "./Undergrad/DegreeProgressNotice";

export default connect(mapStateToProps)(AdvisorUndergradDegreeProgressCard);

function mapStateToProps(state) {
  const {
    advising: {
      undergradDegreeProgress: {
        links: {
          academicProgressReport: aprLink,
          academicProgressReportFaqs: aprFaqsLink,
          academicProgressReportWhatIf: aprWhatIfLink,
          degreePlanner: degreePlannerLink,
        } = {},
        progresses: undergradDegreeProgresses = [],
        transferCreditReviewDeadline,
      } = {},
    } = {},
  } = state;

  return {
    aprLink,
    aprFaqsLink,
    aprWhatIfLink,
    degreePlannerLink,
    undergradDegreeProgresses,
    transferCreditReviewDeadline,
  };
}

function AdvisorUndergradDegreeProgressCard({
  aprLink,
  aprFaqsLink,
  aprWhatIfLink,
  degreePlannerLink,
  undergradDegreeProgresses,
  transferCreditReviewDeadline,
}) {
  return (
    <Card>
      <CardTitle>
        <h2>Degree Requirements</h2>
      </CardTitle>
      <CardBody>
        {transferCreditReviewDeadline && <DegreeProgressNotice />}

        <VerticallySpaced>
          <UndergradDegreeProgresses progresses={undergradDegreeProgresses} />
          <APRLink aprLink={aprLink} aprFaqsLink={aprFaqsLink} />
          <DegreePlannerLink degreePlannerLink={degreePlannerLink} />
          <APRWhatIfLink aprWhatIfLink={aprWhatIfLink} />
          <PNPCalculatorButton isAdvisingStudentLookup={true} />
        </VerticallySpaced>
      </CardBody>
    </Card>
  );
}

AdvisorUndergradDegreeProgressCard.propTypes = {
  aprLink: PropTypes.object,
  aprFaqsLink: PropTypes.object,
  aprWhatIfLink: PropTypes.object,
  degreePlannerLink: PropTypes.object,
  undergradDegreeProgresses: PropTypes.array,
  transferCreditReviewDeadline: PropTypes.string,
};

function UndergradDegreeProgresses({ progresses = [] }) {
  if (progresses.length > 0) {
    return (
      <UnstyledList>
        {progresses.map((progress, index) => (
          <UndergradDegreeProgress key={index} progress={progress} />
        ))}
      </UnstyledList>
    );
  }

  return null;
}

UndergradDegreeProgresses.propTypes = {
  progresses: PropTypes.array,
};

function UndergradDegreeProgress({ progress }) {
  return (
    <li>
      {progress.reportDate && (
        <div>
          Degree Progress as of {progress.reportDate}
          <MediumSpacer />
        </div>
      )}

      <BasicTable>
        <thead>
          <tr>
            <th>University Requirement</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {progress.requirements.map((requirement, index) => (
            <tr key={index}>
              <td>{requirement.name}</td>
              <td>
                <UndergradDegreeProgressIcon status={requirement.status} />{" "}
                {requirement.status}
              </td>
            </tr>
          ))}
        </tbody>
      </BasicTable>
    </li>
  );
}

UndergradDegreeProgress.propTypes = {
  progress: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    reportDate: PropTypes.string,
    requirements: PropTypes.array,
  }),
};

function UndergradDegreeProgressIcon({ status }) {
  switch (status) {
    case "Satisfied":
      return <GreenCheck />;
    case "Not Satisfied":
      return <YellowWarning />;
    case "In Progress":
      return <ClockIcon />;
    case "Under Review":
      return <RefreshIcon />;
    default:
      return null;
  }
}

UndergradDegreeProgressIcon.propTypes = {
  status: PropTypes.string,
};
