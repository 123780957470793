import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Accordion as BaseAccordion,
  AccordionItem as BaseAccordionItem,
  AccordionButton as BaseAccordionButton,
  AccordionPanel as BaseAccordionPanel,
} from "components/ui/Accordion";
import { ChevronCircleIcon as SharedChevronCircleIcon } from "components/ui/ChevronCircleIcon";

// import chevronUp from "../../icons/chevron-up.svg";
import chevronDown from "../../icons/chevron-down.svg";

export const Accordion = BaseAccordion;
Accordion.displayName = "ChevronAccordion";

export const ChevronCircleIcon = SharedChevronCircleIcon;

export function AccordionItem({ children, ...props }) {
  // This state will end up on a DOM element as an attribute ("hover") that
  // React doesn't understand as a boolean, so we use string values for the
  // state to prevent getting yelled at by the console
  const [hoverActive, setHoverActive] = useState("false");

  return (
    <ChevronAccordionItemStyles {...props} hover={hoverActive}>
      {children({ hoverActive, setHoverActive })}
    </ChevronAccordionItemStyles>
  );
}
AccordionItem.displayName = "ChevronAccordionItem";
AccordionItem.propTypes = {
  children: PropTypes.func,
};

export const ChevronAccordionItemStyles = styled(BaseAccordionItem)`
  background-color: ${({ hover }) =>
    hover === "true" ? `var(--foam)` : `white`};
`;

export const AccordionPanel = BaseAccordionPanel;
AccordionPanel.displayName = "ChevronAccordionPanel";

export const AccordionButton = ({ children, className, setHoverActive }) => {
  return (
    <BaseAccordionButton
      className={className}
      onFocus={() => setHoverActive("true")}
      onBlur={() => setHoverActive("false")}
      onMouseEnter={() => setHoverActive("true")}
      onMouseLeave={() => setHoverActive("false")}
    >
      {children}
    </BaseAccordionButton>
  );
};
AccordionButton.displayName = "ChevronAccordionButton";
AccordionButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  setHoverActive: PropTypes.func,
};

export const AccordionButtonStyles = styled(BaseAccordionButton)`
  width: 20px;
  height: 20px;
  border-width: 0;
  border-radius: 10px;
  background: url("${chevronDown}") center center / auto no-repeat;

  &[data-state="open"] {
    // Flip the chevron to the open position
    transform: scaleY(-1);
  }

  &:hover,
  &:focus {
    background-color: var(--link-water);
  }
`;
