import React from "react";

import styles from "./iconWrapper.module.scss";

function CampusSolutionsIcon() {
  return (
    <div className={styles.iconWrapper}>
      <div className="cc-icon cc-icon-campussolutions"></div>
    </div>
  );
}

export default CampusSolutionsIcon;
