import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";
import { Field } from "formik";
import { BlueButton } from "components/ui/Buttons";
import Spinner from "components/ui/Spinner";
import { LargeSpacer } from "components/ui/VerticalSpacers";

import Form from "components/ui/Form";
import UserSearchResults from "./UserSearchResults";

const SearchFormStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const BlockLabel = styled.label`
  text-align: left;
  font-weight: bold;
`;

export default connect(mapStateToProps)(SearchUsersPanel);

function SearchUsersPanel({ storedUsers }) {
  const storedUserIds = new Set(storedUsers.map((u) => u.ldapUid));

  const [submittedQuery, setSubmittedQuery] = useState("");
  const [loadState, setLoadState] = useState("");
  const [results, setResults] = useState([]);

  const onSubmit = async (values) => {
    setLoadState("pending");
    setSubmittedQuery(values.query);

    const res = await fetch(`/api/advisees/find/${values.query}/`);
    const { users } = await res.json();

    setResults(
      users.map((user) => ({
        ...user,
        saved: storedUserIds.has(user.ldapUid),
      }))
    );

    setLoadState("success");
  };

  const toggleStar = (changedUser) => {
    setResults((previous) =>
      previous.map((user) => {
        if (user.ldapUid === changedUser.ldapUid) {
          return { ...user, saved: !user.saved };
        }

        return user;
      })
    );
  };

  return (
    <div data-testid="search-users-tab-panel">
      <Formik
        initialValues={{
          query: "",
        }}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <BlockLabel htmlFor="query">
              Enter name, SID, UID or @berkeley.edu address
            </BlockLabel>

            <SearchFormStyles>
              <Field
                id="query"
                name="query"
                type="text"
                style={{ padding: `5px`, flex: 1 }}
              />

              <BlueButton type="submit" disabled={values.query === ""}>
                Search
              </BlueButton>
            </SearchFormStyles>
          </Form>
        )}
      </Formik>

      <LargeSpacer />

      {loadState === "pending" && <Spinner />}
      {loadState === "success" && (
        <UserSearchResults
          users={results}
          afterToggleSave={toggleStar}
          query={submittedQuery}
        />
      )}
    </div>
  );
}

SearchUsersPanel.displayName = "SearchUsersPanel";
SearchUsersPanel.propTypes = {
  storedUsers: PropTypes.array,
};

function mapStateToProps({
  storedUsers: { data: { users: { saved = [] } = {} } = {} } = {},
}) {
  return { storedUsers: saved };
}
