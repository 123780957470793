import PropTypes from "prop-types";

export const deadlineShape = PropTypes.shape({
  session: PropTypes.string,
  addDeadlineDatetime: PropTypes.string,
  optionDeadlineDatetime: PropTypes.string,
});

export const constraintShape = PropTypes.shape({
  acadCareer: PropTypes.string,
  maxUnits: PropTypes.string,
  deadlines: PropTypes.arrayOf(deadlineShape),
});

export const timeShape = PropTypes.shape({
  pacific: PropTypes.string,
});

export const enrollmentPeriodShape = PropTypes.shape({
  name: PropTypes.string,
  acadCareer: PropTypes.string,
  beginTime: timeShape,
  endTime: timeShape,
});

export const presentationShape = PropTypes.shape({
  constraints: constraintShape,
});

export const termInstructionShape = PropTypes.shape({
  term_id: PropTypes.string,
  enrollmentPeriods: PropTypes.arrayOf(enrollmentPeriodShape),
});
