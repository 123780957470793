import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { react2angular } from "react2angular";

import ReduxProvider from "components/ReduxProvider";
import EnrollmentCards from "components/EnrollmentCards";

const NgEnrollmentCards = () => (
  <ReduxProvider>
    <Router>
      <EnrollmentCards />
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("enrollmentCards", react2angular(NgEnrollmentCards));
