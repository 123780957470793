import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./SortArrows.scss";

import sortDescInactive from "icons/sort-desc-inactive.svg";
import sortDescActive from "icons/sort-desc-active.svg";

const reverseSortOrder = (sortOrder) => (sortOrder === "DESC" ? "ASC" : "DESC");

const SortArrows = ({
  label,
  column,
  defaultOrder,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}) => {
  const matching = column === sortBy;
  if (matching) {
    return (
      <span
        className="SortArrows"
        onClick={() => setSortOrder(reverseSortOrder(sortOrder))}
      >
        {label}

        <div className="SortArrows__arrow-group">
          {sortOrder === "DESC" ? (
            <Fragment>
              <img src={sortDescInactive} className="transform-y" />
              <img src={sortDescActive} />
            </Fragment>
          ) : (
            <Fragment>
              <img src={sortDescActive} className="transform-y" />
              <img src={sortDescInactive} />
            </Fragment>
          )}
        </div>
      </span>
    );
  } else {
    return (
      <span
        className="SortArrows"
        onClick={() => {
          setSortBy(column);
          setSortOrder(defaultOrder);
        }}
      >
        {label}
        <div className="SortArrows__arrow-group">
          <img src={sortDescInactive} className="transform-y" />
          <img src={sortDescInactive} />
        </div>
      </span>
    );
  }
};

SortArrows.propTypes = {
  label: PropTypes.string,
  column: PropTypes.string,
  defaultOrder: PropTypes.string,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  setSortBy: PropTypes.func,
  setSortOrder: PropTypes.func,
};

export default SortArrows;
