import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PageTitle from "components/PageTitle";
import PageStatus from "components/ui/PageStatus";
import { StatusCard, StatusTitle, StatusBody } from "components/ui/StatusCard";
import { BlueButton } from "components/ui/Buttons";
import bigSpinner from "../icons/big-spinner.svg";

export default function DelegateLandingCard() {
  const [secondsPassed, setSecondsPassed] = useState(0);
  const minutesToWait = 15;
  const secondsToButtonEnabled = 45;
  const secondsPerMinute = 60;
  const secondsRemaining = minutesToWait * secondsPerMinute - secondsPassed;
  const buttonEnabled = secondsPassed >= secondsToButtonEnabled;
  const minutesRemaining =
    secondsRemaining > 0
      ? parseInt((secondsRemaining - 1) / secondsPerMinute) + 1
      : 0;

  const ready = secondsRemaining === 0;

  useEffect(() => {
    if (secondsRemaining > 0) {
      setTimeout(() => {
        setSecondsPassed((prev) => prev + 1);
      }, 1000);
    }
  }, [secondsRemaining]);

  return (
    <PageStatus>
      <PageTitle title="Preparing Account" />
      <StatusCard>
        <StatusTitle>
          <h2>
            {ready ? "Your Account Is Ready" : "Your Account Is Almost Ready"}
          </h2>
        </StatusTitle>
        <DelegateLandingStyles>
          <div className="container">
            <div className="textContent">
              <h3>{ready ? "Set Up Your Account" : "Wait a Few Minutes"}</h3>
              <p>
                Your CalCentral account is being prepared. This process can take
                up to 15 minutes.
              </p>

              <Countdown minutesRemaining={minutesRemaining} />

              <p>
                If you close this browser window before the countdown is
                complete (15 minutes), locate the email from calnet@berkeley.edu
                to access the link and additional instructions to complete the
                account setup.
              </p>
            </div>

            <div className="media">
              {minutesRemaining === 0 ? (
                <i
                  className="fa fa-check-circle"
                  style={{ fontSize: `210px`, color: `var(--jewel)` }}
                />
              ) : (
                <img src={bigSpinner} style={{ maxWidth: `185px` }} />
              )}
            </div>
          </div>

          <BlueButton
            style={{ fontSize: `18px` }}
            disabled={!buttonEnabled}
            onClick={() => (window.location.pathname = "/delegate_welcome")}
          >
            Complete Account Setup
          </BlueButton>
        </DelegateLandingStyles>
      </StatusCard>
    </PageStatus>
  );
}

const DelegateLandingStyles = styled(StatusBody)`
  h3 {
    font-size: 25px;
  }

  @media screen and (min-width: 768px) {
    .container {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      overflow: auto;
    }

    .media {
      float: right;
    }
  }
`;

const CountdownStyles = styled.div`
  font-size: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: baseline;
  gap: 5px;

  .baselineAlignment {
    display: flex;
    align-items: baseline;
    gap: 5px;
  }

  .minutesRemaining {
    font-size: 24px;
    width: 40px;
    height: 40px;
    background-color: var(--colonial-white);
    display: grid;
    place-items: center;
  }

  span {
    font-size: 14px;
  }
`;

function Countdown({ minutesRemaining }) {
  return (
    <CountdownStyles>
      <strong>Countdown:</strong>

      <div className="baselineAlignment">
        <div className="minutesRemaining">{minutesRemaining}</div>
        <div style={{ lineHeight: `21px` }}>
          <div style={{ fontSize: `18px` }}>minutes remaining</div>
          <div style={{ fontSize: `14px` }}>(approximately)</div>
        </div>
      </div>
    </CountdownStyles>
  );
}

Countdown.propTypes = {
  minutesRemaining: PropTypes.number,
};
