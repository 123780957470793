import {
  MESSAGE_CATALOG_START,
  MESSAGE_CATALOG_SUCCESS,
  MESSAGE_CATALOG_FAILURE,
} from "../action-types";

export default function messageCatalog(state = {}, action) {
  switch (action.type) {
    case MESSAGE_CATALOG_START:
      return { ...state, loadState: "pending" };
    case MESSAGE_CATALOG_SUCCESS:
      return { ...state, messages: action.value, loadState: "success" };
    case MESSAGE_CATALOG_FAILURE:
      return { ...state, loadState: "failure" };
    default:
      return state;
  }
}
