import React from "react";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const Majors = () => {
  const { majors } = useSelector((state) => {
    const {
      advising: { userId: advisingStudentUid },
    } = state;

    const { advisingStudentAcademicProfile: { majors = [] } = {} } =
      advisingStudentState(state, advisingStudentUid) || {};

    return {
      majors,
    };
  });

  if (majors && majors.length > 0) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>
            {majors.length === 1 ? "Major" : "Majors"}
          </StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {majors.map((major, index) => (
            <div key={index}>
              <div style={{ color: "var(--dusty-gray)" }}>{major.college}</div>
              <div>{major.description}</div>
              {major.subPlans.map((subPlan, index) => (
                <div key={index}>{subPlan}</div>
              ))}
            </div>
          ))}
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
Majors.displayName = "Majors";
