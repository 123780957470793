import React from "react";
import styled from "styled-components";
import { AccordionItem as BaseAccordionItem } from "@reach/accordion";

import AccordionButton from "./AccordionButton";
import AccordionPanel from "./AccordionPanel";
import { bCalScheduleItemShape } from "components/propTypes/bCalScheduleItemShape";

export default function AccordionItem({ item }) {
  return (
    <AccordionItemStyles data-testid="accordion-item">
      <AccordionButton item={item} />
      <AccordionPanel item={item} />
    </AccordionItemStyles>
  );
}

AccordionItem.displayName = "AccordionItem";
AccordionItem.propTypes = {
  item: bCalScheduleItemShape,
};

const AccordionItemStyles = styled(BaseAccordionItem)`
  padding-top: var(--medium-spacing);
  padding-bottom: var(--medium-spacing);

  &[data-state="open"] {
    background-color: var(--bianca);
  }

  &:hover,
  &:focus {
    background-color: var(--foam);
  }

  & + & {
    border-top: var(--border-hairline);
  }
`;
