import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import AdvisorDegreeProgressLoader from "components/AdvisorDegreeProgressLoader";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function NgAdvisorDegreeProgressLoader() {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route
            path="/user/overview/:uid"
            element={<AdvisorDegreeProgressLoader />}
          />
        </Routes>
      </Router>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component(
    "advisorDegreeProgressLoader",
    react2angular(NgAdvisorDegreeProgressLoader)
  );
