import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import getMessageCatalog from "data/actions/getMessageCatalog";

function WithMessage({ fetchData, children, message }) {
  useEffect(() => {
    fetchData();
  }, []);

  if (message) {
    return children(message);
  }

  return null;
}

WithMessage.propTypes = {
  fetchData: PropTypes.func,
  children: PropTypes.func,
  message: PropTypes.object,
  set: PropTypes.string,
  number: PropTypes.string,
};

function mapStateToProps(
  { messageCatalog: { messages = [], loadState } },
  { set, number }
) {
  const message = messages.find((m) => m.set === set && m.number === number);

  return { message, loadState };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData: () => dispatch(getMessageCatalog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithMessage);
