import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const PronounHeading = styled.div`
  color: var(--dove-gray);
  font-size: 11px;
  font-weight: normal;
`;

export const Pronouns = () => {
  const { isDelegate, pronouns } = useSelector((state) => {
    const {
      myStatus: { delegateActingAsUid } = {},
      userProfile: {
        pronouns = ""
      } = {}
    } = state;
    const isDelegate = !!delegateActingAsUid;

    return { isDelegate, pronouns };
  });

  if (pronouns && !isDelegate) {
    return (<PronounHeading>pronouns: {pronouns}</PronounHeading>);
  } else {
    return null;
  }
};
Pronouns.displayName = "Pronouns";

