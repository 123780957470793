import React from "react";
import WithCSRFToken from "../WithCSRFToken";

export default function SignOutButton() {
  const signOut = (csrfToken) => () => {
    fetch("/logout", { method: "POST", headers: { "x-csrf-token": csrfToken } })
      .then((response) => response.json())
      .then((data) => (window.location = data.redirectURL));
  };

  return (
    <WithCSRFToken>
      {(csrfToken) => (
        <button
          onClick={signOut(csrfToken)}
          style={{ fontSize: `13px`, fontWeight: `normal` }}
        >
          Log out
        </button>
      )}
    </WithCSRFToken>
  );
}
