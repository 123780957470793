import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";

import { format, parseISO } from "date-fns";
import Icon from "react/components/Icon/Icon";
import {
  ICON_GRADUATION,
  ICON_CERTIFICATE,
} from "react/components/Icon/IconTypes";

import "./Degrees.scss";

const degreeMajors = (degree) =>
  degree.majors.map((major) => major.formalDescription).join(", ");

const formattedAwardDate = (degree) => {
  return format(parseISO(degree.dateAwarded), "MMMM d, y");
};

const Degree = ({ degree, index }) => {
  const honors = degree.honors || [];

  return (
    <div className="Degree" key={index}>
      <div className="Degree__icon-container">
        {degree.majors[0].typeCode === "CRT" ? (
          <Icon name={ICON_CERTIFICATE} />
        ) : (
          <Icon name={ICON_GRADUATION} />
        )}
      </div>
      <div className="Degree__body">
        <div className="Degree__description">
          {degree.description} <span>in</span> {degreeMajors(degree)}
        </div>

        {degree.designatedEmphases.length > 0 && (
          <div className="Degree__emphases">
            {degree.designatedEmphases.map((emphasis, index) => (
              <span key={index}>{emphasis.formalDescription}</span>
            ))}
          </div>
        )}

        <div className="Degree__awarded-on">
          Awarded: {formattedAwardDate(degree)}
        </div>

        {degree.isUndergrad && (
          <Fragment>
            <div>{degree.college}</div>

            {honors.map((honor, index) => (
              <div key={index}>{honor.formalDescription}</div>
            ))}

            {degree.minors.map((minor, index) => (
              <div key={index}>{minor.formalDescription}</div>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};

Degree.displayName = "Degree";
Degree.propTypes = {
  degree: PropTypes.object,
  index: PropTypes.number,
};

const Degrees = () => {
  const { degrees } = useSelector((state) => {
    const { academicProfile: { degrees = [] } = {} } =
      currentUserState(state) || {};

    return { degrees };
  });

  if (degrees.length) {
    return (
      <tr>
        <th>{degrees.length === 1 ? "Degree" : "Degrees"} Conferred</th>
        <td>
          {degrees.map((degree, index) => (
            <Degree key={index} degree={degree} index={index} />
          ))}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

Degrees.displayName = "Degrees";
export default Degrees;
