// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".category___BIuTc{display:block}.categoryWithBorder___ZN8Lx{border-bottom:1px solid var(--silver)}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/Category.module.scss"],"names":[],"mappings":"AAEA,kBACE,aAAA,CAGF,4BACE,qCAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.category {\n  display: block;\n}\n\n.categoryWithBorder {\n  border-bottom: 1px solid var(--silver);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"category": "category___BIuTc",
	"categoryWithBorder": "categoryWithBorder___ZN8Lx"
};
export default ___CSS_LOADER_EXPORT___;
