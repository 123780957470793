const asyncRequest = (method) => (path, csrfToken) => (values) => {
  return fetch(path, {
    method: method,
    headers: headers(csrfToken),
    body: values ? JSON.stringify(values) : null,
  });
};

function headers(csrfToken) {
  if (csrfToken) {
    return {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
}

export default asyncRequest;
