import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";
import LightText from "components/ui/LightText";

const Minor = () => {
  const { minors } = useSelector((state) => {
    const { academicProfile: { minors = [] } = {} } =
      currentUserState(state) || {};

    return { minors };
  });

  if (minors.length) {
    return (
      <tr>
        <th>{minors.length === 1 ? "Minor" : "Minors"}</th>
        <td>
          {minors.map((minorObj, index) => (
            <div key={index}>
              <LightText>{minorObj.college}</LightText>
              <div>{minorObj.description}</div>
              {minorObj.subPlans.map((subPlan, index) => (
                <div key={index} className="cc-widget-profile-indent">
                  {subPlan}
                </div>
              ))}
            </div>
          ))}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

Minor.displayName = "Minor";
export default Minor;
