import {
  PUBLIC_LINKS_SET,
  PUBLIC_LINKS_START,
  PUBLIC_LINKS_SUCCESS,
} from "../action-types";

export default function publicLinksReducer(state = {}, action) {
  switch (action.type) {
    case PUBLIC_LINKS_START:
      return { ...state, loadState: "pending" };
    case PUBLIC_LINKS_SUCCESS:
      return { ...state, loadState: "success" };
    case PUBLIC_LINKS_SET:
      return {
        ...state,
        ...action.value.reduce((acc, current) => {
          if (current) {
            return { ...acc, [current.urlId]: current };
          }
          return acc;
        }, {}),
      };
    default:
      return state;
  }
}
