import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";
import { InlineSelect } from "components/ui/Select";
import { BaseButtonLink } from "components/ui/ButtonLink";
import { DownloadIcon } from "components/ui/Icons";

const inlineSelectStyle = {
  height: "26px",
  width: "200px",
};

export const StudentListOptions = function ({
  courseId,
  enrollmentStatus,
  sectionOptions,
  selectedSectionId,
  updateSelectedSection,
}) {
  const exportUrl =
    "/api/academics/rosters/campus/csv/" +
    courseId +
    ".csv?section_id=" +
    selectedSectionId +
    "&enroll_option=" +
    enrollmentStatus;

  return (
    <div>
      <BaseButtonLink
        href={exportUrl}
        style={{ marginRight: "var(--small-spacing)" }}
      >
        <DownloadIcon />
        Export
      </BaseButtonLink>
      <VisuallyHidden>
        <label htmlFor="section-select">Filter by Section</label>
      </VisuallyHidden>

      <InlineSelect
        className="value"
        value={selectedSectionId}
        onChange={(e) => updateSelectedSection(e.target.value)}
        style={inlineSelectStyle}
      >
        {sectionOptions &&
          sectionOptions.map((sectionOption, sectionOptionIndex) => (
            <option value={sectionOption.ccn} key={sectionOptionIndex}>
              {sectionOption.name}
            </option>
          ))}
      </InlineSelect>
    </div>
  );
};

StudentListOptions.displayName = "StudentListOptions";
StudentListOptions.propTypes = {
  courseId: PropTypes.string,
  enrollmentStatus: PropTypes.string,
  sectionOptions: PropTypes.arrayOf(PropTypes.object),
  selectedSectionId: PropTypes.string,
  updateSelectedSection: PropTypes.func,
};
