import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import AlumniHomepage from "components/../react/components/_dashboard/AlumniHomepage/AlumniHomepage";

const NgAlumniHomepage = () => (
  <ReduxProvider>
    <AlumniHomepage />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("alumniHomepage", react2angular(NgAlumniHomepage));
