// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noticeWrapper___qFNzU{padding:10px;margin-bottom:15px;border-radius:5px;display:flex}.noticeWrapper___qFNzU.yellow___h_qwD{background-color:var(--half-dutch-white)}.noticeWrapper___qFNzU.gray___JZ57T{background-color:var(--gallery)}.iconContainer___dPKRB{width:24px;margin-top:2px;flex-shrink:0}.messageContainer___WSy8B div p{margin-bottom:10px}.messageContainer___WSy8B div>*:last-child{margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/react/components/StyledNotice.module.scss"],"names":[],"mappings":"AAEA,uBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CAEA,sCACE,wCAAA,CAGF,oCACE,+BAAA,CAIJ,uBACE,UAAA,CACA,cAAA,CACA,aAAA,CAKE,gCACE,kBAAA,CAGF,2CACE,eAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.noticeWrapper {\n  padding: 10px;\n  margin-bottom: 15px;\n  border-radius: 5px;\n  display: flex;\n\n  &.yellow {\n    background-color: var(--half-dutch-white);\n  }\n\n  &.gray {\n    background-color: var(--gallery);\n  }\n}\n\n.iconContainer {\n  width: 24px;\n  margin-top: 2px;\n  flex-shrink: 0;\n}\n\n.messageContainer {\n  div {\n    p {\n      margin-bottom: 10px;\n    }\n\n    > *:last-child {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noticeWrapper": "noticeWrapper___qFNzU",
	"yellow": "yellow___h_qwD",
	"gray": "gray___JZ57T",
	"iconContainer": "iconContainer___dPKRB",
	"messageContainer": "messageContainer___WSy8B"
};
export default ___CSS_LOADER_EXPORT___;
