import { buildUserSlice } from "data/utils/buildSlice";
import { userDataThunk } from "data/utils/thunks";
import { routes } from "data/routes";
import { userRestRequest } from "data/utils/requests";

const key = "loanHistoryCumulative";

export const slice = buildUserSlice(key);
export const { reducer: loanHistoryCumulative } = slice;
export const fetchLoanHistoryCumulative = userDataThunk(key, slice, {
  request: userRestRequest({ url: routes[key] }),
});
