import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useSelector } from "react-redux";

import VisuallyHidden from "components/ui/VisuallyHidden";
import UserButton from "./UserButton";
import bearProfile from "assets/images/bear-profile.svg";

const BadgeStyles = styled.span`
  border-radius: 50%;
  cursor: pointer;
  font-size: 8px;
  height: 15px;
  left: 20px;
  line-height: 10px;
  min-width: 15px;
  padding: 3px;
  position: absolute;
  top: -2px;
  z-index: 1;
  background-color: var(--thunderbird);
`;

const BadgeCount = ({ count }) => {
  if (count > 0) {
    return <BadgeStyles>{count}</BadgeStyles>;
  }

  return null;
};

BadgeCount.propTypes = { count: PropTypes.number };

function UserProfileButton({ active, badgeCount, ...rest }) {
  const [imageLoadError, setImageLoadError] = useState(false);

  const { firstName } = useSelector((state) => {
    const {
      userProfile: {
        firstName
      } = {},
    } = state || {};

    return { firstName };
  });

  return (
    <UserButton active={active} {...rest}>
      {imageLoadError ? (
        <span
          className="photo"
          style={{
            background: `url("${bearProfile}") center center / cover no-repeat`,
          }}
        />
      ) : (
        <span
          style={{
            background: `url('/api/my/photo') center center / cover no-repeat`,
          }}
        >
          <img
            src="/api/my/photo"
            onError={() => setImageLoadError(true)}
            style={{ display: `none` }}
          />
        </span>
      )}

      <span style={{ marginRight: `3px` }}>{firstName}</span>

      <BadgeCount count={badgeCount} />
      <VisuallyHidden>Show/Hide profile menu</VisuallyHidden>
    </UserButton>
  );
}

UserProfileButton.propTypes = {
  active: PropTypes.bool,
  badgeCount: PropTypes.number,
};
UserProfileButton.displayName = "UserProfileButton";
export default UserProfileButton;
