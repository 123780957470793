import {
  FETCH_MY_UP_NEXT_START,
  FETCH_MY_UP_NEXT_SUCCESS,
  FETCH_MY_UP_NEXT_FAILURE,
} from '../action-types';

export const fetchMyUpNextStart = () => ({
  type: FETCH_MY_UP_NEXT_START,
});

export const fetchMyUpNextSuccess = myUpNext => ({
  type: FETCH_MY_UP_NEXT_SUCCESS,
  value: myUpNext,
});

export const fetchMyUpNextFailure = error => ({
  type: FETCH_MY_UP_NEXT_FAILURE,
  value: error,
});

export const fetchMyUpNext = () => {
  return (dispatch, getState) => {
    const { myUpNext } = getState();

    if (myUpNext.loaded || myUpNext.isLoading) {
      return new Promise((resolve, _reject) => resolve(myUpNext));
    } else {
      dispatch(fetchMyUpNextStart());
      const api_url = '/api/my/up_next';
      // const api_url = '/dummy/json/up_next.json';

      fetch(api_url, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              message: 'An error has occurred obtaining your calendar items.',
            };
            dispatch(fetchMyUpNextFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchMyUpNextSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              message: 'An error has occurred obtaining your calendar items.',
            };
            dispatch(fetchMyUpNextFailure(failure));
          }
        });
    }
  };
};
