import {
  BILLING_ACTIVITY_START,
  BILLING_ACTIVITY_SUCCESS,
  BILLING_ACTIVITY_FAILURE,
} from "data/action-types";

export default function financesReducer(state = {}, action) {
  switch (action.type) {
    case BILLING_ACTIVITY_START:
      return { ...state, ...action.value, loadState: "pending" };
    case BILLING_ACTIVITY_SUCCESS:
      return { ...state, ...action.value, loadState: "success" };
    case BILLING_ACTIVITY_FAILURE:
      return { ...state, ...action.value, loadState: "failure" };
    default:
      return state;
  }
}
