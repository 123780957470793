import { useEffect } from "react";
import getUserLinks from "data/actions/getUserLinks";
import { connect } from "react-redux";

export default connect()(WithLinks);

function WithLinks({ dispatch, children }) {
  useEffect(() => {
    dispatch(getUserLinks());
  }, []);

  return children;
}
