import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import WithLinks from "components/WithLinks";
import UserProfileButton from "./UserProfileButton";
import PopoverContainer from "./PopoverContainer";
import StatusPopover from "./StatusPopover";

import { useDispatch, useSelector } from "react-redux";
import { fetchStatusAndHolds } from "data/actions/statusActions";
import { fetchHolds } from "data/actions/holdsActions";
import { fetchBillingActivity } from "data/actions/financesActions";
import { fetchStandings } from "data/actions/standingsActions";

function UserPopover() {
  const [dataLoaded, setdataLoaded] = useState(false);

  const { badgeCount, roles, delegateActingAsUid, canActOnFinances } =
    useSelector((state) => {
      const {
        myStatus: { firstName, roles, delegateActingAsUid, canActOnFinances },
        myStatusAndHolds: {
          termRegistrations = [],
          loaded: statusAndHoldsLoaded = false,
        },
        myHolds: { holds = [] },
        myStandings: { currentStandings = [] },
        finances: { summary: { amountDueNow: minimumDue = 0 } = {} } = {},
      } = state;

      const standingsBadgeCount =
        currentStandings.length && currentStandings[0].statusCode !== "GST"
          ? 1
          : 0;

      const registrationBadgeCount = termRegistrations
        .map((reg) => reg.badgeCount)
        .reduce((acc, val) => acc + val, 0);

      const holdsBadgeCount = holds.length;

      const financesBadgeCount = minimumDue > 0 ? 1 : 0;
      const badgeCount =
        standingsBadgeCount +
        registrationBadgeCount +
        holdsBadgeCount +
        financesBadgeCount;

      return {
        badgeCount,
        firstName,
        roles,
        statusAndHoldsLoaded,
        delegateActingAsUid,
        canActOnFinances,
      };
    });

  const dispatch = useDispatch();
  useEffect(() => {
    let fetches = [dispatch(fetchHolds())];

    if (roles.undergrad) {
      fetches.push(dispatch(fetchStandings()));
    }

    if (shouldLoadHolds(roles)) {
      fetches.push(dispatch(fetchStatusAndHolds()));
    }

    if (shouldLoadFinances({ delegateActingAsUid, canActOnFinances })) {
      fetches.push(dispatch(fetchBillingActivity()));
    }

    Promise.all(fetches).then(() => setdataLoaded(true));
  }, [dispatch, roles]);

  return (
    <PopoverContainer>
      {({ toggle, isOpen, ref, triggerRef }) => (
        <>
          <WithLinks>
            <UserProfileButton
              onClick={toggle}
              active={isOpen}
              title="Settings"
              node={triggerRef}
              badgeCount={badgeCount}
            />
          </WithLinks>

          {isOpen && (
            <StatusPopover
              node={ref}
              togglePopover={toggle}
              loaded={dataLoaded}
            />
          )}
        </>
      )}
    </PopoverContainer>
  );
}

UserPopover.propTypes = {
  badgeCount: PropTypes.number,
  fetchData: PropTypes.func,
  fetchFinances: PropTypes.func,
  fetchStatusAndHolds: PropTypes.func,
  fetchStandings: PropTypes.func,
  roles: PropTypes.object,
  delegateActingAsUid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  canActOnFinances: PropTypes.bool,
};
UserPopover.displayName = "UserPopover";

function shouldLoadHolds(roles) {
  const { student, applicant, exStudent, concurrentEnrollmentStudent } = roles;
  return student || applicant || exStudent || concurrentEnrollmentStudent;
}

function shouldLoadFinances({ delegateActingAsUid, canActOnFinances }) {
  return !delegateActingAsUid || canActOnFinances;
}

export default UserPopover;
