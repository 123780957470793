import React from "react";
import PropTypes from "prop-types";

const UnitsRow = (props) => (
  // <div data-testid="units-row">
  <>
    <dt>{props.name}</dt>
    <dd>{props.value}</dd>
  </>
  // </div>
);

UnitsRow.displayName = "UnitsRow";
UnitsRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default UnitsRow;
