import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import warningIcon from "icons/warning.svg";

const StyledErrorMessage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  img {
    margin-right: 5px;
    padding-top: 3px;
  }
`;

export function ErrorMessage({ message }) {
  if (message) {
    return (
      <StyledErrorMessage data-testid="error-message">
        <img src={warningIcon} />
        <div>{message}</div>
      </StyledErrorMessage>
    );
  } else {
    return null;
  }
}

ErrorMessage.displayName = "ErrorMessage";
ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default ErrorMessage;
