import styled from "styled-components";

export const AcademicProfileList = styled.dl`
  display: grid;
  gap: var(--md) var(--md);
  grid-template-columns: 30% 70%;

  > dt,
  > dd {
    margin: 0;
    padding-top: var(--md);
    border-top: 1px solid var(--gallery);
  }

  > dt {
    font-weight: bold;
    color: var(--dove-gray);
  }

  > dd {
  }
`;

AcademicProfileList.displayName = "AcademicProfile";
