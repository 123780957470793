import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { ClipboardIcon } from "components/ui/Icons";
import { BaseButton } from "components/ui/Buttons";
import { MessageButton } from "./MessageButton";

const DietClipboardIcon = styled(ClipboardIcon)`
  height: inherit;
  width: inherit;
`;
DietClipboardIcon.displayName = "DietClipboardIcon";

export const ViewControls = function ({
  displayedView,
  filteredStudents,
  messageButtonsDisabled,
  messageSections,
  setDisplayedView,
}) {
  return (
    <div style={{ fontSize: "13px" }}>
      <span style={{ marginRight: "10px" }}>
        On List: <strong>{filteredStudents.totalStudentCount}</strong> | Open:{" "}
        <strong>{filteredStudents.openSeatsCount}</strong> | Shown:{" "}
        <strong>{filteredStudents.shownStudentCount}</strong>
      </span>

      <BaseButton
        style={{ marginRight: "var(--small-spacing)" }}
        onClick={() => {
          setDisplayedView("addresses");
        }}
        disabled={messageButtonsDisabled || displayedView == "addresses"}
      >
        <DietClipboardIcon /> Copy Addresses
      </BaseButton>

      {messageSections &&
        messageSections.map((messageSection) => (
          <MessageButton
            key={messageSection}
            messageSection={messageSection}
            messageButtonsDisabled={messageButtonsDisabled}
            setDisplayedView={setDisplayedView}
          />
        ))}
    </div>
  );
};

ViewControls.displayName = "ViewControls";
ViewControls.propTypes = {
  displayedView: PropTypes.string,
  filteredStudents: PropTypes.object,
  messageSections: PropTypes.arrayOf(PropTypes.string),
  messageButtonsDisabled: PropTypes.bool,
  setDisplayedView: PropTypes.func,
};
