import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Widget from "components/../react/components/Widget/Widget";
import "icons/clipboard-list.svg";
import styles from "./AlumniHomepage.module.scss";

const defaultAlumData = {
  homepageLinkObj: {},
  landingPageMessage: "",
};

const AlumniHomepage = (props) => {
  const [errored, setErrored] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [alumData, setAlumData] = useState(defaultAlumData);

  const httpHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    "x-csrf-token": props.csrfToken,
  };

  const widgetConfig = {
    errored: errored,
    errorMessage: "The Alumni Homepage is not available right now.",
    isLoading: isLoading,
    padding: true,
    title: "Alumni Homepage",
    visible: true,
  };

  const fetchData = () => {
    fetch("/api/alumni/alumni_profiles", { method: "GET" })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setErrored(true);
          setIsLoading(false);
        }
      })
      .then((data) => {
        setAlumData({
          homepageLinkObj: data.homepageLink,
          landingPageMessage: data.landingPageMessage.descrlong,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  const navigateToLandingPage = (homepageURL) => {
    const callLogout = fetch("/logout", {
      method: "POST",
      headers: httpHeaders,
    });
    let promiseList = [callLogout];
    setIsLoading(true);
    Promise.all(promiseList)
      .then((_res) => {
        window.location.href = homepageURL;
      })
      .catch((_err) => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnClick = () => {
    navigateToLandingPage(alumData.homepageLinkObj.url);
  };

  return (
    <Widget config={{ ...widgetConfig }}>
      <p className={styles.messageText}>{alumData.landingPageMessage}</p>
      <div className={styles.buttonDiv}>
        <a
          title={alumData.homepageLinkObj.title}
          target="_self"
          className="cc-react-button cc-react-button--blue"
          onClick={handleOnClick}
        >
          {alumData.homepageLinkObj.name}
        </a>
      </div>
      <br />
    </Widget>
  );
};

AlumniHomepage.propTypes = {
  csrfToken: PropTypes.string,
};

const mapStateToProps = ({ config: { csrfToken } }) => {
  return { csrfToken };
};

export default connect(mapStateToProps)(AlumniHomepage);
