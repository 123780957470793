// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper___Icu1z{padding:10px 20px 25px 40px}.description___kjP2Z{color:#474747}.actionLink___uI3m4{width:100%;display:block;text-align:center;margin-top:10px}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/TaskDetail.module.scss"],"names":[],"mappings":"AAAA,iBACE,2BAAA,CAGF,qBACE,aAAA,CAGF,oBACE,UAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".wrapper {\n  padding: 10px 20px 25px 40px;\n}\n\n.description {\n  color: #474747;\n}\n\n.actionLink {\n  width: 100%;\n  display: block;\n  text-align: center;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper___Icu1z",
	"description": "description___kjP2Z",
	"actionLink": "actionLink___uI3m4"
};
export default ___CSS_LOADER_EXPORT___;
