import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import RequireSuperuser from "./RequireSuperuser";
import { UnstyledList } from "components/ui/UnstyledList";
import { BlueButton } from "components/ui/Buttons";
import { MediumSpacer } from "components/ui/VerticalSpacers";

function IconForState({ state }) {
  if (state === "failure") {
    return <i title="Failed" className="fa fa-check-circle" />;
  } else if (state === "success") {
    return <i title="Success" className="fa fa-check-circle" />;
  } else {
    return <i title="Pending" className="fa fa-spinner fa-spin" />;
  }
}

IconForState.propTypes = {
  state: PropTypes.string,
};

function stateColor(state) {
  switch (state) {
    case "success":
      return "var(--jewel)";
    case "failure":
      return "var(--thunderbird)";
    default:
      return "var(--mine-shaft)";
  }
}

function TestRoute({ path, checkFor, loadState, setLoadState }) {
  useEffect(() => {
    if (loadState === "idle") {
      setLoadState("pending");
      fetch(path)
        .then((res) => {
          if (res.ok) {
            if (checkFor) {
              return res.json();
            } else {
              setLoadState("success");
            }
          } else {
            setLoadState("failure");
          }
        })
        .then((data) => {
          if (data) {
            if (data[checkFor]) {
              setLoadState("success");
            } else {
              setLoadState("failure");
            }
          }
        });
    }
  }, [loadState]);

  return (
    <li style={{ color: stateColor(loadState) }}>
      <IconForState state={loadState} />
      <span style={{ marginLeft: `5px` }}>{path}</span>
    </li>
  );
}

TestRoute.propTypes = {
  path: PropTypes.string,
  checkFor: PropTypes.string,
  loadState: PropTypes.string,
  setLoadState: PropTypes.func,
};

export default function APITestCard() {
  const [started, setStarted] = useState(false);
  const [running, setRunning] = useState(false);
  const [testRoutes, setTestRoutes] = useState([
    { path: "/api/academics/degree_progress/grad" },
    { path: "/api/academics/degree_progress/ugrd", checkFor: "feed" },
    { path: "/api/academics/enrollment_verification", checkFor: "messages" },
    { path: "/api/academics/exam_results", checkFor: "exams" },
    { path: "/api/academics/has_exam_results" },
    {
      path: "/api/academics/pnp_calculator/ratio_message",
      checkFor: "descrlong",
    },
    { path: "/api/academics/transfer_credits", checkFor: "undergraduate" },
    { path: "/api/advising/my_advising", checkFor: "feed" },
    {
      path: "/api/campus_solutions/delegate_terms_and_conditions",
      checkFor: "feed",
    },
    { path: "/api/campus_solutions/delegate_access/students" },
    { path: "/api/campus_solutions/billing_activity", checkFor: "feed" },
    { path: "/api/campus_solutions/billing_links", checkFor: "feed" },
    { path: "/api/campus_solutions/faculty_resources", checkFor: "feed" },
    { path: "/api/campus_solutions/financial_aid_compare_awards_current" },
    { path: "/api/campus_solutions/financial_aid_compare_awards_list" },
    { path: "/api/campus_solutions/financial_aid_compare_awards_prior" },
    { path: "/api/campus_solutions/financial_aid_data" },
    { path: "/api/campus_solutions/fpp_enrollment", checkFor: "feed" },
    { path: "/api/campus_solutions/higher_one_url", checkFor: "feed" },
    { path: "/api/campus_solutions/link" },
    { path: "/api/campus_solutions/slr_deeplink", checkFor: "feed" },
    { path: "/api/campus_solutions/student_resources", checkFor: "feed" },
    { path: "/api/config", checkFor: "csrfToken" },
    { path: "/api/financial_aid/financial_resources", checkFor: "links" },
    { path: "/api/my/am_i_logged_in" },
    { path: "/api/my/aid_years", checkFor: "aidYears" },
    { path: "/api/my/academics", checkFor: "semesters" },
    { path: "/api/my/academics/diploma" },
    { path: "/api/my/academics/standings", checkFor: "learnMoreMessage" },
    { path: "/api/my/academics/status_and_holds" },
    {
      path: "/api/my/academic_records",
      checkFor: "officialTranscriptRequestData",
    },
    { path: "/api/my/badges", checkFor: "badges" },
    { path: "/api/my/campuslinks", checkFor: "links" },
    { path: "/api/my/classes", checkFor: "classes" },
    { path: "/api/my/finances/billing_items" },
    { path: "/api/my/financial_aid_summary", checkFor: "financialAidSummary" },
    { path: "/api/my/financial_aid/award_comparison", checkFor: "message" },
    { path: "/api/my/groups", checkFor: "groups" },
    { path: "/api/my/holds", checkFor: "feed" },
    { path: "/api/my/law_awards", checkFor: "awards" },
    { path: "/api/my/loan_history_aid_years", checkFor: "links" },
    { path: "/api/my/loan_history_cumulative", checkFor: "links" },
    { path: "/api/my/loan_history_inactive", checkFor: "links" },
    { path: "/api/my/loan_history_summary" },
    { path: "/api/my/photo" },
    { path: "/api/my/registrations" },
    { path: "/api/my/residency", checkFor: "residency" },
    { path: "/api/my/status", checkFor: "isLoggedIn" },
    { path: "/api/my/tasks/agreements", checkFor: "completedAgreements" },
    { path: "/api/my/tasks/b_courses_activities" },
    { path: "/api/my/tasks/b_courses_todos", checkFor: "bCoursesTodos" },
    { path: "/api/my/tasks/checklist_items", checkFor: "incompleteItems" },
    { path: "/api/my/tasks/web_messages", checkFor: "universityNotifications" },
    { path: "/api/my/bcal_schedule", checkFor: "items" },
    { path: "/api/ping" },
    { path: "/api/server_info", checkFor: "firstVisited" },
    { path: "/api/service_alerts" },
    { path: "/api/stats" },
    { path: "/api/view_as/my_stored_users", checkFor: "users" },
  ]);

  const start = () => {
    setTestRoutes((prevState) =>
      prevState.map((item) => ({ ...item, loadState: "idle" }))
    );
    setStarted(true);
    setRunning(true);
  };

  const onComplete = (path) => (loadState) => {
    setTestRoutes((prevState) => {
      const found = prevState.find((obj) => obj.path === path);

      const newState = prevState.map((item, index) => {
        if (prevState.indexOf(found) === index) {
          return { ...found, loadState: loadState };
        }

        return item;
      });

      const foundPending = newState.find((item) => item.loadState == "pending");

      if (!foundPending) {
        setRunning(false);
      }

      return newState;
    });
  };

  return (
    <RequireSuperuser>
      <Card>
        <CardTitle>
          <h2>API Test</h2>
        </CardTitle>
        <CardBody>
          <BlueButton onClick={start} disabled={running}>
            Run
          </BlueButton>
          <MediumSpacer />

          {started && (
            <UnstyledList>
              {testRoutes.map(({ path, checkFor, loadState }) => (
                <TestRoute
                  key={path}
                  path={path}
                  checkFor={checkFor}
                  loadState={loadState}
                  setLoadState={onComplete(path)}
                />
              ))}
            </UnstyledList>
          )}
        </CardBody>
      </Card>
    </RequireSuperuser>
  );
}
