import styled from "styled-components";

const PopoverMenu = styled.div`
  position: absolute;
  right: 0;
  border-radius: var(--normal-radius);

  padding-bottom: 10px;
  width: 300px;
  background: #eee;
  color: var(--text-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  top: 35px;

  ${(props) =>
    props.triangle
      ? `
      &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        right: 11px;

        border-bottom: 11px solid #ccc;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
      }`
      : null}
`;

export default PopoverMenu;
