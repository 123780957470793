import React from "react";
import { react2angular } from "react2angular";

import { BrowserRouter as Router } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import UserPopover from "components/StatusIcons/UserPopover";

function NgUserPopover() {
  return (
    <ReduxProvider>
      <Router>
        <UserPopover />
      </Router>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("userPopover", react2angular(NgUserPopover));
