import React from "react";

import LightText from "components/ui/LightText";
import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import styled from "styled-components";
import { ClockIcon } from "components/ui/Icons";

export const GreyClockIcon = styled(ClockIcon)`
  color: var(--gumbo);
`;

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const ExpectedGraduationTermUndergraduate = () => {
  const {
    isNonDegreeSeekingSummerVisitor,
    termsInAttendance,
    undergraduateGraduationTerm,
  } = useSelector((state) => {
    const {
      advising: { userId: advisingStudentUid },
    } = state;

    const {
      advisingStudentAcademicProfile: {
        termsInAttendance,
        graduation: { undergraduate: undergraduateGraduationTerm } = {},
      } = {},
    } = advisingStudentState(state, advisingStudentUid) || {};

    const {
      advising: {
        studentData: {
          academicRoles: {
            historical: { degreeSeeking, summerVisitor } = {},
          } = {},
        } = {},
      } = {},
    } = state;

    const isNonDegreeSeekingSummerVisitor = summerVisitor && !degreeSeeking;

    return {
      isNonDegreeSeekingSummerVisitor,
      termsInAttendance,
      undergraduateGraduationTerm,
    };
  });

  if (
    !isNonDegreeSeekingSummerVisitor &&
    (termsInAttendance || undergraduateGraduationTerm)
  ) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>Terms Information</StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {termsInAttendance && (
            <div>
              <LightText>Terms in Attendance</LightText>
              <div>{termsInAttendance}</div>
            </div>
          )}

          {undergraduateGraduationTerm &&
            undergraduateGraduationTerm.termName && (
              <div>
                <LightText>Expected Graduation</LightText>
                <span>
                  {undergraduateGraduationTerm.appointmentsInGraduatingTerm && (
                    <GreyClockIcon />
                  )}
                  <strong>
                    <span>{undergraduateGraduationTerm.termName}</span>
                  </strong>
                </span>
              </div>
            )}
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
ExpectedGraduationTermUndergraduate.displayName =
  "ExpectedGraduationTermUndergraduate";
