import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  UC_CX_GT_SSCNTENRL_ADD,
  UC_CX_GT_SSCNTENRL_VIEW,
  UC_CX_GT_SSCNTENRL_UPD,
  UC_SR_SS_ENROLLMENT_CENTER,
} from "functions/urlIds";

import { presentationShape, termInstructionShape } from "./propTypes";

import { BlueButtonLink } from "components/ui/ButtonLink";
import WithAPILink from "components/ui/WithAPILink";
import EnrollmentCard from "./EnrollmentCard";
import EnrollmentInfoExpander from "./EnrollmentInfoExpander";
import EnrollmentInfo from "./ConcurrentEnrollment/EnrollmentInfo";
import StyledMessage from "components/StyledMessage";

export default function ConcurrentEnrollmentCard({
  presentations = [],
  termInstructions = [],
}) {
  const [resourcesExpanded, setResourcesExpanded] = useState(true);

  return (
    <EnrollmentCard
      presentations={presentations}
      termInstructions={termInstructions}
    >
      {() => {
        return (
          <StyledMessage>
            <p>
              <strong>Enroll</strong>
            </p>
            <p>
              <WithAPILink urlID={UC_CX_GT_SSCNTENRL_ADD} as={BlueButtonLink} />
            </p>
            <p>
              <WithAPILink urlID={UC_CX_GT_SSCNTENRL_VIEW} />
              <span style={{ padding: `0 10px` }}>•</span>
              <WithAPILink urlID={UC_CX_GT_SSCNTENRL_UPD} />
            </p>
            <p>
              <strong>Update Enrollments</strong>
            </p>
            <div>
              <WithAPILink
                urlID={UC_SR_SS_ENROLLMENT_CENTER}
                text="Edit Class Options or Drop a Class"
              />
            </div>
            <hr />
            <EnrollmentInfoExpander
              title="Learn more about Enrollment"
              className="cc-button-link"
              onClick={() => setResourcesExpanded(!resourcesExpanded)}
              expanded={resourcesExpanded}
            />
            {resourcesExpanded && <EnrollmentInfo />}
          </StyledMessage>
        );
      }}
    </EnrollmentCard>
  );
}

ConcurrentEnrollmentCard.propTypes = {
  presentations: PropTypes.arrayOf(presentationShape),
  termInstructions: PropTypes.arrayOf(termInstructionShape),
};
