import _ from "lodash";
import { fetchUserProfile } from "data/slices/userProfile";
import { slice as statusSlice } from "data/slices/myStatus";

angular
  .module("calcentral.services")
  .service(
    "userService",
    function ($http, $location, $route, httpService, utilService, $ngRedux) {
      var profile = {};
      var events = {
        isLoaded: false,
        isAuthenticated: false,
        isAuthenticatedAndHasGoogle: false,
        profile: false,
      };
      var statusURL = "/api/my/status";

      // Private methods that are only exposed for testing but shouldn't be used within the views

      var redirectToHome = function () {
        if ($location.path() === "/") {
          if (profile.hasDashboardTab) {
            utilService.redirect("dashboard");
          } else if (profile.hasAcademicsTab) {
            utilService.redirect("academics");
          } else if (profile.hasFinancialsTab) {
            utilService.redirect("finances");
          } else if (profile.hasCampusTab) {
            utilService.redirect("campus");
          } else if (profile.hasToolboxTab) {
            utilService.redirect("toolbox");
          } else {
            utilService.redirect("inactive");
          }
        }
      };

      var redirectToPage = function (page) {
        var pageAccessible = false;
        switch (page) {
          case "dashboard": {
            pageAccessible = !!profile.hasDashboardTab;
            break;
          }
          case "academics": {
            pageAccessible = !!profile.hasDashboardTab;
            break;
          }
          case "finances": {
            pageAccessible = !!profile.hasFinancialsTab;
            break;
          }
          case "campus": {
            pageAccessible = !!profile.hasCampusTab;
            break;
          }
          case "toolbox": {
            pageAccessible = true;
            break;
          }
        }
        if (pageAccessible) {
          utilService.redirect(page);
        } else {
          redirectToHome();
        }
      };

      /*
       * Handle the access to the page that the user is watching
       * This will depend on
       *   - whether they are logged in or not
       *   - whether the page is public
       */
      var handleAccessToPage = function () {
        // Redirect to the login page when the page is private and you aren't authenticated
        if (!$route.current.isPublic && !events.isAuthenticated) {
          signIn();
          // Record that the user visited calcentral
        } else if (
          events.isAuthenticated &&
          !profile.firstLoginAt &&
          !profile.actingAsUid &&
          !profile.delegateActingAsUid &&
          !profile.advisorActingAsUid
        ) {
          $http.post("/api/my/record_first_login");
          profile.firstLoginAt = new Date().getTime();
          redirectToHome();
        } else if (events.isAuthenticated) {
          redirectToHome();
        }
      };

      var setExtraProperties = function (profile) {
        if (profile.roles) {
          // Set this boolean to true when they only have the applicant role
          var activeRoles = getActiveRoles(profile.roles);
          profile.isApplicantOnly =
            _.size(activeRoles) === 1 && activeRoles[0] === "applicant";
        }

        // Set whether the current user can POST information when acting as someone
        profile.actAsOptions = {
          canPost: !(
            _.get(profile, "features.preventActingAsUsersFromPosting") &&
            !profile.isDirectlyAuthenticated
          ),
          canSeeCSLinks:
            profile.canSeeCSLinks && !$route.current.isAdvisingStudentLookup,
        };

        return profile;
      };

      /**
       * Returns array of active roles
       * @param  {Object} roles   profile roles object
       * @return {Array}          array of role keys that are true
       */
      var getActiveRoles = function (roles) {
        var activeRoles = [];
        _.forEach(roles, function (value, key) {
          if (value) {
            activeRoles.push(key);
          }
        });
        return activeRoles;
      };

      var handleUserLoaded = function (data) {
        angular.extend(profile, data);

        // Set extra properties on the profile
        profile = setExtraProperties(profile);

        events.isLoaded = true;
        // Check whether the current user is authenticated or not
        events.isAuthenticated = profile && profile.isLoggedIn;
        // Check whether the current user is authenticated and has a google access token
        events.isAuthenticatedAndHasGoogle =
          profile.isLoggedIn && profile.hasGoogleAccessToken;
        // Expose the profile into events
        events.profile = profile;

        handleAccessToPage();
      };

      /**
       * Get the actual user information
       * @param {Object} options Options that need to be passed through
       * @return {Object} user information
       */
      var fetch = function (options) {
        httpService.clearCache(options, statusURL);

        // TODO: Migrate user profile dependencies from
        // /api/my/status to userProfile
        $ngRedux.dispatch(fetchUserProfile());
        $ngRedux.dispatch(statusSlice.actions.start());

        $ngRedux.subscribe(() => {
          const { userProfile } = $ngRedux.getState() || {};
          angular.extend(profile, userProfile);
        });

        return $http
          .get(statusURL, {
            cache: true,
          })
          .then(function (xhr) {
            $ngRedux.dispatch(statusSlice.actions.success(xhr.data));
            return handleUserLoaded(xhr.data);
          })
          .catch((error) => {
            $ngRedux.dispatch(
              statusSlice.actions.failure({
                status: error.status,
                statusText: error.statusText,
              })
            );
          });
      };

      // Deprecated: see src/functions/users.js
      var authorizeGoogleAccess = function () {
        window.location = "/api/google/request_authorization";
      };

      var handleRouteChange = function () {
        if (!profile.features) {
          fetch();
        } else {
          handleAccessToPage();
        }
      };

      var signIn = function () {
        window.location = "/auth/cas";
      };

      var signOut = function () {
        $http.post("/logout").then(
          function successCallback(response) {
            if (response.data && response.data.redirectURL) {
              window.location = response.data.redirectURL;
            }
          },
          function errorCallback(response) {
            if (response && response.status === 401) {
              // User is already logged out
              window.location = "/";
            }
          }
        );
      };

      // Expose methods
      return {
        authorizeGoogleAccess: authorizeGoogleAccess,
        events: events,
        fetch: fetch,
        handleAccessToPage: handleAccessToPage,
        handleRouteChange: handleRouteChange,
        handleUserLoaded: handleUserLoaded,
        profile: profile,
        redirectToHome: redirectToHome,
        redirectToPage: redirectToPage,
        signIn: signIn,
        signOut: signOut,
      };
    }
  );
