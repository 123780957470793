import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Helmet from "react-helmet";
import { setCurrentRouteProperties } from "data/actions/routeActions";

export function Page({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      setCurrentRouteProperties({
        name: document.title,
        url: window.location.href,
        pathname: location.pathname,
      })
    );
  }, [dispatch, location]);

  return <main>{children}</main>;
}

Page.displayName = "Page";
Page.propTypes = {
  children: PropTypes.node,
};

export function PageTitle({ title }) {
  return (
    <Helmet>
      <title>{title} | CalCentral</title>
    </Helmet>
  );
}

PageTitle.propTypes = { title: PropTypes.string };

export const Columns = styled.div`
  display: flex;
  gap: 15px;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const UnGappedColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const PageHeader = styled.h1`
  color: var(--mine-shaft);
  margin: 0;
  padding: 10px 0 0 10px;
  font-weight: normal;
`;
