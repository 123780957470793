// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner___QF4Rq{margin-top:15px;background:var(--colonial-white);height:22px;line-height:22px;text-align:center}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/NotificationsCard/University/Unread.module.scss"],"names":[],"mappings":"AAIA,gBACE,eAAA,CACA,gCAAA,CACA,WALO,CAMP,gBANO,CAOP,iBAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n$height: 22px;\n\n.banner {\n  margin-top: 15px;\n  background: var(--colonial-white);\n  height: $height;\n  line-height: $height;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "banner___QF4Rq"
};
export default ___CSS_LOADER_EXPORT___;
