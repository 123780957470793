import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router } from "react-router-dom";

import { ReduxProvider } from "components/data/ReduxProvider";
import { StudentLookupCard } from "components/cards/StudentLookupCard";

function NgStudentLookupCard() {
  return (
    <Router>
      <ReduxProvider>
        <StudentLookupCard />
      </ReduxProvider>
    </Router>
  );
}

angular
  .module("calcentral.react")
  .component("studentLookupCard", react2angular(NgStudentLookupCard));
