import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import TinyText from "components/ui/TinyP";
import WithAPILink from "components/ui/WithAPILink";
import WithLinks from "components/WithLinks";

import clipboardList from "icons/clipboard-list.svg";

export default connect(mapStateToProps)(LawDegreeAudit);

function mapStateToProps({
  apiLinks: { loadState = "pending" } = {},
  myStatus: {
    academicRoles: { current: { lawDegreeAudit: showCard = false } = {} } = {},
  } = {},
}) {
  return {
    loadState,
    showCard,
  };
}

function LawDegreeAudit({ loadState, showCard }) {
  if (showCard) {
    return (
      <WithLinks>
        <Card>
          <CardTitle>
            <h2>Law Degree Audit</h2>
          </CardTitle>
          <CardBody>
            {loadState === "pending" ? (
              <Spinner />
            ) : loadState === "failure" ? (
              <>The Law Degree Audit is not available right now.</>
            ) : (
              <DegreeAuditStyles>
                <div>
                  <img src={clipboardList} />
                </div>
                <div>
                  <h3>Law Degree Audit</h3>
                  <TinyText>
                    Review your progress in meeting degree requirements.
                  </TinyText>
                  <p>
                    <WithAPILink urlID="UC_AA_LAW_DEGREE_AUDIT" />
                  </p>
                  <p>
                    Learn more at{" "}
                    <WithAPILink urlID="UC_AA_LAW_DEGREE_AUDIT_FAQ" />
                  </p>
                </div>
              </DegreeAuditStyles>
            )}
          </CardBody>
        </Card>
      </WithLinks>
    );
  }

  return null;
}

LawDegreeAudit.propTypes = {
  loadState: PropTypes.string,
  showCard: PropTypes.bool,
};

const DegreeAuditStyles = styled.div`
  display: flex;

  div:first-child {
    width: 20px;
    padding-top: 2px;
  }

  h3 {
    color: var(--dove-gray);
    font-size: 13px;
    margin: 0;
  }

  h3 + p {
    margin-top: 0;
  }

  p {
    line-height: 1.5;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
