import styled from "styled-components";

import blueSquareIcon from "icons/blue-square.svg";

const StyledMessage = styled.div`
  margin-top: 10px;

  h2 {
    font-size: 13px;
    font-weight: bold;
  }

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: none;

    li {
      background-image: url("${blueSquareIcon}");
      background-position: 0 7px;
      background-repeat: no-repeat;
      padding-left: var(--large-spacing);
    }
  }
`;

export default StyledMessage;
