import React from "react";
import PropTypes from "prop-types";
import { UnstyledList } from "components/ui/Lists";
import ResultUser from "./ResultUser";
export default function UserSearchResults({ users, afterToggleSave, query }) {
  if (users.length > 0) {
    return (
      <UnstyledList>
        {users.map((user) => (
          <ResultUser
            key={user.studentId}
            user={user}
            afterToggleSave={afterToggleSave}
          />
        ))}
      </UnstyledList>
    );
  } else {
    return (
      <p>Your search on &quot;{query}&quot; did not match any students.</p>
    );
  }
}

UserSearchResults.displayName = "UserSearchResults";
UserSearchResults.propTypes = {
  users: PropTypes.array,
  afterToggleSave: PropTypes.func,
  query: PropTypes.string,
};
