// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pagination___ibElz{display:flex;justify-content:center;align-items:center}.currentState___Aufw4{margin:0 15px}", "",{"version":3,"sources":["webpack://./src/components/Pagination.module.scss"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sBACE,aAAA","sourcesContent":["@import '../react/stylesheets/variables';\n\n.Pagination {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.currentState {\n  margin: 0 $large-spacing;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Pagination": "Pagination___ibElz",
	"currentState": "currentState___Aufw4"
};
export default ___CSS_LOADER_EXPORT___;
