import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "../Button";

const TermLimiter = ({
  hubTermFeatureActivated,
  pastSemestersCount,
  setTermsExpanded,
  termsExpanded,
}) => {
  const [termDataNoticeExpanded, setTermDataNoticeExpanded] = useState(false);

  return (
    <div style={{ marginTop: "15px" }}>
      {pastSemestersCount <= 1 && hubTermFeatureActivated && (
        <>
          <strong>Note: </strong>
          Enrollment data for current term and back to Spring 2010 (where
          applicable) is displayed.
          {!termDataNoticeExpanded && (
            <div>
              <Button
                style={{ display: "block", margin: "10px auto" }}
                onClick={() => {
                  setTermDataNoticeExpanded(true);
                }}
              >
                Show more
              </Button>
            </div>
          )}
          {termDataNoticeExpanded && (
            <div style={{ marginTop: "10px" }}>
              If enrollments exist in terms prior to Spring 2010, the data will
              be displayed in Summer 2017. If you require a full record now,
              please order a transcript.
            </div>
          )}
        </>
      )}
      {pastSemestersCount > 1 && (
        <Button
          style={{ display: "block", margin: "10px auto" }}
          onClick={() => {
            setTermsExpanded(!termsExpanded);
          }}
        >
          {termsExpanded && <span>Show Less</span>}
          {!termsExpanded && <span>Show More</span>}
        </Button>
      )}
    </div>
  );
};

TermLimiter.propTypes = {
  hubTermFeatureActivated: PropTypes.bool,
  pastSemestersCount: PropTypes.number,
  setTermsExpanded: PropTypes.func,
  termsExpanded: PropTypes.bool,
};

export default TermLimiter;
