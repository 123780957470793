import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import { AdvisingResourcesCard } from "components/cards/AdvisingResourcesCard";
import { LargeSpacer } from "components/ui/VerticalSpacers";

const NgAdvisingResources = () => (
  <ReduxProvider>
    <LargeSpacer />
    <AdvisingResourcesCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("advisingResources", react2angular(NgAdvisingResources));
