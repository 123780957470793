import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import APILink from "components/ui/APILink";

import reviewIcon from "icons/review.svg";

import AccessibleLinkContent from "components/ui/AccessibleLinkContent";

const MessagesStyles = styled.div`
  flex: 1;
`;

const MessageStyles = styled.div`
  padding-top: var(--large-spacing);
  padding-bottom: var(--large-spacing);

  &:hover {
    background-color: var(--foam);
  }

  .source {
    color: var(--dove-gray)
    font-size: 11px;
    line-height: 13px;
  }

  .title {
    color: var(--mine-shaft);
    font-size: 13px;
    line-height: var(--large-spacing);
  }
`;

const MessageLink = styled.a`
  display: flex;
  margin-top: var(--medium-spacing);
  gap: var(--small-spacing);
`;

const Messages = ({ messages }) => {
  return (
    <MessagesStyles>
      {messages.map((message, index) => (
        <Message key={index} message={message} />
      ))}
    </MessagesStyles>
  );
};

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      source: PropTypes.string,
    })
  ),
};

const Message = ({
  message: { source, title, actionText, link, fixedURL },
}) => {
  return (
    <MessageStyles>
      <div className="source">{source}</div>
      <div className="title">{title}</div>

      {fixedURL ? (
        <APILink link={link} as={MessageLink} />
      ) : (
        <APILink link={link}>
          {({ props, showNewWindow }) => (
            <MessageLink {...props}>
              <AccessibleLinkContent showNewWindow={showNewWindow}>
                <img src={reviewIcon} width="15" />
                <span>View and {actionText}</span>
              </AccessibleLinkContent>
            </MessageLink>
          )}
        </APILink>
      )}
    </MessageStyles>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string,
    source: PropTypes.string,
    link: PropTypes.object,
    actionText: PropTypes.string,
    fixedURL: PropTypes.bool,
  }),
};

export default Messages;
