import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

export default function PageTitle({ title }) {
  return (
    <Helmet>
      <title>{title} | CalCentral</title>
    </Helmet>
  );
}

PageTitle.propTypes = { title: PropTypes.string };
