import {
  FETCH_BILLING_ITEMS_START,
  FETCH_BILLING_ITEMS_SUCCESS,
  FETCH_BILLING_ITEMS_FAILURE,
  FETCH_BILLING_ITEM_START,
  FETCH_BILLING_ITEM_SUCCESS,
  FETCH_BILLING_ITEM_FAILURE,
} from '../action-types';

export const fetchBillingItemsStart = () => ({
  type: FETCH_BILLING_ITEMS_START,
});

export const fetchBillingItemsSuccess = billingItems => ({
  type: FETCH_BILLING_ITEMS_SUCCESS,
  value: billingItems,
});

export const fetchBillingItemsFailure = error => ({
  type: FETCH_BILLING_ITEMS_FAILURE,
  value: error,
});

export const fetchBillingItems = () => {
  return (dispatch, getState) => {
    const { billingItems } = getState();

    if (billingItems.loaded || billingItems.isLoading) {
      return new Promise((resolve, _reject) => resolve(billingItems));
    } else {
      dispatch(fetchBillingItemsStart());

      fetch('/api/my/finances/billing_items', { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(
              fetchBillingItemsFailure(failure)
            );
          }
        })
        .then((data) => {
          dispatch(fetchBillingItemsSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchBillingItemsFailure(failure));
          }
        });
    }
  };
};

export const fetchBillingItemStart = id => ({
  type: FETCH_BILLING_ITEM_START,
  value: id,
});

export const fetchBillingItemSuccess = billingItem => ({
  type: FETCH_BILLING_ITEM_SUCCESS,
  value: billingItem,
});

export const fetchBillingItemFailure = (id, error) => ({
  type: FETCH_BILLING_ITEM_FAILURE,
  id: id,
  value: error,
});

export const fetchBillingItem = id => {
  return (dispatch, getState) => {
    const { billingItems: { items } = {} } = getState();

    const item = items.find(item => item.id === id);

    if (item.loadedPayments || item.loadingPayments) {
      return new Promise((resolve, _reject) => resolve(item));
    } else {
      dispatch(fetchBillingItemStart(id));

      fetch(`/api/my/finances/billing_items/${item.id}`, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(
              fetchBillingItemFailure(item.id, failure)
            );
          }
        })
        .then((data) => {
          dispatch(fetchBillingItemSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchBillingItemsFailure(failure));
          }
        });
    }
  };
};
