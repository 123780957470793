import React, { useState } from "react";
import PropTypes from "prop-types";

import ellipsize from "ellipsize";

import ClassLabel from "./ClassLabel";
import ClassGrade from "./ClassGrade";
import ClassSchedules from "./ClassSchedules";
import GradingLapseDeadline from "./GradingLapseDeadline/GradingLapseDeadline";
import MidpointGradeNotice from "./MidpointGradeNotice";

import styles from "./SemestersCard.module.scss";

const SectionRows = ({
  canViewGrades,
  isAdvisingView,
  isInactiveTerm,
  semesterClass,
  section,
}) => {
  if (!section) {
    return null;
  }

  const [midpointGradeExpanded, setMidpointGradeExpanded] = useState(false);

  return (
    <React.Fragment key={section.ccn}>
      {section.is_primary_section &&
        !section.waitlisted &&
        !semesterClass.transcript && (
          <>
            <tr
              className={`${styles.sectionRows} ${styles.backgroundOverride}`}
              data-testid="semester-class-section-row"
            >
              <ClassLabel
                isAdvisingView={isAdvisingView}
                section={section}
                semesterClass={semesterClass}
              />
              <td className={styles.classTitleDataCell}>
                <div data-testid="semester-class-section-row-title">
                  {!isInactiveTerm && ellipsize(semesterClass.title, 28)}
                  {isInactiveTerm && semesterClass.title}
                </div>
                <ClassSchedules
                  section={section}
                  semesterClass={semesterClass}
                  isInactiveTerm={isInactiveTerm}
                />
              </td>
              <td
                className={styles.classUnitsDataCell}
                data-testid="semester-class-section-row-units"
              >
                {section.units && (
                  <span>{Number.parseFloat(section.units).toFixed(1)}</span>
                )}
              </td>
              <td
                className={styles.classGradeDataCell}
                data-testid="semester-class-section-row-grade"
              >
                <ClassGrade
                  canViewGrades={canViewGrades}
                  midpointGradeExpanded={midpointGradeExpanded}
                  section={section}
                  setMidpointGradeExpanded={setMidpointGradeExpanded}
                />
              </td>
            </tr>
            <GradingLapseDeadline
              section={section}
              frozenDisplayColumnIndex={3}
              lapseDateDisplayColumnIndex={0}
              totalColumns={4}
            />
            <MidpointGradeNotice
              midpointGradeExpanded={midpointGradeExpanded}
              setMidpointGradeExpanded={setMidpointGradeExpanded}
              section={section}
            />
          </>
        )}
    </React.Fragment>
  );
};

SectionRows.propTypes = {
  canViewGrades: PropTypes.bool,
  isAdvisingView: PropTypes.bool,
  isInactiveTerm: PropTypes.bool,
  section: PropTypes.object,
  semesterClass: PropTypes.object,
};

export default SectionRows;
