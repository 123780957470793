import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ValueOrDash from "components/cards/AcademicSummaryCard/ValueOrDash";
import SectionIncompleteGradingStatus from "./SectionIncompleteGradingStatus";
import LawSection from "./LawSection";
import { useSlice } from "components/hooks/useSlice";

const SingleSection = ({
  showPoints,
  canViewGrades,
  klass,
  requirementsDesignation,
  units,
  lawUnits,
  grading,
  sectionLabel,
}) => {
  if (!showPoints) {
    return (
      <LawSection
        klass={klass}
        requirementsDesignation={requirementsDesignation}
        sectionLabel={sectionLabel}
        lawUnits={lawUnits}
        grading={grading}
        units={units}
        canViewGrades={canViewGrades}
        showPoints={showPoints}
      />
    );
  }

  const formattedlawUnits = lawUnits || "0.0";

  return (
    <Fragment>
      <tr data-testid="academic-summary-semester-primary-section-row">
        <td>
          <a href={klass.url}>
            {klass.course_code}&nbsp;
            {sectionLabel && <Fragment>{sectionLabel}&nbsp;</Fragment>}
            {klass.session_code && (
              <Fragment>(Session {klass.session_code})</Fragment>
            )}
          </a>
        </td>
        <td data-testid="academic-summary-semester-class-title">
          {klass.title}&nbsp;
          {requirementsDesignation && (
            <div
              className="cc-requirements-designation"
              data-testid="academic-summary-semester-primary-section-requirements-designation"
            >
              {requirementsDesignation}
            </div>
          )}
        </td>
        <td
          style={{ textAlign: `right`, paddingRight: `var(--lg)` }}
          data-testid="academic-summary-semester-primary-section-units"
        >
          <ValueOrDash value={units} />
        </td>
        {!showPoints && (
          <td
            style={{ textAlign: `right`, paddingRight: `var(--lg)` }}
            data-testid="academic-summary-semester-primary-section-law-units"
          >
            <ValueOrDash value={formattedlawUnits} />
          </td>
        )}
        <td>
          {canViewGrades && grading && <ValueOrDash value={grading.grade} />}
        </td>
        <td>
          {canViewGrades && showPoints && grading && (
            <ValueOrDash value={grading.gradePointsAdjusted} />
          )}
        </td>
      </tr>
      {grading && (
        <SectionIncompleteGradingStatus
          gradingLapseDeadlineDisplay={grading.gradingLapseDeadlineDisplay}
          gradingLapseDeadline={grading.gradingLapseDeadline}
          gradingBasis={grading.gradingBasis}
          frozenDisplayColumnIndex={3}
          lapseDateDisplayColumnIndex={3}
          totalColumns={5}
          klass={klass}
        />
      )}
    </Fragment>
  );
};
SingleSection.propTypes = {
  klass: PropTypes.object,
  canViewGrades: PropTypes.bool,
  showPoints: PropTypes.bool,
  requirementsDesignation: PropTypes.string,
  units: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lawUnits: PropTypes.number,
  grading: PropTypes.object,
  sectionLabel: PropTypes.string,
};

export const PrimarySection = ({ section, showPoints }) => {
  const { canViewGrades } = useSlice("myStatus");

  if (section.class.multiplePrimaries) {
    return section.class.sections.map((sek, index) => (
      <SingleSection
        key={index}
        showPoints={showPoints}
        canViewGrades={canViewGrades}
        klass={section.class}
        requirementsDesignation={sek.requirementsDesignation}
        units={sek.units}
        lawUnits={sek.lawUnits}
        grading={sek.grading}
        sectionLabel={sek.section_label}
      />
    ));
  }

  return (
    <SingleSection
      showPoints={showPoints}
      canViewGrades={canViewGrades}
      klass={section.class}
      requirementsDesignation={section.requirementsDesignation}
      units={section.units}
      lawUnits={section.lawUnits}
      grading={section.grading}
      sectionLabel={null}
    />
  );
};

PrimarySection.propTypes = {
  section: PropTypes.object,
  showPoints: PropTypes.bool,
};
