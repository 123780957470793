'use strict';

var _ = require('lodash');

/**
 * SIR (Statement of Intent to Register) item received controller
 * This controller will be executed when the current checklist item is in received status
 */
angular.module('calcentral.controllers').controller('SirItemReceivedController', function(apiService, sirFactory, sirService, $interval, $scope, $q) {
  $scope.formatCurrency = sirService.formatCurrency;

  // The Higher One URL expires after 5 minutes, so we refresh it every 4.5 minutes
  var expireTimeMilliseconds = 4.5 * 60 * 1000;

  $scope.higherOneURL = '';

  var getHigherOneURL = function() {
    return sirFactory.getHigherOneURL({
      refreshCache: true
    }).then(
      function successCallback(response) {
        $scope.higherOneURL = _.get(response, 'data.feed.root.higherOneURL.url');
      }
    );
  };

  /*
   * Start the Higher One URL interval since it expires after 5 minutes
   */
  var startHigherOneURLInterval = function() {
    getHigherOneURL();
    $interval(getHigherOneURL, expireTimeMilliseconds);
  };

  var init = function() {
    if (apiService.user.profile.canActOnFinances) {
      startHigherOneURLInterval();
    }

    return $q.resolve($scope.higherOneURL);
  };

  init();
});
