import React from "react";
import PropTypes from "prop-types";

export const ExamRow = ({ name, value }) => {
  if (value > 0) {
    return (
      <tr data-testid="transfer-credit-exam-row">
        <td>{name}</td>
        <td className="text-right">{value.toFixed(3)}</td>
      </tr>
    );
  } else {
    return null;
  }
};

ExamRow.displayName = "ExamRow";
ExamRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
};
