import {
  fetchAdvisingAcademicsStart,
  fetchAdvisingAcademicsSuccess
} from 'data/actions/advisingActions';

angular.module('calcentral.factories').factory('advisingFactory', function (apiService, $ngRedux) {
  var urlAdvisingAcademics = '/api/advising/academics/';
  var urlAdvisingAcademicsCacheExpiry = '/api/advising/cache_expiry/academics/';
  var urlAdvisingCommittees = '/api/advising/student_committees/';
  var urlAdvisingDegreeProgressGraduate = '/api/advising/degree_progress/grad/';
  var urlAdvisingDegreeProgressUndergrad = '/api/advising/degree_progress/ugrd/';
  var urlAdvisingRegistrations = '/api/advising/registrations/';
  var urlAdvisingStudent = '/api/advising/student/';
  var urlAdvisingStudentSuccess = '/api/advising/student_success/';
  var urlAlumniProfile = '/api/alumni/alumni_profiles';

  // Dummy feeds
  // var urlAdvisingAcademics = '/dummy/json/advising_student_academics.json';
  // var urlAdvisingCommittees = '/dummy/json/advising_committees.json';
  // var urlAdvisingDegreeProgressGraduate = '/dummy/json/degree_progress_grad.json';
  // var urlAdvisingDegreeProgressUndergrad = '/dummy/json/degree_progress_ugrd.json';
  // var urlAdvisingRegistrations = 'dummy/json/advising_registrations.json';
  // var urlAdvisingStudent = '/dummy/json/advising_student_academics.json';
  // var urlAdvisingStudentSuccess = '/dummy/json/advising_student_success.json';

  var getAlumniProfile = function (options) {
    return apiService.http.request(options, urlAlumniProfile);
  };

  var getStudent = function (options) {
    return apiService.http.request(options, urlAdvisingStudent + options.uid);
  };

  var getStudentAcademics = function (options) {
    const {
      advising: {
        academics = {}
      } = {}
    } = $ngRedux.getState();

    if (academics.loaded || academics.isLoading) {
      return new Promise((resolve, _reject) => resolve({ data: academics }));
    } else {
      $ngRedux.dispatch(fetchAdvisingAcademicsStart());

      const promise = apiService.http.request(options, urlAdvisingAcademics + options.uid);

      promise.then(response => {
        $ngRedux.dispatch(fetchAdvisingAcademicsSuccess(response.data));
      });

      return promise;
    }
  };

  var getStudentRegistrations = function (options) {
    return apiService.http.request(options, urlAdvisingRegistrations + options.uid);
  };

  var getStudentSuccess = function (options) {
    return apiService.http.request(options, urlAdvisingStudentSuccess + options.uid);
  };

  var getDegreeProgressGraduate = function (options) {
    return apiService.http.request(options, urlAdvisingDegreeProgressGraduate + options.uid);
  };

  var getDegreeProgressUndergrad = function (options) {
    return apiService.http.request(options, urlAdvisingDegreeProgressUndergrad + options.uid);
  };

  var expireAcademicsCache = function (options) {
    return apiService.http.request(options, urlAdvisingAcademicsCacheExpiry + options.uid);
  };

  var getStudentCommittees = function (options) {
    return apiService.http.request(options, urlAdvisingCommittees + options.uid);
  };

  return {
    getStudent: getStudent,
    getStudentAcademics: getStudentAcademics,
    getStudentRegistrations: getStudentRegistrations,
    getStudentSuccess: getStudentSuccess,
    getDegreeProgressGraduate: getDegreeProgressGraduate,
    getDegreeProgressUndergrad: getDegreeProgressUndergrad,
    expireAcademicsCache: expireAcademicsCache,
    getStudentCommittees: getStudentCommittees,
    getAlumniProfile: getAlumniProfile
  };
});
