import {
  FETCH_CHECKLIST_ITEMS_START,
  FETCH_CHECKLIST_ITEMS_SUCCESS,
  FETCH_CHECKLIST_ITEMS_FAILURE,
} from 'data/action-types';

export const fetchChecklistItemsStart = () => ({
  type: FETCH_CHECKLIST_ITEMS_START,
});

export const fetchChecklistItemsSuccess = data => ({
  type: FETCH_CHECKLIST_ITEMS_SUCCESS,
  value: data,
});

export const fetchChecklistItemsFailure = () => ({
  type: FETCH_CHECKLIST_ITEMS_FAILURE,
});

export const fetchChecklistItems = () => {
  return (dispatch, getState) => {
    const { myChecklistItems } = getState();

    if (myChecklistItems.loaded || myChecklistItems.isLoading) {
      return new Promise((resolve, _reject) => resolve(myChecklistItems));
    } else {
      dispatch(fetchChecklistItemsStart());
      fetch('/api/my/tasks/checklist_items', { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchChecklistItemsFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchChecklistItemsSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchChecklistItemsFailure(failure));
          }
        });
    }
  };
};
