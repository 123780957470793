import React from "react";
import PropTypes from "prop-types";

import mapStateToCSRF from "utils/mapStateToCSRF";

import ButtonAsLink from "components/ui/ButtonAsLink";
import TableStyles from "./TableStyles";
import TableHeaders from "./TableHeaders";
import { connect } from "react-redux";

import { deleteSavedUsers, deleteSavedUser } from "utils/requests";

function SavedUsers({ csrfToken, savedUsers, viewAsUser, refreshCard }) {
  const deleteUsers = async () => {
    const success = await deleteSavedUsers(csrfToken)();
    if (success) {
      refreshCard();
    }
  };

  const deleteUser = async (user) => {
    const success = await deleteSavedUser(csrfToken)(user);
    if (success) {
      refreshCard();
    }
  };

  return (
    <>
      <strong>Saved Users</strong> (
      <ButtonAsLink onClick={deleteUsers}>clear all</ButtonAsLink>)
      <TableStyles>
        <TableHeaders />
        <tbody>
          {savedUsers.map((user) => (
            <tr key={user.ldapUid}>
              <td>
                <ButtonAsLink onClick={() => viewAsUser(user)}>
                  {user.ldapUid}
                </ButtonAsLink>
              </td>
              <td>{user.studentId ? user.studentId : "none"}</td>
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td>
                <ButtonAsLink onClick={() => deleteUser(user)}>
                  delete
                </ButtonAsLink>
              </td>
            </tr>
          ))}
        </tbody>
      </TableStyles>
    </>
  );
}

SavedUsers.displayName = "SavedUsers";
SavedUsers.propTypes = {
  csrfToken: PropTypes.string,
  savedUsers: PropTypes.array,
  viewAsUser: PropTypes.func,
  refreshCard: PropTypes.func,
};

export default connect(mapStateToCSRF)(SavedUsers);
