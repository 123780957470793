import {
  BILLING_ACTIVITY_START,
  BILLING_ACTIVITY_SUCCESS,
} from 'data/action-types';

export function fetchBillingActivity() {
  return (dispatch, getState) => {
    const { finances } = getState();

    if (finances.loadState === 'success') {
      return new Promise((resolve, _reject) => resolve(finances));
    } else if (finances.loadState !== 'pending') {
      dispatch({ type: BILLING_ACTIVITY_START });

      return fetch('/api/campus_solutions/billing_activity')
        .then(res => res.json())
        .then(({ feed }) => {
          dispatch({
            type: BILLING_ACTIVITY_SUCCESS,
            value: feed,
          });
        });
    }
  };
}
