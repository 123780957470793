import { createSlice, combineReducers } from "@reduxjs/toolkit";
import { delegateToReducerIfKey } from "data/utils/delegateToReducerIfKey";

import { finaidCostOfAttendance } from "./finaid/finaidCostOfAttendance";
import { finaidProfile } from "./finaid/finaidProfile";
import { housing } from "./finaid/housing";

const finaidReducer = combineReducers({ housing, finaidCostOfAttendance, finaidProfile });

const slice = createSlice({
  name: "finaid",
  initialState: {
    name: "finaid",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addDefaultCase(
      delegateToReducerIfKey(finaidReducer, "finaidYearId")
    );
  },
});

export const { reducer: finaid } = slice;
