import React from "react";
import PropTypes from "prop-types";

import { shortDateIfCurrentYear, parseDate } from "functions/formatDate";

import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import APILink from "components/ui/APILink";
import Task from "../Task";
import TaskHeader from "../TaskHeader";
import TaskTitle from "../TaskTitle";
import CampusSolutionsIcon from "../Icons/CampusSolutionsIcon";

const Agreement = ({ agreement: task, index }) => {
  return (
    <Task index={index} task={task} type="" hasDetail={false}>
      <TaskHeader task={task}>
        <CampusSolutionsIcon />
        <TaskTitle
          title={task.title}
          subtitle={`Responded ${task.response} on ${shortDateIfCurrentYear(
            parseDate(task.responseDate)
          )}`}
        />

        {task.url && (
          <APILink link={task.url}>
            {({ props, showNewWindow }) => (
              <a {...props}>
                <AccessibleLinkContent showNewWindow={showNewWindow}>
                  {task.updatesAllowed ? "View/Update" : "View"}
                  <i className="fa fa-arrow-right"></i>
                </AccessibleLinkContent>
              </a>
            )}
          </APILink>
        )}
      </TaskHeader>
    </Task>
  );
};

Agreement.propTypes = {
  agreement: PropTypes.object,
  index: PropTypes.number,
};

export default Agreement;
