import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import getUserLinks from "data/actions/getUserLinks";

import { BulletList } from "components/ui/Lists";
import Spinner from "components/ui/Spinner";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import WithAPILink from "components/ui/WithAPILink";

const CardSubTitle = styled.h3`
  padding: 0;
  margin: 0 0 10px 0;
`;

export function AdvisingResourcesCard() {
  const dispatch = useDispatch();

  const { csrfToken, advisor, loadState } = useSelector(
    ({
      csrfToken,
      myStatus: { roles: { advisor } = {} },
      apiLinks: { loadState = "pending" },
    }) => ({
      csrfToken,
      advisor,
      loadState,
    })
  );

  useEffect(() => {
    if (csrfToken) {
      dispatch(getUserLinks(csrfToken));
    }
  }, [dispatch, csrfToken]);

  if (!advisor) {
    return null;
  }

  if (loadState === "error") {
    return null;
  }

  const toolLinkIDs = [
    "UC_CC_ACCOMM_HUB_ADVISOR",
    "UC_CX_CLASS_SEARCH",
    "UC_CX_GT_ACTION_CENTER",
    "UC_CX_GT_WORK_CENTER",
    "UC_CX_APPOINTMENT_ADV_MY_APPTS",
    "UC_CX_REPORTING_CENTER",
    "UC_CX_ADV_CRSCAMPENR_APRV",
    "UC_CC_JOB_AIDS",
    "UC_AA_JOB_AIDS",
  ];

  const campusSolutionsLinkIDs = [
    "UC_CX_ADV_TSCRPT_BATCH",
    "UC_CX_APR_RPT",
    "UC_CX_STUDENT_ADVISOR",
    "UC_CX_ARCH_TSCRPT_ADVISOR",
    "UC_CX_ADV_CHG_CRS_LOAD",
    "UC_AA_DEGREE_PLANNER_SEARCH",
    "UC_CX_SERVICE_IND_DATA",
    "UC_CX_AA_MILESTONE",
    "UC_CX_XFER_CREDIT_REPORT",
  ];

  return (
    <Card>
      <CardTitle>
        <h2>Advising Resources</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <>
            <CardSubTitle>Tools</CardSubTitle>
            <BulletList>
              {toolLinkIDs.map((urlID) => (
                <li key={urlID}>
                  <WithAPILink urlID={urlID} />
                </li>
              ))}
            </BulletList>
            <MediumSpacer />
            <CardSubTitle>Campus Solutions Links</CardSubTitle>
            <BulletList>
              {campusSolutionsLinkIDs.map((urlID) => (
                <li key={urlID}>
                  <WithAPILink urlID={urlID} />
                </li>
              ))}
            </BulletList>
          </>
        )}
      </CardBody>
    </Card>
  );
}

AdvisingResourcesCard.displayName = "AdvisingResourcesCard";
