import { gql } from "graphql-request";
export const userAcademicProfile = gql`
  query ($uid: String) {
    user(uid: $uid) {
      awardHonors {
        awardDateFormatted
        termDisplayName
        termId
        termEndDate
        termEndDateFormatted
        typeCode
        typeFormalDescription
      }
      degrees {
        college
        dateAwarded
        description
        designatedEmphases {
          careerDescription
          college
          formalDescription
          typeCategory
          typeCode
        }
        honors {
          formalDescription
        }
        isUndergrad
        majors {
          careerDescription
          college
          formalDescription
          typeCategory
          typeCode
        }
        minors {
          careerDescription
          college
          formalDescription
          typeCategory
          typeCode
        }
      }
      designatedEmphases {
        college
        description
        formalDescription
        subPlans
        typeCategory
        typeCode
        typeDescription
      }
      gpa {
        errored
        role
        cumulativeGPA
        description
      }
      graduation {
        undergraduate {
          activeTermsWithEnrollmentAppointments
          appointmentsInGraduatingTerm
          showGraduationChecklist
          termName
        }
        gradOrLaw {
          expectedGraduationTerms {
            career {
              code
              description
              formalDescription
            }
            expectedGradTermIds
            expectedGradTermNames
            plans
            program
          }
        }
      }
      hasHolds
      ihubPersonServerErrored
      latestCareers
      latestLevels {
        careerCode
        description
      }
      latestRegistrationTerm {
        id
        description
      }
      majors {
        college
        description
        formalDescription
        subPlans
        typeCategory
        typeCode
        typeDescription
      }
      minors {
        college
        description
        formalDescription
        subPlans
        typeCategory
        typeCode
        typeDescription
      }
      plans {
        careerCode
        description
        formalDescription
      }
      termsInAttendance
      units {
        errored
        testingUnits
        totalLawUnits
        totalPreviousCareerCumulativeUnits
        totalPreviousCareerLawUnits
        totalTransferAndTestingUnits
        totalUnits
        totalUnitsAttempted
        totalUnitsForGPA
        totalUnitsPassedNotForGPA
        totalUnitsTakenNotForGPA
        transferUnitsAccepted
      }
    }
  }
`;
