import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import TermRegistrationStatuses from "components/../react/components/_academics/StatusAndHoldsCard/TermRegistrationStatuses";

const NgAdvisorTermRegistrations = () => (
  <ReduxProvider>
    <TermRegistrationStatuses isAdvisor={true} />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "advisorTermRegistrations",
    react2angular(NgAdvisorTermRegistrations)
  );
