import styled from "styled-components";

const TableStyles = styled.table`
  width: 100%;
  border: 0;
  border-spacing: 0;
  margin: 0;

  thead {
    background: none;
  }

  thead tr th {
    text-align: left;
    padding: 0;
    line-height: 1.8;
  }

  tbody tr td {
    border-top: 1px solid var(--silver);
    padding: 0;
    background: white;
    line-height: 1.8;

    &:last-child {
      text-align: right;
    }
  }
`;
TableStyles.displayName = "TableStyles";
export default TableStyles;
