import React from "react";
import styled from "styled-components";
import { LargeSpacer } from "components/ui/VerticalSpacers";
import { ItemGroup } from "./ItemGroup";
import { useFinaidYear } from "components/hooks/useFinaidYear";

export const FinaidProfileBody = () => {
  const {
    message,
    finaidYearId,
    itemGroups: originalItemGroups,
  } = useFinaidYear((year) => year.finaidProfile || {});

  const itemGroups = originalItemGroups.map((group) =>
    group.filter(filterItems(finaidYearId)).map(transformTitles(finaidYearId))
  );

  return (
    <>
      <p>{message}</p>
      <h3 style={{ margin: 0 }}>Financial Aid Profile</h3>
      <LargeSpacer />
      <ItemGroupStyles>
        {itemGroups.map((itemGroup, index) => (
          <ItemGroup key={index} itemGroup={itemGroup} />
        ))}
      </ItemGroupStyles>
    </>
  );
};

FinaidProfileBody.displayName = "FinaidProfileBody";

const ItemGroupStyles = styled.div`
  .item {
    overflow: hidden;

    .term {
      font-weight: bold;
      float: left;
    }

    .termValue {
      float: right;
      text-align: right;
    }

    + .item {
      margin-top: var(--lg);
    }
  }
`;

const filterItems = (finaidYearId) => (item) => {
  if (finaidYearId >= 2025) {
    if (item.title === "Berkeley Parent Contribution") {
      return false;
    }

    if (item.title === "Family Members in College") {
      return false;
    }
  }

  return true;
};

const transformTitles = (finaidYearId) => (item) => {
  if (finaidYearId >= 2025) {
    if (item.title.match("EFC")) {
      return {
        title: item.title
          .replace("EFC", "SAI")
          .replace("Expected Family Contribution", "Student Aid Index"),
        value: item.value && item.value.replace("$ ", ""),
      };
    }
  }

  return item;
};
