import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { gradingLapseDeadlineMessage } from "./helpers";
import APILink from "components/ui/APILink";

export const GradingLapseDeadlineColumns = ({
  academicGuideGradesPolicy,
  deadline,
  displayColumnIndex,
  gradingBasisIsFrozen,
  showSingleColumn,
  showGradingLapseDeadline,
  totalColumns,
}) => {
  const nbsp = String.fromCodePoint(160);
  let columnIndexes;
  let colspan;

  if (totalColumns > 0) {
    columnIndexes = [...Array(totalColumns).keys()];
  } else {
    totalColumns = 0;
    columnIndexes = [0];
  }

  if (showSingleColumn) {
    colspan = totalColumns;
    columnIndexes = [0];
  } else {
    colspan = 1;
  }

  let message = gradingLapseDeadlineMessage({
    deadline,
    gradingBasisIsFrozen,
    showGradingLapseDeadline,
  });

  return (
    <>
      {columnIndexes.map((col) => (
        <td
          key={col}
          data-testid={`grading-lapse-deadline-column-${col}`}
          className="cc-academics-semester-incomplete-notice"
          colSpan={colspan}
        >
          {displayColumnIndex === col && (
            <APILink
              link={academicGuideGradesPolicy}
              text={message}
              showNewWindow={true}
            />
          )}
          {displayColumnIndex !== col && nbsp}
        </td>
      ))}
    </>
  );
};

GradingLapseDeadlineColumns.propTypes = {
  academicGuideGradesPolicy: PropTypes.object,
  columnIndexes: PropTypes.array,
  deadline: PropTypes.string,
  displayColumnIndex: PropTypes.number,
  showSingleColumn: PropTypes.bool,
  gradingBasisIsFrozen: PropTypes.bool,
  showGradingLapseDeadline: PropTypes.bool,
  totalColumns: PropTypes.number,
};

function mapStateToProps({ myAcademics }) {
  const { studentLinks: { academicGuideGradesPolicy = {} } = {} } = myAcademics;

  return { academicGuideGradesPolicy };
}

export default connect(mapStateToProps)(GradingLapseDeadlineColumns);
