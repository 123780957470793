'use strict';

var _ = require('lodash');

angular.module('calcentral.controllers').controller('StudentSuccessController', function(advisingFactory, $routeParams, $scope) {
  $scope.studentSuccess = {
    gpaChart: {
      series: {
        className: 'cc-student-success-color-blue',
      },
      xAxis: {
        floor: 0,
        visible: false,
      },
      yAxis: {
        min: 0,
        max: 4.0,
        visible: false,
      },
    },
    showChart: true,
    isLoading: true,
  };

  $scope.highCharts = {
    dataSeries: [],
  };

  var chartGPATrend = function(termGPAs) {
    var chartData = _.map(termGPAs, 'termGPA');

    // The last element of the data series must also contain custom marker information to show the GPA.
    chartData[chartData.length - 1] = {
      y: chartData[chartData.length - 1],
      dataLabels: {
        color: chartData[chartData.length - 1] >= 2 ? '#2b6281' : '#cf1715',
        enabled: true,
        style: {
          fontSize: '12px',
        },
      },
      marker: {
        enabled: true,
        fillColor:
          chartData[chartData.length - 1] >= 2 ? '#2b6281' : '#cf1715',
        radius: 3,
        symbol: 'circle',
      },
    };
    $scope.highCharts.dataSeries.push(chartData);
  };

  var loadStudentSuccess = function() {
    advisingFactory
      .getStudentSuccess({
        uid: $routeParams.uid,
      })
      .then(function successCallback(response) {
        $scope.studentSuccess.outstandingBalance = _.get(
          response,
          'data.outstandingBalance'
        );
        $scope.studentSuccess.gpa = _.get(response, 'data.gpa');
        $scope.studentSuccess.units = _.get(response, 'data.units');
        parseTermGPA(response);
      })
      .finally(function() {
        $scope.studentSuccess.isLoading = false;
      });
  };

  var parseTermGPA = function(response) {
    var termGPAs = _.get(response, 'data.termGPA');
    var termGPAsWithZero = _.get(response, 'data.termGPAWithZero');
    $scope.studentSuccess.termGPA = _.sortBy(termGPAs, ['termId']);
    $scope.studentSuccess.termGPAWithZero = _.sortBy(termGPAsWithZero, [
      'termId',
    ]);
    if (termGPAs.length >= 2) {
      chartGPATrend(termGPAs);
    } else {
      $scope.studentSuccess.showChart = false;
    }
  };

  loadStudentSuccess();
});
