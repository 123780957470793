import styled from "styled-components";

const ListBullets = styled.div`
  margin: 10px 10px 0 15px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li::before {
      color: var(--hoki);
      content: "\\25A0";
      float: left;
      margin-left: -16px;
      margin-top: -2px;
      padding-right: 8px;
    }
  }
`;

export default ListBullets;
