import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import SignOutButton from "./SignOutButton";

import PopoverMenu from "./PopoverMenu";
import RegistrationStatusItems from "components/../react/components/_header/RegistrationStatusItems";
import HoldsStatusItem from "./HoldsStatusItem";
import FinancesStatusItem from "./FinancesStatusItem";
import StandingsStatusItem from "./StandingsStatusItem";
import PersonalProfileLink from "../Profile/PersonalProfileLink";

const PopoverStyles = styled(PopoverMenu)`
  .student-id {
    background-color: #eee;
    padding: 5px 10px;
  }

  a + .navigation {
    margin-top: 10px;
  }

  .navigation {
    a,
    button {
      border: 0;
      background: white;
      display: block;
      text-align: left;
      width: 100%;
      color: var(--link-color);
      padding: 10px;
      border-bottom: 1px solid #eee;
      transition: background-color 300ms ease-out;

      &:hover {
        background: var(--foam);
        text-decoration: none;
      }
    }
  }
`;

const StatusPopover = ({ togglePopover, node }) => {
  const { campusSolutionsID, isDelegateUser, officialBmailAddress } = useSelector((state) => {
    const {
      myStatus: { campusSolutionsID, isDelegateUser, officialBmailAddress },
    } = state;

    return { campusSolutionsID, isDelegateUser, officialBmailAddress };
  });

  return (
    <PopoverStyles ref={node}>
      {campusSolutionsID && (
        <div className="student-id">
          <strong>Student ID:</strong> {campusSolutionsID}
        </div>
      )}

      <RegistrationStatusItems />
      <HoldsStatusItem onClick={togglePopover} />
      <StandingsStatusItem />
      <FinancesStatusItem />

      <div className="navigation">
        {officialBmailAddress && !isDelegateUser && (
          <Link to="/settings" onClick={togglePopover}>
            CalCentral Settings
          </Link>
        )}
        <PersonalProfileLink />
        <SignOutButton />
      </div>
    </PopoverStyles>
  );
};

StatusPopover.propTypes = {
  togglePopover: PropTypes.func,
  node: PropTypes.any,
};
export default StatusPopover;
