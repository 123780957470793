import React, { useState } from "react";
import PropTypes from "prop-types";

import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import APILink from "components/ui/APILink";
import Category from "../Category";
import CategoryHeader from "./CategoryHeader";
import CategorySection from "./CategorySection";
import Task from "../Task";
import TaskHeader from "../TaskHeader";
import TaskTitle from "../TaskTitle";
import CampusSolutionsIcon from "../Icons/CampusSolutionsIcon";

const Agreements = ({ category, tasks }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Category withBottomBorder={true}>
      <CategoryHeader
        tasks={tasks}
        title={category.title}
        expanded={expanded}
        setExpanded={setExpanded}
        incompleteCount={tasks.length}
      />

      {expanded && (
        <CategorySection items={tasks}>
          {tasks.map((task, index) => {
            return (
              <Task
                key={index}
                index={index}
                task={task}
                type=""
                hasDetail={false}
              >
                <TaskHeader task={task}>
                  <CampusSolutionsIcon />
                  <TaskTitle title={task.title} />

                  {task.url && (
                    <APILink link={task.url}>
                      {(props, showNewWindow) => (
                        <a {...props}>
                          <AccessibleLinkContent showNewWindow={showNewWindow}>
                            View <i className="fa fa-arrow-right"></i>
                          </AccessibleLinkContent>
                        </a>
                      )}
                    </APILink>
                  )}
                </TaskHeader>
              </Task>
            );
          })}
        </CategorySection>
      )}
    </Category>
  );
};

Agreements.propTypes = {
  category: PropTypes.object,
  tasks: PropTypes.array,
};

export default Agreements;
