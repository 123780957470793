import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SpinnerStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @keyframes spin-normal {
    from {
      transform: rotate(0turn) scale(.47);
    }
    to {
      transform: rotate(1turn) scale(.47);
    }
  }

  .message {
    margin-top: 15px;

  }

  .message {
    display none !important;
  }
`;

const AnimatedSpinner = styled.div`
  --offset-even: 12px;
  --offset-odd: 17px;
  --offset-neg-even: -12px;
  --offset-neg-odd: -17px;

  --spinner-1: var(--silver);
  --spinner-2: var(--dusty-gray);
  --spinner-3: var(--mine-shaft);
  --spinner-4: var(--black);

  display: inline-block;
  height: 21px;
  width: 21px;
  position: relative;

  &::before {
    animation: spin-normal 1s infinite steps(8);
    border-radius: 50%;
    box-shadow: 0 var(--offset-neg-odd) var(--spinner-1),
      // Top
      var(--offset-even) var(--offset-neg-even) var(--spinner-1),
      // Top right
      var(--offset-odd) 0 var(--spinner-2),
      // Right
      var(--offset-even) var(--offset-even) var(--spinner-3),
      // Bottom right
      0 var(--offset-odd) var(--spinner-4),
      // Bottom
      var(--offset-neg-even) var(--offset-even) var(--spinner-1),
      // Bottom left
      var(--offset-neg-odd) 0 var(--spinner-1),
      // Left
      var(--offset-neg-even) var(--offset-neg-even) var(--spinner-1); // Top left
    content: "";
    display: inline-block;
    height: 10px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 10px;
  }
`;

export function Spinner({ message }) {
  return (
    <SpinnerStyles>
      <AnimatedSpinner
        data-testid="spinner"
        aria-busy={true}
        aria-live="polite"
      />
      {message && <div className="message">{message}</div>}
    </SpinnerStyles>
  );
}

Spinner.displayName = "Spinner";
Spinner.propTypes = {
  message: PropTypes.string,
};

export default Spinner;
