import React from "react";
import { useSelector } from "react-redux";
import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledInfoContainer,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const ContactInformation = () => {
  const { addresses, emails, phones } = useSelector((state) => {
    const {
      advising: {
        studentData: {
          contacts: {
            feed: { addresses = [], phones = [], emails = [] } = {},
          } = {},
        } = {},
      } = {},
    } = state;

    return {
      addresses,
      emails,
      phones,
    };
  });

  const showCard =
    (addresses && addresses.length > 0) ||
    (emails && emails.length > 0) ||
    (phones && phones.length > 0);

  if (showCard) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>Contact Information</StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {addresses &&
            addresses.map((address, i) => {
              const { type, formattedAddress } = address;
              const [addressLineOne, addressLineTwo] =
                formattedAddress.split("\\n");
              return (
                <StyledInfoContainer key={i}>
                  <strong>{type.description} Address</strong>
                  <span>{addressLineOne}</span>
                  <span>{addressLineTwo}</span>
                </StyledInfoContainer>
              );
            })}
          {phones &&
            phones.map((phone, i) => {
              const { type, number, primary } = phone;

              return (
                <StyledInfoContainer key={i} $isFirst={i === 0}>
                  <span>
                    <strong>{type.description} Phone</strong>
                    {primary && <span> (preferred)</span>}
                  </span>
                  {number}
                </StyledInfoContainer>
              );
            })}

          {emails &&
            emails.map((email, i) => {
              const { type, emailAddress, primary } = email;

              return (
                <StyledInfoContainer key={i} $isFirst={i === 0}>
                  <span>
                    <strong>{type.description} Email</strong>
                    {primary && <span> (preferred)</span>}
                  </span>
                  <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                </StyledInfoContainer>
              );
            })}
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
ContactInformation.displayName = "ContactInformation";
