import React from "react";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

import {
  ColumnOne,
  ColumnTwo,
  StyledHeader,
  StyledRow,
} from "components/cards/advising/StudentSummaryCard/sharedComponents";

export const Career = () => {
  const { latestCareers } = useSelector((state) => {
    const {
      advising: { userId: advisingStudentUid },
    } = state;

    const { advisingStudentAcademicProfile: { latestCareers = [] } = {} } =
      advisingStudentState(state, advisingStudentUid) || {};

    return {
      latestCareers,
    };
  });

  if (latestCareers && latestCareers.length > 0) {
    return (
      <StyledRow>
        <ColumnOne>
          <StyledHeader>
            {latestCareers.length === 1
              ? "Academic Career"
              : "Academic Careers"}
          </StyledHeader>
        </ColumnOne>
        <ColumnTwo>
          {latestCareers.map((career) => (
            <div key={career}>{career}</div>
          ))}
        </ColumnTwo>
      </StyledRow>
    );
  }
  return null;
};
Career.displayName = "Career";
