import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import UserStar from "./UserStar";
import { saveRecentUser } from "utils/requests";
import { useSelector } from "react-redux";

export default function ResultUser({ user, afterToggleSave }) {
  const { csrfToken } = useSelector(
    (state) => {
      const {
        csrfToken,
      } = state || {};

      return { csrfToken };
    }
  );

  const saveResultUser = function(user) {
    saveRecentUser(csrfToken)(user);
    window.location = `/user/overview/${user.ldapUid}`;
  };

  return (
    <ResultItem>
      <div>
        <div>
          <a
            onClick={() => saveResultUser(user)}
          >
            {livedName(user)}
          </a>{" "}
          ({user.studentId})
        </div>
        <div>
          {user.academicPrograms}{" "}
          {user.email && <BmailLink address={user.email} />}
        </div>
      </div>
      <UserStar user={user} afterToggleSave={afterToggleSave} />
    </ResultItem>
  );
}

ResultUser.displayName = "ResultUser";
ResultUser.propTypes = {
  user: PropTypes.object,
  afterToggleSave: PropTypes.func,
};

const ResultItem = styled.li`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--base-border-color);
  padding: var(--tiny-spacing) 0;

  &:first-child {
    border-top: none;
  }
`;

function BmailLink({ address }) {
  return (
    <a
      href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${encodeURIComponent(
        address
      )}`}
    >
      {address}
    </a>
  );
}

BmailLink.displayName = "BmailLink";
BmailLink.propTypes = {
  address: PropTypes.string,
};

function livedName(user) {
  const {
    firstName,
    lastName,
    middleName,
  } = user;

  return [firstName, middleName, lastName].join(
    " "
  );
}
