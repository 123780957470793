import { combineReducers } from "redux";

import academics from "./advising/academics";
import appointments from "./advising/appointments";
import graduateDegreeProgress from "./advising/graduateDegreeProgress";
import statusAndHolds from "./advising/statusAndHolds";
import studentData from "./advising/studentData";
import undergradDegreeProgress from "./advising/undergradDegreeProgress";
import userId from "./advising/userId";
import resources from "./advising/resources";

const AdvisingReducer = combineReducers({
  academics,
  appointments,
  graduateDegreeProgress,
  statusAndHolds,
  studentData,
  undergradDegreeProgress,
  userId,
  resources,
});

export default AdvisingReducer;
