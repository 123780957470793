// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tasksCard___GNb_A{margin-right:15px}.fullWidthContainer___ZDALW{margin:0 -15px -15px}.textContainer___egKsJ{padding:15px}.textContainer___egKsJ p:last-child{margin-bottom:0}.buttonContainer___QzZxq{overflow:hidden;margin-left:auto;margin-right:auto;margin-top:15px}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/TasksCard.module.scss"],"names":[],"mappings":"AAAA,mBACE,iBAAA,CAGF,4BACE,oBAAA,CAGF,uBACE,YAAA,CAEA,oCACE,eAAA,CAIJ,yBACE,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".tasksCard {\n  margin-right: 15px;\n}\n\n.fullWidthContainer {\n  margin: 0 -15px -15px;\n}\n\n.textContainer {\n  padding: 15px;\n\n  p:last-child {\n    margin-bottom: 0;\n  }\n}\n\n.buttonContainer {\n  overflow: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tasksCard": "tasksCard___GNb_A",
	"fullWidthContainer": "fullWidthContainer___ZDALW",
	"textContainer": "textContainer___egKsJ",
	"buttonContainer": "buttonContainer___QzZxq"
};
export default ___CSS_LOADER_EXPORT___;
