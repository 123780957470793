import styled from "styled-components";

export const Card = styled.section`
  background: white;
  margin-top: 15px;
`;

export const CardTitle = styled.header`
  background: #eee;
  font-family: Arial, sans-serif;
  height: 39px;
  padding: 0 15px;

  display: flex;
  align-items: baseline;

  h2 {
    font-weight: normal;
    font-size: 18px;
    margin: 0;
    line-height: 39px;

    span {
      color: #999;
      font-size: 13px;
      text-transform: uppercase;
    }
  }
`;

export const CardBody = styled.div`
  padding: 15px;
`;
