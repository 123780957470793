import styled from "styled-components";

export const StatusCard = styled.div`
  background: var(--white);

  @media screen and (min-width: 768px) {
    width: 635px;
  }
`;

export const StatusTitle = styled.div`
  background: var(--gallery);

  h2 {
    font-family: var(--arial);
    font-size: 25px;
    padding: 10px 35px;
    font-weight: 500;
    margin: 2px 0;
  }
`;

export const StatusBody = styled.div`
  padding: 25px 35px;
  font-size: 18px;

  h3 {
    font-size: 20px;
    margin: 0 0 15px;
  }
`;
