import React from "react";
import { useSelector } from "react-redux";
import { currentUserState } from "utils/currentUserState";
import PropTypes from "prop-types";

const Careers = () => {
  const { careers, isCurrentSummerVisitor } = useSelector((state) => {
    const {
      myStatus: {
        academicRoles: {
          current: { summerVisitor: isCurrentSummerVisitor } = {},
        } = {},
      } = {},
    } = state || {};

    const { academicProfile: { latestCareers: careers = [] } = {} } =
      currentUserState(state) || {};

    return { careers, isCurrentSummerVisitor };
  });

  if (!isCurrentSummerVisitor && careers.length) {
    return (
      <tr data-testid="academic-careers">
        <th>{careers.length === 1 ? "Academic Career" : "Academic Careers"}</th>
        <td>
          {careers.map((career) => (
            <div key={career}>{career}</div>
          ))}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

Careers.displayName = "Careers";
Careers.propTypes = {
  careers: PropTypes.array,
  isCurrentSummerVisitor: PropTypes.bool,
};
export default Careers;
