import React from "react";
import PropTypes from "prop-types";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import FinancialAidArchiveLink from "components/../react/components/_finances/FinancialAidArchiveLink";

const NgFinancialAidArchiveLink = ({ year }) => (
  <ReduxProvider>
    <FinancialAidArchiveLink year={year} />
  </ReduxProvider>
);

NgFinancialAidArchiveLink.propTypes = {
  year: PropTypes.string,
};

angular
  .module("calcentral.react")
  .component(
    "financialAidArchiveLink",
    react2angular(NgFinancialAidArchiveLink)
  );
