import buildDataActions from '../build-data-actions';

import {
  FETCH_BCOURSES_ACTIVITIES_START,
  FETCH_BCOURSES_ACTIVITIES_SUCCESS,
  FETCH_BCOURSES_ACTIVITIES_FAILURE,
} from '../action-types';

const { loadData: fetchBCoursesActivities } = buildDataActions({
  url: '/api/my/tasks/b_courses_activities',
  key: 'myBCoursesActivities',
  start_const: FETCH_BCOURSES_ACTIVITIES_START,
  success_const: FETCH_BCOURSES_ACTIVITIES_SUCCESS,
  failure_const: FETCH_BCOURSES_ACTIVITIES_FAILURE,
});

export { fetchBCoursesActivities };
