import React, { useEffect } from "react";
import { currentUserState } from "utils/currentUserState";
import { useDispatch, useSelector } from "react-redux";

import { fetchAcademicsDiploma } from "data/actions/academics/diplomaActions";
import { fetchAcademicProfile } from "data/slices/users/academicProfile";
import { fetchEnrollments } from "data/slices/users/enrollments";
import { fetchLawAwards } from "data/actions/lawAwardsActions";
import { fetchTransferCredit } from "data/actions/transferCreditActions";
import { fetchUserProfile } from "data/slices/userProfile";
import { isLoading } from "data/utils/statusHelpers";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/../react/components/Spinner";

import AcademicProfileTable from "./AcademicProfile/AcademicProfileTable";
import CollegeAndLevelError from "./AcademicProfile/CollegeAndLevelError";
import ConcurrentEnrollmentMessage from "./AcademicProfile/ConcurrentEnrollmentMessage";
import StatusAsOf from "./AcademicProfile/StatusAsOf";
import NameAndPhoto from "./AcademicProfile/NameAndPhoto";
import Major from "./AcademicProfile/Major";
import Minor from "./AcademicProfile/Minor";
import Emphases from "./AcademicProfile/Emphases";
import Careers from "./AcademicProfile/Careers";
import Levels from "./AcademicProfile/Levels";
import GradLawGraduation from "./AcademicProfile/GradLawGraduation";
import UndergraduateGraduation from "./AcademicProfile/UndergraduateGraduation";
import GPAToggle from "./AcademicProfile/GPAToggle";
import CumulativeUnits from "./AcademicProfile/CumulativeUnits";
import Degrees from "./AcademicProfile/Degrees";
import LawAwards from "./AcademicProfile/LawAwards";
import Diploma from "./AcademicProfile/Diploma.js";

const AcademicProfileCard = () => {
  const { currentUID, dataIsLoading } = useSelector((state) => {
    const {
      academics: { diploma: { isLoading: diplomaIsLoading } = {} },
      currentUID,
      myLawAwards: { isLoading: myLawAwardsIsLoading = false } = {},
      myStatus: { isLoading: myStatusIsLoading = false } = {},
      userProfile: {
        loadState: userProfileLoadState
      } = {},
    } = state || {};

    const {
      academicProfile: { loadState: academicProfileLoadState = "idle" } = {},
      enrollments: { loadState: enrollmentsLoadState } = {},
    } = currentUserState(state) || {};

    const userProfileIsLoading = isLoading(userProfileLoadState);
    const academicProfileIsLoading = isLoading(academicProfileLoadState);
    const enrollmentsIsLoading = isLoading(enrollmentsLoadState);

    const dataIsLoading =
      academicProfileIsLoading ||
      enrollmentsIsLoading ||
      myLawAwardsIsLoading ||
      myStatusIsLoading ||
      diplomaIsLoading ||
      userProfileIsLoading;

    return {
      currentUID,
      dataIsLoading,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUID) {
      dispatch(fetchUserProfile(currentUID));
      dispatch(fetchAcademicProfile(currentUID));
      dispatch(fetchEnrollments(currentUID));
    }

    dispatch(fetchAcademicsDiploma());
    dispatch(fetchTransferCredit());
    dispatch(fetchLawAwards());
  }, [dispatch, currentUID]);

  return (
    <Card>
      <CardTitle>
        <h2>Academic Profile</h2>
      </CardTitle>
      <CardBody>
        {dataIsLoading && <Spinner />}
        {!dataIsLoading && (
          <>
            <CollegeAndLevelError />
            <ConcurrentEnrollmentMessage />
            <StatusAsOf />

            <AcademicProfileTable>
              <tbody>
                <NameAndPhoto />
                <Major />
                <Minor />
                <Emphases />
                <Careers />
                <Levels />
                <UndergraduateGraduation showCheckListLink={true} />
                <GradLawGraduation />
                <CumulativeUnits />
                <GPAToggle />
                <Degrees />
                <LawAwards showLink={true} />
                <Diploma />
              </tbody>
            </AcademicProfileTable>
          </>
        )}
      </CardBody>
    </Card>
  );
};

AcademicProfileCard.displayName = "AcademicProfileCard";
export default AcademicProfileCard;
