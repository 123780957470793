'use strict';

var _ = require('lodash');

/**
 * Settings Menu Serives - provide all the information for the settings menu
 * Previously named the "Profile Menu Service"
 */
angular
  .module('calcentral.services')
  .factory('settingsMenuService', function(apiService, $q) {
    var navigation = [
      {
        label: 'CalCentral Settings',
        categories: [
          {
            id: 'bconnected',
            name: 'bConnected',
            hideWhenActAsModes: ['advisorActingAsUid'],
          },
        ],
      },
    ];

    var defer = function(navigation, callback) {
      var deferred = $q.defer();

      navigation = callback(navigation);

      deferred.resolve(navigation);
      return deferred.promise;
    };

    var filterCategories = function(navigation, callback) {
      return _.map(navigation, function(item) {
        item.categories = callback(item.categories);
        return item;
      });
    };

    var filterActAsInCategory = function(categories) {
      var userProfile = apiService.user.profile;
      return _.filter(categories, function(category) {
        if (!_.get(category, 'hideWhenActAsModes.length')) {
          return true;
        } else {
          return !_.some(category.hideWhenActAsModes, function(
            hideWhenActAsMode
          ) {
            return userProfile[hideWhenActAsMode];
          });
        }
      });
    };

    var filterActAsInNavigation = function(navigation) {
      return filterCategories(navigation, filterActAsInCategory);
    };

    var filterActAs = function(navigation) {
      return defer(navigation, filterActAsInNavigation);
    };

    var filterEmptyInNavigation = function(navigation) {
      return _.filter(navigation, function(item) {
        return !!_.get(item, 'categories.length');
      });
    };

    /*
     * If we remove all the links in a certain section, we need to make sure we
     * don't show the heading
     */
    var filterEmpty = function(navigation) {
      return defer(navigation, filterEmptyInNavigation);
    };

    var filterBConnectedUsers = function(navigation) {
      return defer(navigation, filterBConnectedUserInNavigation);
    };

    var filterBConnectedUserInNavigation = function(navigation) {
      return filterCategories(navigation, filterBConnectedUserInCategory);
    };

    var filterBConnectedUserInCategory = function(categories) {
      var hasBConnectedAccount = !!apiService.user.profile.officialBmailAddress;
      return _.filter(categories, function(category) {
        if (hasBConnectedAccount) {
          return true;
        } else {
          return category.id != 'bconnected';
        }
      });
    };

    var initialNavigation = function() {
      return $q(function(resolve) {
        resolve(navigation);
      });
    };

    var getNavigation = function() {
      return apiService.user
        .fetch()
        .then(initialNavigation)
        .then(filterActAs)
        .then(filterBConnectedUsers)
        .then(filterEmpty);
    };

    return {
      getNavigation: getNavigation,
    };
  });
