import React from "react";
import PropTypes from "prop-types";
import { ErrorCard } from "./ErrorCard";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, info) {
  // Example "componentStack":
  //   in ComponentThatThrows (created by App)
  //   in ErrorBoundary (created by App)
  //   in div (created by App)
  //   in App
  // logErrorToMyService(error, info.componentStack);
  // }

  render() {
    if (this.state.hasError) {
      return <ErrorCard title={this.props.title} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
