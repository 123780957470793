import {
  FETCH_REGISTRATIONS_START,
  FETCH_REGISTRATIONS_SUCCESS,
  FETCH_REGISTRATIONS_FAILURE,
} from "../action-types";

export const fetchRegistrationsStart = () => ({
  type: FETCH_REGISTRATIONS_START,
});

export const fetchRegistrationsSuccess = (registrations) => ({
  type: FETCH_REGISTRATIONS_SUCCESS,
  value: registrations,
});

export const fetchRegistrationsFailure = (error) => ({
  type: FETCH_REGISTRATIONS_FAILURE,
  value: error,
});

export const fetchRegistrations = () => {
  return (dispatch, getState) => {
    const { myRegistrations } = getState();

    if (myRegistrations.loaded || myRegistrations.isLoading) {
      return new Promise((resolve, _reject) => resolve(myRegistrations));
    } else {
      dispatch(fetchRegistrationsStart());

      const url = "/api/my/registrations";
      return fetch(url, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchRegistrationsFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchRegistrationsSuccess(data.feed.student));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchRegistrationsFailure(failure));
          }
        });
    }
  };
};
