import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import LawDegreeAudit from "components/cards/LawDegreeAudit";

const NgLawDegreeAudit = () => (
  <ReduxProvider>
    <LawDegreeAudit />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("lawDegreeAudit", react2angular(NgLawDegreeAudit));
