import { buildUserSlice } from "data/utils/buildSlice";
import { userDataThunk } from "data/utils/thunks";
import { userRestRequest } from "data/utils/requests";
import { routes } from "data/routes";

const key = "courses";
export const slice = buildUserSlice(key);
export const { reducer: courses } = slice;
export const fetchCourses = userDataThunk(key, slice, {
  request: userRestRequest({ url: routes[key] }),
});
