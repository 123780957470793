import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { serviceAlertProps } from "./ServiceAlerts/serviceAlert.module.js";
import fetchServiceAlerts from "data/actions/serviceAlertsActions";

function AlertBar({
  fetchServiceAlerts,
  serviceAlert: { title } = {},
  csrfToken,
}) {
  useEffect(() => {
    if (csrfToken) {
      fetchServiceAlerts(csrfToken);
    }
  }, [csrfToken]);

  if (title) {
    return (
      <AlertBarStyles>
        <strong>CalCentral Update</strong>

        <div>
          <i className="fa fa-warning" /> {title}
        </div>

        <Link to="/calcentral_update">
          <strong>Learn More</strong>
        </Link>
      </AlertBarStyles>
    );
  }

  return null;
}

AlertBar.propTypes = {
  csrfToken: PropTypes.string,
  fetchServiceAlerts: PropTypes.func,
  serviceAlert: PropTypes.shape(serviceAlertProps),
};

const mapDispatchToProps = (dispatch) => ({
  fetchServiceAlerts: (csrfToken) => {
    dispatch(fetchServiceAlerts(csrfToken));
  },
});

const mapStateToProps = ({ csrfToken, serviceAlerts: { data = [] } }) => ({
  serviceAlert: data.filter((s) => s.banner)[0],
  csrfToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertBar);

const AlertBarStyles = styled.div`
  color: var(--dove-gray);
  background: var(--white);
  border: 1px solid var(--silver);
  padding: var(--medium-spacing) var(--large-spacing);

  display: flex;
  gap: var(--large-spacing);

  > div {
    flex: 1;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: var(--small-spacing);
  }

  @media print {
    display: none; // Maybe needs !important
  }
`;
