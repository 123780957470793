import { useEffect } from "react";
import { connect } from "react-redux";

import getMessageCatalog from "data/actions/getMessageCatalog";

function PNPMessageWrapper({ children, csrfToken, fetchData, messages }) {
  useEffect(() => {
    fetchData(csrfToken);
  }, [csrfToken]);

  const { description } =
    messages.find((m) => m.set === "32000" && m.number === "17") || {};

  return children(description);
}

function mapStateToProps({
  config: { csrfToken },
  messageCatalog: { messages = [] } = {},
}) {
  return { csrfToken, messages };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (csrfToken) => dispatch(getMessageCatalog(csrfToken)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PNPMessageWrapper);
