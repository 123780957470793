// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categorySection___z1QnP .tasks___lJMmU{margin-bottom:10px}.categorySection___z1QnP+.categorySection___z1QnP{margin-top:-10px}.beingProcessedDivider___palh2{border-top:1px solid var(--gallery);border-left:4px solid var(--silver);font-size:14px;font-weight:600;padding:22px 30px 16px 10px}.beingProcessedDivider___palh2 .Badge___mdsfG{color:#fff;background-color:var(--silver);padding:2px 5px}", "",{"version":3,"sources":["webpack://./src/react/components/_dashboard/TasksCard/IncompleteTasks/CategorySection.module.scss"],"names":[],"mappings":"AAGE,wCACE,kBAAA,CAIJ,kDACE,gBAAA,CAGF,+BACE,mCAAA,CACA,mCAAA,CACA,cAAA,CACA,eAAA,CACA,2BAAA,CAEA,8CACE,UAAA,CACA,8BAAA,CACA,eAAA","sourcesContent":["@import 'src/react/stylesheets/variables';\n\n.categorySection {\n  .tasks {\n    margin-bottom: 10px;\n  }\n}\n\n.categorySection + .categorySection {\n  margin-top: -10px;\n}\n\n.beingProcessedDivider {\n  border-top: 1px solid var(--gallery);\n  border-left: 4px solid var(--silver);\n  font-size: 14px;\n  font-weight: 600;\n  padding: 22px 30px 16px 10px;\n\n  .Badge {\n    color: white;\n    background-color: var(--silver);\n    padding: 2px 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categorySection": "categorySection___z1QnP",
	"tasks": "tasks___lJMmU",
	"beingProcessedDivider": "beingProcessedDivider___palh2",
	"Badge": "Badge___mdsfG"
};
export default ___CSS_LOADER_EXPORT___;
