import React, { useState } from "react";
import styled from "styled-components";
import { BaseButton } from "components/ui/Buttons";
import { useFeatures } from "components/hooks/useFeatures";
import { useSlice } from "components/hooks/useSlice";

const NameToggleButton = styled(BaseButton)`
  margin-left: var(--small-spacing);
  padding: 3px;

  @media print {
    display: none;
  }
`;

export const TranscriptName = () => {
  // GRLN Transcript Name Toggle
  const legalNameToggleActive = useFeatures(
    (features) => features.CLC_GRLN_SHOW_TRANSCRIPT_ACCESS || false
  );

  const { fullName: livedFullName, legalFullName } = useSlice("userProfile");

  const [showLegalName, setShowLegalName] = useState(false);
  const toggleName = () => {
    setShowLegalName(!showLegalName);
  };

  const displayedName = displayName(
    legalNameToggleActive,
    showLegalName,
    livedFullName,
    legalFullName
  );

  return (
    <>
      {!legalNameToggleActive && (
        <div data-testid="transcript-name">{displayedName}</div>
      )}

      {legalNameToggleActive && (
        <div data-testid="transcript-name">
          <span id="academic-summary-display-name" aria-live="polite">
            {displayedName}
          </span>
          {legalFullName && (
            <NameToggleButton
              aria-controls="academic-summary-display-name"
              onClick={toggleName}
            >
              {showLegalName && "Show Lived Name"}
              {!showLegalName && "Show Legal Name"}
            </NameToggleButton>
          )}
        </div>
      )}
    </>
  );
};

const displayName = (
  legalNameToggleActive,
  showLegalName,
  livedFullName,
  legalFullName
) => {
  if (legalNameToggleActive && showLegalName) {
    return legalFullName;
  } else {
    return livedFullName;
  }
};

TranscriptName.displayName = "TranscriptName";
